import { useState } from 'react';

import { NavLink } from 'react-router-dom';

import { 
  Paper, 
  Box,  
  Dialog, 
  DialogContent, 
  DialogTitle, 
  Typography, 
  MenuList,
  MenuItem,
  ListItemIcon,
  IconButton,
  Divider,
  Button
} from '@mui/material';

import {
  EditOutlined,
  DeveloperBoardOutlined,
  MoneyOutlined,
  DesktopAccessDisabledOutlined,
  DesktopWindowsOutlined,
  VpnKeyOutlined,
  ResetTvOutlined,
  Close,
  WarningAmber,
  AccountBalanceOutlined
} from '@mui/icons-material';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import ConfirmationButton from 'components/ConfirmationButton';

import VirtualAccountCard from 'pages/hatcheries/widget-components/VirtualAccountCard';

import { permissionCheck } from 'permissions';
import { updateUserAccountAdminOps } from 'services/user';
import { setHatcheryPassword } from 'services/hatcheries';

const HatcheryOperations = (props) => {
  
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [currentOp, setCurrentOp] = useState(null);
  const [userData, setUserData] = useState(props.userData);
  const [statusMessage, setStatusMessage] = useState(null);

  const virtualAccount =  props.hatcheryData ?  props.hatcheryData.rzrpayVirtualAccount : null;
  
  const handleHatcheryOps = async (operation) => {
    setCurrentOp(operation);
    
    switch (operation) {
      case 'set-password':
      case 'grant-revoke-access':
      case 'reset-login':
      case 'view-virtual-account':
        setModalOpen(true);
      break;
      default:
      break;
    }
  }
  
  const handleOpConfirmation = async (operation) => {
    let response = null;
    let statusMessage = { status : "success", message : "Successfully updated hatchery details..."};
    
    switch (operation) {
      case 'set-password':
        statusMessage.message = "New Password was generated and sent to hatchery owner's registered email...";
        try {
          response = await setHatcheryPassword({ hatcheryId : props.hatcheryData.id});
        } catch(error) {
          console.log(error);
          statusMessage = { status : "error", message : error.message };
        }
        setStatusMessage(statusMessage);
      break;
      case 'grant-revoke-access':
        statusMessage.message = "Hatchery account is successfully " + (userData.user.blocked ? "unblocked" : "blocked");
        try {
          response = await updateUserAccountAdminOps(userData.id, {blocked : !userData.user.blocked});
          setUserData(response.data);
        } catch(error) {
          console.log(error);
          statusMessage = { status : "error", message : error.message };
        }
        setStatusMessage(statusMessage);
      break;
      case 'reset-login':
        statusMessage.message = "Hatchery login is successfully reset...";
        try {
          response = await updateUserAccountAdminOps(userData.id, {userLoginInfo : []});
          setUserData(response.data);
        } catch(error) {
          console.log(error);
          statusMessage = { status : "error", message : error.message };
        }
        setStatusMessage(statusMessage);
      break;
      default:
      break;
    }
    
    
    // reset modal
    setModalOpen(false);
    setCurrentOp(null)
  }
  
  return (
    <Paper elevation={4} sx={{ p :1, mx : 1 }}>
      <Typography variant="h4" align="center"> Hatchery Management </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
      {
        /*
        permissionCheck('hatchery', 'update') &&
        <Tooltip title = "Edit Hatchery Details">
          <Box component="span" sx={{ m:1, p: 1, border: '1px dashed grey'}}>
            <IconButton color="error" size="large"  component={NavLink} to= {"/aqf/hatcheries/edit/" + props.hatcheryData.id}>
              <EditOutlined fontSize="medium" />
            </IconButton>
          </Box>
        </Tooltip>
        */
      }
      {
        props.hatcheryData.userProfile &&
        <>
          {
            statusMessage &&
            <CustomSnackbar variant={statusMessage.status}
              message={statusMessage.message}
              open={statusMessage.status}
              onClose={() => setStatusMessage(null)}
            />
          }
          <Dialog maxWidth="md" open={modalOpen} aria-labelledby="form-dialog-title">
            {
              ['set-password', 'grant-revoke-access', 'reset-login'].includes(currentOp) === false &&
              <DialogTitle id="responsive-dialog-title" sx={{display : 'flex', justifyContent : 'flex-end'}}>
                <IconButton onClick={() => { setModalOpen(false), setCurrentOp(null)}}><Close /></IconButton>
              </DialogTitle>
            }
            <DialogContent sx={{textAlign:"center"}}> 
            {
              ['set-password', 'grant-revoke-access', 'reset-login'].includes(currentOp) &&
              <>
                <WarningAmber color="warning" sx={{ mr : 2, fontSize : 64, fontWeight : 300 }} />
                <Box>
                  
                  <Typography variant="subtitle1" sx={{ verticalAlign: 'middle',  display: 'inline-flex'}}> 
                    Are you sure you want to proceed with the current operation ?
                  </Typography>
                </Box>
                <Divider light={true} sx={{mt :2 }}/>
                <Box sx={{float : "right", m : 1}}>
                  <Button color="error" onClick={ () => {  setModalOpen(false) }} 
                    size="small" variant="outlined"> 
                    Cancel 
                  </Button>
                </Box>
                <Box sx={{float : "right", m : 1}}>
                  <Button color="success" onClick={async (event) => {handleOpConfirmation(currentOp) }} 
                    size="small" variant="outlined"> 
                    Confirm 
                  </Button>
                </Box>
              </>
            }
            {
                currentOp === 'view-virtual-account' &&
                <VirtualAccountCard hatcheryData={props.hatcheryData} noPaper={true} />
            }
            </DialogContent>
          </Dialog>
          <MenuList>
          {
            props.hatcheryData &&  
            <MenuItem key = {6} 
              onClick = {async e => await handleHatcheryOps('view-virtual-account') }
              divider={true}
            >
              <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
                <AccountBalanceOutlined color="primary" fontSize="medium"/>
              </ListItemIcon>
              <Typography variant="button" sx={{mx : 2}} >View Virtual Account</Typography>
            </MenuItem>
          }
          {
            (permissionCheck('booking', 'view-self') || permissionCheck('booking', 'view-any')) &&
            <MenuItem key = {1} 
              component={NavLink} 
              to="/aqf/bookings/list"
              state={{hatcheryId : props.hatcheryData.id, name : props.hatcheryData.name}}
              divider={true}
            >
              <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
                <DeveloperBoardOutlined color="secondary" fontSize="medium" />
              </ListItemIcon>
              <Typography variant="button" sx={{mx : 2}} >View Bookings</Typography>
            </MenuItem>
          }
          {
            (permissionCheck('transaction', 'view-self') || permissionCheck('transaction', 'view-any')) &&
            <MenuItem key = {2} 
              component={NavLink} 
              to="/aqf/bookings/transactions"
              state={{hatcheryId : props.hatcheryData.id, name : props.hatcheryData.name}}
              divider={true}
            >
              <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
                <MoneyOutlined color="success" fontSize="medium"/>
              </ListItemIcon>
              <Typography variant="button" sx={{mx : 2}} >View Transactions</Typography>
            </MenuItem>
          }
          {
            permissionCheck('hatchery', 'update') &&
            <MenuItem key = {3} 
              onClick = { async e => await handleHatcheryOps('set-password') }
              divider={true}
            >
              <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
                <VpnKeyOutlined color="info" fontSize="medium"/>
              </ListItemIcon>
              <Typography variant="button" sx={{mx : 2}} >Set New Login Password </Typography>
            </MenuItem>
          }
          {
            permissionCheck('user-accounts', 'update') &&
            <MenuItem key = {4} 
              onClick = {async e => await handleHatcheryOps('grant-revoke-access') }
              divider={true}
            >
              <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
                {
                  userData && userData.user.blocked 
                  ? <DesktopWindowsOutlined color = "success" fontSize="medium" /> 
                  : <DesktopAccessDisabledOutlined color="error" fontSize="medium" />
                }
              </ListItemIcon>
              <Typography variant="button" sx={{mx : 2}} >
                {userData && userData.user.blocked ? "Unblock Hatchery Account" : "Block Hatchery Account"}
              </Typography>
            </MenuItem>
         
          }
          {
            permissionCheck('user-accounts', 'update') && (userData && userData.user.blocked === false) && false &&
            <MenuItem key = {5} 
              onClick = {async e => await handleHatcheryOps('reset-login') }
              divider={true}
            >
              <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
                <ResetTvOutlined color="primary" fontSize="medium"/>
              </ListItemIcon>
              <Typography variant="button" sx={{mx : 2}} >Force Logout User</Typography>
            </MenuItem>
          }
        </MenuList>
      </>
      }
    </Paper>
  );
}

export default HatcheryOperations;
