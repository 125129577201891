import { Routes, Route, Navigate } from 'react-router-dom';

import { ProtectedComponent } from 'hoc';

// layout views
import DashboardLayout from 'layout/DashboardLayout';

// Report pages
import AppReportsList from 'pages/app-reports/index';
import ImportAndDispatchReport from 'pages/app-reports/import-dispatch-report';
import SupplierWiseBooking from 'pages/app-reports/supplier-wise-booking';
import ZeroBookingsReport from 'pages/app-reports/zero-bookings';
import FyWiseHatcheriesReport from 'pages/app-reports/fy-wise-hatcheries';

import { permissionCheck } from 'permissions';

const AppReportRoutes = (props) =>  {
  console.log("loading app report routes");
  return (
    <Routes>
      <Route key="aqf-app-reports" path="*"
        element={
          <ProtectedComponent Component={DashboardLayout}  redirectLink='/' authCheck={props.isAuthenticated} /> 
        }
      >
        <Route key="aqf-reports-import-dispatch" path = "list" element = {<AppReportsList />} />
        {
          permissionCheck('app-reports', 'import-dispatch') &&
          <Route key="aqf-reports-import-dispatch" path = "import-dispatch" element = {<ImportAndDispatchReport />} />
        }
        {
          permissionCheck('app-reports', 'supplier-wise-booking') &&
          <Route key="aqf-reports-supplier-wise" path = "supplier-wise-booking" element = {<SupplierWiseBooking />} />
        }
        {
          permissionCheck('app-reports', 'top-hatchery-bookings') &&
          <Route key="aqf-reports-zero-bookings" path = "zero-bookings" element = {<ZeroBookingsReport />} />
        }
        {
          permissionCheck('hatchery', 'view-any') &&
          <Route key="aqf-reports-fy-wise-hatcheries" path = "fy-wise-hatcheries" element = {<FyWiseHatcheriesReport />} />
        }
        <Route key="no-matching-route" path="*" element= {<Navigate to="/not-found" />} />
      </Route>
    </Routes>
  );
};

export default AppReportRoutes;