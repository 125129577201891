import React, { Component } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import {
  Box,
  Paper,
  Grid,
  Typography,
  Hidden,
  Container,
  Menu,
  MenuItem,
  IconButton
} from '@mui/material';

import {
  HelpOutlineOutlined,
  PolicyOutlined,
  FactCheckOutlined,
  HowToRegOutlined
} from '@mui/icons-material';

import MenuIcon  from '@mui/icons-material/Menu';

import {withStyles} from '@mui/styles';
import {withNavigator} from 'hoc';

import Carousel from 'react-material-ui-carousel';

import SignInForm from './components/SignInForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import OtpValidateForm from './components/OtpValidateForm';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

// Component styles
const styles = (theme) => ({
  root: {
    backgroundImage: `url("${process.env.PUBLIC_URL}/static/images/home/bg.jpg")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding : '8px !important',
    margin : '0px !important',
    minHeight : '100vh'
  },
});
 
 class Home extends Component {
  
  static SIGNIN_VIEW = 1;
  static FORGOT_PASSWORD_VIEW = 2;
  static OTP_VALIDATION_VIEW = 3;
  
  constructor (props) {
    super(props);
    this.state = { view : Home.SIGNIN_VIEW, mobileNumber : null, statusMessage : null, anchorEl: null};
  }
  
  handleForgotPassword = async () => {
    await this.setState({view : Home.FORGOT_PASSWORD_VIEW});
  }
  
  handleBackToLogin = async () => {
    await this.setState({view : Home.SIGNIN_VIEW});
  }
  
  handleOtpValidate = async (mobileNumber, statusMessage) => {
    await this.setState({view : Home.OTP_VALIDATION_VIEW, mobileNumber: mobileNumber, statusMessage});
  }
  
  handleResendOTP  = async() => {
    await this.setState({view : Home.FORGOT_PASSWORD_VIEW});
  }
  
  handleMenuClick = (event) => {
    this.setState({ anchorEl : event.currentTarget});
  };
  
  handleMenuClose = () => {
    this.setState({ anchorEl : null});
  };
  
  render () {

    const classes = this.props.classes;
    const props = this.props;
    
    return (<Container className={classes.root} maxWidth={false}>
      <Box sx={{flexGrow:1}} />
      <Hidden lgDown>
        <Grid container px={2} py={1} justifyContent="flex-end" alignItems="center" spacing={1}>
          <Grid item xs={12} sm="auto">
            <Typography variant="h6" color="white" noWrap align="center"  
              sx={{display:'flex', alignItems: 'center',flexWrap: 'wrap', mr:2}}
            >
              <PolicyOutlined sx={{fontSize:28,mx:1}}/>
              <RouterLink to="/main/privacy" style={{color:"white", textDecoration:"none", align:"center"}}> Privacy Policy </RouterLink>
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography variant="h6" noWrap color="white" align="center"
              sx={{display:'flex', alignItems: 'center',flexWrap: 'wrap', mr:2}}
            >
              <FactCheckOutlined sx={{fontSize:28,mx:1}}/>
              <RouterLink to="/main/terms" style={{color:"white", textDecoration:"none", align:"center"}}> Terms & Conditions </RouterLink>
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography variant="h6" noWrap color="white" align="center"
               sx={{display:'flex', alignItems: 'center',flexWrap: 'wrap', mr:2}}
            >
              <HelpOutlineOutlined sx={{fontSize:28,mx:1}} />
              <RouterLink to="/main/support" style={{color:"white", textDecoration:"none"}}> Support </RouterLink>
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography variant="h6" noWrap color="white" align="center"
               sx={{display:'flex', alignItems: 'center',flexWrap: 'wrap'}}
            >
              <HowToRegOutlined sx={{fontSize:28,mx:1}} />
              <RouterLink to="/aqf/hatcheries/main/register" style={{color:"yellow", textDecoration:"none"}}>
               Register Hatchery
              </RouterLink>
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <IconButton
          color="inherit"
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenuClick}
          sx={{float:"right"}}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: 256,
              maxWidth: 256,
            },
          }}
          sx={{float:"right"}}
        >
          <MenuItem key={1} onClick={this.handleClose}>
            <PolicyOutlined sx={{fontSize:28,mx:1}}/>
            <RouterLink to="/main/privacy" style={{textDecoration:"none", align:"center"}}> Privacy Policy </RouterLink>
          </MenuItem>
          <MenuItem key={2} onClick={this.handleClose}>
            <FactCheckOutlined sx={{fontSize:28,mx:1}}/>
            <RouterLink to="/main/terms" style={{textDecoration:"none", align:"center"}}> Terms & Conditions </RouterLink>
          </MenuItem>
          <MenuItem key={3} onClick={this.handleClose}>
            <HelpOutlineOutlined sx={{fontSize:28,mx:1}} />
            <RouterLink to="/main/support" style={{textDecoration:"none", align:"center"}}> Support </RouterLink>
          </MenuItem>
          <MenuItem key={4} onClick={this.handleClose}>
            <HowToRegOutlined sx={{fontSize:28,mx:1}} />
            <RouterLink to="/aqf/hatcheries/main/register" style={{textDecoration:"none", align:"center"}}>
              Register Hatchery
            </RouterLink>
          </MenuItem>
        </Menu>
      </Hidden>
      {
        this.state.statusMessage &&
        <CustomSnackbar variant={this.state.statusMessage.status}
          message={this.state.statusMessage.message}
          open={this.state.statusMessage.status}
          onClose={async () => await this.setState({ statusMessage: null })}
        />
      }
    
      <Grid container justifyContent="center" alignItems="flex-start" sx={{m : 1, p:1}}>
        <Grid item xs={12} sm={2} md={3} align="center">
        <img src={process.env.PUBLIC_URL + '/static/images/home/mpeda-logo.png'} alt = "MPEDA logo" height="140px"/>          
         </Grid>
        <Grid item xs={12} sm={6} md={5} align="center">
          <Typography variant="h1" style={{color:"yellow"}}> 
              Rajiv Gandhi Centre for Aquaculture
            </Typography>
            <Typography variant="h1" style={{color:"yellow"}}> 
              Aquatic Quarantine Facility
            </Typography>
            <Typography variant="body1" color="white" my={1}> 
              MPEDA. Ministry of Commerce & Industry, Govt. of India <br/>
              TNFDC Hatchery Complex, Kapaleeswarar Nagar, Beach Road <br/>
              Neelankarai-600115, Chennai, Tamil Nadu
            </Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={3} align="center">
        <img src={process.env.PUBLIC_URL + '/static/images/home/rgca-logo.png'} alt = "RGCA logo" height="140px"/>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="flex-start" direction="row" spacing={2}>
        <Grid item xs={12} sm={6}>
          <Carousel indicators={false} animation="slide" interval={4000}>
            <img src={process.env.PUBLIC_URL + '/static/images/home/slider_1.jpg'} height="450" width="100%"/>
            <img src={process.env.PUBLIC_URL + '/static/images/home/slider_2.jpg'} height="450" width="100%"/>
            <img src={process.env.PUBLIC_URL + '/static/images/home/slider_3.jpeg'} height="450" width="100%"/>
            <img src={process.env.PUBLIC_URL + '/static/images/home/slider_4.jpg'} height="450" width="100%"/>
            <img src={process.env.PUBLIC_URL + '/static/images/home/slider_5.jpg'} height="450" width="100%"/>
          </Carousel>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
        {
          this.state.view === Home.SIGNIN_VIEW &&
          <SignInForm handleForgotPassword = { this.handleForgotPassword} {...props} />
        }
        {
          this.state.view === Home.FORGOT_PASSWORD_VIEW &&
          <ForgotPasswordForm 
            handleOtpValidate = { this.handleOtpValidate} 
            handleBackToLogin = { this.handleBackToLogin}
          />
        }
        {
          this.state.view === Home.OTP_VALIDATION_VIEW &&
          <OtpValidateForm mobileNumber = {this.state.mobileNumber} handleResendOTP={this.handleResendOTP}/>
        }
        </Grid>
      </Grid>
    </Container>);
  };
}

export default withStyles(styles)(Home);