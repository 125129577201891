import React, { Component } from "react";

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";
import { 
  fetchAddnChargesTotalCount, 
  fetchAddnChargesData, 
} from 'services/additional-charges';

import addnChargesFields from './additional-charges.fields';

import { permissionCheck } from 'permissions';

export default class AddnChargesList extends Component {

  constructor(props) {
    super(props);
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Additional Charges',
      addButtonText : 'New Additional Charge',
      addUrl : null, //permissionCheck('transaction', 'create-additional-charges') ? '/aqf/bookings/additional-charges/add': null,
      searchEnable : true,              
      searchPlaceholder : (this.currentUser.user.role.name === 'Hatchery Owner') 
        ? 'Search By Booking ID ' : 'Search By Booking ID / Hatchery Name',
      
      // table content props
      columns : addnChargesFields,
      // editUrlPath : permissionCheck('transaction', 'update-additional-charges') ? `/aqf/bookings/additional-charges/edit/` : null,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchAddnChargesDataWrapper,
      fetchCountApi : this.fetchAddnChargesCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'status',
        value : 'ALL',
        options : [
          {id : "ALL", value : "-- All  --"},
          {id : "PendingPayment", value : "Pending Payment"},
          {id : "RetryPayment", value : "Retry Payment"},
          {id : "Paid", value : "Paid"},
          {id : "Rejected", value : "Rejected"},
        ]
      }],
      // export to xl, pdf props
      exportEnable : false,
    };
  }

  fetchAddnChargesCountWrapper = async(filters, searchValue) => {
    let hatcheryId = 'ALL';
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    }
    
    let response = await fetchAddnChargesTotalCount({
      status : filters['status'],
      type : filters['type'],
      hatcheryId: hatcheryId,
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue))) ? searchValue : null,
    });

    return response;
  }    

  fetchAddnChargesDataWrapper = async (page, perPage, filters, searchValue) => {
    let hatcheryId = 'ALL';
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    }
    
    let response = await fetchAddnChargesData({
      page: page,
      per_page: perPage,
      hatcheryId: hatcheryId,
      status : filters['status'],
      type : filters['type'],
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue))) ? searchValue : null,
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
        entry.hatcheryId = entry.hatcheryId ? entry.hatcheryId.name : '';
        if (entry.bookingId) {
          entry.fromDate = entry.bookingId.fromDate;
          entry.arrivedDate = entry.bookingId.stockStatus ? entry.bookingId.stockStatus.arrivalReport : null;
          entry.bookingId = entry.bookingId.oldId ? `${entry.bookingId.oldId} / ${entry.bookingId.id}` : entry.bookingId.id;
        }
      });
    }
    
    return response;
  }

  async componentDidMount() {
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}
