import React, { Component } from 'react';

import { withLocationParams } from 'hoc';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import NavigateBack from 'components/NavigateBack';

// Material components/
import { Paper, Typography } from '@mui/material';
import BookingScheduleForm from './form-components/BookingScheduleForm';

import { findBookingByDateRange } from 'services/booking-schedules';

class EditBookingSchedule extends Component {
  
  state = { 
    currentValues : null,
    statusMessage : {status: "warning", message: "Please wait !! current schedule is being fetched..."}
  };
  
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const availableDate =  new Date(this.props.locationParams.date);
    
    const year = availableDate.getFullYear();
    const month =  availableDate.getMonth();
    
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    try {
      let response = await findBookingByDateRange({
        start : firstDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
        end : lastDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
        speciesType : this.props.locationParams.speciesType,
        all : true
      });
      this.setState({currentValues : response.data, statusMessage: null});
    } catch (error) {
      console.log(error);
      this.setState({statusMessage : {status: "error", message : error.message }});
    }
  }

  render() {
    return (
      <>
        <NavigateBack/>
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          {
            this.state.statusMessage &&
            <CustomSnackbar variant={this.state.statusMessage.status}
              message={this.state.statusMessage.message}
              open={this.state.statusMessage.status}
              onClose={async () => await this.setState({ statusMessage: null })}
            />
          }
          
          <Typography variant="h4"> 
          { 
            'Edit Booking Schedule' + 
            (this.state.currentValues ? ` - ${this.state.currentValues.month}, ${this.state.currentValues.year}` : '')
          }
          </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          {
            this.state.currentValues &&
            <BookingScheduleForm currentValues = {this.state.currentValues} />
          }
        </Paper>
      </>
    );
  }
}

export default withLocationParams(EditBookingSchedule);
