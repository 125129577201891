import React, { Component } from "react";

import {withLocation} from 'hoc';

// Externals
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

// Material helpers
import { withStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";

// Material components
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Paper, 
  Stack,
  Link,
  IconButton
} from "@mui/material";

import {
  RemoveRedEyeOutlined,
  CreateOutlined
} from '@mui/icons-material';


import ImageOrDocument from 'components/ImageOrDocument';

// Component styles
import styles from "./styles";

const RenderTableCell = props => {
  return (
  <TableCell key={props.keyval} className={props.checkBox ? props.classes.tableCellExtraPadding : props.classes.tableCell}>
    {props.checkBox && (
      <Checkbox
        checked={props.checked}
        disabled={props.selection === false}
        color="primary"
        onChange={event => props.handleSelectOne(event, props.id)}
        value={true}
      />
    )}   
    {
      props.type === "plaintext" && (
      <Typography
        key={props.keyval}
        className={props.classes.nameText}
        variant={props.checkBox ? "body1" : "body2"}
      >
      {(typeof(props.value) == 'undefined' || props.value == null)
        ?  "N/A"
        : props.value.length > 100
          ? props.value.slice(0, 100) + "...."
          : props.value
      }
      </Typography>
    )}

    {
      props.type === "html" && (
      <div>
      {ReactHtmlParser(
        props.value && props.value.length > 100
        ? props.value.slice(0, 100) + "...."
        : props.value
      )}
      </div>
    )}
    {
      props.type === "currency" && 
      (<p dangerouslySetInnerHTML={{__html : (props.value ? "&#x20B9; " + props.value : 'N/A')}} />)
    }
    {
      (props.type === "image" || props.type === "file") &&
      <ImageOrDocument document={props.value} minView />
    }
    
    {
      props.type === "url" && (
      <Button
        color="primary"
        size="small"
        variant="outlined"
        disabled={props.value ? false : true}
        href={props.value ? `http://${props.value}` : "#"}
        target="_blank"
      >
        Visit Link
      </Button>
    )}
  </TableCell>
  );
};

class DTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: props.selectedItems,
      //rowsPerPage: props.perpage,
      //page: props.page - 1,
      data: props.data
    };
  }

  render() {
    const {
      classes,
      columns,
      data,
      rowsPerPage,
      handlePageChange,
      handleSelectAll,
      handleSelectOne,
      handleRowsPerPageChange,
      page,
      dataTotal,
      selectedItems
    } = this.props;

    let pageChanger = 0;
    
    // initialize the signal back
    //
    return (
      <Paper elevation = {4} className={classes.root}>
      <Table sx={{ overflow : 'auto !important'}}>
        <TableHead>
          <TableRow>
            <TableCell key='sno#' className={this.props.multiDeleteEnabled ? classes.tableHeadCellExtraPadding : classes.tableHeadCell}>
              {
                this.props.multiDeleteEnabled &&
                <Checkbox
                  checked={selectedItems && selectedItems.length === data.length}
                  color="primary"
                  indeterminate={
                    selectedItems &&
                    selectedItems.length > 0 &&
                    selectedItems.length < data.length
                  }
                  onChange={handleSelectAll}
                />
              }
              S.No
            </TableCell>
            {
              columns.map((col, i) => {
                // skip columns which are not relevant to the current action
                let actions = col.actions;
                if (!Array.isArray(actions)) {
                  actions = actions.split(/,| /);
                }
                  if (!actions.find(action => action === "index")) {
                  return null;
                }
                // render checkbox alongside first column field.
                return (
                  <TableCell key={i}
                    className={this.props.multiDeleteEnabled ? classes.tableHeadCellExtraPadding : classes.tableHeadCell}>
                  { col.label }
                  </TableCell>
                );
              })
            }
            {
              (this.props.itemUrlPath || this.props.editUrlPath) &&
              <TableCell key="#actions" 
                className={this.props.multiDeleteEnabled ? classes.tableHeadCellExtraPadding : classes.tableHeadCell}
              > 
                Actions 
              </TableCell>
            }
          </TableRow>
          </TableHead>
        <TableBody>
        {
          data.slice(pageChanger, rowsPerPage).map((item, pos) => {
            
            // check if item can be selected. Default, can be selected.
            let selection = item["selection"] !== undefined ? item["selection"] : true;
  
            // compose item url. each item may have a url with different query params
            // or a common url path + item.id
            //
            const itemUrl = item["url"] !== undefined ? item["url"] : `${this.props.itemUrlPath}${item.id}`;
            const editUrl = item["editUrl"] !== undefined ? item["editUrl"] : `${this.props.editUrlPath}${item.id}`;
            return (
              <TableRow
                className={classes.tableRow}
                hover
                key={`trow-${pos+1}`}
                selected={selectedItems && selectedItems.indexOf(item[columns[0].id]) !== -1 }
              >
                <RenderTableCell
                  key={`snodata-${pos+1}`}
                  keyval={`snodata-${pos+1}`}
                  classes={classes}
                  selection={selection}
                  checkBox={this.props.multiDeleteEnabled}
                  checked={ selectedItems && selectedItems.indexOf(item["id"]) !== -1}
                  handleSelectOne={e => {
                     handleSelectOne( e, item["id"]);
                  }}
                  type="plaintext"
                  value={((page - 1)* rowsPerPage) + pos+1}
                />
                {
                  columns.map((col, i) => {
                    // skip columns which are not relevant to the current action

                    let actions = col.actions;
                    if (!Array.isArray(actions)) {
                      actions = actions.split(/,| /);
                    }

                    if (!actions.find(action => action === "index")) {
                      return null; // fix warnings
                    }
                      switch (col.type) {
                      case "string": //plain text
                      case "number":
                      case "float":
                      case "email":
                      case "url":
                      case "text":
                      case "address":
                        return (
                        <RenderTableCell
                          key={`${pos}-${i}`}
                          keyval={`${pos}-${i}`}
                          itemid={item["id"]}
                          classes={classes}
                          type={col.type === "url"
                            ? "url"
                            : col.type === "password"
                            ? "password"
                            : col.type === "text"
                            ? "html"
                            : "plaintext"
                          }
                          value={item[col.name]}
                        />
                        );
                      case "select":
                        return (
                        <RenderTableCell
                          key={`${pos}-${i}`}
                          keyval={`${pos}-${i}`}
                          itemid={item["id"]}
                          classes={classes}
                          type="plaintext"
                          value={col.selectOptions && col.selectOptions[item[col.name]] ? col.selectOptions[item[col.name]] : item[col.name]}
                        />
                        );
                      case "currency":
                        return (
                          <RenderTableCell
                            key={`${pos}-${i}`}
                            keyval={`${pos}-${i}`}
                            itemid={item["id"]}
                            classes={classes}
                            type="currency"
                            value={item[col.name]}
                          />
                        );
                      case "boolean":
                        return (
                        <RenderTableCell
                          key={`${pos}-${i}`}
                          keyval={`${pos}-${i}`}
                          itemid={item["id"]}
                          classes={classes}
                          type={"plaintext"}
                          value={
                          col.valueStrings === undefined
                            ? item[col.name]
                            ? "True"
                            : "False"
                            : col.valueStrings[item[col.name] === undefined ? 1 : item[col.name] ? 1 : 0]
                          }
                        />
                        );
                      case "image": // image urls
                        return (
                        <RenderTableCell
                          key={`${pos}-${i}`}
                          keyval={`${pos}-${i}`}
                          itemid={item["id"]}
                          classes={classes}
                          type="image"
                          value={item[col.name]}
                        />
                        );
                       case "file": // file urls
                        return (
                        <RenderTableCell
                          key={`${pos}-${i}`}
                          keyval={`${pos}-${i}`}
                          itemid={item["id"]}
                          classes={classes}
                          type="file"
                          value={item[col.name]}
                        />
                        );
                      case "date":
                        return (
                        <RenderTableCell
                          key={`${pos}-${i}`}
                          keyval={`${pos}-${i}`}
                          itemid={item["id"]}
                          classes={classes}
                          type="plaintext"
                          value={
                          [null, undefined].includes(item[col.name]) ? "N/A" : moment(item[col.name]).format('DD/MM/YYYY')
                          }
                        />
                        );
                      case "datetime":
                          return (
                            <RenderTableCell
                              key={`${pos}-${i}`}
                              keyval={`${pos}-${i}`}
                              itemid={item["id"]}
                              classes={classes}
                              type="plaintext"
                              value={
                              [null, undefined].includes(item[col.name]) ? "N/A" : moment(item[col.name]).format("DD/MM/YYYY HH:mm:ss")
                              }
                            />
                            );
                      default:
                        break;
                      }
                      return null; //fix warnings
                    })
                }
                {
                  (this.props.itemUrlPath || this.props.editUrlPath) &&
                  <TableCell key="#actionlinks" className={this.props.checkBox ? classes.tableCellExtraPadding : classes.tableCell}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    {
                      this.props.itemUrlPath &&
                      <>
                      {
                        typeof this.props.itemUrlPath === 'function' ?
                        <IconButton aria-label="view" size="small"
                          onClick = {e => this.props.itemUrlPath(pos, item) }
                        >
                          <RemoveRedEyeOutlined color="primary" fontSize="small" />
                        </IconButton> : 
                        <IconButton aria-label="view" size="small" 
                          component={NavLink} 
                          to={itemUrl}
                          state={{
                             entry : item, 
                             page : page, 
                             rowsPerPage : rowsPerPage, 
                             toolbarState : this.props.toolbarState,
                             from : this.props.location.pathname
                          }}
                        >
                          <RemoveRedEyeOutlined color="primary" fontSize="small" />
                        </IconButton>
                      }
                      </>
                    }
                    {
                      this.props.editUrlPath && item["editable"] !== false &&
                      <>
                      {
                        typeof this.props.editUrlPath === 'function' ?
                        <IconButton aria-label="view" size="small" 
                          onClick = {e => this.props.editUrlPath(pos, item) }
                        >
                          <CreateOutlined color="warning" fontSize="small" />
                        </IconButton> : 
                        <IconButton aria-label="view" size="small" 
                          component={typeof this.props.editUrlPath === 'function' ? Button : NavLink} 
                          to={editUrl}
                          state={{
                             entry : item, 
                             page : page, 
                             rowsPerPage : rowsPerPage, 
                             toolbarState : this.props.toolbarState,
                             from : this.props.location.pathname
                          }}
                        >
                          <CreateOutlined color="warning" fontSize="small" />
                        </IconButton>
                      }
                      </>
                    }
                    </Stack>
                  </TableCell>
                }
              </TableRow>);
          })
        }
        {
          this.props.showColumnTotals &&
          <TableRow>
            <TableCell className={classes.tableCell}><Typography variant="h5"> Total : </Typography></TableCell>
            {
              columns.map((col, i) => {
                // skip columns which are not relevant to the current action
                let actions = col.actions;
                if (!Array.isArray(actions)) {
                  actions = actions.split(/,| /);
                }
                if (!actions.find(action => action === "index")) {
                  return null; // fix warnings
                }
                
                return (
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h5">{col.columnTotal ? data.reduce((t, c) => t + c[col.name], 0) : '--'}</Typography>
                  </TableCell>
                );
              })
            }
          </TableRow>
        }
        </TableBody>
      </Table>

      {
        (dataTotal > rowsPerPage) &&
        <TablePagination
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          component="div"
          count={dataTotal}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page - 1}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 50, 100]}
          showFirstButton={true}
          showLastButton={true}
        />
      }
      </Paper>
    );
  }
}

export default withStyles(styles)(withLocation(DTable));