import React, { Component } from 'react';

import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

// services
import {fetchBookingsTotalCount} from 'services/bookings';

import { AuthStore, HatcheryStore } from 'store';


export default class TotalBookings extends Component {

  state = { count : '...' }
  
  async componentDidMount () {
    let currentUser = AuthStore.state ? AuthStore.state.currentUser : null;
    let hatcheryId = 'ALL';
    
    if (currentUser.user.role.name === 'Hatchery Owner') {
      if (HatcheryStore.state && HatcheryStore.state.currentHatchery) {
        hatcheryId = HatcheryStore.state.currentHatchery.id;
      } else {
        // failed to get current hatchery of the owner
        this.setState({count : 0});
        return;
      }
    }
   
    try {
      let response = await fetchBookingsTotalCount({
        hatcheryId : hatcheryId, 
        status : 'ALL', 
        supplierId : 'ALL',
        speciesType : 'ALL',
        shipmentStatus : 'ALL'
      });
      this.setState({count : response.data})
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {
    return (
      <SummaryWidget 
        label = {'Total Bookings'} 
        count = {this.state.count} 
        icon = {<DeveloperBoardIcon />}
        url = { '/aqf/bookings/list' }
      />
    );
  }
}
