import React, { Component } from 'react';

import DomainIcon from '@mui/icons-material/Domain';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

// services
import {fetchHatcheriesTotalCount} from 'services/hatcheries';

export default class TotalHatcheries extends Component {

  state = { count : '...' }
  
  async componentDidMount () {
    try {
      let response = await fetchHatcheriesTotalCount({status : 'ALL', expired : 'ALL', state : 'ALL', speciesType : 'ALL'});
      this.setState({count : response.data})
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {
    return (
      <SummaryWidget 
        label = {'Total Hatcheries'} 
        count = {this.state.count} 
        icon = {<DomainIcon />}
        url = { '/aqf/hatcheries/list' }
      />
    );
  }
}