import React, { Component } from 'react';

import { withLocationParams } from 'hoc';

// Material components/
import { Paper, Typography } from '@mui/material';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import NavigateBack from 'components/NavigateBack';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { hatcheryFields } from './hatchery.fields';

import { fetchSettingsByType } from 'services/common';

import { fetchAllSpecies } from 'services/species-details';

import { fetchHatcheryById, updateHatcheryDetails } from 'services/hatcheries';

import { checkUniqueAttributes } from 'services/common';

class EditHatchery extends Component {
  
  state = {
    hatcheryData : null, 
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."}
  };
  
  constructor(props) {
    super(props);
    
    hatcheryFields[4].selectOptions = {};
    hatcheryFields[4].callback = this.handleStateSelected;
    hatcheryFields[5].selectOptions = {};
  }
  
  handlePreFormSubmit = async(formData) => {
    
    let statusMessage = { status: "success", message: "" };
    
    let validation = {isValid : true};
    
    if (formData.permittedSpeciesId !== this.state.hatcheryData.permittedSpeciesId &&
        this.state.hatcheryData.userProfile) {
      return { status: "error", message: "Permitted species type for a hatchery cannot be updated once registered" };
    }
      
    // check uniqueness of the attributes
    try {
      let uniqueParams = [{
        'attribute' : 'hatcheryName',
        'value' : formData.name,
        'currentId' : this.state.hatcheryData.id
      }];

      let result = await checkUniqueAttributes(uniqueParams);
      
      if (result.data.hatcheryName && result.data.hatcheryName.unique === false) {
        validation.isValid = false;
        validation['name'] = { isInvalid : true, message : result.data.message };
      }
    } catch(error) {
      statusMessage =  { status: "error", message: error.message };
    }
  
    if (validation.isValid === false) {
      statusMessage =  { 
        status: "error", 
        message: 'One or more fields in the form data doesn\'t meet uniqueness criteria', 
        data : validation 
      };
    }
    
    return statusMessage;
    
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated supplier details..." };
    
    // todo API calls
    try {
      let response = await updateHatcheryDetails(this.state.hatcheryData.id, params);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  handleStateSelected = async (name, value, formData, asyncFn) => {
    let response = await fetchSettingsByType({type : 'DISTRICT', name : 'DISTRICT', parent : value});
    hatcheryFields[5].selectOptions = {};
    if (response.status === 200) {
      response.data.map (district => {
        hatcheryFields[5].selectOptions[district.id] = district.value
      });
    }
    
    return { fields : hatcheryFields, values : null };
  }
  
  async componentDidMount() {
    let hatcheryData = null;
    let statusMessage = this.state.statusMessage;
    try {
      let response = await fetchHatcheryById(this.props.locationParams.id);
      hatcheryData = response.data;
      hatcheryData.permittedSpeciesId = (hatcheryData.permittedSpeciesId || {}).id;
      
      // fetch current license fields
      if (hatcheryData.license) {
        hatcheryData.CAARegistrationNumber = hatcheryData.license.CAARegistrationNumber;
        hatcheryData.validFrom = hatcheryData.license.validFrom;
        hatcheryData.validTo = hatcheryData.license.validTo;
        hatcheryData.issuingDate = hatcheryData.license.issuingDate;
        hatcheryData.noOfBroodsStockPerYear = hatcheryData.license.noOfBroodsStockPerYear;
      }
      
      statusMessage = {status: "success", message: "Fetched hatchery details successfully !"}  
    } catch (error) {
      console.log (error);
      statusMessage = { status : "error", message : error.message };
    }
    // fetch all states data
    let response = await fetchSettingsByType({type : 'STATE', name : 'STATE'});
    if (response.status === 200) {
      response.data.map ( state => {
        hatcheryFields[4].selectOptions[state.id] = state.value
      });
      
      // fetch all species
      response = await fetchAllSpecies({page : 1, per_page : -1, sort : 'id:ASC'});
      response.data.map ( species => {
        hatcheryFields[10].selectOptions[species.id] = species.name;
      });
    }
    
    // fetch district data if state was set
    if (hatcheryData && hatcheryData.state) {
        await this.handleStateSelected('state', hatcheryData.state);
    }
    
    await this.setState({hatcheryData, statusMessage});
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Update Hatchery Details </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          {
            this.state.statusMessage &&
            <CustomSnackbar variant={this.state.statusMessage.status}
              message={this.state.statusMessage.message}
              open={this.state.statusMessage.status}
              onClose={async () => await this.setState({ statusMessage: null })}
            />
          }
          {
            this.state.hatcheryData &&
            <DynamicForm
              formFields={hatcheryFields}
              action="update"
              handleSubmit={this.handleFormSubmit}
              preFormSubmit={this.handlePreFormSubmit}
              buttonText="Update Hatchery Details"
              buttonWidth= "full"
              columns = {3}
              currentValues={this.state.hatcheryData}
              redirect={null}
              confirmation={"Are you sure you want to save the details ? "}
            />
          }
        </Paper>
      </>
    );
  }
}

export default withLocationParams(EditHatchery);
