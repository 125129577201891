import React, { Component } from 'react';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

// Shared services
import { fetchUnregisteredHatcheries, fetchUnregisteredHatcheryById } from 'services/hatcheries';

export default class CAARegistrationForm extends Component {

  state = { isLoading : true };
  
  constructor(props) {
    super(props);
    

    this.formFields = [
      {
        name: 'hatcheryId',
        label: 'Select CAA Registration No.',
        type: 'select',
        selectOptions : {},
        properties: [{required:true}],
      }
    ];

  }
  
  async componentDidMount() {
    try {
      let response = await fetchUnregisteredHatcheries({page: 1, per_page: 100});
      response.data.map( p => {
        if (p.license) {
          this.formFields[0].selectOptions[p.id] = `${p.license.CAARegistrationNumber} - ${p.name}`;
        } else {
          this.formFields[0].selectOptions[p.id] = `${p.name}`;
        }
      });
      this.setState({isLoading : false});
    } catch (error) {
      console.log(error);
    }
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully fetched unregistered hatchery details...." };
    try {
      const response = await fetchUnregisteredHatcheryById(params.hatcheryId);
      if (this.props.handleHatcheryDetails) {
        this.props.handleHatcheryDetails(response.data);
      }
    } catch (error) {
      console.log(error);
      statusMessage = {status : "error", message : error.message};
    }
    
    return statusMessage;
  }


  render() {
    return (
      <DynamicForm
        formFields={this.formFields}
        handleSubmit={this.handleFormSubmit}
        buttonText="Fetch Hatchery Details"
        buttonWidth= "full"
        columns = {1}
        redirect={null}
      />
    );
  }
}