import React, { Component } from 'react';

import { Paper, Grid, Box, Button } from '@mui/material';

import WebcamImgCapture from 'components/WebcamImgCapture';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import { bookingFields } from '../bookings.fields';

import { HatcheryStore } from 'store';

import { fetchAllSuppliers } from 'services/suppliers';
import { 
  fetchRequiredCubicles, fetchBookingLimits, 
  filterBookingFormFieldsBySpeciesType 
} from 'services/bookings';
import { faceRecognitionMatch } from 'services/common';

export default class BookingForm extends React.Component {
  
  state = {
    suppliers : false,
    statusMessage : null,
    isSubmitting : false,
    startCapture : false,
    capturedImage : null,
    fyLimitData : this.props.fyLimitData,
  }
  
  constructor(props) {
    super(props);
    
    let currentYear = (new Date()).getFullYear();
    
    // deep copy form fields    
    this.formFields = [];
    bookingFields.map (f => {
      let field = { ...f};
      
      field.properties = [];
      
      // deep copy properties
      f.properties.map ( p => field.properties.push({...p}));
      
      // deep copy select options
      if (f.selectOptions) {
        field.selectOptions = {...f.selectOptions};
      }
      
      // update hatchery name
      if (f.name === 'hatcheryName' && HatcheryStore.state.currentHatchery) {
        field.default = HatcheryStore.state.currentHatchery.name;
      }
      
      if (f.name === 'selectFyear') {
        field.selectOptions[currentYear] = `${currentYear}-${currentYear + 1}`;
        field.selectOptions[currentYear + 1] = `${currentYear + 1}-${currentYear + 2}`;
        field.callback = this.handleFyearChange;
      }
      
      // update fyBalance label
      if (f.name === 'fyBalance' && this.props.fyLimitData) {
        field.label = `Available Balance(FY:${response.data.fyear})`;
        field.default = this.props.fyLimitData.fyStockBalance;
      }
      
      // update CAA license number
      if (f.name === 'CAARegistrationNumber' && this.props.fyLimitData) {
        field.label = `CAA Registration Number(FY:${response.data.fyear})`;
        field.default = this.props.fyLimitData.license ? this.props.fyLimitData.license.CAARegistrationNumber : '';
      }
      
      // update user profile select options
      if (f.name === 'ownerOrAuthPerson' && HatcheryStore.state.currentHatchery) {
        let userProfile = HatcheryStore.state.currentHatchery.userProfile;
        if (userProfile.picture) {
          field.selectOptions[userProfile.picture] = userProfile.name;
        }
        if (userProfile.authPerson1 && userProfile.authPerson1.picture) {
          field.selectOptions[userProfile.authPerson1.picture] = userProfile.authPerson1.name;
        }
        if (userProfile.authPerson2 && userProfile.authPerson2.picture) {
          field.selectOptions[userProfile.authPerson2.picture] = userProfile.authPerson2.name;
        }
        field.callback = this.faceProfileSelected;
      }
      
      // cubicle calculation callback
      if (f.name === 'bioMassPerStock' || f.name === 'totalNumberOfStock') {
        field.callback = this.fetchRequiredCubiclesData;
      }

      this.formFields.push(field);
    });
    
    this.formFields = filterBookingFormFieldsBySpeciesType('booking', 
      HatcheryStore.state.currentHatchery.permittedSpeciesId.type, 
      this.formFields);
    
    this.selectedProfile = null;
    this.debounceCubicleNum = null;
    this.faceProfileVerified = false;
  }
  
  async componentDidMount () {
    if (HatcheryStore.state.currentHatchery) {
      try {
        // fetch and update list of suppliers for the species
        let response = await fetchAllSuppliers({
           speciesType : HatcheryStore.state.currentHatchery.permittedSpeciesId.id,
           status : true,
           per_page : -1,
           page : 1
        });
        
        response.data.map( supplier => {
          this.formFields[6].selectOptions[supplier.id] = supplier.name;
        });
        
        this.setState({suppliers : true });
        
      } catch(error) {
        console.log(error);
      }
    } 
  }
  
  handleFyearChange = async(name, value, formData, asyncFn) => {
    
    const currentHatchery = HatcheryStore.state.currentHatchery;
    
    this.setState({
      statusMessage : { status : "warning", message : "Fetching hatchery import balance for the selected FY..."} 
    });
    
    try {
      const response = await fetchBookingLimits(currentHatchery.id, value, null);
      this.setState({
        statusMessage : { status : "success", message : "Successfully fetched hatchery import balance for the selected FY.." },
        fyLimitData : {
          fyStockLimit : response.data.fyStockLimit,
          fyStockImported : response.data.fyStockImported,
          fyStockBalance : response.data.fyStockBalance,
          fyear : response.data.fyear,
          license : response.data.license
        }
      });
      
      this.formFields.map ( field => {
        // update fyBalance label
        if (field.name === 'fyBalance') {
          field.label = `Available Balance(FY:${response.data.fyear})`;
          field.default = formData['fyBalance'] = response.data.fyStockBalance;
        }
        
        // update CAA license number
        if (field.name === 'CAARegistrationNumber') {
          field.label = `CAA Registration Number(FY:${response.data.fyear})`;
          field.default = formData['CAARegistrationNumber'] = response.data.license ? response.data.license.CAARegistrationNumber : '';
        }
        
        return field;
        
      });
      
    } catch (error) {
      console.log(error);
      this.setState({
        statusMessage : {status : "error", message : error.message},
        fyLimitData : null
      });
    }
    return { fields : this.formFields, values : formData}
  }
  
  handlePreFormSubmit = async (formData) => {
    
    await this.setState({isSubmitting : true});
    
    const currentHatchery = HatcheryStore.state.currentHatchery;

    // check if number of cubicles exceeded max allowed cubicles for the species
    if (currentHatchery.permittedSpeciesId.maxAllowedCubicles > 0 &&
      parseInt(formData.numCubicles) > currentHatchery.permittedSpeciesId.maxAllowedCubicles) {
      return { 
        status : "error", 
        message : "Required No. of cubicles exceeded maximum allowed cubicles per booking limit",
      }
    }

    // check if number of cubicles is within the allowed monthly and quaterly limits
    if (currentHatchery.permittedSpeciesId.monthlyCubicleLimit > 0 &&
      currentHatchery.monthlyBookings && currentHatchery.monthlyBookings.cubicles && 
      (currentHatchery.monthlyBookings.cubicles + parseInt(formData.numCubicles)) > currentHatchery.permittedSpeciesId.monthlyCubicleLimit
    ) {
      return { 
        status : "error", 
        message : "Required No. of cubicles exceeded monthly cubicles limit, Cannot proceed with cubicle bookings",
      }
    }

    if (currentHatchery.permittedSpeciesId.quarterlyCubicleLimit > 0 &&
      currentHatchery.quarterlyBookings && currentHatchery.quarterlyBookings.cubicles && 
      (currentHatchery.quarterlyBookings.cubicles + parseInt(formData.numCubicles)) > currentHatchery.permittedSpeciesId.quarterlyCubicleLimit
    ) {
      return { 
        status : "error", 
        message : "Required No. of cubicles exceeded quaterly cubicles limit, Cannot proceed with cubicle bookings",
      }
    }
    
    // some more validations here before save
    if (!this.state.capturedImage) {
      return { status: "error", message: "Please capture image for facial recognition" };
    }
    
    if (!this.faceProfileVerified) {
      return { status: "error", message: "Face verification was not successful, please verify again !!" };
    }
    return { status : "success" };
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (formData) => {
    let statusMessage = { status: "success", message: "Successfully saved booking details...." };
    formData.status = 'Blocked';
    formData.totalNumberOfStock = formData.totalNumberOfStock ? parseInt(formData.totalNumberOfStock) : 0;
    if (HatcheryStore.state.currentHatchery) {
      formData.hatcheryId = HatcheryStore.state.currentHatchery.id;
      formData.speciesType = HatcheryStore.state.currentHatchery.permittedSpeciesId['id'];
    }
    if (this.props.handleFormSubmit) {
      this.props.handleFormSubmit(this.state.fyLimitData, formData);  
    }
    return statusMessage;
  }
  
  faceProfileSelected = async (name, value, formData, asyncFn) => {
    
    this.selectedProfile = value;
    
    if (value && this.state.capturedImage) {
      // check face recognition
      try {
        let statusMessage = await faceRecognitionMatch(this.state.capturedImage, value);
        if (statusMessage.status === "error") {
          this.setState({statusMessage});
        } else {
          this.faceProfileVerified = true;
        }
      } catch(error) {
        this.setState({statusMessage : {status : "error", message : error.message}});
      }
    }
    
    // no changes to dynamic form fields or values
    return { fields : null, values : null}
  }
  
  handleCapturePhoto = async (name, value) => {
    this.setState({capturedImage : value});
    if (value && this.selectedProfile) {
      // check face recognition
      try {
        let statusMessage = await faceRecognitionMatch(value, this.selectedProfile);
        if (statusMessage.status === "error") {
          this.setState({statusMessage});
        } else {
          this.faceProfileVerified = true;
        }
      } catch(error) {
        this.setState({statusMessage : {status : "error", message : error.message}});
      }
    }
    
  }
  
  fetchRequiredCubiclesData = async (name, value, formData, asyncFn) => {
    let bioMassPerStock = formData.bioMassPerStock ? parseInt(formData.bioMassPerStock) : 0;
    let totalNumberOfStock = formData.totalNumberOfStock ? parseInt(formData.totalNumberOfStock) : 0;
    
    formData.totalBioMass = Math.ceil((bioMassPerStock * totalNumberOfStock) / 1000);
    
    if (HatcheryStore.state.currentHatchery.permittedSpeciesId.type === 'PPL') {
      bioMassPerStock = 1;
    } else {
      
      
    }
    
    if (this.debounceCubicleNum) {
      clearTimeout(this.debounceCubicleNum);
      this.debounceCubicleNum = null;
      formData.numCubicles = '';
      formData.cubicleFee = '';
      formData.incenerationFee = '';
      formData.gst = '';
      formData.pgCharges = '';
      formData.totalPrice = '';
    }
    
    if (
      (HatcheryStore.state.currentHatchery.permittedSpeciesId.type === 'PPL' || bioMassPerStock) && 
      (totalNumberOfStock)
    ) {
      
      this.setState({
        statusMessage : { 
          status : "warning", 
          message : "Please wait, calculating number of cubicles required and pricing..."
        }
      });
      
      this.asyncFieldUpdates = asyncFn;
      
      this.debounceCubicleNum = setTimeout(async () => {
        try {
          let response = await fetchRequiredCubicles(bioMassPerStock, totalNumberOfStock);
          await asyncFn({
            'numCubicles' : response.data.cubicleRequired,
            'cubicleFee' : response.data.cubiclePrice,
            'incenerationFee' : response.data.incenerationFee,
            'gst': response.data.gst,
            'pgCharges' : response.data.pgCharges,
            'totalPrice' : response.data.totalAmount,
          });
          this.setState({statusMessage : null});
        } catch (error) {
          console.log(error);
          this.setState({statusMessage : { status : "error", message : error.message}});
        }
      }, 3000);
    }
    
    return { fields : null, values : formData};
  }
  
  componentWillUnmount() {
    if (this.debounceCubicleNum) {
      clearTimeout(this.debounceCubicleNum);
    }      
  }
  
  render() {
    
    return (<Paper elevation={4} sx={{p:1, mt : 2}}>
      <Grid container justifyContent="flex-start" alignItems="flex-start" direction="row">
        <Grid item xs={12} sm={9}>
          {
            this.state.statusMessage &&
            <CustomSnackbar variant={this.state.statusMessage.status}
              message={this.state.statusMessage.message}
              open={this.state.statusMessage.status}
              onClose={async () => await this.setState({ statusMessage: null })}
            />
          }
          
          <DynamicForm
            formFields={this.formFields}
            action={this.props.currentValues ? "update" : "create"}
            handleSubmit={this.handleFormSubmit}
            preFormSubmit={this.handlePreFormSubmit}
            buttonText="Save Booking Details"
            buttonWidth= "full"
            columns = {3}
            redirect={null}
            currentValues = {this.props.currentValues}
            confirmation={"Are you sure you want to save the details ? "}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box sx={{mt:2}}>
          {
            !this.state.capturedImage && this.state.startCapture ?
            (<WebcamImgCapture name="owner" capturePhoto={this.handleCapturePhoto} />) :
            (<Grid container justify="center" direction="column" alignItems="center">
              <Grid item> 
                <img src={this.state.capturedImage ? this.state.capturedImage : 
                process.env.PUBLIC_URL + '/static/images/misc/dummy.png'} height="128"/>
              </Grid>
              <Grid item>
                <Button size="small" variant="outlined" color="primary"
                  onClick={ async () => await this.setState({capturedImage : null, startCapture : true})}
                > Start Photo Capture </Button>
              </Grid>
            </Grid>)
          }
          </Box>
        </Grid>
      </Grid>
    </Paper>);
  }
}
