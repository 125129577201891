import validator from 'validator';
import { customValidator } from './validationRules';

export default class FormValidator {

  constructor(validations, inputFields) {
    // validations is an array of validation rules specific to a form
    this.validations = validations;
    this.requiredFields = [];
    
    // get the list of required fields
    inputFields.map( f => {
      let isRequired = f.properties && f.properties.length > 0 &&
        f.properties.findIndex(p => p.required !== undefined && (p.required === true || p.required === 'true')) >= 0;
      
      if(isRequired) this.requiredFields.push(f.name);
    });
  }

  validate(state) {
    // start out assuming valid
    let validation = this.valid();
    
    // for each validation rule
    this.validations.map(rule => {
      // if the field hasn't already been marked invalid by an earlier rule
      if (!validation[rule.field].isInvalid) {
      
        // determine the field value, the method to invoke and optional args from
        // the rule definition
        let field_value;
        
        if (['isFileSizeMb', 'isFileType'].includes(rule.method)) {
          // no-op, file validations are handled separately in single rule validators
          return;
        } else if (rule.method === 'isJSON' || typeof state[rule.field] === 'object') {
          field_value = JSON.stringify(state[rule.field]);
        } else {
          field_value = state[rule.field] === undefined || state[rule.field] === null ? '' : state[rule.field].toString();
        }
        
        // skip validation if field is not required and there is no value entered
        if (this.requiredFields.includes(rule.field) === false && field_value.length === 0) {
          console.log("returning for " + rule.field + " Val : " + field_value);
          return;
        }
        
        const args = rule.args || [];
        const validation_method = validator[rule.method] === undefined ?
                      customValidator[rule.method] :
                      validator[rule.method]
        
        // call the validation_method with the current field value as the first
        // argument, any additional arguments. If the result doesn't match the 
        // rule.validWhen property, then modify the validation object for the 
        // field and set the isValid field to false
        // 
        if(validation_method(field_value, ...args) !== rule.validWhen) {
          validation[rule.field] = { isInvalid: true, message: rule.message }
          validation.isValid = false;
        }
      }
      return;
    });
    
    return validation;
  }

  validateSingleRule(field, rulename, value) {
    // start out assuming valid
    let validation = { isValid: true };
    validation[field] = { isInvalid: false, message: '' };
    
    let rule = this.validations.find(r => (r.field === field && r.method === rulename));
    
    if (rule && !validation[rule.field].isInvalid) {

      // determine the field value, the method to invoke and optional args from
      // the rule definition
      // console.log (rule);
      const field_value = value.toString();
      const args = rule.args || [];
      const validation_method = validator[rule.method] === undefined ?
      customValidator[rule.method] :
      validator[rule.method]

      // call the validation_method with the current field value as the first
      // argument, any additional arguments, and the whole state as a final
      // argument.  If the result doesn't match the rule.validWhen property,
      // then modify the validation object for the field and set the isValid
      // field to false
      if (validation_method(field_value, ...args, value) !== rule.validWhen) {
        validation[rule.field] = { isInvalid: true, message: rule.message }
        validation.isValid = false;
      }
    }
    return validation;
  }

  valid() {
    const validation = {}

    this.validations.map(rule => (
      validation[rule.field] = { isInvalid: false, message: '' }
    ));

    return { isValid: true, ...validation };
  }
}
