import React, { Component } from 'react';

import MoneyOutlined from '@mui/icons-material/MoneyOutlined';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

import { HatcheryStore } from 'store';
import { fetchTransactionsData } from 'services/transactions';

export default class HatcheryPendingPaymentsCard extends Component {
  
  state = { count : 0 };
  
  async componentDidMount () {
    
    const hatcheryData = HatcheryStore.state.currentHatchery;
    
    try {
      let response = await fetchTransactionsData({
        hatcheryId : hatcheryData.id,
        status : 'ApprovalPending',
        additionalId : 'ALL',
        page : 1,
        per_page : 100
      });
      
      let count = 0;
      response.data.map( t => {
        count += t.amount;
      });
      
      this.setState({ count });
      
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const hatcheryData = HatcheryStore.state.currentHatchery;
    return (
      <SummaryWidget 
        label = {<div dangerouslySetInnerHTML={{__html : 'Pending Payment Dues (&#x20B9;)'}} />} 
        count = { this.state.count + '/-'}
        icon = {<MoneyOutlined />}
        url = { '/aqf/bookings/transactions'}
      />
    );
  }
}
