import {
  // Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell  
} from '@mui/material';

import theme from 'theme';

import ImageOrDocument from 'components/ImageOrDocument';

const currentValue = (field, hatcheryData) => {
  if (!hatcheryData.updateRequestDetails.currentValues) return null;
  else return hatcheryData.updateRequestDetails.currentValues[field];
};

const updatedValue = (field, hatcheryData) => {
  if (hatcheryData.updateRequestDetails[field]) return hatcheryData.updateRequestDetails[field];
  else return null;
};

const LicenseDetails = (props) => {
  /*return (
    <TableContainer sx={{ p: 2 }}>
      <Table>
        <TableHead>
        <TableRow tabIndex={-1} key={0} sx={{backgroundColor: theme.palette.secondary.main, color:"#fff"}}>
          <TableCell key={'header-col-0'}  align="left" sx={{color: "#FFF"}}> Field Name </TableCell>
          <TableCell key={'header-col-1'}  align="left" sx={{color: "#FFF"}}> Current Value </TableCell>
          <TableCell key={'header-col-2'}  align="left" sx={{color: "#FFF"}}> Updated Value </TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover role="checkbox" tabIndex={-1} key={1}>
            <TableCell key={'row-1-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>CAA Registration No. </p></TableCell>
            <TableCell key={'row-1-col-1'} align="left">{currentValue('CAARegistrationNumber', props.hatcheryData)}</TableCell>
            <TableCell key={'row-1-col-2'} align="left">{updatedValue('CAARegistrationNumber', props.hatcheryData)}</TableCell>
          </TableRow>
          <TableRow hover role="checkbox" tabIndex={-1} key={2}>
            <TableCell key={'row-2-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>License Issue Date</p></TableCell>
            <TableCell key={'row-2-col-1'} align="left">{currentValue('issuingDate', props.hatcheryData)}</TableCell>
            <TableCell key={'row-2-col-2'} align="left">{updatedValue('issuingDate', props.hatcheryData)}</TableCell>
          </TableRow>
          <TableRow hover role="checkbox" tabIndex={-1} key={3}>
            <TableCell key={'row-3-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Valid From</p></TableCell>
            <TableCell key={'row-3-col-1'} align="left">{currentValue('validFrom', props.hatcheryData)}</TableCell>
            <TableCell key={'row-3-col-2'} align="left">{updatedValue('validFrom', props.hatcheryData)}</TableCell>
          </TableRow>
          <TableRow hover role="checkbox" tabIndex={-1} key={4}>
            <TableCell key={'row-4-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Valid Till</p></TableCell>
            <TableCell key={'row-4-col-1'} align="left">{currentValue('validTo', props.hatcheryData)}</TableCell>
            <TableCell key={'row-4-col-2'} align="left">{updatedValue('validTo', props.hatcheryData)}</TableCell>
          </TableRow>
          <TableRow hover role="checkbox" tabIndex={-1} key={5}>
            <TableCell key={'row-4-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Import Limit (Yr)</p></TableCell>
            <TableCell key={'row-4-col-1'} align="left">{currentValue('noOfBroodsStockPerYear', props.hatcheryData)}</TableCell>
            <TableCell key={'row-4-col-2'} align="left">{updatedValue('noOfBroodsStockPerYear', props.hatcheryData)}</TableCell>
          </TableRow>
          <TableRow hover role="checkbox" tabIndex={-1} key={6}>
            <TableCell key={'row-5-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Document</p></TableCell>
            <TableCell key={'row-5-col-1'} align="left">
              <ImageOrDocument document={currentValue('document', props.hatcheryData)} minView align="flex-start"/>
            </TableCell>
            <TableCell key={'row-5-col-2'} align="left">
              <ImageOrDocument document={updatedValue('document', props.hatcheryData)} minView align="flex-start"/>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );*/
  
  return (<ImageOrDocument document={updatedValue('document', props.hatcheryData)} align="center"/>);
};

export default LicenseDetails;