import { 
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell  
} from '@mui/material';

import theme from 'theme';

import ImageOrDocument from 'components/ImageOrDocument';

const updatedValue = (field, authPerson) => {
  if (authPerson.updateRequestDetails[field]) return authPerson.updateRequestDetails[field];
  else return authPerson[field];
};

const BankDetails = (props) => {
  return (<TableContainer sx={{ p:2 }}>
    <Table size="small">
      <TableHead>
      <TableRow tabIndex={-1} key={0} sx={{backgroundColor: theme.palette.secondary.main, color:"#fff"}}>
        <TableCell key={'header-col-0'}  align="left" sx={{color: "#FFF"}}> Field Name </TableCell>
        <TableCell key={'header-col-1'}  align="left" sx={{color: "#FFF"}}> Current Value </TableCell>
        <TableCell key={'header-col-2'}  align="left" sx={{color: "#FFF"}}> Updated Value </TableCell>
      </TableRow>
      </TableHead>
      <TableBody>
       <TableRow hover role="checkbox" tabIndex={-1} key={1}>
        <TableCell key={'row-1-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Account Name</p></TableCell>
        <TableCell key={'row-1-col-1'} align="left">{props.bankDetails.accountName}</TableCell>
        <TableCell key={'row-1-col-2'} align="left">{updatedValue('accountName', props.bankDetails)}</TableCell>
      </TableRow>
      <TableRow hover role="checkbox" tabIndex={-1} key={2}>
        <TableCell key={'row-2-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Account Number</p></TableCell>
        <TableCell key={'row-2-col-1'} align="left">{props.bankDetails.accountNumber}</TableCell>
        <TableCell key={'row-2-col-2'} align="left">{updatedValue('accountNumber', props.bankDetails)}</TableCell>
      </TableRow>
      <TableRow hover role="checkbox" tabIndex={-1} key={3}>
        <TableCell key={'row-3-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Account Type</p></TableCell>
        <TableCell key={'row-3-col-1'} align="left">{props.bankDetails.accountType}</TableCell>
        <TableCell key={'row-3-col-2'} align="left">{updatedValue('accountType', props.bankDetails)}</TableCell>
      </TableRow>
      <TableRow hover role="checkbox" tabIndex={-1} key={4}>
        <TableCell key={'row-4-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Bank Name</p></TableCell>
        <TableCell key={'row-4-col-1'} align="left">{props.bankDetails.bankName}</TableCell>
        <TableCell key={'row-4-col-2'} align="left">{updatedValue('bankName', props.bankDetails)}</TableCell>
      </TableRow>
      <TableRow hover role="checkbox" tabIndex={-1} key={5}>
        <TableCell key={'row-5-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>IFSC Code</p></TableCell>
        <TableCell key={'row-5-col-1'} align="left">{props.bankDetails.IFSC}</TableCell>
        <TableCell key={'row-5-col-2'} align="left">{updatedValue('IFSC', props.bankDetails)}</TableCell>
      </TableRow>
      <TableRow hover role="checkbox" tabIndex={-1} key={6}>
        <TableCell key={'row-5-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Proof Document</p></TableCell>
        <TableCell key={'row-5-col-1'}>
          <ImageOrDocument document={props.bankDetails.bankAccountDetailProof} minView align="flex-start"/>
        </TableCell>
        <TableCell key={'row-5-col-2'}>
          <ImageOrDocument document={updatedValue('bankAccountDetailProof', props.bankDetails)} minView align="flex-start"/>
        </TableCell>
      </TableRow>
      </TableBody>
    </Table>
  </TableContainer>);
};

export default BankDetails;