import React, { Component } from 'react';
import { withLocationParams, withPrint } from 'hoc';
import { Navigate } from 'react-router-dom';

import { Paper, Grid, Typography, Tabs, Tab, Button, Box } from '@mui/material';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

// import TableView Component
import DTable from "components/DataTables/DTable";

// import single entry view component
import EntryGridView from 'components/EntryGridView';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import NavigateBack from 'components/NavigateBack';

// import booking ops component
import BookingOperations from './view-components/BookingOperations';
import BookingDocuments from './view-components/BookingDocuments';
import BookingStockStatus from './view-components/BookingStockStatus';

// import services
import { 
  fetchBookingById, fetchCubicleStocking, 
  filterBookingFormFieldsBySpeciesType, filterBookingDataBySpeciesType 
} from 'services/bookings';
import { fetchTransactionsTotalCount,  fetchTransactionsData } from 'services/transactions';
import { fetchSampleCollection } from 'services/sample-collection';

import transactionFields from 'pages/transactions/transactions.fields';
import  { 
  bookingFields, 
  shipmentFields, 
  authPersonFields, 
  cubicleStockingFields, 
  bookingInvoiceFields 
} from './bookings.fields';

import { sampleCollectionFields } from 'pages/sample-collection/sample-collection.fields';

import supplierFields from '../suppliers/supplier.fields';

// import permissions for role based ops
import { permissionCheck } from 'permissions';

class ViewBooking extends Component {

  constructor(props) {
    super(props);
    
    this.sampleCollectionFields = Array.from(sampleCollectionFields);
    this.sampleCollectionFields.splice(1,2);
    
    // copy list of columns
    this.transactionFields = Array.from(transactionFields);
    this.transactionFields.splice(2,1);
  }
  
  state = { 
    bookingData : null, 
    stockingData : null,
    sampleCollectionData : null,
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."},
    activeTab : "Supplier Details",
    summaryTab : "Booking Summary",
    printView : false
  };
  
  refreshBookingData = async (latestData) => {
    let statusMessage = this.state.statusMessage;
    let bookingData = {}, stockingData = null, sampleCollectionData = null;
    
    if (latestData) {
      this.setState({bookingData : latestData});
      return;
    }
    
    try {
      let response = await fetchBookingById(this.props.locationParams.id);
      bookingData = response.data;
      
      // initialize display fields used in single entry view
      bookingData.hatcheryName = bookingData.hatcheryId.name;
      bookingData.hatcheryAddress = bookingData.hatcheryId.address;
      
      if (bookingData.hatcheryId.license) {
        bookingData.CAARegistrationNumber = bookingData.hatcheryId.license.CAARegistrationNumber;
        bookingData.validFrom = bookingData.hatcheryId.license.validFrom;
        bookingData.validTo = bookingData.hatcheryId.license.validTo;
      }
      
      bookingData.supplierDetails = bookingData.supplierId;
      bookingData.supplierId =  bookingData.supplierId.name;
      bookingData.numCubicles = bookingData.cubicleBookingScheduleIds && bookingData.cubicleBookingScheduleIds.length
        ? bookingData.cubicleBookingScheduleIds.length 
        : bookingData.cubicleDetails ? bookingData.cubicleDetails.length : 0;
      bookingData.cubicleNumber = bookingData.cubicleDetails ? bookingData.cubicleDetails.map( x => x.cubicleName).join(',') : '';
      
      // initialize shipment details and noc data
      if (bookingData.shipmentDetail.updateRequestDetails) {
        bookingData.shipmentDetail = bookingData.shipmentDetail.updateRequestDetails;
      }
        
      if (bookingData.shipmentDetail) {
        if (!bookingData.shipmentDetail.document) {
          bookingData.shipmentDetail.document = 
            bookingData.documents && bookingData.documents.updateRequestDetails && false
            ? bookingData.documents.updateRequestDetails.shipmentDocument 
            : bookingData.documents ? bookingData.documents.shipmentDocument : null;
        }
        if (!bookingData.shipmentDetail.nocCertificate) {
          bookingData.shipmentDetail.nocCertificate = 
            bookingData.documents && bookingData.documents.updateRequestDetails 
            ? bookingData.documents.updateRequestDetails.nocCertificate 
            : bookingData.documents ? bookingData.documents.nocCertificate : null;
        }
      }
      bookingData.shipmentDetail.shipmentApproval = bookingData.shipmentApproval;
      bookingData.shipmentDetail.shipmentRejectReason = bookingData.shipmentRejectReason;
      
      // update transaction types
      if (bookingData && bookingData.transactions) {
        bookingData.transactions.map( t => t.type = t.additionalBookingChargesId ? "Additional Charge" : "Booking");
      }
      
      // update pricing info
      bookingData.cubicleFee = `${bookingData.cubicleFee}/-`;
      bookingData.gst = `${bookingData.gst}/-`;
      bookingData.incenerationFee = `${bookingData.incenerationFee}/-`;
      bookingData.totalPrice = `${bookingData.totalPrice}/-`;

      // update species data from supplier
      bookingData.supplierDetails.speciesName = bookingData.speciesType.name;
      
      // fetch stocking data
      if (permissionCheck('booking', 'view-cubicle-stocking')) {
        response = await fetchCubicleStocking({
           bookingId : this.props.locationParams.id,
           page : 1,
           per_page : 100
        });
        
        if (response.data[0] && response.data[0].bulkData) {
          stockingData = [];
          response.data[0].bulkData.map ( c => {
            c.bookingId = this.props.locationParams.id;
            c.cubicleId = c.name;
            stockingData.push(c);
          });
        }
      }
      
      // fetch sample collections 
      if (permissionCheck('booking', 'view-sample-collection-storage')) {
        response = await fetchSampleCollection({
           bookingId : this.props.locationParams.id,
           hatcheryId : 'ALL',
           speciesType : 'ALL',
           page : 1,
           per_page : 100
        });
        
        if (response.data[0] && response.data[0].bulkData) {
          sampleCollectionData = [];
          response.data[0].bulkData.map ( c => {
            c.bookingId = this.props.locationParams.id;
            sampleCollectionData.push(c);
          }); 
        }
      }        
      
      statusMessage = {status: "success", message: "Fetched latest booking details successfully !"};
      
      await this.setState({bookingData, statusMessage, stockingData, sampleCollectionData});
      
    } catch (error) {
      console.log (error);
      if (bookingData) {
        statusMessage = {status: "success", message: "Fetched latest booking details successfully !"};
        this.setState({bookingData, statusMessage});
      } else {
         statusMessage = { status : "error", message : error.message, code : error.status };
         this.setState({statusMessage});
      }
    }
  }
  
  async componentDidMount() {
    this.refreshBookingData(null);
  }
  
  handlePrint = () => {
    this.setState({printView : true});
  }
  
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.printView) {
      this.props.handlePrint();
      setTimeout (() => this.setState({printView : false}), 500);
    }
  }
  
  render() {
    
    if (this.state.statusMessage && [404, 403].includes(this.state.statusMessage.code)) {
      return (<Navigate to="/not-found" />);
    }
    
    let bookingSummary = this.state.bookingData ? {...this.state.bookingData} : null;
    if (bookingSummary) {
      bookingSummary = filterBookingDataBySpeciesType(bookingSummary.speciesType.type, bookingSummary);
      bookingSummary.id = bookingSummary.oldId ? `${bookingSummary.oldId} / ${bookingSummary.id}` : bookingSummary.id;
      bookingSummary.speciesType = bookingSummary.speciesType.name;
    }
    
    return (<>
      {
        this.props.locationParams && this.state.statusMessage &&
        <CustomSnackbar variant={this.state.statusMessage.status}
          message={this.state.statusMessage.message}
          open={this.state.statusMessage.status}
          onClose={async () => await this.setState({ statusMessage: null })}
        />
      }
      {
        this.state.printView === false ?
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          {
            this.state.bookingData && this.state.bookingData.status === 'Confirmed' &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', px:1, py : 1}} component="span">
              <Button
                variant="outlined" 
                color="warning" 
                size="small"
                startIcon={<PrintOutlinedIcon />}
                onClick={this.handlePrint}
              > 
                Print 
              </Button>
            </Box>  
          }
          <NavigateBack />
        </Grid> : 
        <Grid container justifyContent="space-between" alignItems="flex-start" direction="row" sx={{mt:1}}>
          <Grid xs={2} item key={1}>
          <img src={process.env.PUBLIC_URL + '/static/images/home/mpeda-logo.png'} alt = "MPEDA logo" height="100px"/>
          </Grid>
          <Grid xs={8} item key={2}>
            <Typography variant="h4" align="center">Rajiv Gandhi Centre for Aquaculture</Typography>
            <Typography variant="h4" align="center">Aquatic Quarantine Facility</Typography>
            <Typography variant="body2" my={1} align="center"> 
              MPEDA. Ministry of Commerce & Industry, Govt. of India <br/>
              TNFDC Hatchery Complex, Kapaleeswarar Nagar, Beach Road <br/>
              Neelankarai-600115, Chennai, Tamil Nadu
            </Typography>
          </Grid>
          <Grid xs={2} item key={3}>
          <img src={process.env.PUBLIC_URL + '/static/images/home/rgca-logo.png'} alt = "RGCA logo" height="100px"/>
          </Grid>
        </Grid>
      }
      
      {
        this.state.printView === false &&
        <Paper elevation={3}  sx={{textAlign:"center", p:2, m:1}}>
          <Typography variant="h3"> 
            View Booking Details
            {this.state.bookingData ? ` - ID ${this.state.bookingData.id} ${this.state.bookingData.oldId ? ' / ' + this.state.bookingData.oldId : ''}` : ''} 
          </Typography>
        </Paper>
      }
      {
        this.state.bookingData && 
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item sm={8} xs={12}>
            {
              this.state.printView === false  &&
              <Paper elevation={3} sx={{textAlign:"center", ml:1}}>
                <Tabs
                  variant="fullWidth"
                  value={this.state.summaryTab}
                  onChange={async (event, newValue) => await this.setState({summaryTab : newValue}) }
                  textColor="primary"
                  indicatorColor="secondary"
                  aria-label="Booking Summary Details"
                >
                  <Tab value="Booking Summary" label="Booking Summary" />
                  {
                    (permissionCheck('transaction', 'view-any') ||  permissionCheck('transaction', 'view-self')) &&
                    <Tab value="Invoice Details" label="Invoice Particulars" />
                  }
                  {
                    this.state.bookingData.arrivalReport &&
                    <Tab value="Quarantine Status" label="Quarantine Status Timeline" />
                  }
                </Tabs>
              </Paper>
            }
            {
              (this.state.summaryTab === "Booking Summary" || this.state.printView) &&
              <div role="tabpanel" hidden={this.state.summaryTab !== "Booking Summary" && !this.state.printView} id="1" aria-labelledby="booking-summary">
                <EntryGridView 
                  values={bookingSummary} 
                  formFields = {bookingFields}
                  title={this.state.printView ? "Booking Summary" : null}
                />
              </div>
            }
            {
              this.state.summaryTab === "Quarantine Status" && this.state.printView === false &&
              <div role="tabpanel" hidden={this.state.summaryTab !== "Quarantine Status"} id="2" aria-labelledby="quarantine-status">
                <BookingStockStatus bookingData={this.state.bookingData} />
              </div>
            }
            {
              (this.state.summaryTab === "Invoice Details" || this.state.printView) &&
              <div role="tabpanel" hidden={this.state.summaryTab !== "Invoice Details" && !this.state.printView} id="3" aria-labelledby="booking-invoice">
                <EntryGridView 
                  values={this.state.bookingData}
                  formFields = {bookingInvoiceFields}
                  title={this.state.printView ? "Booking Invoice" : null}
                />
              </div>
            }
            {
              this.state.printView === false &&
              <EntryGridView
                title = "Authorised Person For Package Recieving BS"
                values={this.state.bookingData.authorisedPerson ? this.state.bookingData.authorisedPerson : {} }
                formFields = {authPersonFields}  
              />
            }
          </Grid>
          <Grid item sm={4} xs={12}>
          {
            this.state.printView === false &&
            this.state.bookingData.status === 'Confirmed' &&
            (this.state.bookingData.arrivalReport ? permissionCheck('booking', 'postArrivalOps') : true) && 
            <BookingOperations 
              bookingData={this.state.bookingData}
              stockingData={this.state.stockingData}
              isSamplesCollected = {this.state.sampleCollectionData && this.state.sampleCollectionData.length >= 5}
              refresh={this.refreshBookingData} />
          }
          </Grid>
        </Grid>
      }
      
      {
        this.state.printView === false &&
        <>
          <Paper elevation={3} sx={{textAlign:"center", m:1}}>
            <Tabs
              variant="fullWidth"
              value={this.state.activeTab}
              onChange={async (event, newValue) => await this.setState({activeTab : newValue}) }
              textColor="primary"
              indicatorColor="secondary"
              aria-label="Owner Details"
            >
              <Tab value="Supplier Details" label="Supplier Details" />
              <Tab value="Shipment Details" label="Shipment Details" />
              <Tab value="Booking Documents" label="Booking Documents" />
              {
                permissionCheck('booking','view-cubicle-stocking') &&
                <Tab value="Cubicle Stocking" label="Cubicle Stocking" />
              }
              {
                permissionCheck('booking', 'view-sample-collection-storage') &&
                <Tab value="Sample Collection" label="Sample Collection" />
              }
              {
               (permissionCheck('transaction', 'view-any') ||  permissionCheck('transaction', 'view-self')) &&
                <Tab value="Transactions" label="Transactions" />
              }
            </Tabs>
          </Paper>
          {
            this.state.activeTab === "Supplier Details" &&
            <div role="tabpanel" hidden={this.state.activeTab !== "Supplier Details"} id="1" aria-labelledby="supplier-details">
              <EntryGridView 
                values={this.state.bookingData ? this.state.bookingData.supplierDetails : {} }
                formFields = {supplierFields}  
              />
            </div>
          }
          {
            this.state.activeTab === "Shipment Details" &&
            <div role="tabpanel" hidden={this.state.activeTab !== "Shipment Details"} id="2" aria-labelledby="shipment-details">
              <EntryGridView 
                values={this.state.bookingData && this.state.bookingData.shipmentDetail ? this.state.bookingData.shipmentDetail : {} }
                formFields = {
                   this.state.bookingData 
                    ? filterBookingFormFieldsBySpeciesType('shipment', this.state.bookingData.speciesType.type, shipmentFields) 
                    : shipmentFields
                }
              />
            </div>
          }
          {
            this.state.activeTab === "Booking Documents" &&
            <div role="tabpanel" hidden={this.state.activeTab !== "Booking Documents"} id="4" aria-labelledby="booking-documents">
              <Paper elevation={3} sx={{textAlign:"center", m:2, p:1}}>
                <Typography variant="h4"> List of All Booking Documents </Typography><br/><hr style={{borderColor:"#eef1f6"}} /> 
                {
                  this.state.bookingData &&
                  <BookingDocuments bookingData={this.state.bookingData} />
                }
              </Paper>
            </div>
          }
          {
            this.state.activeTab === "Cubicle Stocking" &&
            <div role="tabpanel" hidden={this.state.activeTab !==  "Cubicle Stocking"} id="5" aria-labelledby="cubicle-stocking">
              <Paper elevation={3} sx={{textAlign:"center", m:2, p:1}}>
                <Typography variant="h4"> Cubicle Stocking Data </Typography><br/><hr style={{borderColor:"#eef1f6"}} /> 
                {
                  this.state.stockingData &&
                  <DTable
                    columns = {cubicleStockingFields}
                    rowsPerPage={this.state.stockingData ? this.state.stockingData.length : 0}
                    dataTotal={this.state.stockingData ? this.state.stockingData.length : 0}
                    data={this.state.stockingData ? this.state.stockingData : []}
                    page={1}
                  />
                }
              </Paper>
            </div>
          }
          {
            this.state.activeTab === "Sample Collection" &&
            <div role="tabpanel" hidden={this.state.activeTab !==  "Sample Collection"} id="6" aria-labelledby="sample-collection">
              <Paper elevation={3} sx={{textAlign:"center", m:2, p:1}}>
                <Typography variant="h4"> Sample Collection Data </Typography><br/><hr style={{borderColor:"#eef1f6"}} /> 
                {
                  <DTable
                    columns = {this.sampleCollectionFields}
                    rowsPerPage={this.state.sampleCollectionData ? this.state.sampleCollectionData.length : 0}
                    dataTotal={this.state.sampleCollectionData ? this.state.sampleCollectionData.length : 0}
                    data={this.state.sampleCollectionData ? this.state.sampleCollectionData : []}
                    page={1}
                  />
                }
              </Paper>
            </div>
          }
          {
            this.state.activeTab === "Transactions" &&
            <div role="tabpanel" hidden={this.state.activeTab !== "Transactions"} id="7" aria-labelledby="booking-transactions">
              <Paper elevation={3} sx={{textAlign:"center", m:2, p:1}}>
                <Typography variant="h4"> List of All Booking Transactions </Typography><br/><hr style={{borderColor:"#eef1f6"}} /> 
                {
                  this.state.bookingData &&
                  <DTable
                    columns = {this.transactionFields}
                    rowsPerPage={this.state.bookingData.transactions ? this.state.bookingData.transactions.length : 0}
                    dataTotal={this.state.bookingData.transactions ? this.state.bookingData.transactions.length : 0}
                    data={this.state.bookingData.transactions ? this.state.bookingData.transactions : []}
                    page={1}
                  />
                }
              </Paper>
            </div>
          }
        </>
      }
    </>);
  }
}

export default withPrint(withLocationParams(ViewBooking));
