import React, { Component } from "react";

import { withLocation } from 'hoc';

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";
import { 
  fetchTransactionsTotalCount, 
  fetchTransactionsData, 
} from 'services/transactions';

import transactionFields from './transactions.fields'

class TransactionsList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    let location = props.location.state;
    
    // copy list of columns
    this.transactionFields = [];
    transactionFields.map ( f => {
      
      // do not show hatchery name in case of hatchery owner
      if (this.currentUser.user.role.name === 'Hatchery Owner' && f.name==='hatcheryName') {
        return null;
      }
      
      let field = {...f};
      field.properties = [];
      // deep copy properties
      f.properties.map ( p => field.properties.push({...p}));
    
      // deep copy select options
      if (f.selectOptions) {
        field.selectOptions = {...f.selectOptions};
      }
    
      this.transactionFields.push(field);
      
      // fix warnings
      return null;
    });
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Transactions' + `${location && location.name ? ' - ' + location.name : ''}`,
      addButtonText : 'New Additional Charges',
      addUrl : null,
      searchEnable : true,
      searchPlaceholder : (this.currentUser.user.role.name === 'Hatchery Owner') 
        ? 'Search By Booking ID ' : 'Search By Booking ID / Hatchery Name',
      
      // table content props
      columns : this.transactionFields,
      itemUrlPath : '/aqf/bookings/transactions',
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchTransactionsDataWrapper,
      fetchCountApi : this.fetchTransactionsCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'status',
        value : 'ALL',
        label : 'Filter By Status',
        options : [
          {id : "ALL", value : "-- All  --"},
          {id : "Approved", value : "Paid"},
          {id : "ApprovalPending", value : "Unpaid"},
          //{id : "Rejected", value : "Rejected"},
          {id : "RefundPending", value : "Refund Pending"},
          {id : "RefundApproved", value : "Refund Completed"},
          {id : "RefundRejected", value : "Refund Rejected"},
          {id : "Cancelled", value : "Cancelled"}
        ]
      }, {
        name : 'additionalId',
        value : 'ALL',
        label : 'Filter By Type',
        options : [
          {id : "ALL", value : "-- All  --"},
          {id : "booking", value : "Booking"},
          {id : "additional", value : "Additional Charges"},
        ]
      }],

      // export to xl, pdf props
      exportEnable : false,
    };
  }

  fetchTransactionsCountWrapper = async(filters, searchValue) => {
    let hatcheryId = 'ALL';
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    } else if (this.props.location && this.props.location.state && 
      this.props.location.state.hatcheryId) {
      hatcheryId = this.props.location.state.hatcheryId;
    }
    
    let response = await fetchTransactionsTotalCount({
      hatcheryId : hatcheryId,
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue))) ? searchValue : null,
      status : filters['status'],
      additionalId : filters['additionalId']
    });
    return response;
  }    

  fetchTransactionsDataWrapper = async (page, perPage, filters, searchValue) => {
    let hatcheryId = 'ALL';
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    } else if (this.props.location && this.props.location.state &&
      this.props.location.state.hatcheryId) {
      hatcheryId = this.props.location.state.hatcheryId;
    }
    
    let response = await fetchTransactionsData({
      page: page,
      per_page: perPage,
      hatcheryId : hatcheryId,
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue))) ? searchValue : null,
      status : filters['status'],
      additionalId : filters['additionalId']
    });
    
    
    response.data.forEach(entry => {
      entry.selection = false; //this.currentUser.user.role.name === 'Front Desk';
      entry.url = `/aqf/bookings/transactions/${entry.id}`;
      entry.type = entry.additionalBookingChargesId ? "Additional Charge" : "Booking";
      entry.bankAccountNo = entry.bankAccountId ? entry.bankAccountId.accountNumber : null;
      entry.bankAccountName = entry.bankAccountId ? entry.bankAccountId.bankName : null;
      if (entry.bookingId) {
        entry.hatcheryName = entry.bookingId.hatcheryId ? entry.bookingId.hatcheryId.name : null;
        entry.bookingId = entry.bookingId.oldId ? `${entry.bookingId.oldId} / ${entry.bookingId.id}` : entry.bookingId.id;
      }
    });
    
    return response;
  }

  async componentDidMount() {
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}

export default withLocation(TransactionsList);
