import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import {Tooltip, Grid} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFViewer(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (<>
    <Grid container justifyContent="space-between">
      <Grid item alignContent="flex-start">
      {
        (pageNumber > 1) &&
        <IconButton aria-label="previous" onClick= { e => {setPageNumber(pageNumber - 1)} }>
          <ArrowBackIos sx={{ml:2}}/>
        </IconButton>
      }
      </Grid>
      <Grid item alignContent="center">
        <p> Page {pageNumber} of {numPages} </p>
      </Grid>
      <Grid item alignContent="flex-end">
      {
        (pageNumber < numPages) &&    
        <>
          <IconButton aria-label="next" onClick= { e => {setPageNumber(pageNumber + 1)} }>
            <ArrowForwardIos />
          </IconButton>
        </>
      }
      {
        props.newWindow &&
        <Tooltip title="Click to Open in new Tab">
          <IconButton color="secondary" aria-label="new-page-pdf" component="a" href={props.url} target="_blank">
            <OpenInNewOutlined />
          </IconButton>
        </Tooltip>
      }
      </Grid>
    </Grid>
    <Grid container justifyContent="center">
      <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess} >
        <Page pageNumber={pageNumber} scale={props.scale ? props.scale : 1.0}/>
      </Document>
    </Grid>
    <Grid container justifyContent="space-between">
      <Grid item alignContent="flex-start">
      {
        (pageNumber > 1) &&
        <IconButton aria-label="previous" onClick= { e => {setPageNumber(pageNumber - 1)} }>
          <ArrowBackIos  sx={{ml:2}}/>
        </IconButton>
      }
      </Grid>
      <Grid item alignContent="center">
        <p> Page {pageNumber} of {numPages} </p>
      </Grid>
      <Grid item alignContent="flex-end">
      {
       (pageNumber < numPages) &&    
        <IconButton aria-label="next" onClick= { e => {setPageNumber(pageNumber + 1)} }>
          <ArrowForwardIos />
        </IconButton>
      }
      </Grid>
    </Grid>
  </>
  );
}