import { useState } from 'react';

import {
  Dialog, DialogContent, DialogTitle, Typography, Box, Button,
  Tooltip, IconButton 
} from '@mui/material';

import Close from '@mui/icons-material/Close';

import ImageOrDocument from 'components/ImageOrDocument';

// import TableView Component
import DTable from "components/DataTables/DTable";

import { licenseFields } from '../hatchery.fields';

import LicenseForm from '../form-components/LicenseForm';

import { permissionCheck } from 'permissions';

const LicenseDetails = (props) => {
  
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ currentValues, setCurrentValues ] = useState(null);
 
  const handleLicenseEdit = async (pos, entry) => {
    setCurrentValues(entry);
    setModalOpen(true);
  }
  
  return (
    <Box sx={{p : 1}}>
      {
        <Dialog open={modalOpen} aria-labelledby="form-dialog-title" maxWidth="lg">
          <DialogTitle id="responsive-dialog-title" sx={{display : 'flex', justifyContent : 'flex-end'}}>
            <IconButton onClick={() => setModalOpen(false)}><Close /></IconButton>
          </DialogTitle>
          <DialogContent> 
            <LicenseForm 
              hatcheryData = { props.hatcheryData }
              currentValues = { currentValues } 
              refresh = { e => { setModalOpen(false) ; props.refresh(); }} />
          </DialogContent>
        </Dialog>
      }
      {
        permissionCheck('hatchery', 'change-request') &&
        props.hatcheryData.updateRequestFlag !== "ApprovalPending" &&
        props.printView === false &&
        <Box sx={{m : 2}} justifyContent="flex-end" display="flex">
          <Button color="primary" size="small" variant="contained" 
            onClick = { e => setModalOpen(true)}
          >
            Add Renewal License
          </Button>
        </Box>
      }
      {
        props.licenseData &&
        <DTable
          columns = {licenseFields}
          rowsPerPage={props.licenseData ? props.licenseData.length : 0}
          dataTotal={props.licenseData ? props.licenseData.length : 0}
          data={props.licenseData ? props.licenseData : []}
          page={1}
          editUrlPath={handleLicenseEdit}
        />
      }
    </Box>
  );
};

export default LicenseDetails;