import { fetchWithTimeout, addPagerParams } from './utils';

const RECVD_SAMPLE_REPORTS_EP_URL = "/received-sample-reports";
const FEEDING_REPORTS_EP_URL = "/feedings";
const MORTALITY_REPORTS_EP_URL = "/mortalities";
const WATER_QUALITY_REPORTS_EP_URL = "/water-quality-analyses";
const SAMPLE_WEIGHT_REPORTS_EP_URL = "/sample-weight-of-brood-stocks";
const QUARANTINE_REPORTS_EP_URL = "/quarantine-reports";
const CLEARANCE_REPORTS_EP_URL = "/clearance-certificates";
const SHIPMENT_PACKING_REPORTS_EP_URL = "/shipment-packings"; 
const LAB_ANALYSIS_FINAL_REPORTS_EP_URL = "/lab-analysis-reports";
const LAB_ANALYSIS_REPORTS_EP_URL = "/lab-analyses";
  
export const fetchBookingReportsCount = async (reportType, params) => {
  let endpoint = null;
  let operation = null;
  switch (reportType) {
    case 'received-sample-report':
      endpoint = RECVD_SAMPLE_REPORTS_EP_URL;
      operation = 'booking-reports:view-received-sample-report';
    break;
    case 'feeding-report':
      endpoint = FEEDING_REPORTS_EP_URL;
      operation = 'booking-reports:view-feeding-report';
    break;
    case 'mortality-report':
      endpoint = MORTALITY_REPORTS_EP_URL;
      operation = 'booking-reports:view-mortality-report';
    break;
    case 'lab-analysis':
      endpoint = LAB_ANALYSIS_REPORTS_EP_URL;
      operation = 'booking-reports:view-lab-analysis';
    break;
    case 'lab-analysis-report':
      endpoint = LAB_ANALYSIS_FINAL_REPORTS_EP_URL;
      operation = 'booking-reports:view-lab-analysis-report';
    break;
    case 'water-quality-analysis':
      endpoint = WATER_QUALITY_REPORTS_EP_URL;
      operation = 'booking-reports:view-water-quality-analysis';
    break;
    case 'sample-weight-of-brood-stock':
      endpoint = SAMPLE_WEIGHT_REPORTS_EP_URL;
      operation = 'booking-reports:view-sample-weight-of-brood-stock';
    break;
    case 'quarantine-report':
      endpoint = QUARANTINE_REPORTS_EP_URL;
      operation = 'booking-reports:view-quarantine-report';
    break;
    case 'clearance-certificate':
      endpoint = CLEARANCE_REPORTS_EP_URL;
      operation = 'booking-reports:view-clearance-certificate';
    break;
    case 'shipment-packing':
      endpoint = SHIPMENT_PACKING_REPORTS_EP_URL;
      operation = 'booking-reports:view-shipment-packing';
    break;
    default:
  }
  return await fetchWithTimeout({
    path : endpoint + '/count?_start=0'
        + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : '')
        + (params.hatcheryId !== 'ALL' ? '&bookingId.hatcheryId=' + params.hatcheryId : '')
        + (params.approvalFlag ? '&approvalFlag=' + params.approvalFlag : '')
        + (params.startDate ? '&created_at_gte=' + params.startDate : '')
        + (params.endDate ? '&created_at_lte=' + params.endDate : '')
        + (params.hatcheryName && params.hatcheryName.length ? '&bookingId.hatcheryId.name_contains=' + params.hatcheryName : '')
        + (params.speciesType !== 'ALL' ? '&bookingId.speciesType=' + params.speciesType : ''),
    method : 'GET',
    operation : params.approvalFlag ? 'app-reports:lab-report-verification' : operation
  });
};

export const fetchBookingReports = async (reportType, params) => { 
  let endpoint = null;
  let operation = null;
    switch (reportType) {
    case 'received-sample-report':
      endpoint = RECVD_SAMPLE_REPORTS_EP_URL;
      operation = 'booking-reports:view-received-sample-report';
    break;
    case 'feeding-report':
      endpoint = FEEDING_REPORTS_EP_URL;
      operation = 'booking-reports:view-feeding-report';
    break;
    case 'mortality-report':
      endpoint = MORTALITY_REPORTS_EP_URL;
      operation = 'booking-reports:view-mortality-report';
    break;
    case 'lab-analysis':
      endpoint = LAB_ANALYSIS_REPORTS_EP_URL;
      operation = 'booking-reports:view-lab-analysis';
    break;
    case 'lab-analysis-report':
      endpoint = LAB_ANALYSIS_FINAL_REPORTS_EP_URL;
      operation = 'booking-reports:view-lab-analysis-report';
    break;
    case 'water-quality-analysis':
      endpoint = WATER_QUALITY_REPORTS_EP_URL;
      operation = 'booking-reports:view-water-quality-analysis';
    break;
    case 'sample-weight-of-brood-stock':
      endpoint = SAMPLE_WEIGHT_REPORTS_EP_URL;
      operation = 'booking-reports:view-sample-weight-of-brood-stock';
    break;
    case 'quarantine-report':
      endpoint = QUARANTINE_REPORTS_EP_URL;
      operation = 'booking-reports:view-quarantine-report';
    break;
    case 'clearance-certificate':
      endpoint = CLEARANCE_REPORTS_EP_URL;
      operation = 'booking-reports:view-clearance-certificate';
    break;
    case 'shipment-packing':
      endpoint = SHIPMENT_PACKING_REPORTS_EP_URL;
      operation = 'booking-reports:view-shipment-packing';
    break;
    default:
  }
  
  return await fetchWithTimeout({
    path : endpoint 
        + addPagerParams(params, (reportType === 'received-sample-report' ? 'arrivingDateTime:DESC' : 'bookingId:DESC'))
        + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : '')
        + (params.hatcheryId !== 'ALL' ? '&bookingId.hatcheryId=' + params.hatcheryId : '')
        + (params.approvalFlag ? '&approvalFlag=' + params.approvalFlag : '')
        + (params.startDate ? '&created_at_gte=' + params.startDate : '')
        + (params.endDate ? '&created_at_lte=' + params.endDate : '')
        + (params.hatcheryName && params.hatcheryName.length ? '&bookingId.hatcheryId.name_contains=' + params.hatcheryName : '')
        + (params.speciesType !== 'ALL' ? '&bookingId.speciesType=' + params.speciesType : ''),
    method : 'GET',
    operation : operation
  });
};

export const fetchBookingReportById = async (reportId, reportType, opcode=null) => { 
  let endpoint = null;
  let operation = null;
  switch (reportType) {
    case 'received-sample-report':
      endpoint = RECVD_SAMPLE_REPORTS_EP_URL;
      operation = 'booking-reports:view-received-sample-report';
    break;
    case 'feeding-report':
      endpoint = FEEDING_REPORTS_EP_URL;
      operation = 'booking-reports:view-feeding-report';
    break;
    case 'mortality-report':
      endpoint = MORTALITY_REPORTS_EP_URL;
      operation = 'booking-reports:view-mortality-report';
    break;
    case 'lab-analysis':
      endpoint = LAB_ANALYSIS_REPORTS_EP_URL;
      operation = 'booking-reports:view-lab-analysis';
    break;
    case 'lab-analysis-report':
      endpoint = LAB_ANALYSIS_FINAL_REPORTS_EP_URL;
      operation = 'booking-reports:view-lab-analysis-report';
    break;
    case 'water-quality-analysis':
      endpoint = WATER_QUALITY_REPORTS_EP_URL;
      operation = 'booking-reports:view-water-quality-analysis';
    break;
    case 'sample-weight-of-brood-stock':
      endpoint = SAMPLE_WEIGHT_REPORTS_EP_URL;
      operation = 'booking-reports:view-sample-weight-of-brood-stock';
    break;
    case 'quarantine-report':
      endpoint = QUARANTINE_REPORTS_EP_URL;
      operation = 'booking-reports:view-quarantine-report';
    break;
    case 'clearance-certificate':
      endpoint = CLEARANCE_REPORTS_EP_URL;
      operation = 'booking-reports:view-clearance-certificate';
    break;
    case 'shipment-packing':
      endpoint = SHIPMENT_PACKING_REPORTS_EP_URL;
      operation = 'booking-reports:view-shipment-packing';
    break;
    default:
  }
  
  return await fetchWithTimeout({
    path : endpoint + '/' + reportId,
    method : 'GET',
    operation : opcode ? opcode : operation
  });
};

export const fetchBookingReportByBookingId = async (bookingId, reportType, opcode=null) => { 
  let endpoint = null;
  let operation = null;
  switch (reportType) {
    case 'received-sample-report':
      endpoint = RECVD_SAMPLE_REPORTS_EP_URL;
      operation = 'booking-reports:view-received-sample-report';
    break;
    case 'feeding-report':
      endpoint = FEEDING_REPORTS_EP_URL;
      operation = 'booking-reports:view-feeding-report';
    break;
    case 'mortality-report':
      endpoint = MORTALITY_REPORTS_EP_URL;
      operation = 'booking-reports:view-mortality-report';
    break;
    case 'lab-analysis':
      endpoint = LAB_ANALYSIS_REPORTS_EP_URL;
      operation = 'booking-reports:view-lab-analysis';
    break;
    case 'lab-analysis-report':
      endpoint = LAB_ANALYSIS_FINAL_REPORTS_EP_URL;
      operation = 'booking-reports:view-lab-analysis-report';
    break;
    case 'water-quality-analysis':
      endpoint = WATER_QUALITY_REPORTS_EP_URL;
      operation = 'booking-reports:view-water-quality-analysis';
    break;
    case 'sample-weight-of-brood-stock':
      endpoint = SAMPLE_WEIGHT_REPORTS_EP_URL;
      operation = 'booking-reports:view-sample-weight-of-brood-stock';
    break;
    case 'quarantine-report':
      endpoint = QUARANTINE_REPORTS_EP_URL;
      operation = 'booking-reports:view-quarantine-report';
    break;
    case 'clearance-certificate':
      endpoint = CLEARANCE_REPORTS_EP_URL;
      operation = 'booking-reports:view-clearance-certificate';
    break;
    case 'shipment-packing':
      endpoint = SHIPMENT_PACKING_REPORTS_EP_URL;
      operation = 'booking-reports:view-shipment-packing';
    break;
    default:
  }
  
  return await fetchWithTimeout({
    path : endpoint + '?bookingId=' + bookingId,
    method : 'GET',
    operation : opcode ? opcode : operation
  });
};


export const createNewBookingReport = async (reportType, params) => {
  
  let endpoint = null;
  let operation = null;
  
  switch (reportType) {
    case 'received-sample-report':
      endpoint = RECVD_SAMPLE_REPORTS_EP_URL;
      operation = 'booking-reports:create-received-sample-report';
    break;
    case 'feeding-report':
      endpoint = FEEDING_REPORTS_EP_URL;
      operation = 'booking-reports:create-feeding-report';
    break;
    case 'mortality-report':
      endpoint = MORTALITY_REPORTS_EP_URL;
      operation = 'booking-reports:create-mortality-report';
    break;
    case 'lab-analysis':
      endpoint = LAB_ANALYSIS_REPORTS_EP_URL;
      operation = 'booking-reports:create-lab-analysis';
    break;
    case 'lab-analysis-report':
      endpoint = LAB_ANALYSIS_FINAL_REPORTS_EP_URL;
      operation = 'booking-reports:create-lab-analysis-report';
    break;
    case 'water-quality-analysis':
      endpoint = WATER_QUALITY_REPORTS_EP_URL;
      operation = 'booking-reports:create-water-quality-analysis';
    break;
    case 'sample-weight-of-brood-stock':
      endpoint = SAMPLE_WEIGHT_REPORTS_EP_URL;
      operation = 'booking-reports:create-sample-weight-of-brood-stock';
    break;
    case 'quarantine-report':
      endpoint = QUARANTINE_REPORTS_EP_URL;
      operation = 'booking-reports:create-quarantine-report';
    break;
    case 'clearance-certificate':
      endpoint = CLEARANCE_REPORTS_EP_URL;
      operation = 'booking-reports:create-clearance-certificate';
    break;
    case 'shipment-packing':
      endpoint = SHIPMENT_PACKING_REPORTS_EP_URL;
      operation = 'booking-reports:create-shipment-packing';
    break;
    default:
  }
  
  return await fetchWithTimeout({
    path : endpoint,
    method : 'POST',
    body : params,
    operation : operation
  });
};

export const updateBookingReport = async (reportType, id, params) => {
  
  let endpoint = null;
  let operation = null;
  
  switch (reportType) {
    case 'received-sample-report':
      endpoint = RECVD_SAMPLE_REPORTS_EP_URL + '/' + id;
      operation = 'booking-reports:update-received-sample-report:' + id;
    break;
    case 'feeding-report':
      endpoint = FEEDING_REPORTS_EP_URL + '/' + id;
      operation = 'booking-reports:update-feeding-report:' + id;
    break;
    case 'mortality-report':
      endpoint = MORTALITY_REPORTS_EP_URL + '/' + id;
      operation = 'booking-reports:update-mortality-report:' + id;
    break;
    case 'lab-analysis':
      endpoint = LAB_ANALYSIS_REPORTS_EP_URL + '/' + id;
      operation = 'booking-reports:update-lab-analysis:' + id;
    break;
    case 'lab-analysis-report':
      endpoint = LAB_ANALYSIS_FINAL_REPORTS_EP_URL + '/' + id;
      operation = 'booking-reports:update-lab-analysis-report:' + id;
    break;
    case 'water-quality-analysis':
      endpoint = WATER_QUALITY_REPORTS_EP_URL + '/' + id;
      operation = 'booking-reports:update-water-quality-analysis:' + id;
    break;
    case 'sample-weight-of-brood-stock':
      endpoint = SAMPLE_WEIGHT_REPORTS_EP_URL + '/' + id;
      operation = 'booking-reports:update-sample-weight-of-brood-stock:' + id;
    break;
    case 'quarantine-report':
      endpoint = QUARANTINE_REPORTS_EP_URL + '/' + id;
      operation = 'booking-reports:update-quarantine-report:' + id;
    break;
    case 'clearance-certificate':
      endpoint = CLEARANCE_REPORTS_EP_URL + '/' + id;
      operation = 'booking-reports:update-clearance-certificate:' + id;
    break;
    case 'shipment-packing':
      endpoint = SHIPMENT_PACKING_REPORTS_EP_URL + '/' + id;
      operation = 'booking-reports:update-shipment-packing:' + id;
    break;
    default:
  }
  
  return await fetchWithTimeout({
    path : endpoint,
    method : 'PUT',
    body : params,
    operation : operation
  });
};

export const approveBookingReport = async (reportType, id, params) => {
  
  let endpoint = null;
  let operation = null;
  
  switch (reportType) {
    case 'lab-analysis-report':
      endpoint = LAB_ANALYSIS_FINAL_REPORTS_EP_URL  + '/' + id;
      operation = 'booking-reports:approve-lab-analysis-report';
    break;
    default:
    break;
  }
  
  return await fetchWithTimeout({
    path : endpoint,
    method : 'PUT',
    body : params,
    operation : operation
  });
};

export const generateBookingPdfReport = async (reportType,id) => {
  let endpoint = null;
  let operation = null;
  
  switch (reportType) {
    case 'received-sample-report':
      endpoint = RECVD_SAMPLE_REPORTS_EP_URL  + '/generate-pdf/' + id;
      operation = 'booking-reports:update-received-sample-report';
    break;
    case 'lab-analysis-report':
      endpoint = LAB_ANALYSIS_FINAL_REPORTS_EP_URL  + '/generate-pdf/' + id;
      operation = 'booking-reports:update-lab-analysis-report';
    break;
    case 'quarantine-report':
      endpoint = QUARANTINE_REPORTS_EP_URL + '/generate-pdf/' + id;
      operation = 'booking-reports:update-quarantine-report';
    break;
    case 'clearance-certificate':
      endpoint = CLEARANCE_REPORTS_EP_URL  + '/generate-pdf/' + id;
      operation = 'booking-reports:update-clearance-certificate';
    break;
    case 'shipment-packing':
      endpoint = SHIPMENT_PACKING_REPORTS_EP_URL  + '/generate-pdf/' + id;
      operation = 'booking-reports:update-shipment-packing';
    break;
    default:
  }
  
  return await fetchWithTimeout({
    path : endpoint,
    method : 'PUT',
    body : {},
    operation : operation
  });
};

// Reports related API
export const fetchImportAndDispatchCount = async (params) => {
  return await fetchWithTimeout({
    path : SHIPMENT_PACKING_REPORTS_EP_URL + '/import-and-dispatch/count?_start=0'
      + (params.supplierId !== 'ALL' ? '&supplierId=' + params.supplierId : '')
      + (params.speciesType !== 'ALL' ? '&speciesType=' + params.speciesType : '')
      + (params.startDate ? '&startDate=' + params.startDate : '')
      + (params.endDate ? '&endDate=' + params.endDate : '')
      + (params.hatcheryName && params.hatcheryName.length ? '&hatcheryName=' + params.hatcheryName : ''),
    method : 'GET',
    operation : 'app-reports:import-dispatch'
  });
};

export const fetchImportAndDispatch = async (params) => {
  return await fetchWithTimeout({
    path : SHIPMENT_PACKING_REPORTS_EP_URL + '/import-and-dispatch' 
      + (params.download ? '?_start=0' : addPagerParams(params))
      + (params.supplierId !== 'ALL' ? '&supplierId=' + params.supplierId : '')
      + (params.speciesType !== 'ALL' ? '&speciesType=' + params.speciesType : '')
      + (params.startDate ? '&startDate=' + params.startDate : '')
      + (params.endDate ? '&endDate=' + params.endDate : '')
      + (params.hatcheryName && params.hatcheryName.length ? '&hatcheryName=' + params.hatcheryName : '')
      + (params.download ? '&download=true' : ''),
    method : 'GET',
    operation : 'app-reports:import-dispatch'
  });
};

export const fetchTotalBsImportedCount = async (params) => {
  return await fetchWithTimeout({
    path : RECVD_SAMPLE_REPORTS_EP_URL + '/total-bs-imported?_start=0'
        + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : '')
        + (params.hatcheryId !== 'ALL' ? '&bookingId.hatcheryId=' + params.hatcheryId : ''),
    method : 'GET',
    operation : 'booking-reports:view-received-sample-report'
  });
};

export const fetchTotalMortalitiesByBooking = async (bookingId, opcode=null) => {
   return await fetchWithTimeout({
    path : MORTALITY_REPORTS_EP_URL + '/total-by-booking/' + bookingId,
    method : 'GET',
    operation : opcode ? opcode : 'booking-reports:view-mortality-report'
  });
}

export const filterFormFieldsBySpeciesType = (reportType, speciesType, formFields) => {
  let newFields = [];

  switch(reportType) {
    case 'received-sample-report':
      formFields.map ( field => {
        if (speciesType === 'PPL') {
          if ([
            'totalMaleBs', 'totalFemaleBs', 'totalMaleMortalities', 'totalFemaleMortalities',
            'avgMaleWt', 'avgFemaleWt', 'maleBsPerBag', 'femaleBsPerBag', 'avgWt'
          ].includes(field.name)) {
            return; // skip fields for PPL
          } else if (field.name === 'totalArrivedBs') {
            field.label = 'Total Arrived PPL';
            field.properties = [{required : true}, {number : true}];
          } else if (field.name === 'totalBsReserved') {
            field.label = 'Total Reserved PPL'
          } else if (field.name === 'totalArrivMortalities') {
            field.properties = [{required : true}, {number : true}];
          } else if (field.name === 'totalBoxes') {
            field.label = 'Total Boxes (Kg.s)';
          }
        } else {
          if (field.name === 'avgWt') return; 
        }
        newFields.push(field);
      });
       
    break;
    case 'mortality-report':
      formFields.map ( field => {
        if (speciesType === 'PPL') {
          if (['maleMortality', 'femaleMortality', 'maleMolts', 'femaleMolts'].includes(field.name)) {
            return; // skip fields for PPL
          } else if (field.name === 'totalMolts') {
            field.properties[0]['required'] = false; // total Molts optional for PPL
          }
        }
        newFields.push(field);
      });
    break;
    case 'sample-weight-of-brood-stock':
      formFields.map ( field => {
        if (speciesType === 'PPL') {
          if (['averageMaleWeight', 'averageFemaleWeight'].includes(field.name)) {
            return; // skip fields for PPL
          } else if (field.name === 'averageWeight') {
            field.label = 'Weight (gms)';
          }
        } else {
          if (field.name === 'averageWeight') return; // skip field for broodstock
        }
        newFields.push(field);
      });
    break;
    case 'shipment-packing':
      formFields.map ( field => {
        if (speciesType === 'PPL') {
          if (['totalMaleBs', 'totalFemaleBs'].includes(field.name)) {
            return; // skip fields for PPL
          } else if (field.name === 'totalBsPacked') {
            field.label = 'Total PPL Packed';
          } else if (field.name === 'bsPerBag') {
            field.label = 'PPL Per Bag';
          }
        }
        newFields.push(field);
      });
    break;
    default:
      newFields = formFields;
    break;
  }
  
  return newFields;
}
      
export const filterDataBySpeciesType = (reportType, speciesType, data) => {
  
  switch(reportType) {
    case 'received-sample-report':
      if (speciesType === 'PPL') {
        data['totalMaleBs'] = data['totalFemaleBs'] = data['totalMaleMortalities'] = 
        data['totalFemaleMortalities'] = data['avgMaleWt'] = data['avgFemaleWt'] = 
        data['maleBsPerBag'] = data['maleBsPerBag'] = null;
      } else {
        data['avgWt'] = null;
      }
    break;
    case 'mortality-report':
      if (speciesType === 'PPL') {
        data['maleMortality'] = data['femaleMortality'] = data['maleMolts'] = 
        data['femaleMolts'] = null;
      }
    break;
    case 'sample-weight-of-brood-stock':
      if (speciesType === 'PPL') {
        data['averageMaleWeight'] = data['averageFemaleWeight'] = null;
      } else {
        data['averageWeight'] = null;
      }
    break;
    case 'shipment-packing':
      if (speciesType === 'PPL') {
        data['totalMaleBs'] = data['totalFemaleBs'] = null;
      } else {
        data['averageWeight'] = null;
      }
    break;
    case 'clearance-certificate':
      // populate dispatched no. info
      data['totalDispatched'] = data['totalNoOfAnimalsImported'] 
        - data['totalNoOfBroodstockMortalityDuringTransit']
        - data['totalNoOfBroodstockMortalityDuringQuarantine'];
    break;
    default:
    break;
  }
  
  return data;
}