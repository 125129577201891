import { Routes, Route, Navigate } from 'react-router-dom';

import { ProtectedComponent } from 'hoc';

// layout views
import DashboardLayout from 'layout/DashboardLayout';

// supplier pages
import SupplierList from 'pages/suppliers/index';
import AddNewSupplier from 'pages/suppliers/add';
import EditSupplier from 'pages/suppliers/edit';

// user account pages
import UserAccountList from 'pages/user-accounts/index';
import AddNewUserAccount from 'pages/user-accounts/add';
import EditUserAccount from 'pages/user-accounts/edit';

// cubicle pages
import CubiclesList from 'pages/cubicles/index';
import AddNewCubicle from 'pages/cubicles/add';
import EditCubicle from 'pages/cubicles/edit';

// species details pages
import SpeciesDetailsList from 'pages/species-details/index';
import AddNewSpecies from 'pages/species-details/add';
import EditSpecies from 'pages/species-details/edit';

// roles and permissions pages
import RolePermissionsList from 'pages/roles-permissions/index';
import AddNewRolePermissions from 'pages/roles-permissions/add';
import EditRolePermissions from 'pages/roles-permissions/edit';

// activity logs
import EventLogsList from 'pages/event-logs/index';
import ViewEventLog from 'pages/event-logs/view';

// settings
import AppSettings from 'pages/settings/index';

import { permissionCheck } from 'permissions';

const AdminRoutes = (props) =>  {
  return (
    <Routes>
      <Route key="aqf-admin-ops" path="*"
        element={
          <ProtectedComponent Component={DashboardLayout}  redirectLink='/' authCheck={props.isAuthenticated} /> 
        }
      >
        {
          permissionCheck('user-accounts', 'view-any') &&
          <Route key="aqf-user-accounts-list" path = "user-accounts/list" element = {<UserAccountList />} />
        }
        {
          permissionCheck('user-accounts', 'create') &&
          <Route key="aqf-user-accounts-add" path = "user-accounts/add" element = {<AddNewUserAccount />} />
        }
        {
          permissionCheck('user-accounts', 'update') &&
          <Route key="aqf-user-accounts-edit" path = "user-accounts/edit/:id" element = {<EditUserAccount />} />
        }
        
        {
          permissionCheck('supplier', 'view-any') &&
          <Route key="aqf-suppliers-list" path = "suppliers/list" element = {<SupplierList />} />
        }
        {
          permissionCheck('supplier', 'create') &&
          <Route key="aqf-suppliers-add" path = "suppliers/add" element = {<AddNewSupplier />} />
        }
        {
          permissionCheck('supplier', 'update') &&
          <Route key="aqf-suppliers-edit" path = "suppliers/edit/:id" element = {<EditSupplier />} />
        }
        
        {
          permissionCheck('common', 'species-create') &&
          <Route key="aqf-species-list" path = "species-details/list" element = {<SpeciesDetailsList />} />
        }
        {
          permissionCheck('common', 'species-create') &&
          <Route key="aqf-species-add" path = "species-details/add" element = {<AddNewSpecies />} />
        }
        {
          permissionCheck('common', 'species-update') &&
          <Route key="aqf-species-edit" path = "species-details/edit/:id" element = {<EditSpecies />} />
        }
        
        {
          permissionCheck('common', 'cubicle-create') &&
          <Route key="aqf-species-list" path = "cubicles/list" element = {<CubiclesList />} />
        }
        {
          permissionCheck('common', 'cubicle-create') &&
          <Route key="aqf-species-add" path = "cubicles/add" element = {<AddNewCubicle />} />
        }
        {
          permissionCheck('common', 'cubicle-update') &&
          <Route key="aqf-species-edit" path = "cubicles/edit/:id" element = {<EditCubicle />} />
        }
        {
          permissionCheck('user-accounts', 'create-role-permissions') &&
          <Route key="aqf-role-permissions-add" path = "role-permissions/add" element = {<AddNewRolePermissions />} />
        }
        {
          permissionCheck('user-accounts', 'update-role-permissions') &&
          <Route key="aqf-role-permissions-edit" path = "role-permissions/edit/:id" element = {<EditRolePermissions />} />
        }
        {
          permissionCheck('user-accounts', 'view-permissions-any') &&
          <Route key="aqf-role-permissions-list" path = "role-permissions/list" element = {<RolePermissionsList />} />
        }
        {
          permissionCheck('common', 'view-event-log-any') &&
          <Route key="aqf-event-logs-list" path = "event-logs/list" element = {<EventLogsList />} />
        }
        {
          permissionCheck('common', 'view-event-log-any') &&
          <Route key="aqf-event-logs-list" path = "event-logs/view/:id" element = {<ViewEventLog />} />
        }
        {
          permissionCheck('common', 'settings-update') &&
          <Route key="aqf-settings-update" path = "settings" element = {<AppSettings />} />
        }
        <Route key="no-matching-route" path="*" element= {<Navigate to="/not-found" />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;