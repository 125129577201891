import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors,
  Icon,
  Typography 
} from '@mui/material';

import theme from 'theme';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { fetchBookingReportsCount } from 'services/booking-reports';

const options = {
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 0 },
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  responsive: true,
  tooltips: {
    backgroundColor: theme.palette.background.paper,
    bodyFontColor: theme.palette.text.secondary,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    enabled: true,
    footerFontColor: theme.palette.text.secondary,
    intersect: false,
    mode: 'index',
    titleFontColor: theme.palette.text.primary
  }
};

const noDataOptions = {
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 0 },
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  responsive: true,
  tooltips: false,
};

const initialData = {
   labels: ['Pending Reports', 'Verified Reports'],
   datasets: [{
    backgroundColor: [
      colors.brown[500],
      colors.lightBlue[500],
    ],
    data: [0, 0],
    label: "%. of Verified Lab Reports"
   }],
};
const noData = {
   labels: ['Pending Reports', 'Verified Reports'],
   datasets: [{
    backgroundColor:'#d2dee2',
    data : [0.00001,0],
    label: "%. of Verified Lab Reports"
  }]
};

export default class ReportVerificationGraph extends Component {
  
  state = { 
    selectedMonth : new Date().toISOString().split('T')[0],
    data : noData,
    noData : true
  };
  
  setupGraphData = async () => {
    let currentData = initialData;
    let total = 0;
    let approved = 0;
    try {
     
      let response = await fetchBookingReportsCount('lab-analysis-report', {
        hatcheryId : 'ALL',
        startDate : this.firstDay.toISOString(),
        endDate : this.lastDay.toISOString(),
        speciesType : 'ALL'
      });
      
      total = response.data;
      
      /* 
      // no lab report approval process anymore, all reports are approved by default
      response = await fetchBookingReportsCount('lab-analysis-report', {
        hatcheryId : 'ALL', approvalFlag : 'Approved',
        startDate : this.firstDay.toISOString(),
        endDate : this.lastDay.toISOString(),
        speciesType : 'ALL'
      });*/
      
      approved = response.data;
      if (total) {
        currentData.datasets[0].data = [
          Math.floor(((total - approved) * 100) / total),
          Math.floor((approved * 100 ) / total),
        ];
        this.setState({data : currentData, noData : false});
      } else {
        noData.datasets[0].backgroundColor='#d2dee2';
        this.setState({data : noData, noData : true});
      }
      
    } catch(error) {
      console.log(error);
      this.setState({data : noData, noData : true});
    }
    
  }
  
  async componentDidMount() {
    
    let date = new Date(this.state.selectedMonth);
    this.year = date.getFullYear();
    this.month =  date.getMonth();
    this.firstDay = new Date(this.year, this.month, 1);
    this.lastDay = new Date(this.year, this.month + 1, 0);
    
    await this.setupGraphData();
  };

  handleMonthChange = async (value) => {
    let currentDate = this.state.selectedMonth;
    
    this.setState({selectedMonth : value.toISOString().split('T')[0], data : noData, noData : true });

    this.year = value.getFullYear();
    this.month =  value.getMonth();
    this.firstDay = new Date(this.year, this.month, 1);
    this.lastDay = new Date(this.year, this.month + 1, 0);
    
    if (currentDate !== value.toISOString().split('T')[0]) {
      await this.setupGraphData();
    }
  }
  
  
  render() {
    return (<Card>
      <CardHeader
        action={(
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month']}
              label="Select Month"
              value={this.state.selectedMonth}
              onChange={this.handleMonthChange}
              renderInput={(params) => <TextField {...params}
              fullWidth
              margin="normal"
              variant="standard"
            />}
            />
          </LocalizationProvider>
        )}
        title="Lab Report Verification Status"
      />
      <Divider />
      <CardContent>
        <Box sx={{ height: 180,  position: 'relative' }} >
          <Doughnut data={this.state.data} options={this.state.noData ? noDataOptions : options} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
          <Box key="Pending Reports" sx={{p: 1, textAlign: 'center'}} >
            <Icon color="action" />
            <Typography color="textPrimary" variant="body1">Pending Reports</Typography>
            <Typography style={{ color: colors.brown[500] }} variant="h2"> 
              {this.state.noData ? 0 : this.state.data.datasets[0].data[0]} % 
            </Typography>
          </Box>
          <Box key="Verified Reports" sx={{p: 1, textAlign: 'center'}} >
            <Icon color="action" />
            <Typography color="textPrimary" variant="body1">Verified Reports</Typography>
            <Typography style={{ color : colors.lightBlue[500] }} variant="h2"> 
              {this.state.noData ? 0 : this.state.data.datasets[0].data[1]} % 
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>);
  }
}