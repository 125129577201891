import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { authPersonFields } from '../bookings.fields';

import { addAuthPersonDetails } from 'services/bookings';

export default class AuthPersonForm extends React.Component {
 
  constructor(props) {
    super(props);
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (formData) => {
    let statusMessage = { status: "success", message: "Successfully saved authorized person details...." };
    try {
      let response = await addAuthPersonDetails(this.props.bookingData.id, formData);
      if (this.props.refresh) this.props.refresh();
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    return statusMessage;
  }
  
  render() {
    return(<>
      <Typography variant="h4" align="center" p={1} > 
      { 
        `Update Authorized Person BS Receiving Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
      }
      </Typography><hr style={{borderColor:"#eef1f6"}} />
      <DynamicForm
        formFields={authPersonFields}
        action={this.props.bookingData.authorisedPerson ? "update" : "create"}
        handleSubmit={this.handleFormSubmit}
        buttonText="Save Auth Person Details"
        buttonWidth= "full"
        columns = {1}
        currentValues={this.props.bookingData.authorisedPerson}
        redirect={null}
        {...this.props}
      />
    </>);
  }
}