export default [
  {  
    name: 'name',
    label: 'Supplier Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'contactPerson',
    label: 'Contact Person',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'speciesIds',
    label: 'Species Type',
    type: 'select',
    properties: [{required:true}],
    selectOptions : {},
    actions : ['create', 'update'],
  },
  {
    name: 'speciesName',
    label: 'Species Type',
    type: 'string',
    properties: [{required:true}],
    actions : ['view', 'index'],
  },
  {
    name: 'country',
    label: 'Country Name',
    type: 'string',
    actions : ['view', 'create', 'update', 'index'],
    properties: [{ required:true}], 
  },
  {
    name: 'state',
    label: 'State Name',
    type: 'string',
    actions : ['view', 'create', 'update'],
    properties: [{ required:false}, {string : true}], 
  },
  {
    name: 'city',
    label: 'City Name',
    type: 'string',
    actions : ['view', 'create', 'update'],
    properties: [{ required:false}, {string : true}], 
  },
  {  
    name: 'address',
    label: 'Address',
    type: 'string',
    properties: [{required:true}],
    actions : ['create', 'update', 'view'],
  },
  {  
    name: 'mobileNumber',
    label: 'Mobile Number',
    type: 'string',
    properties: [{required:true}],
    actions : ['create', 'update'],
  },
  {
    name: 'phoneNumber',
    label: 'Alternate Number',
    type: 'string',
    actions : ['view', 'create', 'update', 'index'],
    properties: [{ required:false }], 
  },
  {  
    name: 'faxNumber',
    label: 'Fax Number',
    type: 'string',
    properties: [{required:false}],
    actions : ['create', 'update'],
  },
  {  
    name: 'email',
    label: 'E-mail Address',
    type: 'email',
    properties: [{required:true, email : true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'status',
    label: 'Status',
    type: 'select',
    properties: [{required:true}],
    default : true,
    selectOptions : {
      true : "Active",
      false : "In Active"
    },
    actions: ['create', 'update', 'view', 'index']
  }
];
