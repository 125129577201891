import React, { Component } from 'react';

import { withLocationParams } from 'hoc';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import addnChargesFields from './additional-charges.fields';

import { fetchAddnChargesById, updateAddnCharges } from 'services/additional-charges';

class EditAdditionalCharges extends Component {
  
  state = { 
    addnChargesData : null, 
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."}
  };
  
  constructor(props) {
    super(props);
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated additional charges details..." };
    
    // todo API calls
    
    return statusMessage;
  }
  
  async componentDidMount() {
    let addnChargesData = null;
    let statusMessage = this.state.statusMessage;
    try {
      let response = await fetchAddnChargesById(this.props.locationParams.id);
      addnChargesData = response.data;
      addnChargesData.arrivedDate = (addnChargesData.bookingId || {}).stockStatus ? addnChargesData.bookingId.stockStatus.arrivalReport : null;
      addnChargesData.bookingId = (addnChargesData.bookingId || {}).id;
      addnChargesData.hatcheryId = (addnChargesData.hatcheryId || {}).name;
      statusMessage = {status: "success", message: "Fetched additional charges details successfully !"}  
    } catch (error) {
      console.log (error);
      statusMessage = { status : "error", message : error.message };
    }
    await this.setState({addnChargesData, statusMessage});
  }

  render() {
    return (
      <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
        <Typography variant="h4"> Update Additional Charges </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
        {
          this.state.addnChargesData &&
          <DynamicForm
            formFields={addnChargesFields}
            action="update"
            handleSubmit={this.handleFormSubmit}
            buttonText="Update Additional Charges"
            buttonWidth= "full"
            columns = {2}
            currentValues={this.state.addnChargesData}
            redirect={null}
            confirmation={"Are you sure you want to save the details ? "}
          />
        }
      </Paper>
    );
  }
}

export default withLocationParams(EditAdditionalCharges);
