import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackbar(props) {
  const { message, onClose, variant, open } = props;
  // const [open, setOpen] = React.useState(true);

  // const handleClick = () => {
  //  setOpen(true);
  // };

  // const handleClose = (event, reason) => {
  //  setOpen(false);
  // };

  return (
  <Snackbar open={open ? true : false} autoHideDuration={6000} onClose={onClose} 
    anchorOrigin = {{vertical : "top", horizontal : "center"}} sx={{p:2, width: '60%'}}>
    <Alert onClose={onClose} severity={variant} variant="filled" sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
  );  
}