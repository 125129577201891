import { AuthStore, PermissionsDataStore } from 'store';

export function permissionCheck(module, operation) {
  
  let roleId = AuthStore.state && AuthStore.state.currentUser ? AuthStore.state.currentUser.user.role.id : 0;
  let permissions = PermissionsDataStore.state;
  let modulePermissions = null;
  
  try {
    switch (module) {
      case 'hatchery':
      case 'transaction':
      case 'common':
      case 'supplier':
        modulePermissions = permissions[module];
        break;
      case 'user-accounts':
        modulePermissions = permissions['userProfile'];
        break;
      case 'shipment':
      case 'booking':
        modulePermissions = permissions['booking'];
        break;
      case 'booking-schedules':
        modulePermissions = permissions['schedule'];
        break;
      case 'booking-reports':
        modulePermissions = permissions['bookingReports'];
        break;
      case 'app-reports':
        modulePermissions = permissions['appReports'];
        break;
      default:
        return false;
    }
    
    if (!modulePermissions) return false;
    
    return modulePermissions.hasOwnProperty(operation) ? modulePermissions[operation].enable : false;
   
  } catch(error) {
    console.log(error);
  }
  
  return false;
};

export function permittedFields(module, operation) {
  
  let permissions = PermissionsDataStore.state;
  let modulePermissions = null;
  
  try {
    switch (module) {
      case 'hatchery':
      case 'transaction':
      case 'common':
      case 'supplier':
        modulePermissions = permissions[module];
        break;
      case 'user-accounts':
        modulePermissions = permissions['userProfile'];
        break;
      case 'shipment':
      case 'booking':
        modulePermissions = permissions['booking'];
        break;
      case 'booking-schedules':
        modulePermissions = permissions['schedule'];
        break;
      case 'booking-reports':
        modulePermissions = permissions['bookingReports'];
        break;
      case 'app-reports':
        modulePermissions = permissions['appReports'];
        break;
      default:
        return false;
    }
    
    if (!modulePermissions) return false;

    return modulePermissions.hasOwnProperty(operation) && modulePermissions[operation].fields 
      ? modulePermissions[operation].fields 
      : [];
   
  } catch(error) {
    console.log(error);
  }
}