import { fetchWithTimeout, addPagerParams } from './utils';
import { AuthStore, resetAllStores } from 'store';

import crypto from "crypto-js";

import { permissionCheck } from 'permissions';

// authentication API
const AUTH_EP_URL_UPDATE = "/users";
const AUTH_EP_URL_LOGIN = "/auth/local";
const AUTH_EP_URL_FORGOT_PASSWORD = "/local/forgot-password"; // forgot password-otp
const AUTH_EP_URL_RESET_PASSWORD = "/local/reset-password"; // reset password token
const AUTH_EP_URL_USER_LOGOUT = "/auth/logout"; // user logout
const AUTH_EP_URL_ROLES = "/users-permissions/roles"; // all roles
const AUTH_CAPTCHA_EP_URL = "/settings/verify-google-captcha"; // google captcha validation

// user account API
const USER_ACCOUNT_EP = '/user-profiles';

export const processLoginReq = async (params) => {
  let encryptedParams = crypto.AES.encrypt(JSON.stringify(params), process.env.REACT_APP_BODY_ENCRYPT_KEY).toString();

  return await fetchWithTimeout({
    path: AUTH_EP_URL_LOGIN,
    method : 'POST',
    body : {formData : encryptedParams}, 
    useToken : false,
    operation: 'user-profile:login'
  });
};

export const processLoginResp = (params) => {
  AuthStore.setState({currentUser : params});
  return params;
}

export const processLogoutReq = async (params) => {
  let user = AuthStore.state.currentUser;
  if (!user) return;
  let response = await fetchWithTimeout({
    path: AUTH_EP_URL_USER_LOGOUT,
    method : 'POST',
    body : {jwt : user.jwt},
    operation: 'user-profile:logout'
  });
  
  if (response.status === 200) {
    // remove currentUser data
    resetAllStores();
  }
  
  return response;
};

export const forgotPasswordOTP = async (params) => {
  return await fetchWithTimeout({
    path: AUTH_EP_URL_FORGOT_PASSWORD,
    method : 'POST',
    body : params,
    useToken : false,
    operation: 'user-profile:reset-password'
  });
}

export const resetPasswordOTP = async (params) => {
  return await fetchWithTimeout({
    path: AUTH_EP_URL_RESET_PASSWORD,
    method : 'POST',
    body : params,
    useToken : false,
    operation: 'user-profile:reset-password'
  });
}

export const fetchUserAccountsTotalCount = async (params) => {
  let response = await fetchWithTimeout({
    path: USER_ACCOUNT_EP + '/count?_start=0'
      + (params.status !== 'ALL' ? '&user.blocked=' + (params.status == "true" ? false : true) : '')
      + (params.role !== 'ALL' ? '&user.role.id=' + !params.role : '&user.role.name_ne=Hatchery Owner')
      + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    useToken : true,
    operation: 'user-profile:view-any'
  });
  
  return response;
}

export const fetchUserAccounts = async (params) => {
  let response = await fetchWithTimeout({
    path: USER_ACCOUNT_EP + addPagerParams(params)
      + (params.status !== 'ALL' ? '&user.blocked=' + (params.status == "true" ? false : true) : '')
      // filter by roles, always ignore hatchery owners
      + (params.role !== 'ALL' ? '&user.role.id=' + params.role :  '&user.role.name_ne=Hatchery Owner')
      + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    useToken : true,
    operation: 'user-profile:view-any'
  });
  
  return response;
}

export const fetchUserAccountById = async (userId) => {
  let response = await fetchWithTimeout({
    path: USER_ACCOUNT_EP + '/' + userId,
    method : 'GET',
    useToken : true,
    operation: permissionCheck('user-accounts', 'view-self') ? 'user-profile:view-self' : 'user-profile:view-any'
  });
  
  return response;
}

export const createNewUserAccout = async (params) => {
  return await fetchWithTimeout({
    path: USER_ACCOUNT_EP,
    method: 'POST',
    body : params,
    operation: 'user-profile:create'
  });
};

export const updateUserAccount = async (userId, params) => {
  return await fetchWithTimeout({
    path: USER_ACCOUNT_EP + '/' + userId,
    method: 'PUT',
    body : params,
    operation: permissionCheck('user-accounts', 'change-request') ? 'user-profile:change-request' : 'user-profile:update'
  });
};

export const updateUserAccountAdminOps = async (userId, params) => {
  return await fetchWithTimeout({
    path: USER_ACCOUNT_EP + '/' + userId,
    method: 'PUT',
    body : params,
    operation:'user-profile:update:'+userId
  });
};

export const updateUserAccountSelf = async (userId, params) => {
  return await fetchWithTimeout({
    path: USER_ACCOUNT_EP + '/' + 3,
    method: 'PUT',
    body : params,
    operation: 'user-profile:update-self:'+userId
  });
};

export const approveUserDetails = async (userId, params) => {
  return await fetchWithTimeout({
    path: USER_ACCOUNT_EP + '/approval/' + userId,
    method: 'PUT',
    body : params,
    operation: 'user-profile:approve-changes:'+userId
  });
}

export const verifyCaptchaInput = async (params) => {
  return await fetchWithTimeout({
    path: AUTH_CAPTCHA_EP_URL,
    method: 'POST',
    body : params,
    useToken:false,
    operation: 'common:validate-otp'
  });
}
