import { useState } from 'react';
import { obfuscateData } from 'services/utils';

import { 
  Grid, TableContainer, Table, TableBody, TableRow, TableCell,
  Dialog, DialogContent, DialogTitle, Typography, Box, Button,
  Tooltip, IconButton
} from '@mui/material';

import { 
  Close,
  AccountBalanceOutlined,
} from '@mui/icons-material';

import ImageOrDocument from 'components/ImageOrDocument';
import SingleBankDetailsForm from '../form-components/SingleBankDetailsForm';

import { permissionCheck } from 'permissions';

const DisplayTable = (props) => {
  return (      
    <TableContainer sx={{ px: 3 }}>
      <Table size="small">
        <TableBody>
         <TableRow hover role="checkbox" tabIndex={-1} key={1}>
          <TableCell key={'row-1-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Account Name</p></TableCell>
          <TableCell key={'row-1-col-1'} align="left">{props.bankDetails.accountName}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={2}>
          <TableCell key={'row-2-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Account Number</p></TableCell>
          <TableCell key={'row-2-col-1'} align="left">{obfuscateData("bank", props.bankDetails.accountNumber)}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={3}>
          <TableCell key={'row-3-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Account Type</p></TableCell>
          <TableCell key={'row-3-col-1'} align="left">{props.bankDetails.accountType}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={4}>
          <TableCell key={'row-4-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Bank Name</p></TableCell>
          <TableCell key={'row-4-col-1'} align="left">{props.bankDetails.bankName}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={5}>
          <TableCell key={'row-5-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Branch Name</p></TableCell>
          <TableCell key={'row-5-col-1'} align="left">{props.bankDetails.branchName}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={6}>
          <TableCell key={'row-6-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>IFSC Code</p></TableCell>
          <TableCell key={'row-6-col-1'} align="left">{props.bankDetails.IFSC}</TableCell>
        </TableRow>
        {
          props.bankDetails.updateRequestFlag === 'ApprovalPending' &&
          <TableRow hover role="checkbox" tabIndex={-1} key={7}>
            <TableCell key={'row-7-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Status</p></TableCell>
            <TableCell key={'row-7-col-1'} align="left">Approval Pending</TableCell>
          </TableRow>
            
        }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BankDetails = (props) => {
  
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ currentValues, setCurrentValues ] = useState(null);
  
  return (
    <>
      {
        <Dialog open={modalOpen} aria-labelledby="form-dialog-title" fullWidth>
          <DialogTitle id="responsive-dialog-title" sx={{display : 'flex', justifyContent : 'flex-end'}}>
            <IconButton onClick={() => setModalOpen(false)}><Close /></IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h4" align="center" style={{borderBottom : '1px solid'}}>Update Bank Account Details</Typography>
            <SingleBankDetailsForm 
              id = { currentValues ? currentValues.id : null}
              currentValues={currentValues} 
              refresh={() => { setModalOpen(false); props.refresh() }}
            />
          </DialogContent>
        </Dialog>
      }
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ p :2 }}>
        {
          [0,1].map(idx => {
            
            let bankDetails = props.bankDetails && idx < props.bankDetails.length ? props.bankDetails[idx] : null;
            let displayDetails = bankDetails && bankDetails.updateRequestFlag === 'ApprovalPending' 
              ? bankDetails.updateRequestDetails : bankDetails;
            
            if (displayDetails) displayDetails.updateRequestFlag = bankDetails.updateRequestFlag;
            
            return (
              <Grid item sm={6} xs={12}>
              {
                displayDetails ?
                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ p :2 }}>
                  {
                    props.printView === false &&
                    <Grid item sm={3} xs={3} align="center" key="proof">
                      <ImageOrDocument document={displayDetails.bankAccountDetailProof} />
                    </Grid>
                  }
                  <Grid item sm={9} xs={9} align="center" key="details">
                    <DisplayTable bankDetails = {displayDetails} />
                    {
                      permissionCheck('hatchery', 'bank-account-change-request') && props.printView === false &&
                      <Box sx={{m : 2}}>
                        <Button color="secondary" size="small" variant="outlined" fullWidth 
                          onClick = { e => {setCurrentValues(props.bankDetails[idx]); setModalOpen(true) }}
                          disabled={props.bankDetails[idx].updateRequestFlag === 'ApprovalPending'}
                        >
                          Update Bank Details 
                        </Button>
                      </Box>
                    }
                  </Grid>
                </Grid> :
                <>
                {
                  props.printView === false &&
                  permissionCheck('hatchery', 'bank-account-change-request') &&
                  <Tooltip title = "Add New Bank Account">
                   <Box display="flex" justifyContent="center">
                      <IconButton color="error" onClick = { e => { setCurrentValues(null); setModalOpen(true) }} >
                        <AccountBalanceOutlined sx={{fontSize:80}} />
                      </IconButton>
                    </Box>
                  </Tooltip>
                }
                </>
              }
              </Grid>
            )
          })
        }
      </Grid>
    </>
  );
}

export default BankDetails;
