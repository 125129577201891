import { fetchWithTimeout, addPagerParams } from './utils';
import { AuthStore, HatcheryStore } from 'store';

import { permissionCheck } from 'permissions';
 
const HATCHERY_EP_URL = "/hatcheries";
const HATCHERY_LICENSE_EP_URL = "/hatchery-licenses";
const BANKACCOUNT_EP_URL = "/bank-account-details";
  
export const fetchHatcheriesTotalCount = async (params) => {
  let statusParams = '';
  switch (params.status) {
    case 'Registered':
      statusParams = '&approvalStatus=Approved';
    break;
    case 'UnRegistered':
      statusParams = '&approvalStatus=ApprovalPending&approvalStatus=Rejected&userProfile_null=true';
    default:
    break;
  }
  return await fetchWithTimeout({
    path : HATCHERY_EP_URL + '/count?_start=0' + statusParams
        + (params.speciesType !== 'ALL' ? '&permittedSpeciesId=' + params.speciesType : '')
        + (params.expired !== 'ALL' ? '&expired=' + params.expired : '')
        + (params.state !== 'ALL' ? '&state=' + params.state : '')
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : (params.status === 'Approved') ? 'hatchery:view-registered' : 'hatchery:view-any'
  });
};

export const fetchAllHatcheriesData = async (params) => {
  let statusParams = '';
  switch (params.status) {
    case 'Registered':
      statusParams = '&approvalStatus=Approved';
    break;
    case 'UnRegistered':
      statusParams = '&approvalStatus=ApprovalPending&approvalStatus=Rejected&userProfile_null=true';
    default:
    break;
  }
  
  return await fetchWithTimeout({
    path : HATCHERY_EP_URL + addPagerParams(params, 'name:ASC') + statusParams
        + (params.speciesType !== 'ALL' ? '&permittedSpeciesId=' + params.speciesType : '')
        + (params.expired !== 'ALL' ? '&expired=' + params.expired : '')
        + (params.state !== 'ALL' ? '&state=' + params.state : '')
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : (params.status === 'Approved') ? 'hatchery:view-registered' : 'hatchery:view-any'
  });  
};

export const fetchUnregisteredHatcheriesCount = async (params) => {
  return await fetchWithTimeout({
    path : HATCHERY_EP_URL + '/count?approvalStatus=ApprovalPending&approvalStatus=Rejected&userProfile_null=true'
        + (params && params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'hatchery:view-unregistered'
  });
}

export const fetchUnregisteredHatcheries = async (params) => {
  return await fetchWithTimeout({
    path : HATCHERY_EP_URL + addPagerParams(params) 
        + '&approvalStatus=ApprovalPending&approvalStatus=Rejected&userProfile_null=true'
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'hatchery:view-unregistered'
  });  
}

export const fetchUnregisteredHatcheryById = async (hatcheryId) => {
  return await fetchWithTimeout({
    path : HATCHERY_EP_URL + '/' + hatcheryId,
    method : 'GET',
    operation : 'hatchery:view-unregistered'
  });
}
  
export const fetchApprovalPendingHatcheriesCount = async (params) => {
  return await fetchWithTimeout({
    path : HATCHERY_EP_URL + '/count?approvalStatus=ApprovalPending&approvalStatus=Rejected&userProfile_null=false'
        + (params && params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'hatchery:view-any'
  });
}

export const fetchApprovalPendingHatcheries = async (params) => {
  return await fetchWithTimeout({
    path : HATCHERY_EP_URL + addPagerParams(params) 
        + '&approvalStatus=ApprovalPending&approvalStatus=Rejected&userProfile_null=false'
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'hatchery:view-any'
  });  
}

export const fetchPendingHatcheryChanges = async (params) => {
  return await fetchWithTimeout({
    path : HATCHERY_EP_URL + addPagerParams(params, 'updated_at:desc') + '&updateRequestFlag=ApprovalPending'
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'hatchery:view-any'
  });  
}

export const fetchPendingHatcheryChangesCount = async (params) => {
  return await fetchWithTimeout({
    path : HATCHERY_EP_URL + '/count?updateRequestFlag=ApprovalPending'
        + (params && params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'hatchery:view-any'
  });
}


export const fetchHatcheryById = async (hatcheryId) => {
  let response = await fetchWithTimeout({
    path : HATCHERY_EP_URL + '/' + hatcheryId,
    method : 'GET',
    operation : permissionCheck('hatchery', 'view-self') ? 'hatchery:view-self' : 'hatchery:view-any'
  });
  
  const currentUser = AuthStore.state.currentUser;
  if (currentUser && currentUser.user.role.name === 'Hatchery Owner') {
    HatcheryStore.setState({currentHatchery : response.data});
  }
  return response;
};

export const fetchLicensesCount = async(params) => {
   return await fetchWithTimeout({
    path: HATCHERY_LICENSE_EP_URL + '/count?_start=0'
      + (params.hatcheryId ? '&hatcheryId=' + params.hatcheryId : ''),
    method: 'GET',
    operation : permissionCheck('hatchery', 'view-self') ? 'hatchery:view-self' : 'hatchery:view-any'
  });
};

export const fetchLicensesData = async(params) => {
   return await fetchWithTimeout({
    path: HATCHERY_LICENSE_EP_URL + addPagerParams(params)
      + (params.hatcheryId ? '&hatcheryId=' + params.hatcheryId : ''),
    method: 'GET',
    operation : permissionCheck('hatchery', 'view-self') ? 'hatchery:view-self' : 'hatchery:view-any'
  });
};

export const createNewHatchery = async (params) => {
  return await fetchWithTimeout({
    path: HATCHERY_EP_URL,
    method: 'POST',
    body : params,
    operation : 'hatchery:create'
  });
};

export const registerHatchery = async (hatcheryId, params) => {
  return await fetchWithTimeout({
    path: HATCHERY_EP_URL + '/register/' + hatcheryId,
    method: 'PUT',
    body : params,
    operation : 'hatchery:register:' + hatcheryId
  });
};


export const updateHatcheryDetails = async (hatcheryId, params) => {
   return await fetchWithTimeout({
    path: HATCHERY_EP_URL + '/' + hatcheryId,
    method: 'PUT',
    body : params,
    operation : permissionCheck('hatchery', 'change-request') ? 'hatchery:change-request:'+hatcheryId : 'hatchery:update:'+hatcheryId
  });
};

export const approveHatcheryDetails = async (hatcheryId, params) => {
  return await fetchWithTimeout({
    path: HATCHERY_EP_URL + '/approval/' + hatcheryId,
    method: 'PUT',
    body : params,
    operation : 'hatchery:approve:' + hatcheryId
  });
};

export const setHatcheryPassword = async (params) => {
   return await fetchWithTimeout({
    path: HATCHERY_EP_URL + '/setpasswd',
    method: 'POST',
    body : params,
    operation : 'hatchery:update'
  });
};

export const createNewBankAccount = async (params) => {
  return await fetchWithTimeout({
    path: BANKACCOUNT_EP_URL,
    method: 'POST',
    body : params,
    operation : 'hatchery:create-bank-account'
  });
};

export const updateBankAccountDetails = async (accountId, params) => {
   return await fetchWithTimeout({
    path: BANKACCOUNT_EP_URL + '/' + accountId,
    method: 'PUT',
    body : params,
    operation : 'hatchery:bank-account-change-request:' + accountId
  });
};


export const approveBankDetails = async (accountId, params) => {
  return await fetchWithTimeout({
    path: BANKACCOUNT_EP_URL + '/approval/' + accountId,
    method: 'PUT',
    body : params,
    operation: 'hatchery:approve-bank-account-changes:' + accountId
  });
}

export const createNewHatcheryLicense = async (params) => {
   return await fetchWithTimeout({
    path: HATCHERY_LICENSE_EP_URL,
    method: 'POST',
    body : params,
    operation : 'hatchery:create-license'
  });
};

export const updateHatcheryLicense = async (licenseId, params) => {
   return await fetchWithTimeout({
    path: HATCHERY_LICENSE_EP_URL + '/' + licenseId,
    method: 'PUT',
    body : params,
    operation : 'hatchery:update-license:'+licenseId
  });
};

export const fetchHatcheriesByFyearCount = async (params) => {
  return await fetchWithTimeout({
    path : HATCHERY_LICENSE_EP_URL + '/count?_start=0&hatcheryId.userProfile_null=false'
      + (params.state !== 'ALL' ? '&hatcheryId.state=' + params.state : '')
      + (params.speciesType !== 'ALL' ? '&hatcheryId.permittedSpeciesId=' + params.speciesType : '')
      + (params.validTo ? '&validTo_gte=' + params.validTo : '')
      + (params.validFrom ? '&validFrom_lte=' + params.validFrom : '')
      + (params.name && params.name.length ? '&hatcheryId.name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'hatchery:view-any'
  });
};

export const fetchHatcheriesByFyear = async (params) => {
  return await fetchWithTimeout({
    path : HATCHERY_LICENSE_EP_URL + '/fy-wise-hatcheries' 
      + (params.download ? '?_start=0&_limit=-1' : addPagerParams(params, 'hatcheryId.name:ASC'))
      + (params.state !== 'ALL' ? '&hatcheryId.state=' + params.state : '')
      + (params.speciesType !== 'ALL' ? '&hatcheryId.permittedSpeciesId=' + params.speciesType : '')
      + (params.validTo ? '&validTo_gte=' + params.validTo : '')
      + (params.validFrom ? '&validFrom_lte=' + params.validFrom : '')
      + (params.name && params.name.length ? '&hatcheryId.name_contains=' + params.name : '')
      + (params.download ? '&download=true' : '')
      + '&hatcheryId.userProfile_null=false',
    method : 'GET',
    operation : 'hatchery:view-any'
  });  
};
