import React, { Component } from 'react';

import {Box, Grid, Typography, Table, TableHead, TableBody, TableRow, TableCell} from '@mui/material';

const headerboxStyle = { width: 64, height: 64,  p: 1, m : 1};
const boxStyle = { width: 64, height: 40,  p: 1, m : 1, borderRadius : '50%', borderColor : 'primary.main', border: '1px dashed', cursor:"pointer" };
const selectedboxStyle = { bgcolor: 'primary.main', color:'#fff', width: 64, height: 40, p: 1, m : 1, borderRadius : '50%', cursor:"pointer", border: '1px dashed' };
const legendboxStyle = { width: 40, height: 40, p: 1, bgcolor: 'primary.main', color:'primary.main'};



export default class MultipleDateSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedDates : [] };
  }
  
  initializeSelectedDates() {
    let date = new Date(this.props.currentMonth);
    this.year = date.getFullYear();
    this.month =  date.getMonth();
    this.firstDay = new Date(this.year, this.month, 1);
    this.lastDay = new Date(this.year, this.month + 1, 0);
    this.totalDays = Math.abs((this.lastDay.getTime() - this.firstDay.getTime()) / (24*60*60*1000)) + 1;
    this.setState({ selectedDates : this.props.selectedDates ? this.props.selectedDates : [] });
  }
  
  componentDidMount() {
    this.initializeSelectedDates();
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentMonth !== prevProps.currentMonth) {
      this.initializeSelectedDates();
    }
  }
  
  handleDateSelect = (date) => {
    let selectedDates = this.state.selectedDates;
    
    let index = selectedDates.findIndex(entry => entry == date);
    if (index >= 0) {
      selectedDates.splice(index, 1);
    } else {
      selectedDates.push(date);
    }
    
    if (this.props.onDateSelect) {
      this.props.onDateSelect(selectedDates);
    }
    this.setState({selectedDates : selectedDates});
  }
  
  render() {
    let monthName = this.firstDay ? this.firstDay.toLocaleString('default', { month: 'short' }) : '';
    let dayname = this.firstDay ? this.firstDay.toLocaleString('default', { weekday : 'short' }).toUpperCase() : '';
    let startOffset = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].findIndex(day => day === dayname);
    let daycount = 0;
    let rows = [];
    if (monthName && dayname) {
      let eachRow = [];
      for (let i = 0; i < startOffset; ++i) {
        eachRow.push(0);
      }
      
      for (let i = 0; i < this.totalDays; ++i) {
        if (eachRow.length === 7) {
          rows.push(eachRow);
          eachRow = [];
        }
        eachRow.push(i+1);
      }
      
      if (eachRow.length) rows.push(eachRow);
    }
    
    return(<>
      {
        this.firstDay && this.year &&
        <Typography variant="h4" align="center"> 
          {`${this.props.cubicle.name} : ${this.firstDay.toLocaleString('default', { month: 'long' })}, ${this.year}` + 
             '- (click on date to select / unselect)'
          }
        </Typography>
      }
      <br/><hr style={{borderColor:"#eef1f6"}} />
      <Grid container sx={{p:2}}>
        <Grid item xs={12} sm={10} alignContent="flex-start">
          <Table sx={{maxWidth : 400, maxHeight:440}} padding="checkbox">
            <TableHead><TableRow>
            {
              ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map (e => {
                return (<TableCell id={'header' + e}sx={{p:1}}>
                  <Box
                    key={e}
                    component="span" 
                    sx={headerboxStyle} 
                  >
                    {e}
                  </Box>
                </TableCell>)
              })
            }
            </TableRow></TableHead>
            <TableBody>
            {
              rows.map((week, idx) => {
                return (<TableRow id = {'row' + idx}>
                {
                  week.map((day, idx2) => {
                    if (day == 0) {
                      return (<TableCell id={'cell' + idx2} sx={{border:'none', p:1}} />)
                    }
                    
                    return (<TableCell id={'cell' + idx2} sx={{border:'none', p:1, height:64}}>
                      <Box
                        key={day}
                        component="span" 
                        sx={this.state.selectedDates.findIndex(entry => entry == (day)) >= 0 ? selectedboxStyle : boxStyle} 
                        onClick={ () => this.handleDateSelect(day) }
                      >
                        {
                          day >= 10 ? <>{day}</> : <>&nbsp;{day}&nbsp;</>
                        }
                      </Box>
                    </TableCell>)
                  })
                }
                </TableRow>)
              })
            }
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={2} alignContent="flex-end">
          <Box key={'1x'} component="span" sx={{ p: 1}}> <Typography variant="overline"> Selected Dates : </Typography></Box>
          <Box key={'1y'} component="span" sx={legendboxStyle}>{'N'}</Box>
        </Grid>
      </Grid>
    </>);
  }
}