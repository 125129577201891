import { useState } from 'react';
import { Paper, TableContainer, Table, TableBody, TableRow, TableCell, Button, Typography  } from '@mui/material';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import EntryGridView from 'components/EntryGridView';

const StatusOptions = {
  "ApprovalPending" : "Approval Pending",
  "Approved" : "Approved",
  "Rejected" : "Rejected"
};

import { hatcheryFields } from '../hatchery.fields';

const HatcheryBasicDetails = (props) => {
  
  const [ alert, setAlert] = useState(!props.hatcheryData.license || props.hatcheryData.updateRequestFlag === 'ApprovalPending');
  
  let formData = { ...props.hatcheryData};
  if (props.hatcheryData.license) {
    formData.CAARegistrationNumber = props.hatcheryData.license.CAARegistrationNumber;
    formData.validFrom = props.hatcheryData.license.validFrom; 
    formData.validTo = props.hatcheryData.license.validTo; 
    formData.noOfBroodsStockPerYear = props.hatcheryData.license.noOfBroodsStockPerYear;
    formData.document = props.hatcheryData.license.document;
    formData.issuingDate = props.hatcheryData.license.issuingDate;
  }
  
  if (typeof props.hatcheryData.permittedSpeciesId === 'object') {
    formData.permittedSpeciesId = props.hatcheryData.permittedSpeciesId.name;
  }

    return (
      <>
        <CustomSnackbar variant="error"
          message= {
            props.hatcheryData.updateRequestFlag === 'ApprovalPending' 
            ? "CAA license renewal document has been submitted, but pending approval" 
            : "CAA license expired for this hatchery !!!!"
          }
          open={alert}
          onClose = { e => setAlert(false) }
        />
        <EntryGridView formFields = { hatcheryFields } values = {formData} />
        {
          props.registration && props.hatcheryData.license &&
          <Button sx={{m : 2}} type="submit" color="primary" onClick={props.handleFormSubmit}
            onSubmit={props.handleFormSubmit} size="large" variant="contained">
            Confirm Hatchery Details
          </Button>
        }
      
    </>
  );
};

export default HatcheryBasicDetails;
