import React, { useState } from "react";
import { NavLink } from 'react-router-dom';

import {
  Paper, Box, Button, List,ListItem,Divider,ListItemText, 
  Typography 
}from '@mui/material';

import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

import {
  LocalShippingOutlined,
  DeveloperBoardOutlined,
  DeveloperBoardOffOutlined,
  ListAltOutlined,
  BusinessOutlined,
  SettingsSuggestOutlined,
  Close
} from '@mui/icons-material';

import NavigateBack from 'components/NavigateBack';

import ReportSettings from './form-components/ReportSettings';

import { permissionCheck } from 'permissions';

export default function AppReportsList() {
  
  const [modalOpen, setModalOpen] = useState(false);
  const [reportType, setReportType] = useState('import-dispatch');
 
  const importDispathPermission = permissionCheck('app-reports', 'import-dispatch');
  const supplierWiseBookingPermissions = permissionCheck('app-reports', 'supplier-wise-booking');
  const zeroBookingsPermissions = permissionCheck('app-reports', 'top-hatchery-bookings');
  const fyHatcheriesReport = permissionCheck('hatchery', 'view-any');
  const reportSettings = permissionCheck('user-accounts', 'update-role-permissions');
  
  const refresh = () => setModalOpen(false);
  
  return (
    <>
      <NavigateBack />
      <Paper elevation={3}  sx={{textAlign:"center", p:2, m:2}}>
        <Typography variant="h3"> List Of All AQMS Reports </Typography>
      </Paper>
      
      <Dialog maxWidth="xl" open={modalOpen} aria-labelledby="form-dialog-title">
        <DialogTitle sx= {{ display : 'flex', justifyContent : 'flex-end'}}>
          <IconButton onClick={()=> setModalOpen(false)}><Close /></IconButton>
        </DialogTitle>
        <DialogContent sx={{textAlign:"center", p:1}}> 
          <ReportSettings reportType={reportType} refresh = {refresh} />
        </DialogContent>
      </Dialog>
        
      <Paper elevation = {4} sx= {{ p:2, m :2 }}>
        <List justifyContent="center" alignItems="center">
          <ListItem alignItems="center" key={1} >
            <Box component="span" sx={{ m: 2, p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
              <LocalShippingOutlined fontSize="large" color={importDispathPermission ? "primary" : "disabled"} />
            </Box>
            <ListItemText
              sx={{flexGrow :1 }}
              primary="Import and Dispatch Report"
              secondary="view and download import and dispatch report for completed bookings."
            />
            { 
              reportSettings &&
              <Button 
                variant="outlined" 
                color="primary" 
                size="small"
                disabled={reportSettings === false} 
                onClick = { e => { setReportType('import-dispatch') ; setModalOpen(true) }}
                startIcon={<SettingsSuggestOutlined />}
                sx= {{ mr : 2}}
              > 
                Settings
              </Button>
            }
            <Button 
              variant="outlined" 
              color="primary" 
              size="small"
              disabled={importDispathPermission === false} 
              component={NavLink}
              to={importDispathPermission ? "/aqf/reports/import-dispatch" : '/not-found'}
              startIcon={<ListAltOutlined />}
            > 
              View Report 
            </Button>
          </ListItem>
          <Divider variant="inset" component="li" key={2} />
          <ListItem alignItems="center" key={3} >
            <Box component="span" sx={{ m:2, p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
              <DeveloperBoardOutlined fontSize="large" color={supplierWiseBookingPermissions ? "warning" : "disabled"}  />
            </Box>
            <ListItemText
              sx={{flexGrow :1 }}
              primary="Supplier wise Bookings Report"
              secondary=" view and download supplier wise booking report."
            />
            { 
              reportSettings &&
              <Button 
                variant="outlined" 
                color="warning" 
                size="small"
                disabled={reportSettings === false}
                onClick = { e => { setReportType('supplier-wise-booking') ; setModalOpen(true) }}
                startIcon={<SettingsSuggestOutlined />}
                sx= {{ mr : 2}}
              > 
                Settings
              </Button>
            }
            <Button 
              variant="outlined" 
              color="warning" 
              size="small"
              disabled={supplierWiseBookingPermissions === false}
              component={NavLink}
              to={supplierWiseBookingPermissions ? "/aqf/reports/supplier-wise-booking" : '/not-found'}
              startIcon={<ListAltOutlined />}
            > 
              View Report 
            </Button>
          </ListItem>
          <Divider variant="inset" component="li" key={4}  />
          <ListItem alignItems="center" key={5} >
            <Box component="span" sx={{ m:2, p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
              <DeveloperBoardOffOutlined fontSize="large" color={zeroBookingsPermissions ? "secondary" : "disabled"}  />
            </Box>
            <ListItemText
              sx={{flexGrow :1 }}
              primary="Zero Booking Hatcheries Report"
              secondary="view and download zero booking hatcheries report."
            />
            { 
              reportSettings &&
              <Button 
                variant="outlined" 
                color="secondary" 
                size="small"
                disabled={reportSettings === false}
                onClick = { e => { setReportType('zero-bookings') ; setModalOpen(true) }}
                startIcon={<SettingsSuggestOutlined />}
                sx= {{ mr : 2}}
              > 
                Settings
              </Button>
            }
            <Button 
              variant="outlined" 
              color="secondary" 
              size="small"
              disabled={zeroBookingsPermissions === false}
              component={NavLink}
              to={zeroBookingsPermissions ? "/aqf/reports/zero-bookings" : '/not-found'}
              startIcon={<ListAltOutlined />}
            > 
              View Report 
            </Button>
          </ListItem>
          <Divider variant="inset" component="li" key={6} />
          <ListItem alignItems="center" key={7} >
            <Box component="span" sx={{ m:2, p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
              <BusinessOutlined fontSize="large" color={fyHatcheriesReport ? "info" : "disabled"}  />
            </Box>
            <ListItemText
              sx={{flexGrow :1 }}
              primary="Financial Year Wise Hatcheries Report"
              secondary="view and download financial year wise hatcheries report."
            />
            { 
              reportSettings &&
              <Button 
                variant="outlined" 
                color="info" 
                size="small"
                disabled={reportSettings === false}
                onClick = { e => { setReportType('fy-hatcheries') ; setModalOpen(true) }}
                startIcon={<SettingsSuggestOutlined />}
                sx= {{ mr : 2}}
              > 
                Settings
              </Button>
            }
            <Button 
              variant="outlined" 
              color="info" 
              size="small"
              disabled={fyHatcheriesReport === false}
              component={NavLink}
              to={fyHatcheriesReport ? "/aqf/reports/fy-wise-hatcheries" : '/not-found'}
              startIcon={<ListAltOutlined />}
            > 
              View Report 
            </Button>
          </ListItem>
          <Divider variant="inset" component="li" key={8} />
        </List>
      </Paper>
    </>
  );
}