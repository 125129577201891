import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import { CKEditor } from 'ckeditor4-react';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
// import GoogleAddress from './GoogleAddress';
import { Typography } from '@mui/material';

import ImageOrDocument from 'components/ImageOrDocument';

import ReCAPTCHA from "react-google-recaptcha";

export const FieldCreator = props => {

  let { name, type, label, selectOptions, value, onChange, error, helperText, required } = props;
  let keys = selectOptions ? Object.keys(selectOptions) : [];
  
  // format label string
  if (type === 'currency') label = label + ' (&#x20B9;)';
  if (required) label = label + ' *';
  
  switch (type) {
    case 'string':
    case 'email':
    case 'url':
    case 'currency':
      return (
        <TextField
          id={name}
          label={type === 'currency' ? <p dangerouslySetInnerHTML={{__html : label }} /> : label}
          name={name}
          fullWidth
          type="text"
          size="small"
          value={value}
          onChange={e => onChange(e, name)}
          margin="normal"
          variant="standard"
          InputProps={{ readOnly: props.readOnly }}
          disabled = { props.readOnly }
          error={error}
          helperText={helperText}
        />
      );
    case 'float':
      let floatInputProps = {readOnly: props.readOnly};
      if (props.floatProps && props.floatProps.min && props.floatProps.max) {
        floatInputProps['min'] = props.floatProps.min;
        floatInputProps['max'] = props.floatProps.max;
      }
      floatInputProps['step'] = props.floatProps && props.floatProps.step ? props.floatProps.step : '0.1';
      return (
        <TextField
          id={name}
          label={label}
          name={name}
          fullWidth
          type="number"
          size="small"
          value={parseFloat(value)}
          margin="normal"
          variant="standard"
          disabled = { props.readOnly }
          inputProps={ floatInputProps }
          onChange={e => onChange(e, name)}
          error={error}
          helperText={helperText}
        />
      );
    case 'number':
      let numberInputProps = {readOnly: props.readOnly};
      if (props.numberProps && props.numberProps.min && props.numberProps.max) {
        numberInputProps['min'] = props.numberProps.min;
        numberInputProps['max'] = props.numberProps.max;
      }
      numberInputProps['step'] = props.numberProps && props.numberProps.step ? props.numberProps.step : '5';
      return (
        <TextField
          id={name}
          label={label}
          name={name}
          fullWidth
          type="number"
          size="small"
          value={value}
          margin="normal"
          variant="standard"
          disabled = { props.readOnly }
          inputProps={ numberInputProps }
          onChange={e => onChange(e, name)}
          error={error}
          helperText={helperText}
        />
      );
    case 'password':
      return (
        <TextField
          id={name}
          label={label}
          name={name}
          fullWidth
          type="password"
          size="small"
          value={value}
          onChange={e => onChange(e, name)}
          margin="normal"
          variant="standard"
          disabled = { props.readOnly }
          InputProps={{ readOnly: props.readOnly }}
          error={error}
          helperText={helperText}
        />
      );
    case 'text':
      /*return (
        <div>
           <Typography
            className="ckeditor-title"
            variant="subtitle1">
            <span>{label}</span>
          </Typography>
          <CKEditor
            onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
            data={value}
            onChange={e => props.onEditorChange(e, name)}
            readOnly={props.readOnly}
          />
        </div>
      );*/
      
      return (
        <TextField
          id={name}
          label={label}
          name={name}
          fullWidth
          type="password"
          size="small"
          value={value}
          onChange={e => onChange(e, name)}
          margin="normal"
          variant="standard"
          disabled = { props.readOnly }
          multiline={true}
          minRows={3}
          InputProps={{ readOnly: props.readOnly }}
          error={error}
          helperText={helperText}
        />
      );
    case 'date':
      return (
        <TextField
          id={name}
          label={label}
          name={name}
          fullWidth
          size="small"
          multiline={false}
          rows = {1}
          type={'date'}
          value={value && value.length ? moment(value).format('YYYY-MM-DD') : null}
          onChange={e => onChange(e, name)}
          margin="normal"
          variant="standard"
          error={error}
          helperText={helperText}
          InputLabelProps={{ shrink: true }}
          InputProps={{ readOnly: props.readOnly }}
          disabled = { props.readOnly }
        />
      );
    case 'datetime':
      return (
        <TextField
          id={name}
          label={label}
          name={name}
          fullWidth
          size="small"
          multiline={false}
          rows = {1}
          type={'datetime-local'}
          value={value && value.length ? moment(value).format('YYYY-MM-DDTHH:mm') : null}
          onChange={e => onChange(e, name)}
          margin="normal"
          variant="standard"
          error={error}
          helperText={helperText}
          InputLabelProps={{ shrink: true }}
          InputProps={{ readOnly: props.readOnly }}
          disabled = { props.readOnly }
        />
      );  
    case 'boolean':
       return (<Box sx={{ mt:'20px', borderBottom : '1px solid grey'}}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={6} sm={6} alignItems="flex-start">
            <Typography variant="body2" align="left">{label}</Typography>
          </Grid>
          <Grid item xs={6} sm={6}  alignContent="flex-start">
            <Switch
              color="primary"
              checked={value}
              name={name}
              size="small"
              value={value}
              onChange={e => onChange(e, name)}
              disabled = {props.readOnly}
            />
            {              
              props.valueStrings !== undefined ?
                <Typography variant="overline">{props.valueStrings[value ? 1 : 0]}</Typography> :
                <Typography variant="overline">{label}</Typography>
            }
          </Grid>
        </Grid>
        <Grid container sx={{ mt :1}}>
        {
          error &&
          <FormHelperText error>{error}</FormHelperText>
        }
        </Grid>
      </Box>);
    case 'image':
    case 'file':
      let width = (type === 'file' || typeof value === 'string' || value.hasOwnProperty('blob')) 
        ? 10 : value.length > 1 ? 6 : 10;
        
      return (
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item xs={width} sm={width} key={1}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              id={name}
              label={label}
              name={name}
              fullWidth
              size="small"
              type='file'
              onClick={e => {
                e.target.value = null;
                props.onFileInputChange(e, name)
              }}
              onChange={e => props.onFileInputChange(e, name)}
              margin="normal"
              variant="standard"
              inputProps={{ 
                readOnly: props.readOnly, 
                multiple : props.selectMultiple ? true : false 
              }}
              error={error}
              helperText={helperText}
            />
          </Grid>
          <Grid item xs={12-width} sm={12-width} key={2}>
          {
            (typeof value === 'string' || value.hasOwnProperty('blob')) ?
            <ImageOrDocument document={value.hasOwnProperty('blob') ? value.blob : value} minView={true} /> :
            <Grid container justifyContent="flex-start" alignItems="flex-start">
            {
              value && Array.from(value).map( (v, idx) => {
                if (type === 'image') {
                  return (
                    <Grid item key={'inner-' + idx}>
                      <ImageOrDocument 
                        document={typeof v === 'object' ? v.blob : v} 
                        minView={true} 
                      />
                    </Grid>
                  )
                } else if (typeof v !== 'object') {
                  return (<Grid item><ImageOrDocument document={v} minView={true} /></Grid>)
                }
              })
            }
            </Grid> 
          }
          </Grid>
        </Grid>
      );
    case 'select':
      return (
        <TextField
          id={name}
          label={label}
          name={name}
          fullWidth
          select
          align="left"
          type='text'
          size="small"
          value={value ? value : props.selectMultiple ? [] : ''}
          onChange={e => onChange(e, name)}
          margin="normal"
          variant="standard"
          error={error}
          helperText={helperText}
          SelectProps = {{ multiple: props.selectMultiple ? true : false }}
          InputProps = {{ readOnly: props.readOnly }}
        >
          {
            keys.map ((opt, i) => {
              return (<MenuItem key={i} value={opt}>{selectOptions[opt]}</MenuItem>);
            })
          }
        </TextField>
      );
    /*case 'address' :
      return (
        <GoogleAddress 
          name={name}
          label={label}
          value={value ? value : ''}
          readOnly = {props.readOnly}
          onChange={e => onChange(e, name)}
        />); 
    */case 'checkbox':
      return (
        <FormControlLabel
          control={<Checkbox 
            checked={value ? value : false} 
            onChange={e => onChange(e, name)} 
            name={name}
            disabled={props.readOnly}/>
          }
          label={label}
        />);
    case 'radio':
      return (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item sm={4}>
            <FormLabel>{label}</FormLabel>
          </Grid>
          <Grid item sm={8}>
            <RadioGroup row aria-label="position" name={name} onChange={e => onChange(e, name)} value={value} >
            {
              keys.map ((opt, i) => {
                return (<FormControlLabel
                  value={opt}
                  control={<Radio color="primary" />}
                  label={selectOptions[opt]}
                  labelPlacement="end"
                  disabled = {props.readOnly}
                />);
              })
            }
            </RadioGroup>
          </Grid>
          {
            error && 
            <Grid item xs={12}>
              <FormHelperText error>{helperText}</FormHelperText>
            </Grid>
          }
        </Grid>
      );
      
    case 'captcha':
      return (<ReCAPTCHA
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
        onChange={e => onChange(e, name)}
      />);
    default:
      return null;
  }
};
