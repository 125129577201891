import React, { Component } from 'react';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import NavigateBack from 'components/NavigateBack';

import { hatcheryFields } from './hatchery.fields';

import { fetchSettingsByType } from 'services/common';

import { fetchAllSpecies } from 'services/species-details';

import { createNewHatchery } from 'services/hatcheries';

import { checkUniqueAttributes } from 'services/common';

export default class AddNewHatchery extends Component {
  
  state = {
    statesData : false,
    districtsData : false,
  };
  
  constructor(props) {
    super(props);
    
    hatcheryFields[4].selectOptions = {};
    hatcheryFields[4].callback = this.handleStateSelected;
    hatcheryFields[5].selectOptions = {};
  }
  
  handlePreFormSubmit = async(formData) => {
    
    let statusMessage = { status: "success", message: "" };
    
    let validation = {isValid : true};
      
    // check uniqueness of the attributes
    try {
      let uniqueParams = [{
        'attribute' : 'hatcheryName',
        'value' : formData.name,
        'currentId' : null
      }];

      let result = await checkUniqueAttributes(uniqueParams);
      console.log(result);
      
      if (result.data.hatcheryName && result.data.hatcheryName.unique === false) {
        validation.isValid = false;
        validation['name'] = { isInvalid : true, message : result.data.message };
      }
    } catch(error) {
      statusMessage =  { status: "error", message: error.message };
    }
  
    if (validation.isValid === false) {
      statusMessage =  { 
        status: "error", 
        message: 'One or more fields in the form data doesn\'t meet uniqueness criteria', 
        data : validation 
      };
    }
    
    return statusMessage;
    
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully add new hatchery..." };
    
    // todo API calls
    try {
      let response = await createNewHatchery(params);
      console.log(response.status);
    } catch (error) {
       statusMessage = {status : "error", message : error.message, data : error.data};
    }
    return statusMessage;
  }
  
  handleStateSelected = async (name, value, formData, asyncFn) => {
    let response = await fetchSettingsByType({type : 'DISTRICT', name : 'DISTRICT', parent : value});
    hatcheryFields[5].selectOptions = {};
    if (response.status === 200) {
      response.data.map ( district => {
        hatcheryFields[5].selectOptions[district.id] = district.value
      });
    }
    return { fields : hatcheryFields, values : null };
  }
  
  async componentDidMount() {
    try {
      // fetch all states data
      let response = await fetchSettingsByType({type : 'STATE', name : 'STATE'});
      response.data.map ( state => {
        hatcheryFields[4].selectOptions[state.id] = state.value
      });
      
      // fetch all species
      response = await fetchAllSpecies({page : 1, per_page : -1, sort : 'id:ASC'});
      response.data.map ( species => {
        hatcheryFields[10].selectOptions[species.id] = species.name;
      });
      
      this.setState({statesData : true});
    } catch(error) {
      console.log(error);
    }
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Add New Hatchery </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          <DynamicForm
            formFields={hatcheryFields}
            action="create"
            handleSubmit={this.handleFormSubmit}
            preFormSubmit={this.handlePreFormSubmit}
            buttonText="Save Hatchery Details"
            buttonWidth= "full"
            columns = {3}
            redirect={'/aqf/hatcheries/list'}
            confirmation={"Are you sure you want to save the details ? "}
          />
        </Paper>
      </>
    );
  }
}
