import React, { Component } from 'react';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import CustomSnackbar from 'components/CustomSnackbar';

import { fetchAdminSettings, updateAdminSettings } from 'services/common';

const settingFields = [
  {  
    name: 'notification_settings',
    label: 'Notification Settings',
    type: 'header',
    properties: [],
    actions: ['update']
  },
  {  
    name: 'sendmail',
    label: 'E-mail Notifications',
    type: 'boolean',
    valueStrings : ['Disable', 'Enable'],
    properties: [],
    actions: ['update']
  },
  {  
    name: 'sendsms',
    label: 'SMS Notifications',
    type: 'boolean',
    valueStrings : ['Disable', 'Enable'],
    properties: [],
    actions: ['update']
  },
  {  
    name: 'booking_settings',
    label: 'Bookings Settings',
    type: 'header',
    properties: [],
    actions: ['update']
  },
  {  
    name: 'pg_charges',
    label: 'Transaction Charges',
    type: 'currency',
    properties: [],
    actions: ['update']
  },
  {  
    name: 'startTime',
    label: 'Bookings Start Time',
    type: 'datetime',
    properties: [],
    actions: ['update']
  },
  {
    name: 'unlimitedStartTime',
    label: 'Unlimited Bookings Start Time',
    type: 'datetime',
    properties: [],
    actions: ['update']
  },
  {  
    name: 'refund_percentages',
    label: 'Booking Refund Settings',
    type: 'header',
    properties: [],
    actions: ['update']
  },
  {  
    name: 'day0to30',
    label: '0 to 30 Days (%)',
    type: 'float',
    properties: [{float : { min : 0, max : 100}}],
    actions: ['update']
  },
  {  
    name: 'day30to45',
    label: '30 to 45 Days (%)',
    type: 'float',
    properties: [{float : { min : 0, max : 100}}],
    actions: ['update']
  },
  {  
    name: 'dayAbove45',
    label: 'Above 45 Days (%)',
    type: 'float',
    properties: [{float : { min : 0, max : 100}}],
    actions: ['update']
  },
];

export default class AppSettings extends Component {  

  state = { 
    currentValues : null, 
    fetched : false,
    statusMessage : { status : "warning", message : "Fetching current application settings.." }
  };
  
  constructor(props) {
    super(props);
  }
  
  async componentDidMount() {
    try {
      let response = await fetchAdminSettings();
      this.setState({ currentValues : response.data, fetched : true, statusMessage: null });
    } catch (error) {
      console.log(error);
      this.setState({ statusMessage : { status : "error", message : error.message }});
    }
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated application settings..." };
    
    // todo API calls
    try {
      let response = await updateAdminSettings(params);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message };
    }
    
    return statusMessage;
  }

  render() {
    return (<>
      <Paper elevation={3} sx ={{ p : 2, m: 1}}>
        <Typography variant = "h3" align="center"> Application Settings </Typography>
        {
          this.state.statusMessage &&
          <CustomSnackbar variant={this.state.statusMessage.status}
            message={this.state.statusMessage.message}
            open={this.state.statusMessage.status}
            onClose={() => this.setState({ statusMessage: null })}
          />
        }
      </Paper>
      <Paper elevation={3} sx ={{ p : 2, m: 1}}>
      {
        this.state.fetched &&
        <DynamicForm
          formFields={settingFields}
          action="update"
          handleSubmit={this.handleFormSubmit}
          buttonText="Save App Settings"
          buttonWidth= "full"
          columns = {3}
          currentValues = {this.state.currentValues}
          redirect={'/aqf/admin/settings'}
          confirmation={"Are you sure you want to save the details ? "}
        />
      }
      </Paper>
    </>);
  }
}