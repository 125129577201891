import React, { Component } from "react";

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";

import { 
  fetchSuppliersTotalCount,
  fetchAllSuppliers, 
} from 'services/suppliers';

import { fetchAllSpecies } from 'services/species-details';

import supplierFields from './supplier.fields';

import { permissionCheck } from 'permissions';

export default class SupplierList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Suppliers',
      addButtonText : 'Add New Supplier',
      addUrl :  permissionCheck('supplier', 'create') ? '/aqf/admin/suppliers/add' : null,
      searchEnable : true,              
      searchPlaceholder : 'Search By Name',
      
      // table content props
      columns : supplierFields,
      itemUrlPath : null,
      editUrlPath : permissionCheck('supplier', 'update') ?  '/aqf/admin/suppliers/edit/' : null,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchSuppliersDataWrapper,
      fetchCountApi : this.fetchSuppliersCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'speciesType',
        value : 'ALL',
        label : 'Filter By Species',
        options : [
          {id : "ALL", value : "-- All  Species --"}
        ]
      }, {
        name : 'status',
        value : 'ALL',
        label : 'Filter By Status',
        options : [
          {id : "ALL", value : "-- All  --"},
          {id : "true", value : "Active"},
          {id : "false", value : "In Active"},
        ]
      }],
      
      // export to xl, pdf props
      exportEnable : false,
    };
  }
  
  fetchSuppliersCountWrapper = async(filters, searchValue) => {
    let response = await fetchSuppliersTotalCount({
      speciesType : filters['speciesType'],
      status : filters['status'],
      name : searchValue
    });
    return response;
  }

  fetchSuppliersDataWrapper = async (page, perPage, filters, searchValue) => {
    let response = await fetchAllSuppliers({
      page: page,
      per_page: perPage,
      speciesType : filters['speciesType'],
      status : filters['status'],
      name : searchValue
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
        entry.url = `/aqf/suppliers/edit/${entry.id}`;
        entry.speciesName = entry.speciesIds.type;
      });
    }
    
    return response;
  }

  async componentDidMount() {
    this.tableProps.selectFilters[0].options = [{id : "ALL", value : "-- All  Species --"}];
    this.tableProps.selectFilters[0].value = 'ALL';
    
    try {
      let response = await fetchAllSpecies({page : 1, per_page : -1, sort : 'id:ASC'});
      response.data.map ( species => {
       this.tableProps.selectFilters[0].options.push({id : species.id, value : species.type});
      });
    } catch(error) {
      console.log(error);
    }
    
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}
