import React, { Component } from 'react';

import BiotechOutlined from '@mui/icons-material/BiotechOutlined';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

// services
import {fetchBookingReportsCount} from 'services/booking-reports';

export default class TotalLabAnalysisReportsCard extends Component {

  state = { count : '...' }
  
  async componentDidMount () {
    try {
      let response = await fetchBookingReportsCount('lab-analysis-report', {hatcheryId : 'ALL', speciesType : 'ALL'});
      this.setState({count : response.data})
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {
    return (
      <SummaryWidget 
        label = {'Total Lab Analysis Reports'} 
        count = {this.state.count} 
        icon = {<BiotechOutlined />}
        url = { '/aqf/bookings/test-reports/lab-analysis-report' }
      />
    );
  }
}