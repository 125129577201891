import { useState } from 'react';
import { calculateAPISignature, obfuscateData } from 'services/utils';

import { 
  Grid, Avatar, TableContainer, Table, TableBody, TableRow, TableCell, Button,
  Dialog, DialogContent, DialogTitle, Typography, Box, Tooltip, IconButton
} from '@mui/material';

import { 
  PersonAddAltOutlined,
  Close
} from '@mui/icons-material';


import SingleAuthPersonForm from '../form-components/SingleAuthPersonForm';

import { permissionCheck } from 'permissions';

const DisplayTable = (props) => {
  return ( 
    <TableContainer sx={{ px: 3 }}>
      <Table size="small">
        <TableBody>
          <TableRow hover role="checkbox" tabIndex={-1} key={1}>
            <TableCell key={'row-1-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Person Name</p></TableCell>
            <TableCell key={'row-1-col-1'} align="left">{props.authPerson.name}</TableCell>
          </TableRow>
          <TableRow hover role="checkbox" tabIndex={-1} key={2}>
            <TableCell key={'row-2-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>E-Mail Address</p></TableCell>
            <TableCell key={'row-2-col-1'} align="left">{obfuscateData("email", props.authPerson.email)}</TableCell>
          </TableRow>
          <TableRow hover role="checkbox" tabIndex={-1} key={3}>
            <TableCell key={'row-3-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Mobile Number</p></TableCell>
            <TableCell key={'row-3-col-1'} align="left">{obfuscateData("phone", props.authPerson.phoneNumber)}</TableCell>
          </TableRow>
          <TableRow hover role="checkbox" tabIndex={-1} key={4}>
            <TableCell key={'row-4-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Aadhaar Number</p></TableCell>
            <TableCell key={'row-4-col-1'} align="left">{obfuscateData("aadhaar", props.authPerson.aadhaarNumber)}</TableCell>
          </TableRow>
          <TableRow hover role="checkbox" tabIndex={-1} key={5}>
            <TableCell key={'row-6-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Status</p></TableCell>
            <TableCell key={'row-6-col-1'} align="left">{props.updateRequestFlag ? 'Approval Pending' : 'Approved'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};


const AuthPersonDetails = (props) => {
  
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ profileType, setProfileType] = useState("");
  
  let userProfile = props.userProfile; 
  let authPerson1 = userProfile.updateRequestDetails && userProfile.updateRequestDetails.authPerson1 
    ? userProfile.updateRequestDetails.authPerson1 : userProfile.authPerson1;
  let authPerson2 = userProfile.updateRequestDetails && userProfile.updateRequestDetails.authPerson2 
    ? userProfile.updateRequestDetails.authPerson2 : userProfile.authPerson2;
  
  const auth1signature = authPerson1 && authPerson1.picture
    ? calculateAPISignature(encodeURIComponent(authPerson1.picture.replace(/ /g, '%20')))
    : null;
  const auth2signature = authPerson2 && authPerson2.picture
    ? calculateAPISignature(encodeURIComponent(authPerson2.picture.replace(/ /g, '%20')))
    : null;
  
  return (
    <>
      {
        <Dialog maxWidth="md" open={modalOpen} aria-labelledby="form-dialog-title" fullWidth>
          <DialogTitle  id="responsive-dialog-title" sx={{display : 'flex', justifyContent : 'flex-end'}}>
            <IconButton onClick={() => setModalOpen(false)}><Close /></IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h4" align="center" style={{borderBottom : '1px solid'}}>
              Update Authorized Person Details
            </Typography>
            <SingleAuthPersonForm
              id = {props.userProfile.id}
              hatcheryId={props.userProfile.hatcheryId}
              currentValues={profileType === 'auth1' ? authPerson1 : authPerson2} 
              profileType = {profileType} 
              refresh={() => { setModalOpen(false); props.refresh()}} 
            />
          </DialogContent>
        </Dialog>
      }
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item sm={6} xs={12} sx= {{ borderRight : '1px solid grey'}}>
        {
          authPerson1 ?
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ p :2 }}>
            <Grid item sm={3} xs={3} align="center">
              <Avatar
                variant="rounded"
                alt="Authorized Person Picture" 
                src= {
                  authPerson1.picture && authPerson1.picture !== 'null'
                    ? process.env.REACT_APP_STRAPI_FILE_URL + authPerson1.picture + '?s=' + auth1signature 
                    : process.env.PUBLIC_URL + "/static/images/misc/dummy.png"
                }              
                sx={{ width: 128, height: 128, border : "1px solid #ececec" }} 
              />
            </Grid>
            <Grid item sm={9} xs={9}>
              <DisplayTable 
                authPerson = {authPerson1} 
                updateRequestFlag = {userProfile.updateRequestDetails && userProfile.updateRequestDetails.authPerson1} 
              />
              {
                permissionCheck('user-accounts', 'change-request') && props.printView === false &&
                <Box sx={{m : 2}}>
                  <Button color="secondary" size="small" variant="outlined" fullWidth 
                    onClick = { e => { setProfileType("auth1") ; setModalOpen(true) }}
                    disabled={Boolean(userProfile.updateRequestDetails && userProfile.updateRequestDetails.authPerson1)}
                  >
                    Update Details 
                  </Button>
                </Box>
              }
            </Grid>
          </Grid> :
          <>
          {
            props.printView === false &&
            permissionCheck('user-accounts', 'change-request') &&
            <Tooltip title = "Add New Authorized Person">
              <Box display="flex" justifyContent="center" sx={{minHeight: '200px'}}>
                <IconButton color="error" size="large" onClick={(e) => {setProfileType("auth1"); setModalOpen(true)}}>
                  <PersonAddAltOutlined sx={{fontSize:80}} />
                </IconButton>
              </Box>
            </Tooltip>
          }
          </>
        }
        </Grid>
        <Grid item sm={6} xs={12} sx= {{ borderRight : '1px solid grey'}}>
        {
          authPerson2 ?
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ p :2 }}>
            <Grid item sm={3} xs={3} align="center">
              <Avatar
                variant="rounded"
                alt="Authorized Person Picture" 
                src= {
                  authPerson2.picture && authPerson2.picture !== 'null'
                    ? process.env.REACT_APP_STRAPI_FILE_URL + authPerson2.picture + '?s=' + auth2signature
                    : process.env.PUBLIC_URL + "/static/images/misc/dummy.png"
                }              
                sx={{ width: 128, height: 128, border : "1px solid #ececec" }} 
              />
            </Grid>
            <Grid item sm={9} xs={9}>
              <DisplayTable 
                authPerson = {authPerson2} 
                updateRequestFlag = {userProfile.updateRequestDetails && userProfile.updateRequestDetails.authPerson2} 
              />
              {
                permissionCheck('user-accounts', 'change-request') && props.printView === false &&
                <Box sx={{m : 2}}>
                  <Button color="secondary" size="small" variant="outlined" fullWidth 
                    onClick = { e => { setProfileType("auth2") ; setModalOpen(true) }}
                    disabled={Boolean(userProfile.updateRequestDetails && userProfile.updateRequestDetails.authPerson2)}
                  >
                    Update Details 
                  </Button>
                </Box>
              }
            </Grid>
          </Grid> :
          <>
          {
            props.printView === false &&
            permissionCheck('user-accounts', 'change-request') &&
            <Tooltip title = "Add New Authorized Person">
              <Box display="flex" justifyContent="center" sx={{minHeight: '200px'}}>
                <IconButton color="error" onClick={e => {setProfileType("auth2") ; setModalOpen(true) }}>
                  <PersonAddAltOutlined sx={{fontSize:80}} />
                </IconButton>
              </Box>
            </Tooltip>
          }
          </>
        }
        </Grid>
      </Grid>    
    </>
  );
};

export default AuthPersonDetails;
