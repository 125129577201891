import React, { Component } from "react";

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";

import { 
  fetchBookingSchedulesByYearCount,
  fetchBookingSchedulesByYear, 
} from 'services/booking-schedules';

import { scheduleFormFields } from './booking-schedules.fields';

import { permissionCheck } from 'permissions';

export default class BookingSchedulesList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    const filterValues = range(currentYear - 5, currentYear + 5, 1).map( year => {return {'id' : year, 'value' : "AY-" + year}});
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Booking Schedules',
      addButtonText : 'Add New Schedule',
      addUrl : permissionCheck('booking-schedules', 'create') ? '/aqf/booking-schedules/add' : null,
      searchEnable : false,              
      // searchPlaceholder : 'Search By Month Name',
      
      // table content props
      columns : scheduleFormFields,
      editUrlPath : `/aqf/booking-schedules/edit/`,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchBookingSchedulesDataWrapper,
      fetchCountApi : this.fetchBookingSchedulesCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'year',
        value : currentYear,
        options : filterValues
      }],
      
      // export to xl, pdf props
      exportEnable : false,
    };
  }
  
  fetchBookingSchedulesCountWrapper = async(filters, searchValue) => {
    let hatcheryId = 'ALL';
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    }
    
    let response = await fetchBookingSchedulesByYear({
      page: 1,
      per_page: 10,
      year : filters['year'],
    });

    return { status : response.status, data: response.data.count};
  }    

  fetchBookingSchedulesDataWrapper = async (page, perPage, filters, searchValue) => {
    let hatcheryId = 'ALL';
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    }
    
    let response = await fetchBookingSchedulesByYear({
      page: page,
      per_page: perPage,
      year : filters['year'],
    });
    
    if (response && response.status === 200) {
      response.data.data.forEach(entry => {
        entry.selection = false; //this.currentUser.user.role.name === 'Front Desk';
        entry.editUrl = `/aqf/booking-schedules/edit/${entry.availableDate}/${entry.speciesId}`;
        entry.speciesId = entry.speciesName;
        entry.displayMonth = entry.month + ', ' + filters['year'];
      });
    }
    
    return { status : response.status, data: response.data.data};
  }

  async componentDidMount() {
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}