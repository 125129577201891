import React, { Component } from 'react';
import { withLocationParams, withPrint } from 'hoc';
import { Navigate } from 'react-router-dom';

import { 
  Paper, Grid, Typography, Tabs, Tab, Button, Box
} from '@mui/material';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

// import view components 
import { 
  HatcheryBasicDetails,
  OwnerDetails,
  AuthPersonDetails,
  BankDetails,
  LicenseDetails
} from './view-components';

import ApprovalForm from './form-components/ApprovalForm';
import HatcheryOperations  from './view-components/HatcheryOperations';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import NavigateBack from 'components/NavigateBack';

// import TableView Component
import DTable from "components/DataTables/DTable";

// import services
import { fetchHatcheryById, fetchLicensesData } from 'services/hatcheries';
// import { fetchUserAccountById } from 'services/user';

import { HatcheryStore } from 'store';

import { permissionCheck } from 'permissions';

class ViewHatchery extends Component {

  state = { 
    hatcheryData : null, 
    licenseData : null,
    userData : null,
    modalOpen : false,
    modalTitle : null,
    currentOps : null,
    activeTab: "Owner Details",
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."},
    printView : false
  };
  
  async componentDidMount() {
    
    let statusMessage = this.state.statusMessage;
    let hatcheryData = {};
    let userData = null;
    let licenseData = [];
    let response;
    
    try {
      response = await fetchHatcheryById(this.props.locationParams.id);
      hatcheryData = response.data;
      hatcheryData.fyBalance = hatcheryData.license 
        ?  hatcheryData.license.noOfBroodsStockPerYear - hatcheryData.fyImportedStock
        : null;
      userData = hatcheryData.userProfile;
      statusMessage = {status: "success", message: "Fetched hatchery Details successfully !"}  
    } catch (error) {
      console.log (error);
      if(HatcheryStore.state && HatcheryStore.state.currentHatchery) {
        hatcheryData = HatcheryStore.state.currentHatchery;
        statusMessage = {status: "success", message: "Fetched hatchery Details successfully !"}  
      } else {
        hatcheryData = null;
        statusMessage = {status : "error", message : error.message, code: error.status};
      }
    }
    
    // enable this code if user login details are not coming in the fetch hatchery //
    /*if (hatcheryData.userProfile) {
      try {
        response = await fetchUserAccountById(hatcheryData.userProfile.id);
        userData = response.data;
      } catch (error) {
        console.log(error);
        statusMessage = {status : "error", message : error.message, code: error.status};
      }
    }*/
    
    // fetch license data
    if (hatcheryData) {
      try {
        response = await fetchLicensesData({
           hatcheryId : hatcheryData.id,
           page : 1,
           per_page : 100,
        });
        licenseData = response.data.map ( e => {
          const validTo = new Date(e.validTo);
          const today = new Date();
          e.expired = today.setHours(0,0,0,0) > validTo;
          e.editable = permissionCheck('hatchery', 'update-license');
          return e;
        });
      } catch (error) {
        console.log(error);
        statusMessage = {status : "error", message : error.message, code: error.status};
      } 
    }
    
    await this.setState({hatcheryData, userData, licenseData, statusMessage});
  }
  
  handleRefresh = async() => {
    let statusMessage = this.state.statusMessage;
    let hatcheryData = this.state.hatcheryData;
    let licenseData = this.state.licenseData;
    let response = null;
    
    try {
      response = await fetchHatcheryById(this.props.locationParams.id);
      hatcheryData = response.data;
      hatcheryData.fyBalance = hatcheryData.license 
        ?  hatcheryData.license.noOfBroodsStockPerYear - hatcheryData.fyImportedStock
        : null;
      statusMessage = {status: "success", message: "Refreshed hatchery Details successfully !"}  
    } catch (error) {
      console.log (error);
      statusMessage = {status : "error", message : error.message, code: error.status};
    }
    
    if (hatcheryData) {
      try {
        response = await fetchLicensesData({
           hatcheryId : hatcheryData.id,
           page : 1,
           per_page : 100,
        });
        licenseData = response.data.map ( e => {
          const validTo = new Date(e.validTo).setUTCHours(23,59,59,999);
          const today = new Date();
          e.expired = today.setHours(0,0,0,0) > validTo;
          e.editable = permissionCheck('hatchery', 'update-license');
          return e;
        });
      } catch (error) {
        console.log(error);
        statusMessage = {status : "error", message : error.message, code: error.status};
      } 
    }
    
    await this.setState({hatcheryData, statusMessage, licenseData});
  }
  
  handleHatcheryOps = async(operation) => {
    let title = '';
    switch (operation) {
      case 'auth1':
      case 'auth2':
        title = "Add Authorized Person Details";
        break;
      case 'bank':
        title = "Add Bank Account Details";
        break;
      default: break;
    }
    
    this.setState({modalOpen : true, currentOps : operation, modalTitle : title});
  }

  handlePrint = () => {
    this.setState({printView : true});
  }
  
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.printView) {
      this.props.handlePrint();
      setTimeout (() => this.setState({printView : false}), 500);
    }
  }
  
  render() {
    
    if (this.state.statusMessage && [404, 403].includes(this.state.statusMessage.code)) {
      return (<Navigate to="/not-found" />);
    }
    
    return (<>
      {
        this.props.locationParams && this.state.statusMessage &&
        <CustomSnackbar variant={this.state.statusMessage.status}
          message={this.state.statusMessage.message}
          open={this.state.statusMessage.status}
          onClose={async () => await this.setState({ statusMessage: null })}
        />
      }
      {
        this.state.printView === false ?
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', px:1, py : 1}} component="span">
            <Button
              variant="outlined" 
              color="warning" 
              size="small"
              startIcon={<PrintOutlinedIcon />}
              onClick={this.handlePrint}
            > 
              Print 
            </Button>
          </Box>  
          <NavigateBack />
        </Grid> : 
        <Grid container justifyContent="space-between" alignItems="flex-start" direction="row" sx={{mt:1}}>
          <Grid xs={2} item key={1}>
          <img src={process.env.PUBLIC_URL + '/static/images/home/mpeda-logo.png'} alt = "MPEDA logo" height="100px"/>
          </Grid>
          <Grid xs={8} item key={2}>
            <Typography variant="h4" align="center">Rajiv Gandhi Centre for Aquaculture</Typography>
            <Typography variant="h4" align="center">Aquatic Quarantine Facility</Typography>
            <Typography variant="body2" my={1} align="center"> 
              MPEDA. Ministry of Commerce & Industry, Govt. of India <br/>
              TNFDC Hatchery Complex, Kapaleeswarar Nagar, Beach Road <br/>
              Neelankarai-600115, Chennai, Tamil Nadu
            </Typography>
          </Grid>
          <Grid xs={2} item key={3}>
          <img src={process.env.PUBLIC_URL + '/static/images/home/rgca-logo.png'} alt = "RGCA logo" height="100px"/>
          </Grid>
        </Grid>
      }
      {
        this.state.printView === false &&
        <Paper elevation={3}  sx={{textAlign:"center", p:2, m : 1}}>
          <Typography variant="h3"> 
            View Hatchery Details{this.state.hatcheryData ? ` - ${this.state.hatcheryData.name}` : ''} 
          </Typography>
        </Paper>
      }
      {
        this.state.hatcheryData && 
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing = {1} sx={{px : 1}}>
          <Grid item sm={8} xs={12}>
            <HatcheryBasicDetails hatcheryData={this.state.hatcheryData} />
          </Grid>
         {
            this.state.printView === false && 
            <Grid item sm={4} xs={12} sx={{mt:1}} >
            {
              this.state.hatcheryData.userProfile && this.state.hatcheryData.approvalStatus === 'ApprovalPending' &&
              this.state.hatcheryData.license &&
              permissionCheck('hatchery', 'approve') ?
              (<ApprovalForm updateRequest = {false} type="hatchery" entity={this.state.hatcheryData.id} />) :
              (<HatcheryOperations hatcheryData = {this.state.hatcheryData} userData = { this.state.userData} />)
            }
            </Grid>
         }
        </Grid>
      }
      {
        this.state.hatcheryData && 
        <>
          {
            this.state.printView === false &&
            <Paper elevation={3} sx={{textAlign:"center", m:2}}>
              <Tabs
                value={this.state.activeTab}
                onChange={async (event, newValue) => await this.setState({activeTab : newValue}) }
                variant="fullWidth"
                textColor="primary"
                indicatorColor="secondary"
                aria-label="Owner Details"
              >
                <Tab value="Owner Details" label="Owner / Incharge Details" />
                {
                  this.state.hatcheryData.userProfile && 
                  (!HatcheryStore.state.currentHatchery || this.state.hatcheryData.userProfile.picture) &&
                  <Tab value="Authorized Persons" label="Authorized Persons" />
                }
                {
                  this.state.hatcheryData.userProfile && 
                  (!HatcheryStore.state.currentHatchery || this.state.hatcheryData.userProfile.picture) &&
                  <Tab value="Bank Accounts" label="Bank Accounts" />
                }
                {
                  this.state.hatcheryData.userProfile && 
                  (!HatcheryStore.state.currentHatchery || this.state.hatcheryData.userProfile.picture) &&
                  <Tab value="License Details" label="License Details" />
                }
              </Tabs>
            </Paper>
          }
          {
            this.state.hatcheryData.userProfile && 
            <Paper elevation={3} sx={{textAlign:"center", m:2}}>
              {
                this.state.printView &&
                <><Typography variant="h4" align="center"> Owner / Incharge Details </Typography><br/><hr style={{borderColor:"#eef1f6"}} /></>
              }
              {
                (this.state.activeTab === "Owner Details" || this.state.printView === true) &&
                <div 
                  role="tabpanel" 
                  hidden={this.state.activeTab !== "Owner Details" && this.state.printView === false} 
                  id="1" aria-labelledby="hatchery-details"
                >
                  <OwnerDetails hatcheryData={this.state.hatcheryData} refresh={this.handleRefresh} printView={this.state.printView}/>
                </div>
              }
              {
                this.state.printView &&
                <><Typography variant="h4" align="center"> Authorized Person Details </Typography><br/><hr style={{borderColor:"#eef1f6"}} /></>
              }
              {
                (this.state.activeTab === "Authorized Persons" || this.state.printView === true) &&
                <div 
                  role="tabpanel" 
                  hidden={this.state.activeTab !== "Authorized Persons" && this.state.printView === false} 
                  id="2" aria-labelledby="authperson-details"
                >
                  <AuthPersonDetails 
                    userProfile={this.state.hatcheryData.userProfile} 
                    refresh={this.handleRefresh}
                    printView={this.state.printView}
                  />
                </div>
              }
              {
                this.state.printView &&
                <><Typography variant="h4" align="center"> Bank Details </Typography><br/><hr style={{borderColor:"#eef1f6"}} /></>
              }
              {
                (this.state.activeTab === "Bank Accounts" || this.state.printView === true) &&
                <div 
                  role="tabpanel" 
                  hidden={this.state.activeTab !== "Bank Accounts" && this.state.printView === false} 
                  id="3" aria-labelledby="bank-accounts">
                  <BankDetails 
                    bankDetails={this.state.hatcheryData.bankDetails}
                    refresh={this.handleRefresh}
                    printView={this.state.printView}
                  /> 
                </div>
              }
              {
                this.state.printView &&
                <><Typography variant="h4" align="center"> License Details </Typography><br/><hr style={{borderColor:"#eef1f6"}} /></>
              }
              {
                (this.state.activeTab === "License Details" || this.state.printView === true) &&
                <div 
                  role="tabpanel" 
                  hidden={this.state.activeTab !== "License Details" && this.state.printView === false} 
                  id="4" aria-labelledby="license-details"
                >
                  <LicenseDetails 
                    licenseData={this.state.licenseData} 
                    hatcheryData={this.state.hatcheryData}
                    refresh={this.handleRefresh}
                    printView={this.state.printView}
                  />
                </div>
              }
            </Paper>
          }
        </>
      }
    </>);
  }
}

export default withPrint(withLocationParams(ViewHatchery));
