import React, { Component } from 'react';

import PieChartOutlined from '@mui/icons-material/PieChartOutlined';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

import { HatcheryStore } from 'store';


export default class QuarterlyCubiclesCount extends Component {

  state = { count : '...' }
  
  async componentDidMount () {
    if(HatcheryStore.state.currentHatchery) {
      this.setState({ count : HatcheryStore.state.currentHatchery.quarterlyBookings.cubicles});
    }
  }
  
  render() {
    return (
      <SummaryWidget 
        label = {'This Quarter Cubicles Count'} 
        count = {this.state.count} 
        icon = {<PieChartOutlined />}
        url = { '/aqf/bookings/list' }
      />
    );
  }
}
