import React, { Component } from "react";

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";
import { 
  fetchHatcheriesTotalCount, 
  fetchAllHatcheriesData,
  // fetchUnregisteredHatcheriesCount,
  // fetchUnregisteredHatcheries,
  fetchApprovalPendingHatcheriesCount,
  fetchApprovalPendingHatcheries,
  fetchPendingHatcheryChangesCount,
  fetchPendingHatcheryChanges
} from 'services/hatcheries';
import { fetchSettingsByType } from 'services/common';
import { fetchAllSpecies } from 'services/species-details';

import { hatcheryFields, hatcheryChangesFields } from './hatchery.fields';

import { permissionCheck } from 'permissions';


export default class HatcheriesList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false, updateListing : false };
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Hatcheries',
      addButtonText : 'Add New Hatchery',
      addUrl : this.props.type === 'Listing' && permissionCheck('hatchery', 'create') ? '/aqf/hatcheries/add' : null,
      searchEnable : true,              
      searchPlaceholder : 'Search By Hatchery Name',
      
      // table content props
      columns : props.type === 'Changes' ? hatcheryChangesFields : hatcheryFields,
      itemUrlPath : permissionCheck('hatchery', 'view-any') ? `/aqf/hatcheries/view/` : null,
      editUrlPath : (this.props.type === 'Listing' && permissionCheck('hatchery', 'update')) ? `/aqf/hatcheries/edit/` : null,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchHatcheriesDataWrapper,
      fetchCountApi : this.fetchHatcheriesCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [],
      
      // export to xl, pdf props
      exportEnable : false,
    };
    
    // set table props based on incoming type
    this.setTableProps();
  }
  
  setTableProps = () => {
    switch(this.props.type) {
      case 'Listing' :
        this.tableProps.selectFilters = [{
          name : 'speciesType',
          value : 'ALL',
          label: 'Filter By Species',
          options : [
            {id : "ALL", value : "-- All  Species --"},
          ]
        }, {
          name : 'state',
          value : 'ALL',
          label: 'Filter By State',
          options : [
            {id : "ALL", value : "-- All  States --"},
          ]
        }, {
          name : 'status',
          label: 'Filter By Status',
          value : 'ALL',
          options : [
            {id : "ALL", value : "-- All  --"},
            {id : "Registered", value : "Registered"},
            {id : "UnRegistered", value : "UnRegistered"},
          ]
        }, {
          name : 'expired',
          value : 'ALL',
          label: 'Filter By Expiry',
          options : [
            {id : "ALL", value : "-- All  --"},
            {id : "false", value : "VALID"},
            {id : "true", value : "EXPIRED"},
          ]
        }];
        
        this.tableProps.title = 'List Of All Hatcheries';
        this.tableProps.itemUrlPath = '/aqf/hatcheries/view/';
        this.tableProps.columns = hatcheryFields;
        this.tableProps.editUrlPath = `/aqf/hatcheries/edit/`;
        
      break;
      // case 'UnRegistered' :
      //  this.tableProps.title = 'List Of All Unregistered Hatcheries';
      //  this.tableProps.itemUrlPath = '/aqf/hatcheries/view/';
      // break;
      case 'Pending' : 
        this.tableProps.selectFilters = [];
        this.tableProps.title = 'List Of All Approval Pending Hatcheries';
        this.tableProps.itemUrlPath = '/aqf/hatcheries/approvals/hatchery/';
        this.tableProps.columns = hatcheryFields;
        this.tableProps.editUrlPath = null;
      break;
      case 'Changes' : 
        this.tableProps.selectFilters = [];
        this.tableProps.title = 'List Of All Hatcheries With Pending Change Requests';
        this.tableProps.itemUrlPath = '/aqf/hatcheries/approvals/hatcherychanges/';
        this.tableProps.columns = hatcheryChangesFields;
        this.tableProps.editUrlPath = null;
      break;
    }
  }

  fetchHatcheriesCountWrapper = async(filters, searchValue) => {
    let response = {};
    switch(this.props.type) {
      case 'Listing' : 
        response = await fetchHatcheriesTotalCount({
          speciesType : filters['speciesType'],
          expired : filters['expired'],
          state : filters['state'],
          name : searchValue,
          status : filters['status'] //'Approved'
        });
      break;
      // case 'UnRegistered': 
      //  response = await fetchUnregisteredHatcheriesCount({name : searchValue});
      // break;
      case 'Pending' : 
        response = await fetchApprovalPendingHatcheriesCount({name : searchValue});
      break;
      case 'Changes' : 
        response = await fetchPendingHatcheryChangesCount({name : searchValue});
      break;
    }
    return response;
  }    

  fetchHatcheriesDataWrapper = async (page, perPage, filters, searchValue) => {
    let response = {};
    switch(this.props.type) {
      case 'Listing' : 
        response = await fetchAllHatcheriesData({
          page: page,
          per_page: perPage,
          speciesType : filters['speciesType'],
          expired : filters['expired'],
          state : filters['state'],
          name : searchValue,
          status : filters['status'] // 'Approved'
        });
      break;
      // case 'UnRegistered': 
      //  response = await fetchUnregisteredHatcheries({name : searchValue, page: page, per_page: perPage});
      // break;
      case 'Pending' : 
        response = await fetchApprovalPendingHatcheries({name : searchValue, page: page, per_page: perPage});
      break;
      case 'Changes' : 
        response = await fetchPendingHatcheryChanges({name : searchValue, page: page, per_page: perPage});
      break;
    }
    
    response.data.forEach(entry => {
      entry.selection = false;
      entry.permittedSpeciesId = entry.permittedSpeciesId && typeof entry.permittedSpeciesId === 'object' ? 
                    entry.permittedSpeciesId.name : entry.permittedSpeciesId;
      if (entry.license) {
        entry.noOfBroodsStockPerYear = entry.license.noOfBroodsStockPerYear;
        entry.fyBalance = entry.license.noOfBroodsStockPerYear - entry.fyImportedStock;
        entry.validFrom = entry.license.validFrom;
        entry.validTo = entry.license.validTo;
        entry.issuingDate = entry.license.issuingDate;
        entry.CAARegistrationNumber = entry.license.CAARegistrationNumber;
      }
      entry.approvalStatus = (entry.approvalStatus === 'PendingApproval' && !entry.userProfile) ? 'UnRegistered' : entry.approvalStatus;

      if (this.props.type === 'Changes') {
        entry.licenseRenewalStatus = entry.updateRequestFlag ? entry.updateRequestFlag : 'Approved';
        
        if (entry.userProfile) {
          entry.authPersonStatus = entry.userProfile[0].updateRequestFlag 
            ? entry.userProfile[0].updateRequestFlag 
            : (entry.userProfile[0].authPerson1 || entry.userProfile[0].authPerson2) ? 'Approved' : 'N/A';
        }
        
        if (entry.bankDetails) {
          for ( let i = 0 ; i < entry.bankDetails.length; ++i) {
            entry.bankAccountStatus = entry.bankDetails[i].updateRequestFlag ? entry.bankDetails[i].updateRequestFlag : 'Approved' ;
            if (entry.bankDetails[i].updateRequestFlag === 'ApprovalPending') {
              break;
            }
          }
        }
      }
    });
    
    return response;
  }

  async componentDidMount() {
    
    if (this.props.type === 'Listing') {
      this.tableProps.selectFilters[0].options = [{id : "ALL", value : "-- All  Species --"}];
      this.tableProps.selectFilters[0].value = 'ALL';
      this.tableProps.selectFilters[1].options = [{id : "ALL", value : "-- All  States --"}];
      this.tableProps.selectFilters[1].value = 'ALL';
      try {
        let response = await fetchAllSpecies({ page : 1, per_page : -1});
        response.data.map ( species => {
           this.tableProps.selectFilters[0].options.push({id : species.id, value : species.name});
        });
        
        response = await fetchSettingsByType({type : 'STATE', name : 'STATE'});
        response.data.map ( state => {
           this.tableProps.selectFilters[1].options.push({id : state.id, value : state.value});
        });
        
      } catch(error) {
        console.log(error);
      }
    }
    
        
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
    
  }
  
  async componentDidUpdate(prevProps, prevState) {
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    } else if (prevProps.type !== this.props.type) {
      this.tableProps.updateContent = true;
      
      // set table props based on new type
      this.setTableProps();
      
      await this.setState({updateContent : true});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}
