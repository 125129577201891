import { createStyles } from '@mui/styles';
import theme from 'theme';

export default createStyles({
  root: {
    margin: theme.spacing(2),
    padding : theme.spacing(2)
  },
  item : {},
  row: {
    marginTop : theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  title : {
    backgroundColor: theme.palette.primary.main,
    color : theme.palette.common.white,
    padding : '10px',
    align : 'center'
  },
  
  deleteButton: {
    color: theme.palette.error.main,
    marginRight: '8px'
  },
  importButton: {
    marginRight: '8px'
  },
  importIcon: {
    marginRight: '8px'
  },
  exportButton: {
    marginRight: '8px'
  },
  exportIcon: {
    marginRight: '8px'
  },
  searchInput: {
    marginRight: '8px'
  }
});
