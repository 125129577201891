import React, { Component } from 'react';

import DateRangeOutlined from '@mui/icons-material/DateRangeOutlined';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

import { HatcheryStore } from 'store';


export default class MonthlyBookingsCount extends Component {

  state = { count : '...' }
  
  async componentDidMount () {
    if(HatcheryStore.state.currentHatchery) {
      this.setState({ count : HatcheryStore.state.currentHatchery.monthlyBookings.bookings  });
    }
  }
  
  render() {
    return (
      <SummaryWidget 
        label = {'This Month Booking Count'} 
        count = {this.state.count} 
        icon = {<DateRangeOutlined />}
        url = { '/aqf/bookings/list' }
      />
    );
  }
}
