import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm, DynamicTableForm } from 'components/Forms';

import { labAnalysisFieldsLvannamei, labAnalysisFieldsMonodon } from '../booking-reports.fields';

import { fetchSampleCollection  } from 'services/sample-collection';
import { createNewBookingReport, updateBookingReport } from 'services/booking-reports';

export default class SampleLabAnalysis extends Component {
  
  state = { samples : false };
  
  constructor ( props ) {
    super (props);
    this.labAnalysisFields = [];
    if (props.bookingData.speciesType.name.toLowerCase().includes('vannamei')) {
      labAnalysisFieldsLvannamei.map ( f => {
        let field = {...f};
        field.properties = [];
        // deep copy properties
        f.properties.map ( p => field.properties.push({...p}));
      
        // deep copy select options
        if (f.selectOptions) {
          field.selectOptions = {...f.selectOptions};
        }
        
        this.labAnalysisFields.push(field);
        
        // fix warnings
        return null;
      });
    } else {
      labAnalysisFieldsMonodon.map ( f => {
        let field = {...f};
        field.properties = [];
        // deep copy properties
        f.properties.map ( p => field.properties.push({...p}));
      
        // deep copy select options
        if (f.selectOptions) {
          field.selectOptions = {...f.selectOptions};
        }
        this.labAnalysisFields.push(field);      
        // fix warnings
        return null;
      });
    }
  }
  
  async componentDidMount() {
    try {
      let response = await fetchSampleCollection({
        bookingId : this.props.bookingData.id.toString(),
        hatcheryId : 'ALL',
        speciesType : 'ALL',
        page : 1, 
        per_page : 100,
        sort : 'sampleStorage:ASC'
      });
      if (response.data[0] && response.data[0].bulkData) {
        let samples = response.data[0].bulkData;
        samples.map ( sample => {
          // use a string key in the select options json, to avoid default sorting
          this.labAnalysisFields[3].selectOptions[`${sample.id} `] = 
            `${sample.sampleStorage} : ${new Date(sample.storageDate).toLocaleDateString('en-GB', {'timezone' : 'Asia/Kolkata'})}`;
        });
        this.setState({samples : true});
      }
    } catch(error) {
      console.log(error);
    }
  }
  
  handleTableDataChange = async (tableData) => {
    // no-op
  }

  handlePreFormSubmit = async (formData) => {
    // validate for duplicate sample ids
    let statusMessage = { status: "success", message: "" };
    const uniqueSamples = formData.tableData.map (x => parseInt(x.sampleId.trim())).sort((a,b) => a - b);
    let validation = [];

    for (let i = 0 ; i < uniqueSamples.length - 1 ; ++i) {
        let rowValidation = { isValid : true };
        
        // found duplicate sample id
        if (uniqueSamples[i] == uniqueSamples[i + 1]) {
            rowValidation = {
                isvalid : false,
                sampleId : { isInvalid : true, message : 'Duplicate sample id selected' }
            };
        }
        validation.push(rowValidation);
    }

    if (Boolean(validation.find(v => v.isValid === false))) {
      statusMessage =  { 
        status: "error", 
        message: 'Table validation failed due to duplicate sample ids in the entered data', 
        tableData : validation
      };
    }

    return statusMessage;
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleCreateFormSubmit = async (params, tableData) => {
    let statusMessage = { status: "success", message: "Successfully added new sample lab analysis details..." };
    
    let formData = { 
      bookingId : this.props.bookingData.id,
      bulkData : tableData.map(t => { 
        // add booking id into the data
        t.bookingId = this.props.bookingData.id; 
        t.sampleId = parseInt(t.sampleId.trim());
        return t;
      })
    };

    // todo API calls
    try {
      let response = await createNewBookingReport('lab-analysis', formData);
      
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message };
      if (error.data && error.data.isValid === false) {
        let errorsOnSubmit = error.data.bulkData[0];
        delete errorsOnSubmit.arrIdx;
        statusMessage['data'] = errorsOnSubmit;
      }
    }
    
    return statusMessage;
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleEditFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated sample lab analysis .." };
    
    // todo API calls
    try {
      params.sampleId = parseInt(params.sampleId.trim());
      let response = await updateBookingReport('lab-analysis', this.props.currentValues.id, params);
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  render () {
    
    let currentValues = this.props.currentValues;
    if (currentValues) {
      // update cubicle info
      currentValues.cubicleId = currentValues.number;
      // string sample id is used to show the select options in correct order
      currentValues.sampleId = `${currentValues.sampleId} `;
    }
    
    return(
      <>
        <Typography variant="h5" align="center" p={2} > 
        { 
          (this.props.currentValues ? 'Update Sample Lab Analysis' : 'Sample Lab Analysis') + 
          ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
        }
        </Typography><hr style={{borderColor:"#eef1f6"}} />
        {
          !this.props.currentValues ?
          <DynamicTableForm
            formFields = {[]}
            tableFormProps = {{
              fields : this.labAnalysisFields,
              handleTableDataChange : this.handleTableDataChange,
              serialNoHeader : 'S.No',
              deleteRows: "none",
              uploadFiles : true,
              minRows : 1,
              maxRows: 15,
              currentValues : null,
            }}
            uploadFiles={true}
            action="create"
            buttonText="Save Sample Lab Analysis Data"
            handleSubmit={this.handleCreateFormSubmit}
            preFormSubmit={this.handlePreFormSubmit}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/lab-analysis"}
          /> :
          <DynamicForm
            formFields={this.labAnalysisFields}
            action="update"
            handleSubmit={this.handleEditFormSubmit}
            buttonText="Update Sample Lab Analysis Data"
            buttonWidth= "full"
            columns = {4}
            currentValues={currentValues}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/lab-analysis"}
          />
        }
    </>);
  }
}
