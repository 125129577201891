import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { clearanceFields } from '../booking-reports.fields';

import { 
  createNewBookingReport, updateBookingReport, 
  fetchTotalMortalitiesByBooking, fetchBookingReportByBookingId 
} from 'services/booking-reports';

export default class ClearanceReport extends Component {
  
  state = { liveBsData : false };

  constructor ( props ) {
    super (props);
    
    // populate current booking id
    let bookingFieldIdx = clearanceFields.findIndex( f => f.name === 'bookingId');
    if (bookingFieldIdx >= 0 && props.bookingData) {
      clearanceFields[bookingFieldIdx].default = props.bookingData.id;
    }
  }

  async componentDidMount() {
    try {
      const speciesDetails = this.props.bookingData.hatcheryId.permittedSpeciesId;
      const opcode = 'booking-reports:' + 
      ( 
        this.props.currentValues
          ? ('update-clearance-certificate:' + this.props.currentValues.id) 
          : 'create-clearance-certificate'
      );
        
      const mortalityReport = await fetchTotalMortalitiesByBooking(this.props.bookingData.id, opcode);
      const labReport = await fetchBookingReportByBookingId(this.props.bookingData.id, 'lab-analysis-report', opcode);
      
      // auto populate data
      if (mortalityReport && mortalityReport.data) {
        let qrntDate = new Date(mortalityReport.data.arrivingDateTime);
        
        qrntDate.setDate(qrntDate.getDate() + (speciesDetails.duration - 1));
        
        clearanceFields[3].default = mortalityReport.data.arrivingDateTime;
        clearanceFields[4].default = qrntDate.toLocaleDateString('en-CA');
        clearanceFields[7].default = mortalityReport.data.totalArrivedBs;
        clearanceFields[8].default = mortalityReport.data.totalArrivMortalities;
        if (labReport && labReport.data && labReport.data.length) {
          clearanceFields[9].default = labReport.data[0].qrtnMortaLities;
        } else {
          clearanceFields[9].default = mortalityReport.data.totalMortality;
        }
        clearanceFields[10].default = clearanceFields[7].default - clearanceFields[8].default - clearanceFields[9].default;
        clearanceFields[11].default = `${this.props.bookingData.supplierDetails.name}, ${this.props.bookingData.supplierDetails.country}`;

        this.setState({liveBsData : true});
      }
    } catch (error) {
      console.log(error);
      clearanceFields[11].default = `${this.props.bookingData.supplierDetails.name}, ${this.props.bookingData.supplierDetails.country}`;
      this.setState({liveBsData : true});
    }
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully created clearance report for the booking..." };
    
    // todo API calls
    try {
      let response;
      if (this.props.currentValues) {
        response = await updateBookingReport('clearance-certificate',  this.props.currentValues.id, params);
        statusMessage = { status: "success", message: "Successfully updated clearance report..." };
      } else {
        response = await createNewBookingReport('clearance-certificate', params);
      }
      
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }

    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  render () {
    
    return(
      <>
        <Typography variant="h5" align="center" p={2} > 
        { 
          (this.props.currentValues ? 'Update Clearance Report' : 'Clearance Report') + 
          ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
        }
        </Typography><hr style={{borderColor:"#eef1f6"}} />
        {
          this.state.liveBsData &&
          <DynamicForm
            formFields={clearanceFields}
            action={this.props.currentValues ? "update" : "create"}
            handleSubmit={this.handleFormSubmit}
            buttonText="Save Report"
            buttonWidth= "full"
            columns = {3}
            uploadFiles = {true}
            currentValues={this.props.currentValues}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/clearance-certificate"}
          />
        }
      </>
    );
  }
}
