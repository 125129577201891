import { Paper, Typography, Grid } from '@mui/material';

import ImageOrDocument from 'components/ImageOrDocument';

const EntryGridView = (props) => {
  
  if (!props.values || !props.formFields || props.formFields.length === 0) {
    console.log("invalid tabular entry");
    console.log(props);
    return (<></>);
  }
  
  return (<Paper elevation={props.noPaper ? 0 : 3} sx={{textAlign:"center", mt : 1, ml : 1, p : 2}}>
    {
      props.title &&
      <><Typography variant="h5"> {props.title} </Typography><br/><hr style={{borderColor:"#eef1f6"}} /></>
    }
    <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2} sx={{ mt : props.title ? 1 : 0}}>
      {
        props.formFields.map ( (field, idx) => {
          if (['file', 'image'].includes(field.type) === false && props.values[field.name] && 
            typeof props.values[field.name] === 'object') {
            return;
          }
          
          if (field.actions && field.actions.find(a => a === 'view')) {
            
            if (props.values.hasOwnProperty(field.name) == false && ['file', 'image'].includes(field.type) === false ) {
              return (
                <Grid item xs={6} sm={props.numColumns ? 12/props.numColumns : 4} key={`row-${idx}`}>
                  <Typography key={`row-${idx}-col-0`} align="left" variant="h5" >{field.label}</Typography>
                  <Typography key={`row-${idx}-col-1`} align="left" variant="body2"> -- </Typography>
                </Grid>
              );
            }
          
            if (['image', 'file'].includes(field.type)) {
              return (<Grid item xs={6} sm={props.numColumns ? 12/props.numColumns : 4} key={`row-${idx}`} >
                <Typography key={`row-${idx}-col-0`} align="left" variant="h5" >{field.label}</Typography>
                {
                  Array.isArray(props.values[field.name]) ?
                  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{m:2}}>
                   {
                    props.values[field.name].map ( (v,idx) => {
                      return (<Grid item key={'inneridx-' + idx}><ImageOrDocument document={v} minView align="flex-start" /></Grid>)
                    })
                   }
                  </Grid>  : 
                  <ImageOrDocument document={props.values[field.name]} minView align="flex-start" />
                }
              </Grid>)
            } else if (field.type === 'select') {
              return (<Grid item xs={6} sm={props.numColumns ? 12/props.numColumns : 4} key={`row-${idx}`}>
                <Typography key={`row-${idx}-col-0`} align="left" variant="h5" >{field.label}</Typography>
                <Typography key={`row-${idx}-col-1`} align="left" variant="body2">
                  {
                    props.values[field.name] ? 
                      field.selectOptions[props.values[field.name]] ?
                      field.selectOptions[props.values[field.name]] : props.values[field.name] : '--'
                  }
                </Typography>
              </Grid>)
            } else if (field.type === 'boolean') {
              return (<Grid item xs={6} sm={props.numColumns ? 12/props.numColumns : 4} key={`row-${idx}`}>
                <Typography key={`row-${idx}-col-0`} align="left" variant="h5" >{field.label}</Typography>
                <Typography key={`row-${idx}-col-1`} align="left" variant="body2">
                  {field.valueStrings ? field.valueStrings[props.values[field.name] ? 1 : 0] : props.values[field.name].toString()}
                </Typography>
              </Grid>)
            } else if (field.type === 'date') {
              let value = props.values[field.name] ? new Date(props.values[field.name]) : null;
              return (<Grid item xs={6} sm={props.numColumns ? 12/props.numColumns : 4} key={`row-${idx}`}>
                <Typography key={`row-${idx}-col-0`} align="left" variant="h5" >{field.label}</Typography>
                <Typography key={`row-${idx}-col-1`} align="left" variant="body2">
                  {value ? value.toLocaleDateString('en-GB', {'timezone' : 'Asia/Kolkata'}) : '--'}
                </Typography>
              </Grid>)
            } else if ('datetime' === field.type) {
              let value = props.values[field.name] ? new Date(props.values[field.name]) : null;
              return (<Grid item xs={6} sm={props.numColumns ? 12/props.numColumns : 4} key={`row-${idx}`}>
                <Typography key={`row-${idx}-col-0`} align="left" variant="h5" >{field.label}</Typography>
                <Typography key={`row-${idx}-col-1`} align="left" variant="body2">
                  {value ? value.toLocaleString('en-GB', {'timezone' : 'Asia/Kolkata'}) : '--'}
                </Typography>
              </Grid>)
            } else if ('text' === field.type && props.values[field.name]) {
              return (<Grid item xs={12} sm={12} key={`row-${idx}`}>
                <Typography key={`row-${idx}-col-0`} align="left" variant="h5" >{field.label}</Typography>
                <Typography key={`row-${idx}-col-1`} align="left" variant="body2">
                  {props.values[field.name]}
                </Typography>
              </Grid>)
            } else if ('currency' === field.type) {
              return (<Grid item xs={6} sm={props.numColumns ? 12/props.numColumns : 4} key={`row-${idx}`}>
                <Typography key={`row-${idx}-col-0`} align="left" variant="h5" >{field.label}</Typography>
                <Typography key={`row-${idx}-col-1`} align="left" variant="body2">
                  <span dangerouslySetInnerHTML={{__html : (props.values[field.name] ? "&#x20B9; " + props.values[field.name] : '')}} />
                </Typography>
              </Grid>)
            } else {
              return (<Grid item xs={6} sm={props.numColumns ? 12/props.numColumns : 4} key={`row-${idx}`}>
                <Typography key={`row-${idx}-col-0`} align="left" variant="h5" >{field.label}</Typography>
                <Typography key={`row-${idx}-col-1`} align="left" variant="body2">
                  {[undefined, null].includes(props.values[field.name]) ? '--' : props.values[field.name]}
                </Typography>
              </Grid>)
            }
          }
        })
      }
    </Grid>
  </Paper>);
};

export default EntryGridView;
