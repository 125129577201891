import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withNavigator  } from 'hoc';
import PropTypes from 'prop-types';

import moment from 'moment';

import socketIOClient from "socket.io-client";

import {
  AppBar,
  Paper,
  Badge,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip,
  Menu,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import InputIcon from '@mui/icons-material/Input';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import Logo from './Logo';

// add idle timeout component
import IdleTimeout from 'components/IdleTimeout';

// services for notification and logout
import { processLogoutReq } from 'services/user';

import { fetchWebNotifications, markNotificationsAsRead } from 'services/common';

import { AuthStore, HatcheryStore } from 'store';

import { calculateAPISignature } from 'services/utils';

import { permissionCheck } from 'permissions';

var notificationSocket = null;

class DashboardNavbar extends React.Component {
  
  state = {
    notifications : [],
    socNotifications: [],
    anchorEl : null
  };
  
  constructor (props) {
    super(props);
  }
  
  handleClick = (event) => {
    this.setState({anchorEl : event.currentTarget});
  };
  
  handleClose = () => {
    this.setState({anchorEl : null});
  };
  
  
  handleSocNotification = (data) => {
    let socNotifications = this.state.socNotifications;
    socNotifications.push(data);
    this.setState({ socNotifications: socNotifications });
  }
  
  handleLogout = async (event) => {
    try {
      let response = await processLogoutReq();
      if (response.status === 200) {
        if (notificationSocket) {
          console.log("disconnecting notification socket");
          // disconnect socket on logout
          notificationSocket.disconnect();
          notificationSocket = null;
        }

        this.props.navigate("/", {replace : true});
      }
      return response;
    } catch(error) {
      console.log(error);
      return error;
    }
  }
  
  async componentDidMount() {
    
    // user email to set user socket id for client
    if (!notificationSocket) {
      console.log("initializing socket for user");
      notificationSocket = socketIOClient(process.env.REACT_APP_API_ENDPOINT, { transports: ['websocket'] });
     
      // register user email as socket identifier
      notificationSocket.on('connect', () => {
        console.log("new socket connection");
        notificationSocket.emit('setUserId', AuthStore.state.currentUser.user.email);
      });

      // to check if io is successful. server emits test data if connection is successful
      notificationSocket.on('test', data => {
        console.log(data);
      });

      notificationSocket.on("notification", data => {
        console.log("received web push notification");
        this.handleSocNotification(data);
      });
      
      // hatchery updated notification
      notificationSocket.on("hatchery", data => {
        console.log("received hatchery update push notification");
        console.log(data);
        if (AuthStore.state.currentUser &&  
          AuthStore.state.currentUser.user.role.name === 'Hatchery Owner') {
          HatcheryStore.setState({currentHatchery : data});
        }
      });
    }
    
    // fetch notifications 
    try {
      let response = await fetchWebNotifications({ page : 1, per_page : 100});
      this.setState({ notifications : response.data});
    } catch (error) {
      console.log(error);
    }
  }
  
  handleMarkAsRead = async () => {
    try {
      let response = await markNotificationsAsRead();
      this.setState({ notifications : [], socNotifications : [] });
    } catch(error) {
      console.log(error);
    }
  }

  render () {
  
    return (
      <AppBar elevation={0}>
        <IdleTimeout timeout={1000 * 60 * 30} handleIdleTimeout = {this.handleLogout} />
        <Toolbar>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          <Hidden lgUp>
            <IconButton
              color="inherit"
              onClick={this.props.onMobileNavOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Paper sx={{backgroundColor : '#F9F9F9'}} variant="outlined">
            <Tooltip title="Support" sx= {{ mx : 1 }}>
              <IconButton 
                color="inherit" 
                component={RouterLink} 
                to={"/main/support"}
              >
                <HelpOutlineOutlined sx={{fontSize:28}}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="My Profile" sx= {{ mx : 1 }}>
              <IconButton 
                color="inherit" 
                component={RouterLink} 
                to={"/aqf/dashboard/account"}
                state={{ id : AuthStore.state.currentUser.user.userProfile.id }}
              >
                <AccountCircleOutlined sx={{fontSize:28}} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Notifications" sx= {{ mx : 1 }}>
              <IconButton color="inherit" onClick={this.handleClick}>
                {
                  (this.state.notifications.length > 0 || this.state.socNotifications.length > 0) ?
                  <Badge
                    badgeContent={this.state.notifications.length + this.state.socNotifications.length }
                    color="error"
                    //variant="dot"
                  >
                    <NotificationsIcon sx={{fontSize:28}} />
                  </Badge> : 
                  <NotificationsIcon />
                }
              </IconButton>
            </Tooltip>
            {
              permissionCheck('common', 'settings-update') &&
              <Tooltip title="Settings" sx= {{ mx : 1 }}>
                <IconButton 
                  color="inherit" 
                  component={RouterLink} 
                  to={"/aqf/admin/settings"}
                >
                  <SettingsSuggestOutlinedIcon sx={{fontSize:28}}/>
                </IconButton>
              </Tooltip>
            }
            <Tooltip title="Logout" sx= {{ mx : 1 }}>
              <IconButton 
                color="inherit" 
                onClick={this.handleLogout}
              >
                <InputIcon sx={{fontSize:28}}/>
              </IconButton>
            </Tooltip>
          </Paper>
          {
            (this.state.notifications.length > 0 || this.state.socNotifications.length > 0) &&
            <Menu
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
              onClick={this.handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflowY: 'scroll',
                  overflowX: 'wrap',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 40,
                    height: 40,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <List sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper', height : 480 }}>
                <ListItem key="actionbtn" sx={{display:'flex', justifyContent:'flex-end'}}>
                  <Button color="error" size="small" 
                    variant="outlined" 
                    startIcon={<DoneAllOutlinedIcon />} 
                    onClick = {this.handleMarkAsRead}
                  > 
                    Clear All
                  </Button>
                </ListItem>
                <Divider/>
              {
                this.state.socNotifications.map((val, idx) => {
                  let signature = val.owner.picture ? calculateAPISignature(encodeURIComponent(val.owner.picture).replace(/ /g, '%20')) : null;
                  return (
                    <>
                    <ListItem alignItems="flex-start" key={'soc' + idx}>
                      <ListItemAvatar>
                        <Avatar 
                          alt="User"
                          src={val.owner && val.owner.picture
                            ? process.env.REACT_APP_STRAPI_FILE_URL + val.owner.picture + "?s=" + signature
                            : process.env.PUBLIC_URL + '/static/images/misc/dummy.png'
                          } 
                        />
                      </ListItemAvatar>
                      <ListItemText 
                        // primary={val.notificationString}
                        secondary={
                          <>
                            <Typography variant="caption" color="text.primary">  {val.notificationString} </Typography>
                            <Box display="flex" justifyContent="flex-end" component="span">
                              <Typography noWrap variant="caption" color="text.secondary">
                                {moment(val.created_at).fromNow()}
                              </Typography>
                            </Box>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" key={'divider-soc' + idx} />
                    </>
                  )
                })
              }
              { 
                this.state.notifications.map((val, idx) => {
                  let signature = val.owner.picture ? calculateAPISignature(encodeURIComponent(val.owner.picture).replace(/ /g, '%20')) : null;
                  return (
                    <>
                    <ListItem alignItems="flex-start" key={'static' + idx}>
                      <ListItemAvatar>
                        <Avatar 
                          alt="User" 
                          src={val.owner && val.owner.picture
                            ? process.env.REACT_APP_STRAPI_FILE_URL + val.owner.picture + "?s=" + signature
                            : process.env.PUBLIC_URL + '/static/images/misc/dummy.png'
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText 
                        // primary={val.notificationString}
                        secondary={
                          <>
                            <Typography variant="caption" color="text.primary">  {val.notificationString} </Typography>
                            <Box display="flex" justifyContent="flex-end" component="span">
                              <Typography noWrap variant="caption" color="text.secondary">
                                {moment(val.created_at).fromNow()}
                              </Typography>
                            </Box>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li"  key={'divider-static' + idx} />
                    </>
                  )
                })
              }
              </List>
            </Menu>
          }
        </Toolbar>
      </AppBar>
    );
  }
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default withNavigator(DashboardNavbar);
