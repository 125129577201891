import React, { Component } from 'react';

// Material components/
import { Paper, Typography } from '@mui/material';
import BookingScheduleForm from './form-components/BookingScheduleForm';

import NavigateBack from 'components/NavigateBack';

export default class AddBookingSchedule extends Component {
  constructor(props) {
    super(props);
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params, tableData) => {
    let statusMessage = { status: "success", message: "Successfully updated booking schedules..." };
    
    // todo API calls
    
    return statusMessage;
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Add New Booking Schedule </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          <BookingScheduleForm />
        </Paper>
      </>
    );
  }
}