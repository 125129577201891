import React, { Component } from 'react';

import { Alert } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { bankFields } from '../hatchery.fields';

// Shared services
import { createNewBankAccount, updateBankAccountDetails } from 'services/hatcheries';
import { checkUniqueAttributes } from 'services/common';

export default class SingleBankDetailsForm extends Component {
  
  handlePreFormSubmit = async(formData) => {
    
    let statusMessage = { status: "success", message: "" };
    
    let validation = {isValid : true};
      
    // check uniqueness of the attributes
    try {
      let uniqueParams = [{
        'attribute' : 'bankAccount',
        'value' : { 'accountNumber' : formData.accountNumber, 'IFSC' : formData.IFSC },
        'currentId' : this.props.id
      }];

      let result = await checkUniqueAttributes(uniqueParams);
      
      if (result.data.bankAccount && result.data.bankAccount.unique === false) {
        validation.isValid = false;
        validation['accountNumber'] = { isInvalid : true, message : result.data.bankAccount.message };
      }
    } catch(error) {
      statusMessage =  { status: "error", message: error.message };
    }
  
    if (validation.isValid === false) {
      statusMessage =  { 
        status: "error", 
        message: 'One or more fields in the form data doesn\'t meet uniqueness criteria', 
        data : validation 
      };
    }
    
    return statusMessage;
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    
    await this.setState({ isSubmitting: true });
    let statusMessage = { status: "success" };
    try {
      let response;

      if (this.props.currentValues) {
        response = await updateBankAccountDetails(this.props.id, params);
      } else {
        params.hatcheryId = this.props.hatcheryId;
        response = await createNewBankAccount(params);
      }
      if (this.props.refresh) this.props.refresh();
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    return statusMessage;
  }


  render() {
    return (
      <>
        <Alert severity="info" sx={{ my : 1 }}>
          Please enter bank account number along with all the leading zeros
        </Alert>
        <DynamicForm
          formFields={bankFields}
          handleSubmit={this.handleFormSubmit}
          preFormSubmit={this.handlePreFormSubmit}
          buttonText={this.props.currentValues ? "Update Bank Details" : "Add Bank Account"}
          buttonWidth= "full"
          action={this.props.currentValues ? "update" : "create"}
          columns = {2}
          currentValues={this.props.currentValues}
          uploadFiles={true}
          redirect={null}
        />
      </>
    );
  }
}
