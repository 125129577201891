import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { withLocationParams } from 'hoc';

// Material components/
import { Paper, Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';
import NavigateBack from 'components/NavigateBack';

import cubicleFields from './cubicle.fields';

import { fetchCubicleById, updateCubicle } from 'services/cubicles';

class EditCubicle extends Component {
  
  state = { 
    cubicleData : null, 
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."}
  };
  
  constructor(props) {
    super(props);
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated cubicle details..." };
    
    // todo API calls
    try {
      let response = await updateCubicle(this.state.cubicleData.id, params);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data};
    }
    
    return statusMessage;
  }
  
  async componentDidMount() {
    let cubicleData = null;
    let statusMessage = this.state.statusMessage;
    try {
      let response = await fetchCubicleById(this.props.locationParams.id);
      cubicleData = response.data;
      statusMessage = {status: "success", message: "Fetched cubicle details successfully !"}  
    } catch (error) {
      console.log (error);
      statusMessage = { status : "error", message : error.message };
    }
    await this.setState({cubicleData, statusMessage});
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Update Cubicle Details </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
          {
            this.state.cubicleData &&
            <DynamicForm
              formFields={cubicleFields}
              action="update"
              handleSubmit={this.handleFormSubmit}
              buttonText="Update Cubicle Details"
              buttonWidth= "full"
              columns = {3}
              currentValues={this.state.cubicleData}
              redirect={'/aqf/admin/cubicles/list'}
              confirmation={"Are you sure you want to save the details ? "}
            />
          }
        </Paper>
      </>
    );
  }
}

export default withLocationParams(EditCubicle);