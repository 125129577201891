import { fetchWithTimeout, addPagerParams, calculateAPISignature } from './utils';

import {uploadImgToBackend, removeImgFromBackend } from './upload';

import { permissionCheck } from 'permissions';

const SETTINGS_EP_URL = "/settings";
const OTP_EP_URL = "/registration-otps";
const NOTIFICATIONS_EP_URL = "/notification-statuses";
const EVENT_LOG_EP_URL = "/activity-loggers";
  
export const fetchSettingsByType = async (params) => {
  return await fetchWithTimeout({
    path : SETTINGS_EP_URL + '?type=' + params.type
        + (params.name ? '&name=' + params.name : '')
        + (params.parent ? '&parent=' + params.parent : ''),
    method : 'GET',
    operation : 'common:view-settings'
  });
};

export const fetchAdminSettings = async () => {
  return await fetchWithTimeout({
    path : SETTINGS_EP_URL + '/admin',
    method : 'GET',
    operation : 'common:view-settings'
  });
};

export const updateAdminSettings = async (body) => {
  return await fetchWithTimeout({
    path : SETTINGS_EP_URL + '/admin',
    method : 'PUT',
    body : body,
    operation : 'common:settings-update'
  });
};
export const createNewOTPForUser = async (params) => {
  return await fetchWithTimeout({
    path : OTP_EP_URL,
    method : 'POST',
    body : params,
    useToken : false,
    operation : 'common:validate-otp'
  });
}

export const verifyOTPCode = async (mobileNumber, otp) => {
  return await fetchWithTimeout({
    path : OTP_EP_URL + '?number=' + mobileNumber + '&OTP=' + otp,
    method : 'GET',
    useToken : false,
    operation : 'common:validate-otp'
  });
};

export const faceRecognitionEnrol = async (hatcheryId, imageSrc, profileType, uploadFile=true) => {
  const time = new Date().getTime();
  const dataImage = imageSrc.toString()
    .replace("\n", "")
    .replace('data:image/png;base64,', '')
    .replace('data:image/jpeg;base64,', '');
  
  let response = {
    status : 'success',
    message : 'Face registered successfully', 
    enrollmentId : `${hatcheryId}-${profileType}-${time}`,
    fileUpload : null
  };
  
  
  let params = {
      "userid": "37",
      "livefaceimage": dataImage,
      "rrn": "45897855885588",
      "reftimestamp": new Date().toISOString(),
      "slk": "IVPAF-LSVMG-JMZCE-TXVEM",
      "servicecode": "23",
      "enrollmentid": `${hatcheryId}-${profileType}-${time}`
  };

  try {
    
    let enrollResponse = await fetchWithTimeout({
      path : SETTINGS_EP_URL + '/faceenrol',
      method : 'POST',
      body : params,
      timeout : 60000,
      operation : 'common:face-recognition'
    }); 
    
    if (enrollResponse.data.status == 1) {
      // enrollment successful
      let fileUploadResponse = {status : "success", url : null};
      if (uploadFile) {
        response.fileUpload = await uploadImgToBackend(imageSrc, `${hatcheryId}-${profileType}-${time}.png`);
      }
    } else {
      // check the status and returned enrollment id
      if (enrollResponse.data.errdesc === 'Query face found') {
        
        let returnEnrolId = enrollResponse.data.enrolledid.split('-');
        
        // check parts of the enrollment id
        if (returnEnrolId[0] == hatcheryId) {
          let profile = returnEnrolId[1] === 'owner' ? 'owner' : 
            returnEnrolId[1] === 'auth1' ? 'authorized person 1' : 'authorized person 2';
            
          if (returnEnrolId[1] == profileType) {
            // this is not a error scenario, same face was found for same profile type
            //
            response.enrollmentId = enrollResponse.data.enrolledid;
            if (uploadFile) {
              response.fileUpload = await uploadImgToBackend(imageSrc, `${hatcheryId}-${profileType}-${time}.png`);
            }
          } else {
            response.status = 'error';
            response.message = 'Face is already registered with the current hatchery ' + profile 
                + '.Please scan a different face. Previous face enrollment ID : ' + enrollResponse.data.enrolledid;
            response.enrollmentId = enrollResponse.data.enrolledid;
          }
        } else {
          response.status = 'error';
          response.message ='Face is already registered with a different hatchery. Please scan a different face. ' 
              + 'Previous face enrollment ID : ' + enrollResponse.data.enrolledid;
          response.enrollmentId = enrollResponse.data.enrolledid;
        }
      } else {
        response.status = 'error';
        response.message = enrollResponse.data.errdesc;
      }
    }
  } catch (error) {
    console.log(error);
    response.status = 'error';
    response.message = error.message;
  }
  
  return response;
}


export const faceRecognitionDelete = async (filepath) => {
  
  let filename = null, enrollment = null;
  console.log(filepath);
  
  if (filepath) {
    // extract the filename from full path
    filename = filepath.split('/');
    filename = filename[filename.length - 1];
    
    // extract enrollment id from filename, the first 3 parts
    filename = filename.split('_');
    if(filename.length > 3) {
      enrollment = `${filename[0]}-${filename[1]}-${filename[2]}`;
    } else {
      // not a valid enrollment id based filename
      return;
    }
  } else {
    // no valid enrollment found
    return;
  }
  
  console.log(filename);
  console.log(enrollment);
  
  const reader = new FileReader();
  reader.onloadend = async () => {
    const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
    let params = {
      "userid": "37",
      "livefaceimage": base64String,
      "rrn": "45897855885588",
      "reftimestamp": new Date().toISOString(),
      "enrollmentid": enrollment
    };
    
    try { 
      let response = await fetchWithTimeout({
        path : SETTINGS_EP_URL + '/facedelete',
        method : 'POST',
        body : params,
        timeout : 60000,
        operation : 'common:face-recognition'
      });
    
      if (response.data.status != 1) {
        console.log(response.data);
        return;
      }
      
      // also delete image from backend here !!!
      await removeImgFromBackend(filepath);
      
    } catch(error) {
      console.log(error);
    }
  };
  
  reader.onerror = error => console.log(error);
  
  try {
    let response = await fetch(process.env.REACT_APP_API_ENDPOINT + filepath);
    if (response.status === 200) {
      response = await response.blob();
      await reader.readAsDataURL(new Blob([response]));
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const faceRecognitionMatch = async (dataImage, matchUrl) => {
  
  let response = {
    status : 'success',
    message : 'Face Verification successfull', 
  };
  
  let fileResponse;
  
  try {
    let signature = calculateAPISignature(encodeURIComponent(matchUrl));
    fileResponse = await fetch(process.env.REACT_APP_API_ENDPOINT + matchUrl + '?s=' + signature);
    if (fileResponse.status !== 200) {
      response.status = 'error';
      response.message = "Face verification failed as uploaded profile picture was not available on server";
    }
  } catch (error) {
    console.log(error);
    response.status = 'error';
    response.message = error.message;
  }
  
  // dont proceed with face match API call
  if (response.status === "error") return response;
  
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onerror = error => resolve({status : "error", message : "Failed to read image file"});
    reader.onloadend = async () => {
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
      let params = {
        "userid": "37",
        "imagedata1": base64String,
        "imagedata2": dataImage.replace('data:', '').replace(/^.+,/, '')
      };
      
      try {
        let enrollResponse = await fetchWithTimeout({
          path : SETTINGS_EP_URL + '/facematch',
          method : 'POST',
          body : params,
          timeout : 60000,
          operation : 'common:face-recognition'
        });
        
        if (enrollResponse.data.status != 1) {
          response.status = "error";
          response.message = enrollResponse.data.errdesc;
        }
      } catch(error) {
        console.log(error);
        response.status = 'error';
        response.message = error.message;
      }
      
      resolve(response);
    };
    
    fileResponse.blob().then(blob => reader.readAsDataURL(new Blob([blob])));  
  });
};


export const mergePdfDocuments = async (files, fileName) => {
  return await fetchWithTimeout({
    path : SETTINGS_EP_URL + '/merge-pdf',
    method : 'POST',
    body : { files, fileName },
    timeout : 60000,
    operation : permissionCheck('shipment', 'add-shipment-self') ? 'booking:add-shipment-self' : 'booking:add-shipment-any'
  });
};

export const fetchWebNotifications = async(params) => {
  return await fetchWithTimeout({
    path : NOTIFICATIONS_EP_URL + addPagerParams(params) + '&read=false',
    method : 'GET',
    operation : 'common:view-notifications'
  });
};

export const markNotificationsAsRead = async() => {
  return await fetchWithTimeout({
    path : NOTIFICATIONS_EP_URL + '/markallread',
    method : 'POST',
    body : {},
    operation : 'common:update-notifications'
  });
}

export const checkUniqueAttributes = async (params) => {
  return await fetchWithTimeout({
    path : SETTINGS_EP_URL + '/test-unique',
    method : 'POST',
    useToken : false,
    body : params,
    operation : 'common:validate-form'
  });
};


export const fetchEventLogsTotalCount = async (params) => {
  return await fetchWithTimeout({
    path : EVENT_LOG_EP_URL + '/count?_start=0'
      + (params.hatcheryId !== 'ALL' ? '&hatcheryId=' + params.hatcheryId : '')
      + (params.hatcheryName && params.hatcheryName.length ? '&hatcheryId.name_contains=' + params.hatcheryName : '')
      + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : ''),
    method : 'GET',
    operation : 'common:view-event-log-any'
  });
};

export const fetchEventLogsData = async (params) => {
  return await fetchWithTimeout({
    path : EVENT_LOG_EP_URL + addPagerParams(params)
      + (params.hatcheryId !== 'ALL' ? '&hatcheryId=' + params.hatcheryId : '')
      + (params.hatcheryName && params.hatcheryName.length ? '&hatcheryId.name_contains=' + params.hatcheryName : '')
      + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : ''),
    method : 'GET',
    operation : 'common:view-event-log-any'
  });
};

export const fetchEventLogById = async (id) => {
  return await fetchWithTimeout({
    path : EVENT_LOG_EP_URL + '/' + id,
    method : 'GET',
    operation : 'common:view-event-log-any'
  });
}
