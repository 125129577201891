import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { cancelBooking } from 'services/bookings';

export default class CancellationForm extends React.Component {
 
  constructor(props) {
    super(props);
    
    this.formFields = [{
        name : 'cancelRemark',
        label : 'Cancel Remarks',
        type : 'string',
        properties : [{required : true }, { string : { min : 8, max : 120}}],
        actions : ['create']
    }];
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (formData) => {
    let statusMessage = { status: "success", message: "Successfully cancelled booking..." };
    try {
      let response = await cancelBooking(this.props.bookingData.id, formData);
      if (this.props.refresh) this.props.refresh();
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    return statusMessage;
  }
  
  render() {
    return(<>
    
      <Typography variant="h4" align="center" p={1} > 
      { 
        `Cancel Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
      }
      </Typography><hr style={{borderColor:"#eef1f6"}} />
      <DynamicForm
        formFields={this.formFields}
        action="create"
        handleSubmit={this.handleFormSubmit}
        buttonText="Cancel Booking"
        buttonWidth= "full"
        columns = {1}
        redirect={null}
      />
    </>);
  }
}