import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm, DynamicTableForm } from 'components/Forms';

import { mortalityFields } from '../booking-reports.fields';

import { createNewBookingReport, updateBookingReport, filterFormFieldsBySpeciesType} from 'services/booking-reports';

export default class MortalityReport extends Component {
  
  constructor ( props ) {
    super (props);
    
    // populate cubicle info
    this.formFields = [];
    mortalityFields.map (f => {
      let field = { ...f};
      
      field.properties = [];
      // deep copy properties
      f.properties.map ( p => field.properties.push({...p}));
      
      // deep copy select options
      if (f.selectOptions) {
        field.selectOptions = {...f.selectOptions};
      }
      
      if (f.name === 'cubicleId') {
        field.selectOptions = {};
        if (this.props.bookingData.cubicleDetails) {
          props.bookingData.cubicleDetails.map( c => {
            if (c.cubicleId) field.selectOptions[c.cubicleId] = c.cubicleName;
            else if (c.cubicleNumber) field.selectOptions[c.cubicleNumber] = `CUB-${c.cubicleNumber}`;
          });
        } else if (this.props.bookingData.cubicleBookingScheduleIds) {
          props.bookingData.cubicleBookingScheduleIds.map(c => {
            field.selectOptions[c.cubicleId] = `CUB-${c.cubicleId}`;
          });
        }
      } 
      this.formFields.push(field);
    });
    
    // filter form fields based on species type
    this.formFields = filterFormFieldsBySpeciesType(
      'mortality-report', props.bookingData.speciesType.type, this.formFields);
  }
   
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  
  handleCreateFormSubmit = async (params, tableData) => {
    let statusMessage = { status: "success", message: "Successfully added new mortality details..." };
    let formData = { 
      bookingId : this.props.bookingData.id,
      bulkData : tableData.map(t => {
        // add booking id into the data
        t.bookingId = this.props.bookingData.id;
        return t;
      })
    };
   
    try {
      let response = await createNewBookingReport('mortality-report', formData);
      
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message };
      if (error.data && error.data.isValid === false) {
        let errorsOnSubmit = error.data.bulkData[0];
        delete errorsOnSubmit.arrIdx;
        statusMessage['data'] = errorsOnSubmit;
      }
    }
    
    return statusMessage;
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleEditFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated mortality details..." };
    try {
      let response = await updateBookingReport('mortality-report', this.props.currentValues.id, params);
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  handleTableDataChange = async (tableData) => {
    // no-op
  }
  
  render () {
    
    let currentValues = this.props.currentValues;
    if (currentValues) {
      // update cubicle info
      currentValues.cubicleId = currentValues.number;
    }
    
    return(
      <>
        <Typography variant="h5" align="center" p={2} > 
        { 
          (this.props.currentValues ? 'Update Mortality Report' : 'Mortality Report') + 
          ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
        }
        </Typography><hr style={{borderColor:"#eef1f6"}} />
        {
          !this.props.currentValues ?
          <DynamicTableForm
            formFields = {[]}
            tableFormProps = {{
              fields : this.formFields,
              handleTableDataChange : this.handleTableDataChange,
              serialNoHeader : 'S.No',
              deleteRows: "none",
              uploadFiles : true,
              minRows : 1,
              maxRows: 15,
              currentValues : null,
            }}
            uploadFiles={true}
            action="create"
            buttonText="Save Mortality Report"
            handleSubmit={this.handleCreateFormSubmit}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/mortality-report"}
          /> :
          <DynamicForm
            formFields={this.formFields}
            action="update"
            handleSubmit={this.handleEditFormSubmit}
            buttonText="Update Mortality Report"
            buttonWidth= "full"
            columns = {4}
            currentValues={currentValues}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/mortality-report"}
          />
        }   
      </>
    );
  }
}
