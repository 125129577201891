import { createStyles } from '@mui/styles';
import theme from 'theme';

export default createStyles({
  root: {
  alignItems: 'center',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  display: 'flex',
  flexBasis: '420px',
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5)
  },
  icon: {
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary
  },
  input: {
  flexGrow: 1,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '-0.05px'
  }
});
