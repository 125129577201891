import React, { Component } from 'react';
// Externals
import PropTypes from 'prop-types';

// Material helpers
import { Typography, Button, Box, Paper } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

// Shared services
import { forgotPasswordOTP } from 'services/user';


export default class ForgotPasswordForm extends Component {

  constructor(props) {
    super(props);

    this.forgotFormFields = [
      {
        name: 'identifier',
        label: 'Enter Phone Number',
        type: 'string',
        properties: [{required:true}]
      },
    ];
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Please enter the OTP sent to your mobile" };
    try {
      const response = await forgotPasswordOTP(params);
      if (response.data.status) {
        statusMessage.message = response.data.message;
      }
      if (this.props.handleOtpValidate) {
        this.props.handleOtpValidate(params.identifier, statusMessage);
      }
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message };
    }
    
    return statusMessage;
  }


  render() {
    const classes = this.props.classes;
    return (
      <Paper sx={{ p : 4 }}>
        <Typography align="center"> Enter your Registered Mobile Number </Typography><hr style={{borderColor:"#eef1f6"}} />
        <DynamicForm
          classes={classes}
          formFields={this.forgotFormFields}
          handleSubmit={this.handleFormSubmit}
          buttonText="Click To Receive OTP"
          buttonWidth= "full"
          columns = {1}
          redirect={null}
        />
        <Button 
          onClick={this.props.handleBackToLogin} 
          startIcon={<ChevronLeft />}
          color="secondary" size="small" style={{float : 'right'}}
        > Back To Login 
        </Button>
      </Paper>
    );
  }
}

ForgotPasswordForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};
