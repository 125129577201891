import React, { Component } from 'react';

import { Container, Paper, Grid, Typography, Dialog, DialogTitle, DialogContent, Button, Box} from '@mui/material';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

// upload services
import { uploadImgToBackend } from 'services/upload';

// import form components
import CAARegistrationForm from './form-components/CAARegistrationForm';
import HatcheryOwnerForm from './form-components/HatcheryOwnerForm';
import AuthorizedPersonForm  from './form-components/AuthorizedPersonForm';
import BankDetailsForm from './form-components/BankDetailsForm';
import OTPValidation from 'pages/common/OTPValidation';

// import confirmation page
import ConfirmationPage from 'pages/common/ConfirmationPage';

import HatcheryBasicDetails from './view-components/HatcheryBasicDetails';

import { registerHatchery } from 'services/hatcheries';

const SAMPLE_REGISTRATION_PAYLOAD = {
  'document' : '/uploads/Dec_2020_7f9610fc9e.pdf',
  'OTP' : '3306',
  'hatcheryData' : {
    "id":1259,
    "name":"New Dev Hatchery V2",
    "address":"Test Address 1",
    "address2":"Test Address 2",
    "contactPerson":"Narendra Vadde",
    "CAARegistrationNumber":"45562/CAA/345",
    "issuingDate":"2020-10-22",
    "approvalStatus":"ApprovalPending",
    "contactNumber":"9886480752",
    "noOfBroodsStockPerYear":2000,
    "validFrom":"2021-01-22",
    "validTo":"2024-10-22",
    "district":"Gandhinagar",
    "zone":"zone-4"
  },
  'userProfile' : {
    'address' : "Test Address 1",
    'email' : "naren389@gmail.com",
    'name' : "New Dev Hatchery V2",
    'password': "Test@123",
    'phoneNumber': "9886480751",
    'picture' : "/uploads/1258_owner_1635101475424_5dbddaae0d.png"
  },
  'authPerson1' : {
    'name' : 'V Krishna',
    'email' : 'dkpv0804@gmail.com',
    'phoneNumber' : '9966515313',
    'aadhaarNumber' : '234123412345',
    'picture' : "/uploads/1258_auth1_1635101532118_2a8cb4d888.png"
  },
  
  'authPerson2' : {
    'name' : 'V Bindu',
    'email' : 'bindupriya.n@gmail.com',
    'phoneNumber' : '7674929003',
    'aadhaarNumber' : '234123412345',
    'picture' : "/uploads/1258_auth2_1635101533888_c5074301b4.png"
  },
  'bankDetails' : [
    {
      'IFSC': "HDFC0000053",
      'accountName': "Bindu",
      'bankName' : 'HDFC Bank',
      'branchName' : 'BENGALURU',
      'accountNumber': "1234567881",
      'accountType': "PERSONAL",
      'bankAccountDetailProof': "/uploads/Mar_2021_5fbce2b9de.pdf"
    }
  ]
};

export default class RegisterHatchery extends Component {
  
  constructor ( props ) {
    super (props);
    this.steps = ['Search Hatchery', 'Owner Details', 'Authorized Person Details', 'Bank Details', 'OTP Verification'];
    this.state = {
      activeStep : 0,
      completed : {},
      statusMessage : null,
      ownerDetails : null,
      authPersonDetails : null,
      hatcheryData : null,
      bankDetails : null,
      modalOpen : true,
      otpPending : true,
      errorsOnSubmit : null
    };
    
    // use sample data for testing
    // this.state.hatcheryData = SAMPLE_REGISTRATION_PAYLOAD.hatcheryData;
    // this.state.ownerDetails = SAMPLE_REGISTRATION_PAYLOAD.userProfile;
    // this.state.ownerDetails.document = SAMPLE_REGISTRATION_PAYLOAD.document;
    // this.state.authPersonDetails = [SAMPLE_REGISTRATION_PAYLOAD.authPerson1, SAMPLE_REGISTRATION_PAYLOAD.authPerson2];
    // this.state.bankDetails = SAMPLE_REGISTRATION_PAYLOAD.bankDetails;
  }

  toggleModal = async () => {
    this.setState ( {modalOpen : !this.state.modalOpen } );
  }
  
  totalSteps = () => {
    return this.steps.length;
  };

  completedSteps = () => {
    return Object.keys(this.state.completed).length;
  };

  isLastStep = () => {
    return this.state.activeStep === this.totalSteps() - 1;
  };

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps();
  };

  handleNext = async () => {
    const newActiveStep =
      this.isLastStep() && !this.allStepsCompleted()
      ? // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      this.steps.findIndex((step, i) => !(i in this.state.completed))
      : this.state.activeStep + 1;
     this.setState({activeStep : newActiveStep});
  };

  handleBack = () => {
    //this.setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  handleStep = step => async () => {
    // await this.setState({activeStep : step});
  };

  handleComplete = async (statusMessage) => {
    const newCompleted = this.state.completed;
    newCompleted[this.state.activeStep] = "true";
    this.setState({completed : newCompleted, statusMessage : statusMessage});
    await this.handleNext();
  };

  handleReset = async () => {
    this.setState({activeStep : 0, completed: {}});
  };
  
  handleHatcheryDetails = async (hatcheryData) => {
    this.setState({ hatcheryData : hatcheryData, 
      statusMessage : {status: 'success', message : 'Fetched hatchery details for the user successfully'},
      modalOpen : false
    });
  }
  
  handleOwnerDetails = async (ownerDetails) => {
    this.setState({ ownerDetails });
    await this.handleComplete({status: 'success', message : 'Proceeding to Authorized Person details...'});
  }
  
  handleSkipAuthPerson = async () => {
    await this.handleComplete({status: 'warning', message : 'Proceeding to Authorized Person Details...'});
  }
  
  handleAuthPersonDetails = async (authPersonDetails) => {
    await this.setState({ authPersonDetails });
    await this.handleComplete({status: 'success', message : 'Proceeding to Bank Details....'});
  }
  
  handleBankDetails = async (bankDetails) => {
    this.setState({ bankDetails });
    await this.handleComplete({status: 'success', message : 'Proceeding to OTP Verification...'});
  }
  
  handleOTPValidation = async (number, otp) => {
    
    let statusMessage = {status: 'success', message : 'Hatchery registration is successful, Approval is pending from AQF...'};
    // check if OTP verification happened on a different mobile number,
    // update owner mobile and email
    
    this.state.ownerDetails.phoneNumber = number;
    
    // update hatchery id in user profile and bank details
    this.state.ownerDetails.hatcheryId = this.state.hatcheryData.id;
    for (let i = 0 ; i < this.state.bankDetails.length ; ++i) {
      this.state.bankDetails[i].hatcheryId = this.state.hatcheryData.id;
    }
    
    // send hatchery registration api call
    let registrationFormData = {
       'document' : this.state.ownerDetails.document,
       'OTP' : otp,
       'userProfile' : this.state.ownerDetails,
       'bankDetails' : this.state.bankDetails
    };
    
    // fill in auth person details from Array
    if (this.state.authPersonDetails) {
      this.state.authPersonDetails.map( (person, idx) => {
        registrationFormData[`authPerson${idx+1}`] = person;
      });
    }
    
    try {
      
      let response = await registerHatchery(this.state.hatcheryData.id, registrationFormData);
      await this.setState({otpPending : false, errorsOnSubmit : null});
      
      // last step not required
      // await this.handleComplete({status: 'success', message : 'Hatchery registration completed successfully !'});
      
    } catch(error) {
      statusMessage = {status : "error", message : error.message};
      let errorsOnSubmit = null;
      
      // go to step where error ocurred
      let activeStep = 4;
      if (error.data && error.data.isValid === false) {
        if (error.data.hasOwnProperty('userProfile') && error.data.userProfile.isValid === false) {
          activeStep = 1;
          errorsOnSubmit = error.data.userProfile;
        } else if (error.data.hasOwnProperty('authPerson1')  || error.data.hasOwnProperty('authPerson2')) {
          errorsOnSubmit = new Array(this.state.authPersonDetails.length).fill({isValid : true});
          activeStep = 2;
          if (error.data.hasOwnProperty('authPerson1') && error.data.authPerson1.isValid === false) {
            errorsOnSubmit[0] = error.data.authPerson1;
          }
          
          if (error.data.hasOwnProperty('authPerson2') && error.data.authPerson2.isValid === false) {
            errorsOnSubmit[1] = error.data.authPerson2;
          }
        } else if (error.data.hasOwnProperty('bankDetails')) {
            errorsOnSubmit = new Array(this.state.bankDetails.length).fill({isValid : true});
            error.data.bankDetails.map(b => errorsOnSubmit[parseInt(b.arrIdx)] = b);
            activeStep = 3;
        }
        this.setState({statusMessage : statusMessage, activeStep : activeStep, errorsOnSubmit : errorsOnSubmit});
      } else {
        this.setState({statusMessage : statusMessage, otpPending : false});
      }
    }
    
  }

   
  render () {
    
    return(<Container maxWidth={false}  sx={{mt:2}}>
      <Paper elevation={4} sx={{p:1}}>
        {
          this.state.statusMessage &&
          <CustomSnackbar variant={this.state.statusMessage.status}
            message={this.state.statusMessage.message}
            open={this.state.statusMessage.status}
            onClose={async () => await this.setState({ statusMessage: null })}
          />
        }
        <Typography variant="h3" align="center" p={2} > Hatchery Registration </Typography><br/><br/>
        <Stepper nonLinear activeStep={this.state.activeStep}>
          {this.steps.map((label, index) => (
            <Step key={label}>
              <StepButton disabled = {this.state.completed[index] ? true : false} 
                onClick={this.handleStep(index)} 
                completed={this.state.completed[index]}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
      </Paper>
      {
        this.state.activeStep === 0 &&
        (<div>
          <Dialog open={this.state.modalOpen} onClose={this.toggleModal} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle><Typography variant="h4" align="center" style={{borderBottom : '1px solid'}}>Search Your Hatchery</Typography></DialogTitle>
            <DialogContent> 
              <CAARegistrationForm handleHatcheryDetails = {this.handleHatcheryDetails} />
            </DialogContent>
          </Dialog>
          {
            this.state.modalOpen === false &&
            <Grid container direction="column" justifyContent="space-between" >
              <Grid item sx={{p:2}}>
                <Button onClick={this.toggleModal}  size="small" variant="contained" color="primary" style={{float:"right"}}> 
                  Not your hatchery ? Select Again
                </Button>
              </Grid>
              <Grid item>
              {
                this.state.hatcheryData &&
                <HatcheryBasicDetails 
                  hatcheryData = {this.state.hatcheryData} 
                  registration={true} 
                  handleFormSubmit={this.handleNext} 
                />
              }
              </Grid>
            </Grid>
          }
        </div>)
      }
      {
        this.state.activeStep === 1 &&
        (<Box sx={{mt:4}}>
           <Typography variant="h4" align="center"> Add Hatchery Owner Details </Typography> <br/>
           <HatcheryOwnerForm 
              action = "register"
              handleFormSubmit={this.handleOwnerDetails} 
              hatcheryData = {this.state.hatcheryData}
              currentValues = {this.state.ownerDetails}
              errorsOnSubmit = {this.state.errorsOnSubmit} 
           />
        </Box>)
      }
      {
        this.state.activeStep === 2 &&
        (<Box sx={{mt:4}}>
          <Typography variant="h4" align="center"> Add Authorized Person Details </Typography> <br/>
          <Grid container justifyContent="flex-end" direction="row" sx={{mb:2}}>
            <Button variant="outlined" color="secondary" onClick = {this.handleSkipAuthPerson}> 
              Skip Authorized Person Details
            </Button>
          </Grid>
          <AuthorizedPersonForm 
            handleFormSubmit={this.handleAuthPersonDetails} 
            hatcheryData = {this.state.hatcheryData}
            ownerData = {this.state.ownerDetails}
            currentValues = {this.state.authPersonDetails}
            errorsOnSubmit = {this.state.errorsOnSubmit}
          />
        </Box>)
      }
      {
        this.state.activeStep === 3 &&
        (<Box sx={{mt:4}}>
          <Typography variant="h4" align="center"> Add Bank Account Details </Typography><br/> 
          <BankDetailsForm 
            handleFormSubmit = { this.handleBankDetails}
            hatcheryData = {this.state.hatcheryData}
            currentValues = {this.state.bankDetails}
            errorsOnSubmit = {this.state.errorsOnSubmit}
          />
        </Box>)
      }
      {
        this.state.activeStep === 4 && this.state.otpPending &&
        <OTPValidation handleOTPValidation={this.handleOTPValidation} 
          number = {this.state.ownerDetails.phoneNumber }
          email = {this.state.ownerDetails.email}
          strict={false}
        />
      }
      {
        this.state.activeStep === 4 && !this.state.otpPending &&
        <ConfirmationPage
          type={this.state.statusMessage ? this.state.statusMessage.status : "success"}
          title={
            this.state.statusMessage && this.state.statusMessage.status === "error" 
              ? "Hatchery Registration Failed !!!"
              : "Hatchery Registration Successful !!!"
          }
          message= {
            this.state.statusMessage && this.state.statusMessage.status === "error" 
            ? this.state.statusMessage.message 
            : "Thank you for updating your hatchery details. Your hatchery data will be reviewed and " + 
              "approved by AQF Admin"
          }
          redirect="/"
          redirectText="Back To Home"
        />
      }
    </Container>);
  }
}