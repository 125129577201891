export default [
  {  
    name: 'id',
    label: 'Receipt No.',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking ID',
    type: 'string',
    properties: [{required:true}],
    actions: ['index', 'view']
  },
  {  
    name: 'hatcheryName',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['index']
  },
  {  
    name: 'reservedFrom',
    label: 'Cubicle(s) Reservation Date',
    type: 'date',
    properties: [{required:true}],
    actions: ['view']
  },
  {  
    name: 'modeOfPayment',
    label: 'Mode Of Payment',
    type: 'string',
    properties: [{required:true}],
    actions: ['view']
  },
  {  
    name: 'type',
    label: 'Type',
    type: 'string',
    properties: [{required:true}],
    actions: ['index']
  },
  {  
    name: 'bankAccountNo',
    label: 'Bank Account',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'update']
  },
  {  
    name: 'bankAccountName',
    label: 'Bank Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'update']
  },
  {  
    name: 'paymentId',
    label: 'Order No.',
    type: 'string',
    properties: [{required:true, readOnly:true}],
    actions: ['update', 'index', 'view']
  },
  {  
    name: 'transactionId',
    label: 'Payment Reference No.',
    type: 'string',
    properties: [{required:true, readOnly:true}],
    actions: ['update', 'index', 'view']
  },
  {  
    name: 'amount',
    label: 'Amount',
    type: 'currency',
    properties: [{required:false}, {float : true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'refundId',
    label: 'Refund No.',
    type: 'string',
    properties: [{required:true, readOnly:true}],
    actions: ['update', 'index', 'view']
  },
  {  
    name: 'refundAmount',
    label: 'Refund Amount',
    type: 'currency',
    properties: [{required:true, readOnly:true}],
    actions: ['update', 'index', 'view']
  },
  {  
    name: 'refundDate',
    label: 'Refund Date',
    type: 'date',
    properties: [{required:true, readOnly:true}],
    actions: ['index', 'view']
  },
  {  
    name: 'paymentDate',
    label: 'Payment Date',
    type: 'date',
    properties: [{required:true, readOnly:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'status',
    label: 'Payment Status',
    type: 'select',
    properties: [{required:false}],
    selectOptions: {
      "Approved"  : "Paid",
      "ApprovalPending" : "Unpaid",
      "Rejected" : "Rejected",
      "RefundPending" : "Refund Pending",
      "RefundApproved" : "Refund Completed",
      //"RefundRejected" : "Refund Rejected",
      "Cancelled" : "Cancelled"
    },
    actions: ['create', 'update', 'index']
  }
];
