const Logo = (props) => (
  <div style={{display : 'flex'}}>
    <img
      alt="Logo"
      src={process.env.PUBLIC_URL + "/static/images/home/mpeda-logo.png"}
      height="56"
      style={{marginRight : "8px"}}
    />
    <img
      alt="Logo"
      src={process.env.PUBLIC_URL +"/static/images/home/rgca-logo.png"}
      height="56"
      
    />
  </div>
);

export default Logo;
