import React, { Component } from 'react';
import { withLocationParams, withPrint } from 'hoc';
import { Navigate } from 'react-router-dom';

import { 
  Paper, Grid, Box, Button, Typography
} from '@mui/material';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

// import single entry view component
import EntryTabularView from 'components/EntryTabularView';

import NavigateBack from 'components/NavigateBack';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

// import shared services
import { fetchTransactionById } from 'services/transactions';

// import form fieldset
import transactionFields from './transactions.fields';

class ViewTransaction extends Component {
    
  constructor ( props ) {
    super (props);
     this.state = { transactionData : null, statusMessage : null, printView : false }
  }
  
  async componentDidMount() {
    try {
      let response = await fetchTransactionById(this.props.locationParams.id);
      
      // populate booking details
      response.data.bookingDetails = response.data.bookingId;
      response.data.bookingId = response.data.bookingDetails.oldId 
        ? `${response.data.bookingDetails.oldId} / ${response.data.bookingDetails.id}` 
        : response.data.bookingDetails.id;
      
      // populate hatchery and species details 
      response.data.speciesType = response.data.bookingDetails.speciesType;
      response.data.reservedFrom = response.data.bookingDetails.fromDate;
      
      response.data.modeOfPayment = 'Bank Transfer';
      response.data.amount = `${response.data.amount}/-`;
      response.data.paymentDate = response.data.response ? new Date(response.data.response.created_at) : response.data.created_at;
      
      this.setState({transactionData : response.data, statusMessage : null})
    } catch (error) {
      console.log ( error );
      this.setState({ statusMessage : { status : "error", message : error.message}});
    }
  }
  
  handlePrint = () => {
    this.setState({printView : true});
  }
  
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.printView) {
      this.props.handlePrint();
      setTimeout (() => this.setState({printView : false}), 500);
    }
  }

  render () {
    
    if (this.state.statusMessage && [404, 403].includes(this.state.statusMessage.code)) {
      return (<Navigate to="/not-found" />);
    }
    
    let title = '';
    let content = '';
    const transaction = this.state.transactionData;
    
    if (transaction && transaction.status === 'Approved') {
      title = 'TRANSACTION DETAILS FOR ';
      if(transaction.additionalBookingChargesId && transaction.additionalBookingChargesId.type === "BUFFER_AMOUNT") {
        title += "BUFFER PERIOD";
        content = `An amount of &#x20B9; ${transaction.amount} has been received as ${transaction.bookingDetails.speciesType.name} buffer period fee ` +  
          `from ${(transaction.bookingDetails.hatcheryId || {}).name} vide your registered bank account, below are the transaction details ` +
          `for your reference.`;
      } else if(transaction.additionalBookingChargesId && transaction.additionalBookingChargesId.type === "ONE_DAY_DELAY"){
        title += "DELAY PERIOD";
        content = `An amount of &#x20B9; ${transaction.amount} has been received as ${transaction.bookingDetails.speciesType.name} arrival delay period fee ` + 
          `from ${(transaction.bookingDetails.hatcheryId || {}).name} vide your registered bank account below are the transaction details ` +
          `for your reference.`;
      } else {
        title += `${transaction.bookingDetails.speciesType.name.toUpperCase()} CUBICLE/S BOOKING`;
        content = `An amount of &#x20B9; ${transaction.amount} has been received as ${transaction.bookingDetails.speciesType.name} quarantine cubicle ` +
          `reservation fee from ${(transaction.bookingDetails.hatcheryId || {}).name} vide your registered bank account below are the ` +
          `transaction details for your reference.`;
      }
    } else if(transaction) {
      title = 'PAYMENT RECEIPT FOR THIS TRANSACTION CAN NOT BE PROCESSED';
      content=`Please note the current transaction No. ${transaction.id} against booking ID. ${transaction.bookingId} from ` +
        `${(transaction.bookingDetails.hatcheryId || {}).name} is not approved as the payment is either in-process or has been ` + 
        `declined OR refunded to the payer's bank account. If the payment was made recently, kindly retry after few hours. Reconcilation ` + 
        `of payments through NEFT/RTGS are subject to clearance and involves some delay`;
    }
    
    return (
      <>
        {
          this.state.statusMessage &&
          <CustomSnackbar variant={this.state.statusMessage.status}
            message={this.state.statusMessage.message}
            open={this.state.statusMessage.status}
            onClose={async () => await this.setState({ statusMessage: null })}
          />
        }
        {
          this.state.printView === false &&
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', px:1, py : 1}} component="span">
              <Button
                variant="outlined" 
                color="warning" 
                size="small"
                startIcon={<PrintOutlinedIcon />}
                onClick={this.handlePrint}
                disabled={!transaction || transaction.status !== 'Approved'}
              > 
                Print 
              </Button>
            </Box>  
            <NavigateBack />
          </Grid>
        }
        <Paper elevation={this.state.printView ? 0 : 3} sx={{textAlign:"center", p:2, m:1}}>
          <Grid container justifyContent="space-between" alignItems="flex-start" direction="row">
            <Grid xs={2} item key={1}>
            <img src={process.env.PUBLIC_URL + '/static/images/home/mpeda-logo.png'} alt = "MPEDA logo" height="100px"/>
            </Grid>
            <Grid xs={8} item key={2}>
            <Typography variant="h4" align="center">Rajiv Gandhi Centre for Aquaculture</Typography>
            <Typography variant="h4" align="center">Aquatic Quarantine Facility</Typography>
              <Typography variant="body1" my={1} align="center"> 
                MPEDA. Ministry of Commerce & Industry, Govt. of India <br/>
                TNFDC Hatchery Complex, Kapaleeswarar Nagar, Beach Road <br/>
                Neelankarai-600115, Chennai, Tamil Nadu
              </Typography>
            </Grid>
            <Grid xs={2} item key={3}>
            <img src={process.env.PUBLIC_URL + '/static/images/home/rgca-logo.png'} alt = "RGCA logo" height="100px"/>
            </Grid>
          </Grid>
          <hr style={{borderColor:"#eef1f6"}} /><br/>
          {
            transaction && transaction.status === 'Approved' &&
            <Typography variant="h3" align="center"><u> PAYMENT RECEIPT </u></Typography>
          }
          <br/>
          <Typography variant="h5" align="center">{title}</Typography><br/>
          <Typography variant="subtitle1" align="left"> <p dangerouslySetInnerHTML={{__html : content }} /> </Typography><br/>
          {
            transaction && transaction.status === 'Approved' &&
            <>
              <EntryTabularView
                values={transaction} 
                formFields = {transactionFields}
                noPaper = {true}
              />
              <Typography variant="caption" align="center">This is a computer generated receipt no signature required. </Typography>
            </>
          }
        </Paper>
      </>
    );
  }
}

export default withPrint(withLocationParams(ViewTransaction));
