export default [
  {  
    name: 'name',
    label: 'Species Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'update', 'view', 'index']
  },
    {  
    name: 'type',
    label: 'Species Type',
    type: 'select',
    selectOptions : {
      'Broodstock' : 'Broodstock',
      'PPL' : 'PPL'
    },
    properties: [{required:true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'quarantine_fee',
    label: 'Quarantine Fee',
    type: 'currency',
    default : 0,
    properties: [{required:true}, {number : true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'lab_fee',
    label: 'Lab Fee',
    type: 'currency',
    default : 0,
    properties: [{required:true}, {number : true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'cubicle_fee',
    label: 'Cubicle Fee',
    type: 'currency',
    default : 0,
    properties: [{required:true}, {readOnly : true}, {number : true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'incenerationFee',
    label: 'Fumigation Fee',
    type: 'currency',
    properties: [{required : true }, {number : true}],
    actions: ['create','update', 'view', 'index']
  },
  {  
    name: 'gst',
    label: 'GST',
    type: 'currency',
    properties: [{required:true}, {number : true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'weightPerCubicle',
    label: 'Weight Per Cubicle (Kgs)',
    type: 'number',
    properties: [{required:true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'stockPerCubicle',
    label: 'Stock Per Cubicle (No.s)',
    type: 'number',
    properties: [{required:true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'duration',
    label: 'Quarantine Duration (days)',
    type: 'number',
    properties: [{required:true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'maxAllowedCubicles',
    label: 'Max Allowed Cubicle Per Booking Duration (No.s)',
    type: 'number',
    properties: [{required:true}],
    actions: ['create', 'update', 'view']
  },
  {  
    name: 'quarterlyBookingLimit',
    label: 'Quarterly Booking Limit',
    type: 'number',
    properties: [{required:true}],
    actions: ['create', 'update', 'view']
  },
  {  
    name: 'quarterlyCubicleLimit',
    label: 'Quarterly Cubicles Limit',
    type: 'number',
    properties: [{required:true}],
    actions: ['create', 'update', 'view']
  },
  {  
    name: 'monthlyBookingLimit',
    label: 'Monthly Booking Limit',
    type: 'number',
    properties: [{required:true}],
    actions: ['create', 'update', 'view']
  },
  {  
    name: 'monthlyCubicleLimit',
    label: 'Monthly Cubicles Limit',
    type: 'number',
    properties: [{required:true}],
    actions: ['create', 'update', 'view']
  }
];