import React, { Component } from 'react';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import NavigateBack from 'components/NavigateBack';

import cubicleFields from './cubicle.fields';

import { createNewCubicle } from 'services/cubicles';

export default class AddNewCubicle extends Component {
  constructor(props) {
    super(props);
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully added new cubicle..." };
    
    // todo API calls
    try {
      let response = await createNewCubicle(params);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data};
    }
    
    return statusMessage;
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Add New Cubicle </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          <DynamicForm
            formFields={cubicleFields}
            action="create"
            handleSubmit={this.handleFormSubmit}
            buttonText="Save Cubicle Details"
            buttonWidth= "full"
            columns = {3}
            redirect={'/aqf/admin/cubicles/list'}
            confirmation={"Are you sure you want to save the details ? "}
          />
        </Paper>
      </>
    );
  }
}