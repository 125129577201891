import EntryTabularView from 'components/EntryTabularView';

const bookingDocumentFields = [{
  'name' : 'declaration',
  'label' : 'Letter of Undertaking',
  'type' : 'file',
  'properties' : [],
  'default' : "/Letter_of_Undertaking.pdf",
  'actions' : ['view']
}, {
  'name' : 'shipmentDocument',
  'label' : 'Shipment Documents',
  'type' : 'file',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'nocCertificate',
  'label' : 'NoC Certificate',
  'type' : 'file',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'arrivalReport',
  'label' : 'Arrival Report',
  'type' : 'file',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'labAnalysisReport',
  'label' : 'Lab Analysis Report',
  'type' : 'file',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'clearanceCertificate',
  'label' : 'Clearance Certificate',
  'type' : 'file',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'shipmentPacking',
  'label' : 'Shipment Packing Report',
  'type' : 'file',
  'properties' : [],
  'actions' : ['view']
}];


const BookingDocuments = (props) => {
  let documents = props.bookingData && props.bookingData.documents ? props.bookingData.documents : null;
  if (documents) {
    documents.declaration = "/Letter_of_Undertaking.pdf";
  }
  
  return (
  <>
    {
      documents &&
      <EntryTabularView 
        formFields={bookingDocumentFields}
        noPaper={true}
        values = {documents} 
      />
    }
  </>
  );
}

export default BookingDocuments;
