import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Paper, Box, Container, Grid, Typography } from '@mui/material';

import TotalBookings from 'pages/bookings/widget-components/TotalBookings';
import HatcheryBalanceCard from 'pages/hatcheries/widget-components/HatcheryBalanceCard';
import HatcheryExpiryTimeCard from 'pages/hatcheries/widget-components/HatcheryExpiryTimeCard';
import HatcheryPendingPaymentsCard from 'pages/transactions/widget-components/HatcheryPendingPaymentsCard';

import AccountInfoCard from 'pages/hatcheries/widget-components/AccountInfoCard';
import VirtualAccountCard from 'pages/hatcheries/widget-components/VirtualAccountCard';
import CubicleStatusCard from 'pages/booking-schedules/widget-components/CubicleStatusCard';

import QuarterlyCubiclesCount from 'pages/bookings/widget-components/QuarterlyCubiclesCount';
import MonthlyBookingsCount from 'pages/bookings/widget-components/MonthlyBookingsCount'
import BookingsCalendar from 'pages/bookings/widget-components/BookingsCalendar';
import CheckFYQuota from 'pages/bookings/widget-components/CheckFYQuota';

import { permissionCheck } from 'permissions';

import CustomSnackbar from 'components/CustomSnackbar';

import EntryTabularView from 'components/EntryTabularView';

// services
import { HatcheryStore } from 'store';

const HatcheryOwner = () => {

  const location = useLocation();
  const [ alert, setAlert] = useState(
    location.pathname === '/aqf/dashboard' &&
    HatcheryStore.state.currentHatchery    
      ? !HatcheryStore.state.currentHatchery.license 
      : false
  );
  
  return (<Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 1 }}>
    <Container maxWidth={false}>
      <CustomSnackbar variant="error"
        message="Attention Required !!! CAA license expired for this hatchery. Please renew"
        open={alert}
        onClose = { e => setAlert(false) }
      />
        
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}><TotalBookings /></Grid>
        <Grid item xs={12} sm={4}><HatcheryBalanceCard /></Grid>
        <Grid item xs={12} sm={4}><HatcheryExpiryTimeCard /></Grid>
        <Grid item xs={12} sm={4}><HatcheryPendingPaymentsCard /></Grid>
        <Grid item xs={12} sm={4}><MonthlyBookingsCount /></Grid>
        <Grid item xs={12} sm={4}><QuarterlyCubiclesCount /></Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{mt:1}}><AccountInfoCard /></Grid>
        <Grid item xs={12} sm={6}>
          <VirtualAccountCard hatcheryData={HatcheryStore.state.currentHatchery} noPaper={false} />
        </Grid>
      </Grid>
      <Box sx={{mt : 1}}><CheckFYQuota /></Box>
      <Box sx={{mt : 1}}><CubicleStatusCard /></Box>
      <Box sx={{mt : 1}}><BookingsCalendar /></Box>
    </Container>
  </Box>);
}

export default HatcheryOwner;
