import { AuthStore, resetAllStores } from 'store';
import crypto from 'crypto-js';
import cxor from 'xor-crypto-js';

export const obfuscateData = (type, data) => {
  switch (type) {
    /*case 'email':
      let parts = data.split('@');
      let str = parts[0].split('');
      for (let i = 0; i < str.length; i=i+2) {
        str[i] ='X';
      }
      parts[0] = str.join('');
      str = parts[1].split('');
      for (let i = 0; i < str.length; i=i+2) {
        str[i] ='X';
      }
      parts[1] = str.join('');
      return parts.join('@');
    */
    // case 'phone':
    case 'aadhaar':
    
      // decrypt aadhaar number
      let aadhaar = cxor.decrypt(data, process.env.REACT_APP_BODY_ENCRYPT_KEY);
      return 'XXXXXXXX' + aadhaar.slice(8);
    case 'bank':
      data = data.split('');
      for (let i = 0; i < data.length; i=i+2) {
        data[i] ='X';
      }
      return data.join('');
    default:
      return data;
  }
}

export const getUrl = function(indexUrl) {
  return `${process.env.REACT_APP_API_ENDPOINT}${indexUrl}`;
};

export const getUserToken = () => {
  try {
  let value = AuthStore.state.currentUser; 
  if (value) {
    // value = JSON.parse(value);
    if (value.jwt !== undefined) return value.jwt;
  }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const loadFetchData = function(method, path, operation, data=null, useToken) {
  
  // calculate body signature
  let signature = null;
  switch (method) {
    case 'GET':
    case 'DELETE':
      // for get API, use path string to calculate signature. Any spaces in the path string must be replaced with
      // %20, as server side path URL contains %20 that is used for signature calculation
      //
      signature = calculateAPISignature(encodeURIComponent(path.replace(/ /g, '%20')));
      break;
    case 'PUT':
      signature = calculateAPISignature(JSON.stringify(
        Object.assign({}, data, {path : encodeURIComponent(path.replace(/ /g, '%20'))})));
      break;
    case 'POST':
      signature = calculateAPISignature(JSON.stringify(data));
      break;
  }
  
  let header = {
    "Content-type": "application/json",
    "x-api-version" : process.env.REACT_APP_API_VERSION,
    "x-api-signature" : signature,
    "x-api-operation" : operation,
   // "x-api-verify" : 1 // enable for testing API
  };
  let token = getUserToken();
  
  if (useToken && token) {
    // add a bearer token
    header ['Authorization'] = `Bearer ${token}`
  }

  return data? 
    {
      method: method,
      body: JSON.stringify(data),
      headers: header
    } : {
      method: method,
      headers: header
    };
};

const handleUnauthorizedException = () => {
  // clean up store data and redirect
  resetAllStores();
  
  // redirect to home
  let home = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : '/';
  if (home !== window.location.pathname) {
    window.location.replace(home);
  }
};

const handleAPIErrorResponse = async (response, contentType) => {
  switch ( response.status) {
    case 200:
      return 'API call successful';
    case 400:
      // 400 error response will have the error text in the response data
      // validation errors are in the form of json response. capture text
      // messages from the response
      //
      if (contentType.startsWith('text/plain;')) {
        return await response.text();
      } else {
        return 'Invalid parameters in the request';
      }
    case 401:
    
      setTimeout( () => {
        handleUnauthorizedException(status);
      }, 2000);
      
      // 401 error response will have error message in some cases
      // check for error messages in response or return generic 
      // error message
      //
      if (contentType.startsWith('application/json;')) {
        const data = await response.json();
        if (data.message) {
          return data.message;
        }
      }
      return 'Login session expired or user is logged in from another device. Try logging in again !!';
    case 403:
      return 'Unauthorized. User is not permitted to do this operation';
    case 404:
      // check for any messages in the response
      if (contentType.startsWith('application/json;')) {
        const data = await response.json();
        if (data.message) {
          return data.message;
        }
      }
      return 'Requested URL was not found. Please check the URL String';
    case 405:
      return 'Method Not Allowed. Please check the API URL';
    case 429:
      return 'Too many requests to the server !';
    case 502:
    case 503:
    case 504:
    case 1000: // special error, n/w not connected
      return 'Server is not reachable, either server is down, or there could be a problem with your internet connection';
    case 1001: // special error, n/w timeouts
      return 'Network Error, timed out trying to fetch data from server';
    case 500:
    default:
      return 'Unexpected error on the server side. Please try later';
  }
}

export const addPagerParams = (params, sort='id:DESC') => {  
  if (params.searchKey && params.searchKey.length) {
    return  ( 
      '?_q=' + params.searchKey
      + '&_start=' + (params.per_page === -1 ? 0 : ((params.page - 1) * params.per_page)) 
      + '&_limit=' + params.per_page 
      + '&_sort=' + sort);
  }
  
  return  (
    '?_start=' + ((params.page - 1) * params.per_page) 
    + '&_limit=' + params.per_page 
    + '&_sort=' + sort);
}

export const calculateAPISignature = (data) => {

    if (!data) {
        return false;
    }
    
    return crypto.HmacSHA256(data, process.env.REACT_APP_BODY_SIGNATURE_KEY).toString(crypto.enc.Hex);
};

export const fetchWithTimeout = (API) => {
  
  return new Promise((resolve, reject) => {
    
    let params = Object.assign ({body: null, timeout : 60000, useToken : true }, API);
    let path = params.path;
    
    // append /api prefix to service urls, some exceptions, we use path as it is.
    if (path.includes("users-permissions/roles") === false) {
      path = '/api' + path;
    }
    
    // API response format : { status : <http status code>, data : <json data if API is success>, 
    // message : < error message if API failed > }
    //
    
    let APIResponse = {status : null, data : {}, message : null};

    let timeout = setTimeout(async () => {
      APIResponse.status = 1001;
      APIResponse.message = await handleAPIErrorResponse({status : 1001}, null);
      reject(APIResponse);
    }, params.timeout);
    
    fetch(getUrl(path), 
      loadFetchData(params.method, path, params.operation, params.body, params.useToken))
    .then(async response => {
      clearTimeout(timeout);
      const contentType = response.headers.get('content-type');
      
      APIResponse.status = response.status;
      APIResponse.message = await handleAPIErrorResponse(response, contentType);
      
      // expect json response when status is 200
      if (response.status === 200) {
        if (contentType.startsWith('application/json;')) {
          APIResponse.data = await response.json();
        } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          APIResponse.data = await response.blob();
        } else if (contentType === 'application/pdf') {
          console.log("got pdf attachment");  
          APIResponse.data = await response.blob();
        } else {
          APIResponse.data = await response.text();
        }
        resolve(APIResponse);
      } else {
        // if status is 400 and response is json, then it is a validation
        // error, capture the error data also.
        //
        if (response.status === 400 && contentType.startsWith('application/json;')) {
          let data = await response.json(); 
          if (typeof data.message === 'object' && data.message.hasOwnProperty('isValid')) {
            APIResponse.data = data.message;
          } else {
            APIResponse.message = data.message;
          }
        }
        
        reject(APIResponse);
      }
    })
    .catch(async error => {
      clearTimeout(timeout);
      console.log(error);
      // if there is no valid json in the response body or if there was some
      // other n/w error.
      APIResponse.status = 1000;
      APIResponse.message = await handleAPIErrorResponse({status : 1000 }, null);
      reject(APIResponse);
    });
  });
}
