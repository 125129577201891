import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm, DynamicTableForm } from 'components/Forms';

import { labAnalysisReportFieldsLvannamei, labAnalysisReportFieldsMonodon } from '../booking-reports.fields';

import { 
  createNewBookingReport, updateBookingReport,fetchTotalMortalitiesByBooking 
} from 'services/booking-reports';

export default class LabAnalysisReport extends Component {
  
  state = { liveBsData : false };
  
  constructor ( props ) {
    super (props);
  }

  async componentDidMount() {
    try {
      const speciesDetails = this.props.bookingData.speciesType;
      console.log(speciesDetails.name);
      
      const opcode = 'booking-reports:' +
      (
        this.props.currentValues
          ? ('update-lab-analysis-report:' + this.props.currentValues.id) 
          : 'create-lab-analysis-report'
      );
        
      const mortalityReport = await fetchTotalMortalitiesByBooking(this.props.bookingData.id, opcode);
      
      // auto populate data
      if (mortalityReport && mortalityReport.data) {
        const arrivalDate = new Date(mortalityReport.data.arrivingDateTime);
        let qrntDate = new Date(mortalityReport.data.arrivingDateTime);
        let totalMortalities = 0;
        
        qrntDate.setDate(qrntDate.getDate() + (speciesDetails.duration - 1));
        
        if (speciesDetails.name.toLowerCase().includes('vannamei')) {
          labAnalysisReportFieldsLvannamei[4].default = mortalityReport.data.totalBags;
          labAnalysisReportFieldsLvannamei[6].default = mortalityReport.data.totalArrivedBs;
          labAnalysisReportFieldsLvannamei[7].default = mortalityReport.data.totalArrivMortalities;
          labAnalysisReportFieldsLvannamei[8].default = `${this.props.bookingData.supplierDetails.name}, ${this.props.bookingData.supplierDetails.country}`;
          labAnalysisReportFieldsLvannamei[9].default = speciesDetails.duration;
          labAnalysisReportFieldsLvannamei[10].default = arrivalDate.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
          labAnalysisReportFieldsLvannamei[11].default = qrntDate.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
          labAnalysisReportFieldsLvannamei[12].default = mortalityReport.data.totalMortality;
        } else {
          labAnalysisReportFieldsMonodon[4].default = mortalityReport.data.totalBags;
          labAnalysisReportFieldsMonodon[6].default = mortalityReport.data.totalArrivedBs;
          labAnalysisReportFieldsMonodon[7].default = mortalityReport.data.totalArrivMortalities;
          labAnalysisReportFieldsMonodon[8].default = `${this.props.bookingData.supplierDetails.name}, ${this.props.bookingData.supplierDetails.country}`;
          labAnalysisReportFieldsMonodon[9].default = speciesDetails.duration;
          labAnalysisReportFieldsMonodon[10].default = arrivalDate.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
          labAnalysisReportFieldsMonodon[11].default = qrntDate.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
          labAnalysisReportFieldsMonodon[12].default = mortalityReport.data.totalMortality;
        }
        this.setState({liveBsData : true});
      }
    } catch (error) {
      console.log(error);
      this.setState({liveBsData : true});
    }
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully added new lab analysis report for the booking..." };
    params.bookingId = this.props.bookingData.id;
    
    try {
      let response;
      if (this.props.currentValues) {
        response = await updateBookingReport('lab-analysis-report', this.props.currentValues.id, params);  
        statusMessage = { status: "success", message: "Successfully updated lab analysis report for the booking..." };
      } else {
        response = await createNewBookingReport('lab-analysis-report', params);
      }
      
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  render () {
    
    let currentValues = this.props.currentValues;
    if (currentValues) {
      // update cubicle info
      currentValues.cubicleId = currentValues.number;
    }
    
    return(
      <>
        <Typography variant="h5" align="center" p={2} >
        { 
          (this.props.currentValues ? 'Update Lab Analysis Report' : 'Lab Analysis Report') + 
          ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
        }
        </Typography><hr style={{borderColor:"#eef1f6"}} />
        {
          this.state.liveBsData &&
          <DynamicForm
            formFields={
              this.props.bookingData.speciesType.name.toLowerCase().includes('vannamei')
                ? labAnalysisReportFieldsLvannamei 
                : labAnalysisReportFieldsMonodon
            }
            action={this.props.currentValues ? "update" : "create"}
            handleSubmit={this.handleFormSubmit}
            buttonText="Save Lab Analysis Report"
            buttonWidth= "full"
            action={this.props.currentValues ? "update" : "create"}
            columns = {4}
            currentValues={currentValues}
            validateOnLoad={true}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/lab-analysis-report"}
          />
        }         
      </>
    );
  }
}
