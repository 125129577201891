import React, { Component } from 'react';
import { withLocationParams, withPrint } from 'hoc';

import { 
  Paper, Grid, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, 
  Box, Button
} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { fetchBookingById, approveShipmentDocuments } from "services/bookings";

import theme from 'theme';

import ImageOrDocument from 'components/ImageOrDocument';

import NavigateBack from 'components/NavigateBack';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

const updatedValue = (field, data) => {
  if (data.updateRequestDetails && data.updateRequestDetails[field]) return data.updateRequestDetails[field];
  else return data[field];
};

const ShipmentData = (props) => {
  return (<TableContainer sx={{ p:2 }}>
    <Table size="small">
      <TableHead>
      <TableRow tabIndex={-1} key={0} sx={{backgroundColor: theme.palette.secondary.main, color:"#fff"}}>
        <TableCell key={'header-col-0'}  align="left" sx={{color: "#FFF"}}> Field Name </TableCell>
        <TableCell key={'header-col-1'}  align="left" sx={{color: "#FFF"}}> Current Value </TableCell>
        <TableCell key={'header-col-2'}  align="left" sx={{color: "#FFF"}}> Updated Value </TableCell>
      </TableRow> 
      </TableHead>
      <TableBody>
        <TableRow hover role="checkbox" tabIndex={-1} key={1}>
          <TableCell key={'row-1-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Hatchery Name</p></TableCell>
          <TableCell key={'row-1-col-1'} align="left">{props.bookingDetails.hatcheryId.name}</TableCell>
          <TableCell key={'row-1-col-2'} align="left">{props.bookingDetails.hatcheryId.name}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={2}>
          <TableCell key={'row-1-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Booking ID</p></TableCell>
          <TableCell key={'row-1-col-1'} align="left">{props.bookingDetails.id}</TableCell>
          <TableCell key={'row-1-col-2'} align="left">{props.bookingDetails.id}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={3}>
          <TableCell key={'row-11-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Supplier Name</p></TableCell>
          <TableCell key={'row-11-col-1'} align="left">
            {props.shipmentDetails.supplierName ? props.shipmentDetails.supplierName : (props.bookingDetails.supplierId || {}).name }</TableCell>
          <TableCell key={'row-11-col-2'} align="left">{updatedValue('supplierName', props.shipmentDetails)}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={4}>
          <TableCell key={'row-1-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Species Type</p></TableCell>
          <TableCell key={'row-1-col-1'} align="left">{props.bookingDetails.speciesType.name}</TableCell>
          <TableCell key={'row-1-col-2'} align="left">{props.bookingDetails.speciesType.name}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={5}>
          <TableCell key={'row-1-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Origin Country Name</p></TableCell>
          <TableCell key={'row-1-col-1'} align="left">
            {props.shipmentDetails.countryName ? props.shipmentDetails.countryName : (props.bookingDetails.supplierId || {}).country}
          </TableCell>
          <TableCell key={'row-1-col-2'} align="left">{updatedValue('countryName', props.shipmentDetails)}</TableCell>
        </TableRow>
        {
          props.bookingDetails.speciesType.type === 'Broodstock' &&
          <TableRow hover role="checkbox" tabIndex={-1} key={6}>
            <TableCell key={'row-12-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Total Male Broodstock</p></TableCell>
            <TableCell key={'row-12-col-1'} align="left">{props.shipmentDetails.maleBs}</TableCell>
            <TableCell key={'row-12-col-2'} align="left">{updatedValue('maleBs', props.shipmentDetails)}</TableCell>
          </TableRow>
        }
        {
          props.bookingDetails.speciesType.type === 'Broodstock' &&
          <TableRow hover role="checkbox" tabIndex={-1} key={7}>
            <TableCell key={'row-13-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Total Female Broodstock</p></TableCell>
            <TableCell key={'row-13-col-1'} align="left">{props.shipmentDetails.femaleBs}</TableCell>
            <TableCell key={'row-13-col-2'} align="left">{updatedValue('femaleBs', props.shipmentDetails)}</TableCell>
          </TableRow>
        }
        <TableRow hover role="checkbox" tabIndex={-1} key={8}>
          <TableCell key={'row-9-col-0'} align="left">
            <p style={{"fontWeight" : "bold"}}>{props.bookingDetails.speciesType.type === 'Broodstock' ? "Total Broodstock" : "Total PPL" }</p>
          </TableCell>
          <TableCell key={'row-9-col-1'} align="left">
            {props.shipmentDetails.totalBs ? props.shipmentDetails.totalBs : props.bookingDetails.totalNumberOfStock}
          </TableCell>
          <TableCell key={'row-9-col-2'} align="left">{updatedValue('totalBs', props.shipmentDetails)}</TableCell>
        </TableRow>
        {
          props.bookingDetails.speciesType.type === 'Broodstock' &&
          <TableRow hover role="checkbox" tabIndex={-1} key={6}>
            <TableCell key={'row-16-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Average Male Broodstock Weight (gms)</p></TableCell>
            <TableCell key={'row-16-col-1'} align="left">{props.shipmentDetails.avgMaleWt}</TableCell>
            <TableCell key={'row-16-col-2'} align="left">{updatedValue('avgMaleWt', props.shipmentDetails)}</TableCell>
          </TableRow>
        }
        {
          props.bookingDetails.speciesType.type === 'Broodstock' &&
          <TableRow hover role="checkbox" tabIndex={-1} key={7}>
            <TableCell key={'row-17-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Average Female Broodstock Weight (gms)</p></TableCell>
            <TableCell key={'row-17-col-1'} align="left">{props.shipmentDetails.avgFemaleWt}</TableCell>
            <TableCell key={'row-17-col-2'} align="left">{updatedValue('avgFemaleWt', props.shipmentDetails)}</TableCell>
          </TableRow>
        }
        <TableRow hover role="checkbox" tabIndex={-1} key={9}>
          <TableCell key={'row-10-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Total Boxes</p></TableCell>
          <TableCell key={'row-10-col-1'} align="left">{props.shipmentDetails.box}</TableCell>
          <TableCell key={'row-10-col-2'} align="left">{updatedValue('box', props.shipmentDetails)}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={10}>
          <TableCell key={'row-6-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Airway Bill Number</p></TableCell>
          <TableCell key={'row-6-col-1'} align="left">{props.shipmentDetails.airwayBillNumber}</TableCell>
          <TableCell key={'row-6-col-2'} align="left">{updatedValue('airwayBillNumber', props.shipmentDetails)}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={11}>
          <TableCell key={'row-2-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Airline Name</p></TableCell>
          <TableCell key={'row-2-col-1'} align="left">{props.shipmentDetails.airlineName}</TableCell>
          <TableCell key={'row-2-col-2'} align="left">{updatedValue('airlineName', props.shipmentDetails)}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={12}>
          <TableCell key={'row-5-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Destination</p></TableCell>
          <TableCell key={'row-5-col-1'} align="left">{props.shipmentDetails.destination}</TableCell>
          <TableCell key={'row-5-col-2'} align="left">{props.shipmentDetails.destination}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={13}>
          <TableCell key={'row-3-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Flight Number</p></TableCell>
          <TableCell key={'row-3-col-1'} align="left">{props.shipmentDetails.flightNumber}</TableCell>
          <TableCell key={'row-3-col-2'} align="left">{updatedValue('flightNumber', props.shipmentDetails)}</TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={14}>
          <TableCell key={'row-4-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Arrival Date</p></TableCell>
          <TableCell key={'row-4-col-1'} align="left">
            {
              props.shipmentDetails.arrivalDate
                ? new Date(props.shipmentDetails.arrivalDate).toLocaleString('en-GB', {'timezone' : 'Asia/Kolkata'})
                : null
            }
          </TableCell>
          <TableCell key={'row-4-col-2'} align="left">
            {
              updatedValue('arrivalDate', props.shipmentDetails)
                ? new Date(updatedValue('arrivalDate', props.shipmentDetails)).toLocaleString( 'en-GB', {'timezone' : 'Asia/Kolkata'})
                : null
            }
          </TableCell>
        </TableRow>
      
        <TableRow hover role="checkbox" tabIndex={-1} key={15}>
          <TableCell key={'row-14-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Shipment Document</p></TableCell>
          <TableCell key={'row-14-col-1'} align="left">
          {
            Array.isArray(props.documents.shipmentDocument) ?
            <Grid container justifyContent="flex-start" alignItems="flex-start">
            {
              props.documents.shipmentDocument.map (s => {
                 return(<Grid item><ImageOrDocument document={s} minView /></Grid>)
              })
            }
            </Grid> : 
            <ImageOrDocument document={props.documents.shipmentDocument} minView align="flex-start" />
          }
          </TableCell>
          <TableCell key={'row-14-col-2'} align="left">
          {
            Array.isArray(updatedValue('shipmentDocument', props.documents)) ?
            <Grid container justifyContent="flex-start" alignItems="flex-start">
            {
              updatedValue('shipmentDocument', props.documents).map (s => {
                 return(<Grid item><ImageOrDocument document={s} minView /></Grid>)
              })
            }
            </Grid> : 
            <ImageOrDocument document={updatedValue('shipmentDocument', props.documents)} minView align="flex-start" />
          }
          </TableCell>
        </TableRow>
        <TableRow hover role="checkbox" tabIndex={-1} key={16}>
          <TableCell key={'row-15-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>NoC Document</p></TableCell>
          <TableCell key={'row-15-col-1'} align="left">
            <ImageOrDocument document={props.documents.nocCertificate} minView align="flex-start"/>
          </TableCell>
          <TableCell key={'row-15-col-2'} align="left">
            <ImageOrDocument document={updatedValue('nocCertificate', props.documents)} minView align="flex-start" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>);
};

class ShipmentApproval extends Component {
  
  state = { 
            bookingData : null,
            printView: false
          };
  constructor(props) {
    super(props);
    this.formFields = [
      {
        name: 'shipmentApproval',
        label: 'Approval Status',
        type: 'select',
        selectOptions : {
          "Approved" : "Approved",
          "Rejected" : "Rejected"
        },
        callback : this.handleStatusChange,
        properties: [{required:true}],
      }
    ];
  }
  
  async componentDidMount() {
    try {
      
      let response = await fetchBookingById(this.props.locationParams.id);
      this.setState({bookingData : response.data});
      
    } catch(error) {
      console.log(error);
    }
  }
  
  handleStatusChange = async (name, value, formData, asyncFn) => {
    if (value === 'Rejected') {
      this.formFields.push({
        name : 'shipmentRejectReason',
        label : 'Reject Reason',
        type: 'string',
        properties: [{ required:true}, {string : { min : 10}}], 
      });
    } else {
      let idx = this.formFields.findIndex( f => f.name === 'shipmentRejectReason');
      if (idx >= 0) {
        this.formFields.splice(idx, 1);
      }
    }
    
    return { fields : this.formFields, values : null };
  }    

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Shipment approval status submitted successfully !!" };
    
    try { 
      let response = await approveShipmentDocuments(this.props.locationParams.id, params);
    } catch (error) {
      statusMessage = { status : "error", message : error.message };
    }
    return statusMessage;
  }
  
  handlePrint = () => {
    this.setState({printView : true});
  }
  
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.printView) {
      this.props.handlePrint();
      setTimeout (() => this.setState({printView : false}), 500);
    }
  }
  
  render() {
    
    return (
      <>
        {
          this.state.printView === false &&
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', px:1, py : 1}} component="span">
              <Button
                variant="outlined" 
                color="warning" 
                size="small"
                startIcon={<PrintOutlinedIcon />}
                onClick={this.handlePrint}
              > 
                Print 
              </Button>
            </Box>  
            <NavigateBack />
          </Grid>
        }
      
        <Grid container justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} sm={8}>
            <Paper elevation={4} sx={{textAlign : "center", p:2, m:2}}>
              <Typography variant="h4"> Shipment Details </Typography> 
              <Typography variant="subtitle2" align="right">
              {
                this.state.bookingData &&
                this.state.bookingData.shipmentDetail &&
                updatedValue('updated_at', this.state.bookingData.shipmentDetail) 
                  ? new Date(updatedValue('updated_at', this.state.bookingData.shipmentDetail)).toLocaleString('en-GB', {'timezone' : 'Asia/Kolkata'})
                  : 'N/A'
              }
              </Typography>
              <hr style={{borderColor:"#eef1f6"}} /> 
              {
                this.state.bookingData &&
                <ShipmentData
                  bookingDetails = {this.state.bookingData}
                  shipmentDetails = {this.state.bookingData.shipmentDetail} 
                  documents = {this.state.bookingData.documents}
                />
              }
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            {
              this.state.printView === false && 
              <Paper elevation={4} sx={{textAlign : "center", p:2, m:2}}>
                <Typography variant="h4"> Approve / Reject Shipment </Typography><br/><hr style={{borderColor:"#eef1f6"}} />   
                <DynamicForm
                  formFields={this.formFields}
                  handleSubmit={this.handleFormSubmit}
                  buttonText="Save Approval Status"
                  buttonWidth= "full"
                  columns = {1}
                  redirect={'/aqf/bookings/list-shipment-approval'}
                  confirmation={"Are you sure you want to save the details ? "}
                />
              </Paper>
            }
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withPrint(withLocationParams(ShipmentApproval));
