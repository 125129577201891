import React, { Component } from 'react';

import AccountBalanceWalletOutlined from '@mui/icons-material/AccountBalanceWalletOutlined';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

// services
import { HatcheryStore } from 'store';

export default class HatcheryBalanceCard extends Component {
  render() {
    const hatcheryData = HatcheryStore.state.currentHatchery;
    return (
      <SummaryWidget 
        label = {'Current FY Import Balance (Qty)'} 
        count = {hatcheryData && hatcheryData.license 
          ? (hatcheryData.license.noOfBroodsStockPerYear - hatcheryData.fyImportedStock)
          : 0
        } 
        icon = {<AccountBalanceWalletOutlined />}
        url = { hatcheryData ? '/aqf/hatcheries/view/' + hatcheryData.id : '/'}
      />
    );
  }
}