import React, { Component } from 'react';

import { Typography, Button} from '@mui/material';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import NotFound from 'pages/common/404Page';

import { permissionCheck } from 'permissions';

// import form components
import ArrivalReport from './form-components/ArrivalReport';
import WaterQualityAnalysis from './form-components/WaterQualityAnalysis';
import SampleLabAnalysis from './form-components/SampleLabAnalysis';
import FeedingReport from './form-components/FeedingReport';
import MortalityReport from './form-components/MortalityReport';
import SampleWeightBsReport from  './form-components/SampleWeightBsReport';
import LabAnalysisReport from './form-components/LabAnalysisReport';
import QuarantineReport from './form-components/QuarantineReport';
import ClearanceReport from './form-components/ClearanceReport';
import ShipmentPackingReport from './form-components/ShipmentPackingReport';

export default class AddNewBookingReport extends Component {
  
  constructor ( props ) {
    super (props);
    this.state = {
      permCheck : true,
      statusMessage : null,
    };
  }
  
  componentDidMount() {
    
    let permCheck = true;
    
    // allow report filter based on permissions
    if (
      (this.props.reportType === 'received-sample-report' && !permissionCheck('booking-reports', 'create-received-sample-report')) ||
      (this.props.reportType === 'feeding-report' && !permissionCheck('booking-reports', 'create-feeding-report')) ||
      (this.props.reportType === 'mortality-report' && !permissionCheck('booking-reports', 'create-mortality-report')) ||
      (this.props.reportType === 'water-quality-analysis' && !permissionCheck('booking-reports', 'create-water-quality-analysis')) ||
      (this.props.reportType === 'lab-analysis' && !permissionCheck('booking-reports', 'create-lab-analysis')) ||
      (this.props.reportType === 'lab-analysis-report' && !permissionCheck('booking-reports', 'create-lab-analysis')) ||
      (this.props.reportType === 'sample-weight-of-brood-stock' && !permissionCheck('booking-reports', 'create-sample-weight-of-brood-stock')) ||
      (this.props.reportType === 'quarantine-report' && !permissionCheck('booking-reports', 'create-quarantine-report')) ||
      (this.props.reportType === 'clearance-certificate' && !permissionCheck('booking-reports', 'create-clearance-certificate')) ||
      (this.props.reportType === 'shipment-packing' && !permissionCheck('booking-reports', 'create-shipment-packing'))
    ) {
      permCheck = false;
    }
    
    this.setState({permCheck});
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (statusMessage) => {
    this.setState({statusMessage});
  }
 
  render () {
    
    if (!this.state.permCheck) {
      return(<NotFound />);
    }
    
    return(
      <>
        {
          this.state.statusMessage &&
          <CustomSnackbar variant={this.state.statusMessage.status}
            message={this.state.statusMessage.message}
            open={this.state.statusMessage.status}
            onClose={async () => await this.setState({ statusMessage: null })}
          />
        }
        {
          this.props.reportType === 'received-sample-report' &&
          <ArrivalReport 
            bookingData = {this.props.bookingData} 
            handleFormSubmit={this.handleFormSubmit}
            redirectUrl={`/aqf/bookings/test-reports/${this.props.reportType}`}
          />
        }
        {
          this.props.reportType === 'water-quality-analysis' &&
          <WaterQualityAnalysis 
            bookingData = {this.props.bookingData} 
            handleFormSubmit={this.handleFormSubmit}
            redirectUrl={`/aqf/bookings/test-reports/${this.props.reportType}`}
          />
        }
        {
          this.props.reportType === 'lab-analysis' &&
          <SampleLabAnalysis 
            bookingData = {this.props.bookingData} 
            handleFormSubmit={this.handleFormSubmit}
            redirectUrl={`/aqf/bookings/test-reports/${this.props.reportType}`}
          />
        }
        {
          this.props.reportType === 'feeding-report' &&
          <FeedingReport 
            bookingData = {this.props.bookingData} 
            handleFormSubmit={this.handleFormSubmit}
            redirectUrl={`/aqf/bookings/test-reports/${this.props.reportType}`}
          />
        }
        {
          this.props.reportType === 'mortality-report' &&
          <MortalityReport 
            bookingData = {this.props.bookingData} 
            handleFormSubmit={this.handleFormSubmit}
            redirectUrl={`/aqf/bookings/test-reports/${this.props.reportType}`}
          />
        }
        {
          this.props.reportType === 'sample-weight-of-brood-stock' &&
          <SampleWeightBsReport 
            bookingData = {this.props.bookingData} 
            handleFormSubmit={this.handleFormSubmit}
            redirectUrl={`/aqf/bookings/test-reports/${this.props.reportType}`}
          />
        }
        {
          this.props.reportType === 'lab-analysis-report' &&
          <LabAnalysisReport 
            bookingData = {this.props.bookingData} 
            handleFormSubmit={this.handleFormSubmit}
            redirectUrl={`/aqf/bookings/test-reports/${this.props.reportType}`}
          />
        }
        {
          this.props.reportType === 'quarantine-report' &&
          <QuarantineReport 
            bookingData = {this.props.bookingData} 
            handleFormSubmit={this.handleFormSubmit}
            redirectUrl={`/aqf/bookings/test-reports/${this.props.reportType}`}
          />
        }
        {
          this.props.reportType === 'clearance-certificate' &&
          <ClearanceReport 
            bookingData = {this.props.bookingData} 
            handleFormSubmit={this.handleFormSubmit}
            redirectUrl={`/aqf/bookings/test-reports/${this.props.reportType}`}
          />
        }
        {
          this.props.reportType === 'shipment-packing' &&
          <ShipmentPackingReport 
            bookingData = {this.props.bookingData} 
            handleFormSubmit={this.handleFormSubmit}
            redirectUrl={`/aqf/bookings/test-reports/${this.props.reportType}`}
          />
        }
          
      </>
    );
  }
}
