import { createStyles } from '@mui/styles';
import theme from 'theme';

export default createStyles({
  root : {
    backgroundColor : 'white', 
    padding: theme.spacing(2), 
    margin : theme.spacing(2),
    overflow : 'auto !important'
  },
  item : {},
  tableHeadCell : {
    padding : theme.spacing(1) + " !important",
    color : theme.palette.common.white + " !important",
    backgroundColor: theme.palette.secondary.main  + " !important",
    textAlign : "left !important"
  },
  tableHeadCellExtraPadding : {
    padding : theme.spacing(2) + " !important",
    color : theme.palette.common.white + " !important",
    backgroundColor: theme.palette.secondary.main  + " !important",
    textAlign : "left !important"
  },   
  tableCell : {
    padding : theme.spacing(1) + " !important",
    '& a' : {
      'text-decoration' : 'none',
      'color' : theme.palette.common.black
    },
    textAlign : "left !important"
  },
  tableCellExtraPadding : {
    padding : theme.spacing(2) + " !important",
    '& a' : {
      'text-decoration' : 'none',
      'color' : theme.palette.common.black,
      'margin' : '0px'
    },
    textAlign : "left !important"
  },
  nameText: {
    display: 'inline-block',
    //marginLeft: '10px',
    fontWeight: 400,
    cursor: 'pointer'
  },
  avatar: {
    // backgroundColor: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 400,
    width: '42px',
    height:'42px'
   },
});
