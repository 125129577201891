import React, { Component } from 'react';

// Material components/
import { Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import CustomSnackbar from 'components/CustomSnackbar';

import { fetchAllRoles, updateRole, fetchRoleById } from 'services/roles-permissions';
import { fetchSettingsByType } from 'services/common';

export default class ReportSettings extends Component {  

  state = {
    currentValues : null, 
    fetched : false,
    statusMessage : null
  };
  
  constructor(props) {
    super(props);
    
    this.permissionId = null;
    this.reportTitle = '';
    this.formFields = [
      {
        name : 'role',
        label : 'Role Name',
        type : 'select',
        selectOptions : { 'ALL' : ' -- Select Role --' },
        properties : [ { required : true } ],
        callback : this.handleRoleSelected,
        actions : ['create', 'update']
      },{
        name : 'reportFields',
        label : 'Select Report Fields',
        type : 'header',
        properties : [ { required : true } ],
        actions : ['create', 'update']
      }
    ];
  }
  
  async componentDidMount() {
    try {
      let settingName;
      switch(this.props.reportType) {
        case 'import-dispatch':
          settingName = 'AQMS_RF_IMPORT_DISPATCH';
          this.reportTitle = 'Import Dispatch Report Settings';
          break;
        case 'supplier-wise-booking':
          settingName = 'AQMS_RF_SUPPLIER_WISE_BOOKINGS';
          this.reportTitle = 'Supplier Wise Bookings Report Settings';
          break;
        case 'zero-bookings':
          settingName = 'AQMS_RF_ZERO_BOOKINGS';
          this.reportTitle = 'Zero Booking Hatcheries Report Settings';
          break;
        case 'fy-hatcheries':
          settingName = 'AQMS_RF_FY_HATCHERIES';
          this.reportTitle = 'FY Wise Hatcheries Report Settings';
          break;
        default:
          break;
      }
      let response = await fetchAllRoles({ page : 1, per_page : -1});
      response.data && response.data.map ( rp => {
        this.formFields[0].selectOptions[rp.id] = rp.role.name;
      });
      
      response = await fetchSettingsByType({type : 'APP_SETTINGS', name : settingName});
      if (response.data && response.data.length) {
        this.permissionId = response.data[0].value;
        response.data[0].JSONvalue.map ( field => {
          field.type = 'boolean';
          field.default = false;
          field.properties = [];
          field.actions = ['create', 'update'];
          field.valueStrings = ['Disabled', 'Enabled'],
          this.formFields.push(field);
        });
      }
      this.setState({ currentValues : null, fetched : true, statusMessage: null });
    } catch (error) {
      console.log(error);
      this.setState({ statusMessage : { status : "error", message : error.message }});
    }
  }
  
  handleRoleSelected = async (name, value, formData, asyncFn) => {
    
    this.setState({ statusMessage : { status : "warning", message : "Fetching report settings for the selected role..."}});
    
    try {
      let response = await fetchRoleById(value);
      if (response.status === 200) {
        formData.role = value;
        if (response.data.fieldPermissions && response.data.fieldPermissions[this.permissionId]) {
          response.data.fieldPermissions[this.permissionId].map( f => formData[f] = true );
        } else {
          this.formFields.map (x => {
            if (x.name === 'role' || x.name === 'reportFields') return;
            formData[x.name] = false;
          });
        }
      }
      this.setState({ currentValues : response.data.fieldPermissions, statusMessage : null });
    } catch(error) {
      this.setState({ statusMessage : { status : "error", message : error.message }});
    }
    
    return { fields : null, values : formData };
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated report settings..." };
    let fieldPermissions = this.state.currentValues ? this.state.currentValues : {};
    fieldPermissions[this.permissionId] = [];
    
    Object.keys(params).map( key => {
      if (key === 'role' || key === 'reportFields') return;
      else if (params[key]) fieldPermissions[this.permissionId].push(key);
    });
    
    // todo API calls
    try {
      let response = await updateRole(params.role,  {fieldPermissions});
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    this.props.refresh();
    
    return statusMessage;
  }

  render() {
    return (<>
      {
        this.state.statusMessage &&
        <CustomSnackbar variant={this.state.statusMessage.status}
          message={this.state.statusMessage.message}
          open={this.state.statusMessage.status}
          onClose={() => this.setState({ statusMessage: null })}
        />
      }
      <Typography variant = "h3" align="center"> { this.reportTitle } </Typography> <hr/>
      {
        this.state.fetched &&
        <DynamicForm
          formFields={this.formFields}
          action="create"
          handleSubmit={this.handleFormSubmit}
          buttonText="Save Report Settings"
          buttonWidth= "full"
          columns = {4}
        />
      }
    </>);
  }
}