import React, { Component } from 'react';

import { Paper, Grid, Box, Button, Tabs, Tab, Typography, FormControlLabel, Checkbox } from '@mui/material';

import PDFViewer from 'components/PDFViewer';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { fetchSettingsByType } from 'services/common';

export default class BookingTandC extends React.Component {
  
  state = {
    tandc : null,
    activeTab : "tandc",
    agree : false
  }
  
  constructor(props) {
    super(props);
  }
  
  async componentDidMount() {
    try {
      let response = await fetchSettingsByType({type:'TERMS_CONDITIONS'});
      if (response.data && response.data.length) {
        this.setState({tandc : response.data[0].JSONvalue});
      }
    } catch(error) {
      console.log(error);
    }
  }
  
  render() {
    
    return (<Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Paper elevation={4} sx={{p:1, mt:2}}>
          <Typography variant="h4" align="center"> Letter of Undertaking</Typography><br/><hr style={{borderColor:"#eef1f6"}} /><br/>
          {
            this.state.tandc && <>
            <Typography variant="body1">{ this.state.tandc['UNDERTAKING_LETTER'] }</Typography><br/>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={this.state.agree} 
                  onChange={e => {
                    console.log(e.target.checked);
                    this.setState({agree : e.target.checked });
                    if(e.target.checked) {
                      // for better UI experience show the checkbox for 1s
                      setTimeout( () => this.props.handleFormSubmit(e.target.checked), 1000);
                    }
                  }} 
                  name={"agree"} 
                />
              }
              label="I Agree to Terms and Conditions"
            /></>
          }
        </Paper>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper elevation={4} sx={{p:1, mt:2}}>
          <Tabs
            variant="fullWidth"
            value={this.state.activeTab}
            onChange={async (event, newValue) => await this.setState({activeTab : newValue}) }
            textColor="primary"
            indicatorColor="secondary"
            aria-label="Terms and Conditions"
          >
            <Tab key="tandc" value={"tandc"} label={"Terms And Conditions"} />
            <Tab key="ppolicy" value={"ppolicy"} label={"Privacy Policy"} />
          </Tabs>
        </Paper>
        <Paper elevation={4} sx={{p:1, mt:2}}>
          <div role="tabpanel" 
            style= {{maxHeight: '400px', overflow:'scroll'}}
            hidden={this.state.activeTab !== "tandc"} 
            id={"tandc"} 
            aria-labelledby="terms-and-conditions"
            key = {"tandc"}
          >
          {
            this.state.tandc &&
            <PDFViewer url = {process.env.PUBLIC_URL + "/static/docs/terms.pdf"} scale={1.4} newWindow={true}/>
          }
          </div>
          <div role="tabpanel" 
            style= {{maxHeight: '400px', overflow:'scroll'}}
            hidden={this.state.activeTab !== "ppolicy"} 
            id={"ppolicy"} 
            aria-labelledby="private-policy"
            key = {"ppolicy"}
          >
          {
            this.state.tandc &&
            <PDFViewer url = {process.env.PUBLIC_URL + "/static/docs/privacy.pdf"} scale={1.4} newWindow={true}/>
          }
          </div>
        </Paper>
      </Grid>
    </Grid>);
  }
}
