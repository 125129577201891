import { Routes, Route, Navigate } from 'react-router-dom';

import { ProtectedComponent } from 'hoc';

// layout views
import DashboardLayout from 'layout/DashboardLayout';

// booking related pages
import BookingsList from 'pages/bookings/index';
import TransactionsList from 'pages/transactions/index';
import AddNewBooking from 'pages/bookings/add';
import ViewBooking from 'pages/bookings/view';
import ShipmentApproval from 'pages/bookings/shipment-components/ShipmentApproval';
import ViewTransaction from 'pages/transactions/view';

// sample collection pages
import SampleCollectionList from 'pages/sample-collection/index';
import ViewSampleCollection from 'pages/sample-collection/view';

// additional charges pages
import AddnChargesList from 'pages/additional-charges/index';
import AddNewAddnCharges from 'pages/additional-charges/add';
import EditAdditionalCharges from 'pages/additional-charges/edit';

// booking reports pages
import BookingReportsList from 'pages/booking-reports/index';
import ViewBookingReport from 'pages/booking-reports/view';
import ClearanceList from 'pages/booking-reports/clearance';

import { permissionCheck } from 'permissions';

const BookingRoutes = (props) =>  {
  console.log("loading booking routes");
  return (
    <Routes>
      <Route key="aqf-bookings" path="*"
        element={
          <ProtectedComponent Component={DashboardLayout}  redirectLink='/' authCheck={props.isAuthenticated} /> 
        }
      >
        {
          permissionCheck('booking', 'create') &&
          <Route key="aqf-bookings-new" path = "add" element = {<AddNewBooking />} />
        }
        {
          (permissionCheck('booking', 'view-any') || permissionCheck('booking', 'view-self'))  &&
          <Route key="aqf-bookings-list-active" path = "list" element = {<BookingsList type="Listing"/>} />
        }
        {
          permissionCheck('booking', 'approve-shipment') &&
          <Route key="aqf-bookings-list-shipment-approval" path = "list-shipment-approval" element = {<BookingsList type="shipment-approval"/>} />
        }
        {
          permissionCheck('booking-reports', 'create-received-sample-report') &&
          <Route key="aqf-bookings-list-arrivals" path = "arrivals" element = {<BookingsList type="arrival-pending"/>} />
        }
        {
          permissionCheck('booking', 'approve-shipment') &&
          <Route key="aqf-bookings-view-shipment-approval" path = "shipment-approval/:id" element = {<ShipmentApproval />} />
        }
        {
          (permissionCheck('transaction', 'view-self') ||  permissionCheck('transaction', 'view-any')) &&
          <Route key="aqf-bookings-transactions" path = "transactions" element = {<TransactionsList />} />
        }
        {
          (permissionCheck('transaction', 'view-self') ||  permissionCheck('transaction', 'view-any')) &&
          <Route key="aqf-bookings-transactions" path = "transactions/:id" element = {<ViewTransaction />} />
        }
        {
          permissionCheck('booking', 'view-sample-collection-storage') &&
          <Route key="aqf-bookings-sample-collection-view" path = "samples/:id" element = {<ViewSampleCollection />} />
        }
        {
          (permissionCheck('booking', 'view-any') || permissionCheck('booking', 'view-self')) &&
          <Route key="aqf-bookings-view" path="view/:id" element= {<ViewBooking />} />
        }
        {
          (permissionCheck('transaction', 'view-additional-charges') || permissionCheck('transaction', 'view-additional-charges-self')) &&
          <Route key="aqf-bookings-addn-charges-list" path="additional-charges/list" element= {<AddnChargesList />} />
        }
        {
          permissionCheck('transaction', 'create-additional-charges') &&
          <Route key="aqf-bookings-addn-charges-create" path="additional-charges/add" element= {<AddNewAddnCharges />} />
        }
        {
          permissionCheck('booking-reports', 'create-clearance-certificate') &&
          <Route key="aqf-bookings-reports-list-clearance" path="clearances" element= {<ClearanceList />} />
        }
        {
         /*   
          permissionCheck('transaction', 'update-additional-charges') &&
          <Route key="aqf-bookings-addn-charges-update" path="additional-charges/edit/:id" element= {<EditAdditionalCharges />} />
         */ 
        }
        <Route key="aqf-bookings-reports-list" path="test-reports" element= {<BookingReportsList />} />
        <Route key="aqf-bookings-reports-list" path="test-reports/:reportType" element= {<BookingReportsList />} />
        <Route key="aqf-bookings-reports-view" path="test-reports/view/:id/:reportType" element= {<ViewBookingReport />} />
        <Route key="no-matching-route" path="*" element= {<Navigate to="/not-found" />} />
      </Route>
    </Routes>
  );
}

export default BookingRoutes;
