import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Paper, Grid, Box, Typography, List, ListItem, ListItemText, ListItemIcon, Button } from '@mui/material';

import SendIcon from '@mui/icons-material/Send';

import EntryGridView from 'components/EntryGridView';
import EntryTabularView from 'components/EntryTabularView';

import VirtualAccountCard from 'pages/hatcheries/widget-components/VirtualAccountCard';

import { HatcheryStore } from 'store';

import { bookingFields } from '../bookings.fields';


const BookingSummary = (props) => {
  
  return (
    <>
      <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} sm={7}>
          <EntryGridView 
            title="Booking Summary" 
            formFields={bookingFields} 
            values = {props.bookingData}/>
        </Grid>
        <Grid item xs={12} sm={5}>
          <VirtualAccountCard hatcheryData={HatcheryStore.state.currentHatchery} noPaper={false} />
        </Grid>
      </Grid>
      <Paper elevation={3} sx={{m :1, p:2}}>
        <Grid container justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={10}>
            <Typography variant="h4" align="left"> Payment Terms and Conditions </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <SendIcon  color="secondary"/>
                </ListItemIcon>
                <ListItemText
                  primary={
                    "This virtual account number can be used only for electronic funds transfer " + 
                    "to provided account number through intra bank Funds Transfer or RTGS / NEFT/ IMPS."
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <SendIcon color="secondary"/>
                </ListItemIcon>
                <ListItemText
                  primary={ 
                    "It is remitter's responsibility to remit the funds to correct account no. with correct amount as provided above. " + 
                    "Incase of any mismatch in account no or amount, the transaction will be rejected and funds will be refunded to remitter " + 
                    "account by next working day."
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <SendIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  primary= {
                    "Please read IFSC (Indian Financial System Code) 5th character as 0 (zero) for this virtual account payment."
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={2} justifyContent="center">
            <Box display="flex" justifyContent="flex-start">
              <img
                alt="confirmation page"
                src= {process.env.PUBLIC_URL + "/static/images/misc/success.png"}
                style={{
                  display: 'inline-block',
                  width: "auto",
                  height:200
                }}
              />
            </Box>
            <Button variant="outlined" component={RouterLink} to="/aqf/bookings/list" sx={{mx:1, mt:2}}>Back To Bookings</Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
export default BookingSummary;