import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { quarantineFields } from '../booking-reports.fields';

import { createNewBookingReport, updateBookingReport } from 'services/booking-reports';

export default class QuarantineReport extends Component {
  
  constructor ( props ) {
    super (props);
    
    // populate current booking id
    let bookingFieldIdx = quarantineFields.findIndex( f => f.name === 'bookingId');
    if (bookingFieldIdx >= 0 && props.bookingData) {
      quarantineFields[bookingFieldIdx].default = props.bookingData.id;
    }
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated quarantine report..." };
    
    // todo API calls
    try {
      let response = await createNewBookingReport('quarantine-report', params);
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  render () {
    return(
      <>
        <Typography variant="h3" align="center" p={2} > 
        { 
          (this.props.currentValues ? 'Update Quarantine Report' : 'Quarantine Report') + 
          ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
        }
        </Typography><hr style={{borderColor:"#eef1f6"}} />
        <DynamicForm
          formFields={quarantineFields}
          action={this.props.currentValues ? "update" : "create"}
          handleSubmit={this.handleFormSubmit}
          buttonText="Save Report"
          buttonWidth= "full"
          columns = {1}
          uploadFiles = {true}
          currentValues={this.props.currentValues}
          redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports"}
        />
      </>
    );
  }
}