import React from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";

import { FieldCreator } from "./FieldCreator";

export default class CaptionForm extends React.Component {
  constructor(props) {
  super(props);

  this.state = {
    caption: this.props.data
  };
  }

  handleEditorChange = (event, name) => {
  this.setState({
    caption: event.editor.getData()
  });
  };

  render() {
  return (
    <React.Fragment>
    <Dialog open={true}>
      <DialogContent style={{ textAlign: "center" }}>
      <FieldCreator
        key={"key_caption"}
        name={"Caption"}
        type={"text"}
        label={"Edit Caption"}
        value={this.state.caption}
        onEditorChange={this.handleEditorChange}
      />
      </DialogContent>
      <DialogActions>
      <Button
        onClick={e => {
        this.props.saveEditCaptionHandler(this.state.caption);
        }}
        color={"primary"}
      >
        Save
      </Button>
      <Button onClick={this.props.cancelEditCaptionHandler} color={"red"}>
        Cancel
      </Button>
      </DialogActions>
    </Dialog>
    </React.Fragment>
  );
  }
}
