export default [
  {  
    name: 'name',
    label: 'Cubicle Name',
    type: 'string',
    properties: [{required:true}, {string : { min : 4, max : 10}}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'number',
    label: 'Cubicle Number',
    type: 'string',
    properties: [{required:true, number: true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'phaseNumber',
    label: 'Phase Number',
    type: 'string',
    properties: [{required:false, string : true}],
    actions : ['view', 'create', 'update', 'index']
  },
  {
    name: 'isDelete',
    label: 'Delete',
    type: 'boolean',
    properties: [{required:true}],
    actions : ['update']
  }
  /* not required as per AQMS team's feedback
  {
    name: 'tanksPerCubicle',
    label: 'Tanks Per Cubicle',
    type: 'number',
    actions : ['view', 'create', 'update'],
    properties: [{ required:false, number:true}], 
  }*/
];