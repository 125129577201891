import crypto from 'crypto-js';
// import AWS from 'aws-sdk';
//import AmazonS3URI from 'amazon-s3-uri';
import { getUserToken, calculateAPISignature } from './utils';


/* List of all service API */
export const UPLOAD_EP = {
  // file upload API
  API_FILE_GET : { path : "/upload/files", method : "GET"},
  API_FILE_UPLOAD : { path : "/upload", method : "POST"},
  API_FILE_DELETE : { path : "/upload/files", method : "DELETE"},
};

/* const s3Instance = new AWS.S3( {
  accessKeyId : process.env.REACT_APP_AWS_S3_ACCESS_KEY,
  secretAccessKey : process.env.REACT_APP_AWS_S3_SECRET_KEY,
  region : process.env.REACT_APP_AWS_S3_REGION
});*/

const mimeToFileExt = (mime) => {
  switch (mime) {
    case 'image/png':
      return 'png';
    case 'image/jpg':
      return 'jpg';
    case 'image/jpeg':
      return 'jpeg';
    case 'application/pdf':
      return 'pdf';
    default:
      return null;
  }
}

//return a promise that resolves with a File instance
const urltoFile = async (url, filename) => {
  let mimeType = (url.match(/^data:([^;]+);/)||'')[1];
  return new Promise ((resolve, reject) => {
    fetch(url)
    .then( res => res.arrayBuffer())
    .then( buf => new File([buf], filename, {type:mimeType}))
    .then (obj => resolve(obj))
  });
}

/*
const uploadImgToAwsS3 = async (blobUrl, filename) => {
  
  var buffer = new Buffer(
    blobUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64'
  );

  var params = {
    Body: buffer,
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Key: filename,
    ACL: 'public-read'
  };
  
  console.log ("uploading new image to s3");

  return s3Instance.upload(params).promise();
}

const removeImageFromAwsS3 = async (fileUrl) => {
  
  //'https://bucket.s3-aws-region.amazonaws.com/key'
  const { bucket, key } = AmazonS3URI(fileUrl);
  
  // skip image delete from any other buckets, no access !!
  if (bucket !== process.env.REACT_APP_AWS_S3_BUCKET) return null;
  
  var params = {
    Bucket: bucket, // 'mybucket'
    Key: key // 'images/myimage.jpg'
  };
  
  console.log ("deleting image from s3");
  
  return s3Instance.deleteObject(params).promise();
}
*/

const uploadImgToStrapi = async (blobUrl, filename, staggered) => {
 
  let headers = new Headers();
  let token = getUserToken();
  let file = await urltoFile(blobUrl, filename);
  let ext = mimeToFileExt(file.type);
  let formdata = new FormData();
  
  headers.append("multipart", "");
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }
  
  headers.append("x-api-version", process.env.REACT_APP_API_VERSION);
  headers.append("x-api-operation", "common:media-create");
  
  
  formdata.append("files", file, filename);
  
  let requestOptions = {
    method: UPLOAD_EP.API_FILE_UPLOAD.method,
    headers: headers,
    body: formdata,
    redirect: 'follow'
  };
  
  return new Promise((resolve, reject) => {
    let timeout = setTimeout(() => reject({status : 1001, data: {}}), 120000);  
    fetch(`${process.env.REACT_APP_API_ENDPOINT}${UPLOAD_EP.API_FILE_UPLOAD.path}`, requestOptions)
      .then(async r => {
        clearTimeout(timeout);
        const contentType = r.headers.get('content-type');
        let response = { status : r.status };
      
        // expect json response when status is 200
        if (r.status === 200) {
          if (contentType.startsWith('application/json;')) {
            response.data = await r.json();
          } else {
            response.data = await r.text();
          }
          resolve(response);
        
        } else {
          // if status is 400 and response is json, then it is a validation
          // error, capture the error data also.
          //
          if (r.status === 400 && contentType.startsWith('application/json;')) {
            let data = await r.json();
            if (typeof data.message === 'object' && data.message.hasOwnProperty('isValid')) {
              response.data = data.message;
              response.message = "Invalid parameters in the request";
            } else {
              response.message = data.message;
            }
          }
          reject(response);
        }
      })
      .then(obj => (staggered) ? setTimeout(() => resolve(obj), 2000) : resolve(obj))
      .catch(error => {
          clearTimeout(timeout);
          console.log(error);
          reject({status : 1000, message : error.message})
      });
  });
}

const getImgFromStrapi = async (fileHash) => {
  let headers = new Headers();
  let token = getUserToken();
  
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }
  
  const path = `${process.env.REACT_APP_API_ENDPOINT}${UPLOAD_EP.API_FILE_GET.path}` + '?hash=' + fileHash;
  const signature = calculateAPISignature(encodeURIComponent(path.replace(/ /g, '%20')));
  headers.append("x-api-version", process.env.REACT_APP_API_VERSION);
  headers.append("x-api-signature", signature);
  headers.append("x-api-operation", "common:media-view");
  
  let requestOptions = {
    method: UPLOAD_EP.API_FILE_GET.method,
    headers: headers
  };
  
  return new Promise((resolve, reject) => {
      setTimeout(() => reject({status : 1001, data: {}}), 5000);     fetch(path, requestOptions).then(r => { 
      return r.status === 200 
      ? r.json().then(data => ({ status: r.status, data: data })) 
      : reject({status:r.status, data : {}})
    })
    .then(obj => resolve(obj))
    .catch(error => reject({status : 1000, data: {}}));
  });
}

const removeImgFromStrapi = async (fileUrl) => {
  
  // get file id from url
  let parts = fileUrl.split('/');
  let fileId = parts[parts.length - 1].split('.')[0];
  let headers = new Headers();
  let token = getUserToken();
  
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }
  
  let response = await getImgFromStrapi(fileId);
  let requestOptions = {
    method: UPLOAD_EP.API_FILE_DELETE.method,
    headers: headers
  };
  
  if (response.status === 200 && response.data.length === 1) {  
    return new Promise((resolve, reject) => {
      setTimeout(() => reject({status : 1001, data: {}}), 5000); 
      
      const path = `${process.env.REACT_APP_API_ENDPOINT}${UPLOAD_EP.API_FILE_DELETE.path}` + '/' + response.data[0].id;
      const signature = calculateAPISignature(encodeURIComponent(path.replace(/ /g, '%20') + "/delete"));
      headers.append("x-api-version", process.env.REACT_APP_API_VERSION);
      headers.append("x-api-signature", signature);
      headers.append("x-api-operation", "common:media-delete");
        fetch(path,requestOptions).then(r => { 
        return r.status === 200 
          ? r.json().then(data => ({ status: r.status, data: data })) 
          : reject({status:r.status, data : {}})
      })
      .then(obj => resolve(obj))
      .catch(error => reject({status : 1000, data: {}}));
    });
  }
}

export const uploadImgToBackend = async (blobUrl, filename=null, staggered=false) => {
  
  let response = null;
  
  // supported only if s3 uploads is enabled in env
  if (process.env.REACT_APP_AWS_S3_UPLOAD === 'true') {
    try {
      // response = await uploadImgToAwsS3(blobUrl, filename);
      // return {status : 'success', url : response.Location};
    } catch ( error ) {
      console.log(error);
      return {status : 'error', url : null, message : error.message}
    }
  }
  
  else if (process.env.REACT_APP_STRAPI_FILE_UPLOAD === 'true') {
    try {
      response = await uploadImgToStrapi(blobUrl, filename, staggered);
      if (response.status === 200 && response.data && response.data.length === 1) {
        let file = response.data[0];
        return {status : 'success', url : file.url};
      } else {
        return {status : 'error', url : null, message : 'Failed to upload files to server...'};
      }
    } catch ( error ) {
      console.log(error);
      return {status : 'error', url : null, message : (error.message ? error.message : 'Failed to upload files to server...')}
    }
  }
}

export const removeImgFromBackend = async (fileUrl) => {
  
  // supported only if s3 uploads is enabled in env
  if (process.env.REACT_APP_AWS_S3_UPLOAD === 'true') {
    try {
      // await removeImageFromAwsS3(fileUrl);
    } catch ( error ) {
      console.log(error);
    }
  }
  
  else if (process.env.REACT_APP_STRAPI_FILE_UPLOAD === 'true') {
    try {
      await removeImgFromStrapi(fileUrl);
    } catch ( error ) {
      console.log(error);
      return {status : 'error', url : null}
    }
  }
}

export const convertFileToBlob = async (file) => {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.addEventListener("load", async () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(file);
  })
};
