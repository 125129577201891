import React, { Component } from 'react';

import { Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { licenseFields } from '../hatchery.fields';

import { 
  updateHatcheryDetails, 
  createNewHatcheryLicense, 
  updateHatcheryLicense,
  approveHatcheryDetails
} from "services/hatcheries";

import { permissionCheck } from 'permissions';
import { AuthStore } from "store";

export default class LicenseForm extends Component {
  
  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    
    // copy list of columns
    this.licenseFields = [];
    
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      this.licenseFields = [{
        name: 'document',
        label: 'CAA Document',
        type: 'file',
        actions : ['view', 'create', 'update', 'index'],
        properties: [
          {required:false }, 
          {filesize : {min : 0, max : 10} }, 
          {filetype : ['pdf']}
        ],
      }];
    } else {
      licenseFields.map ( f => {
      
        // do not show document field
        if (f.name==='document') {
          return null;
        }
        
        let field = {...f};
        field.properties = [];
        // deep copy properties
        f.properties.map ( p => field.properties.push({...p}));
      
        // deep copy select options
        if (f.selectOptions) {
          field.selectOptions = {...f.selectOptions};
        }
      
        this.licenseFields.push(field);
        
        // fix warnings
        return null;
      });
    }
  }
 
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    
    let statusMessage = { status: "success", message : "Hatchery license details updated successfully..." };
    let response = null;
    try {
      let formData = params;
      formData.hatcheryId = this.props.hatcheryData.id;
      if (permissionCheck('hatchery', 'change-request')) {
        formData.licenseId = this.props.currentValues ? this.props.currentValues.id : null;
        response = await updateHatcheryDetails(this.props.hatcheryData.id, formData);
      } else if (this.props.currentValues && this.props.currentValues.id) {
        response = await updateHatcheryLicense(this.props.currentValues.id, formData);
      } else if (permissionCheck('hatchery', 'approve-changes')) {
        formData.updateRequestFlag = 'Approved';
        response = await approveHatcheryDetails(this.props.hatcheryData.id, formData);
      } else {
        response = await createNewHatcheryLicense(formData);
      }
      
      if (this.props.refresh) this.props.refresh();
      
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    return statusMessage;
  }

  render() {
    return (
      <>
        <Typography variant="h4" align="center" sx={{borderBottom : '1px solid', p:1}}>
          {this.props.currentValues ? 'Update CAA License Details' : 'Add CAA License Details'}
        </Typography>
        <DynamicForm
          formFields={this.licenseFields}
          handleSubmit={this.handleFormSubmit}
          buttonText="Save CAA License Details"
          buttonWidth= "full"
          currentValues = {this.props.currentValues}
          action={this.props.currentValues ? "update" : "create"}
          columns = {this.licenseFields.length > 1 ? 2 : 1}
          uploadFiles={true}
          redirect={!this.props.refresh ? '/aqf/hatcheries/approvals/hatcherychanges' : null}
        />
      </>
    );
  }
}