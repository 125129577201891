import React, { Component } from "react";

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";

import { 
  fetchCubiclesTotalCount,
  fetchAllCubicles, 
} from 'services/cubicles';

import cubicleFields from './cubicle.fields';

import { permissionCheck } from 'permissions';

export default class CubiclesList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Cubicles',
      addButtonText : 'Add New Cubicle',
      addUrl : permissionCheck('common', 'cubicle-create') ?  '/aqf/admin/cubicles/add' : null,
      searchEnable : true,              
      searchPlaceholder : 'Search By Name',
      
      // table content props
      columns : cubicleFields,
      itemUrlPath : null,
      editUrlPath : permissionCheck('common', 'cubicle-update') ?  '/aqf/admin/cubicles/edit/' : null,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchCubiclesDataWrapper,
      fetchCountApi : this.fetchCubiclesCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [],
      
      // export to xl, pdf props
      exportEnable : false,
    };
  }
  
  fetchCubiclesCountWrapper = async(filters, searchValue) => {
    let response = await fetchCubiclesTotalCount({
      name : searchValue
    });
    return response;
  }

  fetchCubiclesDataWrapper = async (page, perPage, filters, searchValue) => {
    let response = await fetchAllCubicles({
      page: page,
      per_page: perPage,
      name : searchValue
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
        entry.url = `/aqf/admin/cubicles/edit/${entry.id}`;
     });
    }
    
    return response;
  }

  async componentDidMount() {
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}