import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicTableForm } from 'components/Forms';

import { cubicleStockingFields } from '../bookings.fields';

import { addCubicleStocking, fetchCubicleStocking } from "services/bookings";
import { fetchBookingReportByBookingId } from 'services/booking-reports';

export default class CubicleStocking extends React.Component {
 
  state = { statusMessage : null, currentValues : null, totalLiveBs : null };
  constructor(props) {
    super(props);
    
    cubicleStockingFields[2].selectOptions = {};
    if (this.props.bookingData.cubicleDetails) {
      props.bookingData.cubicleDetails.map( c => {
        if (c.cubicleId) cubicleStockingFields[2].selectOptions[c.cubicleId] = c.cubicleName;
        else if (c.cubicleNumber) cubicleStockingFields[2].selectOptions[c.cubicleNumber] = `CUB-${c.cubicleNumber}`;
      });
    } else if (this.props.bookingData.cubicleBookingScheduleIds) {
      props.bookingData.cubicleBookingScheduleIds.map(c => {
        cubicleStockingFields[2].selectOptions[c.cubicleId] = `CUB-${c.cubicleId}`;
      });
    }
  }
  
  async componentDidMount() {
    try {
      // fetch arrival data for cross checking
      let arrival = await fetchBookingReportByBookingId(
        this.props.bookingData.id, 'received-sample-report'
      );
      
      // fetch stocking data
      let response = await fetchCubicleStocking({
         bookingId : this.props.bookingData.id,
         page : 1,
         per_page : 100
      });
      
      let stockingData = [];
      response.data && response.data.length && 
      response.data[0].bulkData.map ( c => {
        // filter by unique stocking id.
        if (!stockingData.find(x => x.id === c.id)) {
          c.bookingId = this.props.bookingData.id;
          c.cubicleId = c.number;
          stockingData.push(c);
        }
      });
      
      this.setState({
         currentValues : stockingData, 
         totalLiveBs : arrival.data && arrival.data.length
          ? arrival.data[0].totalArrivedBs - arrival.data[0].totalArrivMortalities
          : 0
      });
      
    } catch(error) {
      console.log(error);
    }      
  }
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params, tableData) => {
    let statusMessage = { status: "success", message: "Successfully saved cubicle stocking details...." };
    
    let formData = { 
      bookingId : this.props.bookingData.id,
      bulkData : tableData
    };
    try {
      let response = await addCubicleStocking(formData);
      if (this.props.refresh) this.props.refresh();
    } catch(error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, tableData : error.data };
    }
    
    return statusMessage;
  }
  
  handleTableDataChange = (tableData) => {
    // no-op
  }
  
  handlePreFormSubmit = (formData) => {
    let tableData = formData.tableData;
    let duplicates = new Set();
    let totalStockCount = 0;
    
    // validate tableData
    tableData.map ( data => {
      duplicates.add(data.cubicleId)
      totalStockCount += data.totalAnimalStocked;
    });
    
    if (tableData.length !== duplicates.size) {
      return {
        status : "error", 
        message : "Duplicate cubicle stocking data entered. Please select different cubicles"
      };
    }
    
    // check if total stock is same as arrived stock, totalBs from arrival report
    if (this.state.totalLiveBs && totalStockCount > this.state.totalLiveBs) {
     return {
        status : "error", 
        message : "Total Animal stocked is not same as the stock arrived, please check numbers"
      };
    }
    
    return  {status : "success"};
  }
  
  render() {
    return (<>
      <Typography variant="h5" align="center" p={1} > 
      { 
        (this.state.currentValues ? 'Update Cubicle Stocking Details ' : 'Add Cubicle Stocking Details') + 
        ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
      }
      </Typography><hr style={{borderColor:"#eef1f6"}} />
      
      <DynamicTableForm
        formFields = {[]}
        tableFormProps = {{
          fields : cubicleStockingFields,
          handleTableDataChange : this.handleTableDataChange,
          serialNoHeader : 'S.No',
          deleteRows: this.state.currentValues ? "none" : "last",
          uploadFiles : true,
          minRows : this.props.bookingData.cubicleBookingScheduleIds.length,
          maxRows : this.props.bookingData.cubicleBookingScheduleIds.length,
          currentValues : this.state.currentValues,
        }}
        preFormSubmit={this.handlePreFormSubmit}
        uploadFiles={true}
        action={this.state.currentValues ? "update" : "create"}
        buttonText="Save Cubicle Stocking Details"
        handleSubmit={this.handleFormSubmit}
      />
    </>);
  }
}