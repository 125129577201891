import React, { Component } from 'react';
import { Grid, Box, Card, CardContent, CardHeader, Typography, Divider, 
  TextField, TableContainer, Table, TableRow, TableCell, MenuItem, Chip 
} from '@mui/material';

import { PersonAddAltOutlined } from '@mui/icons-material';

// services
import { fetchBookingsData } from 'services/bookings';
import { HatcheryStore } from 'store';

export default class BookingsCalendar extends Component {
  
  state = {
    year : new Date().getFullYear(),
    bookings : [],
    selectedMonth : new Date().getMonth(),
  };
  
  async componentDidMount() {
    let startDate = new Date(this.state.year, this.state.selectedMonth, 1);
    let endDate = new Date(this.state.year, this.state.selectedMonth + 1, 0);
    
    const hatcheryData = HatcheryStore.state.currentHatchery;
    
    try {
      let response = await fetchBookingsData({
        type : 'date-range',
        page : 1,
        per_page : 100,
        status : 'ALL',
        supplierId : 'ALL',
        hatcheryId : hatcheryData.id,
        startDate : startDate.toISOString(),
        endDate : endDate.toISOString(),
        speciesType : hatcheryData.permittedSpeciesId.id,
        shipmentStatus : 'ALL'
      });
      this.setState({bookings : response.data});
    } catch(error) {
      console.log(error);
    }
  }
  
  handleMonthSelect = async (monthIdx) => {
    let startDate = new Date(this.state.year, monthIdx, 1);
    let endDate = new Date(this.state.year, monthIdx + 1, 0);
    
    const hatcheryData = HatcheryStore.state.currentHatchery;
    
    try {
      let response = await fetchBookingsData({
        type : 'date-range',
        page : 1,
        per_page : 100,
        status : 'ALL',
        supplierId : 'ALL',
        hatcheryId : hatcheryData.id,
        startDate : startDate.toISOString(),
        endDate : endDate.toISOString(),
        speciesType : hatcheryData.permittedSpeciesId.id,
        shipmentStatus : 'ALL'
      });
      this.setState({bookings : response.data, selectedMonth : monthIdx});
    } catch(error) {
      console.log(error);
    }
  }
  
  handleYearSelect = async (year) => {
    let startDate = new Date(year, this.state.selectedMonth, 1);
    let endDate = new Date(year, this.state.selectedMonth + 1, 0);
    
    const hatcheryData = HatcheryStore.state.currentHatchery;
    
    try {
      let response = await fetchBookingsData({
        type : 'date-range',
        page : 1,
        per_page : 100,
        status : 'ALL',
        supplierId : 'ALL',
        hatcheryId : hatcheryData.id,
        startDate : startDate.toISOString(),
        endDate : endDate.toISOString(),
        speciesType : hatcheryData.permittedSpeciesId.id,
        shipmentStatus : 'ALL'
      });
      this.setState({bookings : response.data, year : year});
    } catch(error) {
      console.log(error);
    }
  }
  
  render() {
    
    let monthNames =['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    const filterValues = range(currentYear - 10, currentYear + 5, 1).map( year => {return {'id' : year, 'value' : "AY-" + year}});
   
    return (
  
      <Card key="0-card">
        <CardHeader 
          action={(
            <TextField
              id="year"
              label="Select Year"
              name="year"
              fullWidth
              select
              type='text'
              value={this.state.year}
              onChange={e => {this.handleYearSelect(e.target.value)}}
              margin="normal"
              variant="standard"
            >
            {
              filterValues.map ((opt, i) => {
                return (<MenuItem key={i} value={opt.id}>{opt.value}</MenuItem>);
              })
            }
            </TextField>
          )}
          
          title={"Bookings Calendar - " + this.state.year}/>
        <Divider />
        <CardContent>
          <Grid key="0-outer" container spacing={3} justifyContent="flex-start" alignItems="center" spacing={3}>
            <Grid key="0-firstgrid" item xs={12} sm={4}>
              <Grid key="0-innerfirst" container spacing={3} justifyContent="flex-start" alignItems="center">
              {
                monthNames.map ( (m, idx) => {
                  return (
                    <Grid item xs={4} sm={3}>
                      <Box
                        key={idx}
                        sx={{ 
                          width: 80, 
                          height: 60,
                          p:2,                         
                          borderColor : 'primary', 
                          border: '1px dashed',
                          borderRadius : '5px',
                          cursor:'pointer',
                          bgcolor: this.state.selectedMonth === idx ? 'primary.main' : 'success.light',
                          color : 'white',
                          textAlign: "center"
                        }}
                        onClick={ () => this.handleMonthSelect(idx) }
                      >  
                        <Typography variant="body1">{m.toUpperCase()}</Typography>
                      </Box>
                    </Grid>
                  )
                })
              }
              </Grid>
            </Grid>
            {
              this.state.bookings.length > 0 ? 
              <>
              {
                this.state.bookings.map( b => {
                  return (
                    <Grid item xs={12} sm={3}>
                       <Card key={"0-card" + b.id} sx={{backgroundColor : '#F4F6F8'}}>
                          <CardHeader title={"Booking No : " + b.id} sx={{textAlign : 'left'}}/>
                          <Divider />
                          <CardContent sx={{textAlign: "left", p:1}}>
                            <TableContainer>
                              <Table size="small">
                                <TableRow key="row-0" sx={{heigth:'30px', p:0}}>
                                  <TableCell key="row-0-col-0" sx={{p : 0, borderBottom:"none"}} ><Typography variant="body2"> Start Date</Typography></TableCell>
                                  <TableCell  key="row-0-col-1" sx={{p : 0, borderBottom:"none"}}><Typography variant="overline"> {b.fromDate} </Typography></TableCell>
                                </TableRow>
                                <TableRow key="row-1" sx={{heigth:'30px', p:0}}>
                                  <TableCell key="row-1-col-0" sx={{p : 0, borderBottom:"none"}}><Typography variant="body2"> End Date</Typography></TableCell>
                                  <TableCell  key="row-1-col-1" sx={{p : 0, borderBottom:"none"}}><Typography variant="overline"> {b.toDate} </Typography></TableCell>
                                </TableRow>
                                <TableRow key="row-2" sx={{heigth:'30px', p:0}}>
                                  <TableCell key="row-2-col-0" sx={{p : 0, borderBottom:"none"}}><Typography variant="body2"> Total Stock (Qty)</Typography></TableCell>
                                  <TableCell  key="row-2-col-1" sx={{p : 0, borderBottom:"none"}}><Typography variant="overline"> {b.totalNumberOfStock} </Typography></TableCell>
                                </TableRow>
                                <TableRow key="row-3" sx={{heigth:'30px', p:0}}>
                                  <TableCell key="row-3-col-0" sx={{p : 0, borderBottom:"none"}}><Typography variant="body2"> Total Cubicles (No.s)</Typography></TableCell>
                                  <TableCell  key="row-3-col-1" sx={{p : 0, borderBottom:"none"}}>
                                    <Typography variant="overline">{b.cubicleDetails ? b.cubicleDetails.length : 0}</Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow key="row-4" sx={{heigth:'30px', p:0}}>
                                  <TableCell key="row-4-col-0" sx={{p : 0, borderBottom:"none"}}><Typography variant="body2"> Current Status</Typography></TableCell>
                                  <TableCell  key="row-4-col-1" sx={{p : 0, borderBottom:"none"}}>
                                    <Chip label={b.status} color={['Confirmed', 'Completed'].includes(b.status) ? 'success' : 'warning'} size="small"/>
                                  </TableCell>
                                </TableRow>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Card>
                    </Grid>)
                  })
              }
              </> :
              <Grid item xs={12} sm={8}>
                <Typography variant="h3"> No bookings available in the selected month </Typography>
              </Grid>
            }
          </Grid>
        </CardContent>
      </Card>
    );
  }
}
