import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import {
  TextField,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@mui/material";

import { FieldCreator } from "./FieldCreator";

import { generateFieldRules } from "./validationRules";
import FormValidator from "./FormValidator";

export class ImageCrop extends React.Component {
  constructor(props) {
  super(props);
  let property = props.formField.properties.find(p => p.crop !== undefined);
  let isCaptionEnabled = props.formField.properties.find(
    p => p.caption !== undefined
  );

  this.fileInput = React.createRef();

  this.state = {
    validation: { isValid: false },
    dialogOpen: false,
    crop: this.initializeCropParams(property),
    croppedImageUrl: null,
    caption: "",
    isCaptionEnabled: isCaptionEnabled ? isCaptionEnabled.caption : false
  };

  this.validationRules = [];
  this.fieldMeta = { touched: false };
  
  // initialize help text
  this.cropHelp = '';
  if (property.crop) {
    if (property.crop.width) {
      this.cropHelp = `Image resolution should be, ${property.crop.width}px X ${property.crop.height}px`;
    } else if (property.crop.minWidth) {
      this.cropHelp = `Minimum image minimum resolution should be ${property.crop.minWidth}px X ${property.crop.minHeight}px`;
    } else if (property.crop.maxWidth) {
      this.cropHelp = `Maximum image resolution should be, ${property.crop.maxWidth}px X ${property.crop.maxHeight}px`;
    }   
  }
  

  // crop image save state
  this.croppedImage = false;
  }

  initializeCropParams(property) {
  let crop;
  if (property.crop.aspect) {
    crop = {
    unit: property.crop.unit,
    x: property.crop.x,
    y: property.crop.y,
    width: property.crop.minWidth
      ? property.crop.minWidth
      : property.crop.maxWidth
      ? property.crop.maxWidth
      : property.crop.width,
    height: property.crop.minHeight
      ? property.crop.minHeight
      : property.crop.maxHeight
      ? property.crop.maxHeight
      : property.crop.height,
    aspect: parseFloat(property.crop.aspect)
    };
  } else {
    crop = {
    unit: property.crop.unit,
    x: property.crop.x,
    y: property.crop.y,
    width: property.crop.minWidth
      ? property.crop.minWidth
      : property.crop.maxWidth
      ? property.crop.maxWidth
      : property.crop.width,
    height: property.crop.minHeight
      ? property.crop.minHeight
      : property.crop.maxHeight
      ? property.crop.maxHeight
      : property.crop.height
    };
  }

  return crop;
  }

  async componentDidMount() {
  let newState = this.state;

  // skip fields not relevant to this action (create/edit)
  if (this.props.formField.actions) {
    let actions = this.props.formField.actions;
    if (!Array.isArray(actions)) {
    actions = actions.split(/,| /);
    }

    if (actions.find(action => action === this.props.action)) {
    // populate required validation rules for the field
    if (this.props.formField.properties) {
      newState[this.props.formField.name] = this.props.currentValue
      ? this.props.currentValues
      : null;

      generateFieldRules(this.props.formField, this.validationRules);

      this.validator = new FormValidator(this.validationRules, this.props.formField);
    }
    }
  }

  await this.setState(newState);
  }

  showFileDialog = () => {
  this.fileInput.current.click();
  };

  // to validate file size and extensions allowed
  validateFileTypeRules(e) {
  let validation = { isValid: true };

  if (this.props.formField.properties.find(p => p.filesize !== undefined)) {
    validation = this.validator.validateSingleRule(
    this.props.formField.name,
    "isFileSizeMb",
    e.target.files[0].size
    );
  }

  if (
    validation.isValid &&
    this.props.formField.properties.find(p => p.filetype !== undefined)
  ) {
    validation = this.validator.validateSingleRule(
    this.props.formField.name,
    "isFileType",
    e.target.value
    );
  }
  return validation;
  }

  // to validate image resolution allowed
  validateImageTypeRules(crop) {
  let exact = this.props.formField.properties.find(
    p => p.crop && p.crop.width !== undefined
  );
  let min = this.props.formField.properties.find(
    p => p.crop && p.crop.minWidth !== undefined
  );
  let max = this.props.formField.properties.find(
    p => p.crop && p.crop.maxWidth !== undefined
  );

  let validation = { isValid: true };

  if (exact) {
    validation = this.validator.validateSingleRule(
    this.props.formField.name,
    "isImgExactResol",
    JSON.stringify(crop)
    );
  } else if (min) {
    validation = this.validator.validateSingleRule(
    this.props.formField.name,
    "isImgMinResol",
    JSON.stringify(crop)
    );
  } else if (max) {
    validation = this.validator.validateSingleRule(
    this.props.formField.name,
    "isImgMaxResol",
    JSON.stringify(crop)
    );
  }
  
  // validate aspect ratio rule
  if (validation.isValid && crop.aspect) {
    validation = this.validator.validateSingleRule(
      this.props.formField.name,
      "isImgAspectRatio",
      JSON.stringify(crop)
    );
  }
  
  return validation;
  }

  onSelectFile = async e => {
  if (e.target.files && e.target.files.length > 0) {
    // TODO:: trigger image validation. don't set state yet !
    this.fieldMeta["touched"] = true;

    let validation = this.validateFileTypeRules(e);

    if (validation.isValid) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      // reset file input ref value
      if (this.fileInput.current) {
      this.fileInput.current.value = "";
      }
      this.setState({
      dialogOpen: true,
      validation: validation,
      [this.props.formField.name]: reader.result
      });
    });

    reader.readAsDataURL(e.target.files[0]);
    } else {
    await this.setState({ validation: validation });
    }
  }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = async image => {
  this.imageRef = image;
  if (this.imageRef && this.state.crop) {
    let validation = this.validateImageTypeRules(this.state.crop);
    await this.setState({ validation: validation });
  }
  };

  onCropComplete = async crop => {
  await this.makeClientCrop(crop);
  };

  onCropChange = async (crop, percentCrop) => {
  await this.setState({ crop });
  };

  async makeClientCrop(crop) {
  if (this.imageRef && crop.width && crop.height) {
    const croppedImageUrl = await this.getCroppedImg(
    this.imageRef,
    crop,
    "newFile"
    );
    //console.log (crop);
    let validation = this.validateImageTypeRules(crop);
    let captionValue = this.state.caption;
    await this.setState({
    croppedImageUrl: croppedImageUrl,
    validation: validation,
    caption: captionValue
    });
  }
  }

  saveCroppedImage = async event => {
  let property = this.props.formField.properties.find(
    p => p.crop !== undefined
  );
  let croppedImage = this.state.croppedImageUrl;
  let captionValue = this.state.caption;
  this.props.onSave(this.props.formField.name, croppedImage, captionValue);

  // update crop state to initial state
  this.state[this.props.formField.name] = null;

  await this.setState({
    dialogOpen: false,
    validation: { isValid: false },
    crop: this.initializeCropParams(property),
    croppedImageUrl: null,
    caption: ""
  });
  };

  handleEditorChange = async (event, name) => {
  await this.setState({
    caption: event.editor.getData()
  });
  };

  getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    let base64Image = canvas.toDataURL();
    resolve(base64Image);
  });
  }

  isFieldError(field, validation) {
  return (
    this.fieldMeta["touched"] &&
    validation[field] &&
    validation[field].message !== ""
  );
  }

  render() {
  let crop = this.state.crop;
  let validation = this.croppedImage
    ? this.validator.validate(this.state)
    : this.state.validation;

  return (
    <React.Fragment>
    {this.props.isHtmlInput ? (
      <div style={{ display: "block" }}>
      {this.props.value && (
        <img alt={"Placeholder"} src={this.props.value ? process.env.REACT_APP_STRAPI_FILE_URL + this.props.value : null} height="50%" width="50%" />
      )}
      {this.props.readOnly === false && (
        <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        id="outlined-name"
        label={this.props.formField.label}
        name={this.props.formField.name}
        type="file"
        onClick={e => {
          e.target.value = null;
        }}
        className={this.props.classes.TextField}
        onChange={this.onSelectFile}
        margin="normal"
        variant="outlined"
        error={this.isFieldError(this.props.formField.name, validation)}
        helperText={
          this.isFieldError(this.props.formField.name, validation)
          ? validation[this.props.formField.name].message
          : ""
        }
        />
      )}
      </div>
    ) : (
      <div>
      {this.isFieldError(this.props.formField.name, validation) && (
        <Typography
        variant="body2"
        color="error"
        >
        {validation[this.props.formField.name].message}
        </Typography>
      )}
      {this.props.readOnly === false && (
        <React.Fragment>
        <input
          type="file"
          name={this.props.formField.name}
          ref={this.fileInput}
          style={{ display: "none" }}
          onChange={this.onSelectFile}
        />
        <img
          src={"/images/add_image.png"}
          alt={"Add Placeholder"}
          onClick={this.showFileDialog}
        />
        </React.Fragment>
      )}
      </div>
    )}
    {this.state[this.props.formField.name] && (
      <Dialog fullScreen open={this.state.dialogOpen}>
      <DialogContent style={{ textAlign: "center" }}>
        <DialogTitle>
        Crop Image - {this.cropHelp}
        {this.state.crop.width && (
          <Typography
          className={this.props.classes.textField}
          variant="body2"
          >
          {`Current resolution - ${this.state.crop.width}px X ${this.state.crop.height}px`}
          </Typography>
        )}
        {this.isFieldError(this.props.formField.name, validation) && (
          <Typography
          className={this.props.classes.fieldError}
          variant="body2"
          color="error"
          >
          {`Error:${validation[this.props.formField.name].message}`}
          </Typography>
        )}
        </DialogTitle>
        <p>{this.props.errorMessage}</p>
        <ReactCrop
        src={this.state[this.props.formField.name]}
        crop={crop}
        onImageLoaded={this.onImageLoaded}
        onComplete={this.onCropComplete}
        onChange={this.onCropChange}
        />

        {this.state.isCaptionEnabled && (
        <FieldCreator
          key={"key_caption"}
          className={this.props.classes.textField}
          name={"Caption"}
          type={"text"}
          label={"Caption"}
          value={this.state["caption"]}
          onEditorChange={this.handleEditorChange}
        />
        )}
      </DialogContent>
      <DialogActions>
        <Button
        disabled={!validation.isValid}
        onClick={this.saveCroppedImage}
        color={"primary"}
        >
        Save
        </Button>
        <Button
        onClick={e => {
          this.setState({
          dialogOpen: false,
          validation: { isValid: true }
          });
        }}
        color={"secondary"}
        >
        Cancel
        </Button>
      </DialogActions>
      </Dialog>
    )}
    </React.Fragment>
  );
  }
}
