export default [
  {  
    name: 'id',
    label: 'Role Permissions Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'roleName',
    label: 'Role Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {
    name : "description",
    label: 'Role Description',
    type: 'string',
    properties: [{required:false}],
    actions: ['create', 'update', 'view', 'index']
  },
  {
    name : "dynamic",
    label: 'Dynamic Role',
    type: 'boolean',
    properties: [{readOnly:true}],
    default : true,
    valueStrings : ["No", "Yes"],
    actions: ['create','update', 'view', 'index']
  }
];
