import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  Paper, Box, Button, List,ListItem,Divider,ListItemText, Grid,
  Typography,  Dialog, DialogContent, DialogTitle,
  IconButton, Link
}from '@mui/material';

import {
  Close,
  PlayCircleFilledWhiteOutlined,
  LocationOnOutlined
} from '@mui/icons-material';

const Obfuscate = (props) => {
  const reverse = props.text.split('').reverse().join('');
  return (<span style = {{ "unicode-bidi": "bidi-override", "direction" : "rtl" }}> { reverse } </span>);
}

export default function Support() {
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ modalTitle, setModalTitle ] = useState('');
  const [ embedId, setEmbedId ] = useState(null);
  
  return (
    <>
      <Dialog open={modalOpen} aria-labelledby="form-dialog-title" maxWidth="lg">
        <DialogTitle id="responsive-dialog-title" sx={{display : 'flex', justifyContent : 'flex-end'}}>
          <IconButton onClick={() => setModalOpen(false)}><Close /></IconButton>
        </DialogTitle>
        <DialogContent>
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={modalTitle}
          />
        </DialogContent>
      </Dialog>
       
    
      <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper elevation = {4} sx= {{ p:2, m :2 }}>
            <Typography variant="h4" color="primary" align="center"> Watch Help Videos </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
            <List justifyContent="center" alignItems="center">
              <ListItem alignItems="center"  key={1} onClick = { e => { 
                  setModalOpen(true) ; setEmbedId('2Ss8I6FavUw');
                }}
                sx={{cursor : 'pointer'}}
              >
                <Box component="span" sx={{ m:2, p: 1, border: '1px dashed grey', borderRadius : '50%'}} >
                  <PlayCircleFilledWhiteOutlined sx={{ fontSize: 64 }}  color="secondary"/>
                </Box>
                <ListItemText
                  sx={{flexGrow :1 }}
                  primary="How to Register"
                  secondary="The Video is about how to complete hatchery registration. Please watch before attempting registration."
                />
              </ListItem>
              <Divider variant="inset" component="li" key={2} />
              <ListItem alignItems="center"  key={3} onClick = { e => { 
                  setModalOpen(true) ; setEmbedId('IED3uZL9AEc');
                }}
                sx={{cursor : 'pointer'}}
              >
                <Box component="span" sx={{ m:2, p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
                  <PlayCircleFilledWhiteOutlined sx={{ fontSize: 64 }}  color="secondary"/>
                </Box>
                <ListItemText
                  sx={{flexGrow :1 }}
                  primary="How to Book Cubicles"
                  secondary="The Video is about how to book cubicles online and make related payments. Please watch before attempting booking."
                />
               
              </ListItem>
              <Divider variant="inset" component="li" key={4} />
              <ListItem alignItems="center"  key={5} onClick = { e => { 
                  setModalOpen(true) ; setEmbedId('Qjn8LfhhaNA');
                }}
                sx={{cursor : 'pointer'}}
              >
                <Box component="span" sx={{ m:2, p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
                  <PlayCircleFilledWhiteOutlined sx={{ fontSize: 64 }} color="secondary" />
                </Box>
                <ListItemText
                  sx={{flexGrow :1 }}
                  primary="How to upload Shipment Documents"
                  secondary="The Video is about how to add shipment documents and NoC after the booking is confirmed. Please watch before attempting booking."
                />
              </ListItem>
              <Divider variant="inset" component="li" key={6}  />
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation = {4} sx= {{ p:2, m :2 }}>
            <Typography variant="h4" color="primary" align="center"> Contact Us </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
            <Box sx={{borderColor : 'primary.main', border: '1px dashed', textAlign:"left", m:2, p:2}} >
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs={9} sm={10}>
                  <Typography variant="h6">RAJIV GANDHI CENTRE FOR AQUACULTURE</Typography>
                  <Typography variant="body2">(MPEDA, Ministry of Commerce & Industry, Govt. of India)</Typography>
                  <Typography variant="body2">3/197, Poompuhar Road, Karaimedu Village</Typography>
                  <Typography variant="body2">Sattanathapuram P.O, Sirkali Taluk, Nagapattinam District-609 109</Typography>
                  <Typography variant="body2">Tamil Nadu, India</Typography><br/>
                  <Typography variant="subtitle2" color="secondary">Email : <Obfuscate text= "rgcaho@gmail.com" />, <Obfuscate text="rgca@mpeda.gov.in" /></Typography>
                  <Typography variant="subtitle2" color="secondary">Phone : <Obfuscate text= "+ 91 4364 265200-217" />, Fax : <Obfuscate text="04364265218" /></Typography>
                </Grid>
                <Grid xs={3} sm={2}>
                  <Link rel="noreferrer" href="https://goo.gl/maps/JRZi6nBWJod35B7T7" target="_blank">
                    <LocationOnOutlined color="secondary" sx={{ fontSize: 72 }} />
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{borderColor : 'primary.main', border: '1px dashed',  m:2, p:2, textAlign:"left"}} >
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs={9} sm={10}> 
                  <Typography variant="h6" align="left">AQUATIC QUARANTINE FACILITY</Typography>
                  <Typography variant="h6" align="left">RAJIV GANDHI CENTRE FOR AQUACULTURE</Typography>
                  <Typography variant="body2">(MPEDA, Ministry of Commerce & Industry, Govt. of India)</Typography>
                  <Typography variant="body2">TNFDC Hatchery Complex</Typography>
                  <Typography variant="body2">Kapaleeswarar Nagar, Beach road, Neelankarai - 600 115, Chennai</Typography>
                  <Typography variant="body2">Tamil Nadu, India</Typography><br/>
                  <Typography variant="body2" color="secondary">Email : <Obfuscate text="aqf.rgca@mpeda.gov.in" /></Typography>
                  <Typography variant="body2" color="secondary">Phone : <Obfuscate text="044 24495070"/>, Fax : <Obfuscate text="044 24492706" /></Typography>
                </Grid>
                <Grid xs={3} sm={2}>
                  <Link rel="noreferrer" href="https://goo.gl/maps/x2gdTFSqAVtGXZddA" target="_blank">
                    <LocationOnOutlined color="secondary" sx={{ fontSize: 72 }} />
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}