import { useState } from 'react';
import { calculateAPISignature, obfuscateData } from 'services/utils';
import { 
  Grid, Avatar, TableContainer, Table, TableBody, TableRow, TableCell,
  Dialog, DialogContent, DialogTitle, Typography, Box, Button,
  Tooltip, IconButton  
} from '@mui/material';

import Close from '@mui/icons-material/Close';

import HatcheryOwnerForm from '../form-components/HatcheryOwnerForm';
import OTPValidation from 'pages/common/OTPValidation';

import { permissionCheck } from 'permissions';

import { updateUserAccount } from 'services/user';

const OwnerDetails = (props) => {
  
  const [ modalOpen, setModalOpen ] = useState(false);
  const currentValues = {
    name : props.hatcheryData.userProfile.name,
    address : props.hatcheryData.userProfile.address,
    email : props.hatcheryData.email,
    password : "DummyP@123", // fake password used just for field validation
    confirmPassword : "DummyP@123",
    phoneNumber : props.hatcheryData.userProfile.phoneNumber,
    picture : props.hatcheryData.userProfile.picture,
    isHatcheryIncharge : props.hatcheryData.userProfile.isHatcheryIncharge ? true : false
  };
  const [formParams, setFormParams] = useState(currentValues);
  const [otp, setOtp] = useState(false);
  
  const handleOwnerDetails = async (params) => {
    
    setFormParams(params);
    
    if (params.phoneNumber !== currentValues.phoneNumber) {
      setOtp(true);
    } else {
      let formData = params;
      if (formData.password === "DummyP@123") {
        delete formData.password;
        delete formData.confirmPassword;
      }
      delete formData.phoneNumber;
      try {
        let response = await updateUserAccount(props.hatcheryData.userProfile.id, formData);
        setModalOpen(false);
        props.refresh();
      } catch (error) {
        console.log(error);
      }
    }
  }
  
  const handleOTPValidation = async (number, otp) => {
    let formData = formParams;
    formData.phoneNumber = number;
    formData.OTP = otp;
    if (formData.password === "DummyP@123") {
      delete formData.password;
      delete formData.confirmPassword;
    }
    setOtp(false);
    try {
      let response = await updateUserAccount(props.hatcheryData.userProfile.id, formData);
      setModalOpen(false);
      props.refresh();
    } catch (error) {
      console.log(error);
    }
  }
  
  const signature = props.hatcheryData && props.hatcheryData.userProfile.picture
    ? calculateAPISignature(encodeURIComponent(props.hatcheryData.userProfile.picture.replace(/ /g, '%20')))
    : null;
    
  return (
    <>
      {
        otp ?
        <OTPValidation
          handleOTPValidation={handleOTPValidation} 
          number = {formParams.phoneNumber }
          email = {formParams.email}
          strict={false}
        /> :
        <Dialog open={modalOpen} aria-labelledby="form-dialog-title" maxWidth="lg">
          <DialogTitle id="responsive-dialog-title" sx={{display : 'flex', justifyContent : 'flex-end'}}>
            <IconButton onClick={() => setModalOpen(false)}><Close /></IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h4" align="center" style={{borderBottom : '1px solid'}}>Update Account Details</Typography>
            <HatcheryOwnerForm
              hatcheryData = {props.hatcheryData}
              handleFormSubmit={handleOwnerDetails}
              currentValues={currentValues} 
            />
          </DialogContent>
        </Dialog>
      }
      <Grid container direction="row" justifyContent="space-between" sx={{ p : 1 }} spacing={1} alignItems="center">
        <Grid item sm={8} xs={6}>
          <TableContainer sx={{ px: 2 }}>
            <Table size="small">
              <TableBody>
                <TableRow hover role="checkbox" tabIndex={-1} key={1}>
                  <TableCell key={'row-1-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Profile Type</p></TableCell>
                  <TableCell key={'row-1-col-1'} align="left">
                    {props.hatcheryData.userProfile.isHatcheryIncharge ? 'Incharge' : 'Owner'}
                  </TableCell>
                </TableRow>
                <TableRow hover role="checkbox" tabIndex={-1} key={1}>
                  <TableCell key={'row-2-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Full Name</p></TableCell>
                  <TableCell key={'row-2-col-1'} align="left">{props.hatcheryData.userProfile.name}</TableCell>
                </TableRow>
                <TableRow hover role="checkbox" tabIndex={-1} key={2}>
                  <TableCell key={'row-3-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Registered E-mail</p></TableCell>
                  <TableCell key={'row-3-col-1'} align="left">{obfuscateData("email", props.hatcheryData.email)}</TableCell>
                </TableRow>
                <TableRow hover role="checkbox" tabIndex={-1} key={3}>
                  <TableCell key={'row-4-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Registered Mobile</p></TableCell>
                  <TableCell key={'row-4-col-1'} align="left">{obfuscateData("phone", props.hatcheryData.userProfile.phoneNumber)}</TableCell>
                </TableRow>
                <TableRow hover role="checkbox" tabIndex={-1} key={4}>
                  <TableCell key={'row-5-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Hatchery Address</p></TableCell>
                  <TableCell key={'row-5-col-1'} align="left">{props.hatcheryData.address}</TableCell>
                </TableRow>
                <TableRow hover role="checkbox" tabIndex={-1} key={5}>
                  <TableCell key={'row-6-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Office Address</p></TableCell>
                  <TableCell key={'row-6-col-1'} align="left">{props.hatcheryData.address2}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {
            permissionCheck('hatchery', 'change-request') && props.printView === false &&
            <Box sx={{m : 2}}>
              <Button color="secondary" size="small" variant="outlined" fullWidth 
                onClick = { e => setModalOpen(true)}
              >
                Update Account Details 
              </Button>
            </Box>
          }
        </Grid>
        <Grid item sm={4} xs={6}>
          <Avatar 
            alt="Owner/Incharge Picture" 
            variant="rounded"
            src= {
              props.hatcheryData.userProfile.picture 
                ? process.env.REACT_APP_STRAPI_FILE_URL + props.hatcheryData.userProfile.picture + '?s=' + signature
                : process.env.PUBLIC_URL + "/static/images/misc/dummy.png"
            }              
            sx={{ width: 180, height: 180, border : "1px solid #ececec" }} 
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OwnerDetails;
