import { Paper, Typography } from '@mui/material';

import EntryTabularView from 'components/EntryTabularView';

const remittenceFields = [{
  'name' : 'name',
  'label' : 'Beneficiary Name',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'account_number',
  'label' : 'Virtual Account Number',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'ifsc',
  'label' : 'IFSC Code',
  'properties' : [],
  'actions' : ['view'],
}, {
  'name' : 'bank_name',
  'label' : 'Bank Name',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'account1',
  'label' : 'Allowed Account #1',
  'properties' : [],
  'actions' : ['view'],
}, {
  'name' : 'account2',
  'label' : 'Allowed Account #2',
  'properties' : [],
  'actions' : ['view'],
}];

const VirtualAccountCard = (props) => {
  let virtualAccount = props.hatcheryData ? props.hatcheryData.rzrpayVirtualAccount : null;
  if (virtualAccount) {
    if (virtualAccount.allowed_payers && virtualAccount.allowed_payers[0]) {
      virtualAccount.receivers[0].account1 = 
        `${virtualAccount.allowed_payers[0].bank_account.account_number} / ${virtualAccount.allowed_payers[0].bank_account.ifsc}`;
    } 
    if (virtualAccount.allowed_payers && virtualAccount.allowed_payers[1]) {
      virtualAccount.receivers[0].account2 = 
        `${virtualAccount.allowed_payers[1].bank_account.account_number} / ${virtualAccount.allowed_payers[1].bank_account.ifsc}`;
    } 
  }

  return (
    <>

      {
        virtualAccount ?
        <EntryTabularView 
          title="Payments NEFT / RTGS Details" 
          formFields={remittenceFields}
          noPaper={props.noPaper}
          values = {virtualAccount.receivers[0]} 
        /> : 
        <Paper elevation={props.noPaper ? 0 : 3} sx={{p:1, mt: 1, textAlign:"left"}}>
          <Typography variant="h4" color="error.main"> Action Required !!!! </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
          <Typography variant="subtitle1" color="primary.main" sx={{mt:1}} align="left"> 
            i. Payments are allowed through NEFT / RTGS from approved bank accounts ONLY. This hatchery does not have
            approved bank accounts. Please add bank account ( upto 2 accounts are allowed ). Once they are 
            approved, bookings can be done.
          </Typography>
          <Typography variant="subtitle1" color="primary.main" sx={{mt:1}} align="left"> 
            ii. Once back account details are approved, hatchery will be provided with a unique RGCA virtual account number
            to which, the payments can be made via NEFT / RTGS from the registered bank account. Virtual account
            information will appear here.
          </Typography>
        </Paper>
      }
    </>
  );
}

export default VirtualAccountCard;
