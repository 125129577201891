import React, { Component } from 'react';

// Material helpers
import {Box, Paper, Button, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

// Shared services
import { 
  processLoginReq, 
  processLoginResp, 
  verifyCaptchaInput
} from 'services/user';

import { fetchRolePermissions } from 'services/roles-permissions';
import { fetchHatcheryById } from 'services/hatcheries';

export default class SignInForm extends Component {

  constructor(props) {
    super(props);
    
    this.formFields = [
      {
        name: 'identifier',
        label: 'Email / Phone Number',
        type: 'string',
        properties: [{required:true}]
      },
      {
        name: 'password',
        label: 'Password',
        type: 'password',
        properties: [{required:true}]
      },
      {
        name: 'verifyCaptcha',
        label: 'Verify Captcha',
        type: 'captcha',
        callback : this.handleCaptchaValidation,
        properties: [{required:true}]
      }
    ];
    
    this.dashboardPath = "/aqf/dashboard";
    
    this.captchaValidated = false;
  }
  
  handleCaptchaValidation = async (name, value, formData, asyncFn) => {
    try {
      let response = await verifyCaptchaInput({captcha : value});
      if (response.data.success) this.captchaValidated = true;
      else console.log(response.data);
    } catch(error) {
      console.log(error);
    }
    
    return { fields : null, values : null };
  }
  
  handlePreFormSubmit = async (params) => {
    let statusMessage = { status : "success" };
    
    if (!this.captchaValidated) {
      statusMessage = { status : "error", message : "Captcha verification failed. Please validate captcha"};
    }
    
    return statusMessage;
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", errors: {} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Login successful. Redirecting now...." };
    
    // remove captcha data if present
    delete params.verifyCaptcha;
    
    try {
      const loginData = await processLoginReq(params);
        
      // update local storage
      processLoginResp(loginData.data);
      
      // fetch all the permissions of this user
      // const permissions = await fetchUserPermissions();
      
      // fetch new app level permissions
      const appPermissions = await fetchRolePermissions();
      
      // if user is a hatchery owner, fetch hatchery details
      if (loginData.data.user.role.name === 'Hatchery Owner') {
        const hatchery = await fetchHatcheryById(loginData.data.user.userProfile.hatcheryId);
      }
      
    } catch (error) {
      console.log(error);
      if (error.status === 400) {
        if (error.message && Array.isArray(error.message) &&
          error.message[0].messages && error.message[0].messages.length ) {
          statusMessage = { 
            status: "error", 
            message: error.message[0].messages[0].message === 'Identifier or password invalid.' 
              ?  'Incorrect username or password !' 
              : error.message[0].messages[0].message
          };
        } else if (error.message) {
           statusMessage = { status: "error", message: error.message};
        } else {
          statusMessage = { status: "error", message: 'Incorrect username or password !' };
        }
      } else {
        statusMessage = { status : "error", message : error.message };
      }
    }
    return statusMessage;
  }


  render() {
    const classes = this.props.classes;
    
    return (
      <Paper>
        <Box p={4}>
          <Typography align="center">SignIn With Your Mobile Number / Email </Typography><hr style={{borderColor:"#eef1f6"}} />
          <DynamicForm
            classes={classes}
            formFields={this.formFields}
            handleSubmit={this.handleFormSubmit}
            preFormSubmit={this.handlePreFormSubmit}
            buttonText="SIGN IN NOW"
            buttonWidth= "full"
            columns = {1}
            redirect={this.dashboardPath}
          />
          <Button onClick={this.props.handleForgotPassword} color="secondary" size="small" 
           style={{float : 'right'}}>Forgot Password ?</Button>
        </Box>
      </Paper>
    );
  }
}
