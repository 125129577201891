import React, { Component } from 'react';

import { Container, Dialog, DialogTitle, DialogContent, Typography, Box, Button } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { verifyOTPCode, createNewOTPForUser } from 'services/common';

export default class OTPValidation extends Component {

  static SEND_OTP_VIEW = 1;
  static VALIDATE_OTP_VIEW = 2;
  
  /*toggleModal = async (event, reason) => {
    if (["escapeKeyDown", "backdropClick"].includes(reason)) return; 
    await this.setState ( {modalOpen : !this.state.modalOpen } );
  }*/
  
  constructor(props) {
    super(props);
    this.otpSendForm = [
      {
        name: 'number',
        label: 'Mobile Number for OTP',
        type: 'string',
        default: props.number ? props.number : null,
        properties: [
          {required:true}, 
          {mobile : true},
          {string : { min : 10, max : 10}},
          {readOnly : (props.number && props.strict) ? true : false}
        ],
      }
    ];
    
    this.otpVerifyForm = [
      {
        name: 'number',
        label: 'Mobile Number for OTP',
        type: 'string',
        properties: [{readOnly:true}],
      },
      {
        name: 'OTP',
        label: 'Enter OTP Code',
        type: 'string',
        properties: [{required:true}]
      }
    ];
    
    this.state = { 
      view : props.number && props.strict ? OTPValidation.VALIDATE_OTP_VIEW : OTPValidation.SEND_OTP_VIEW, 
      number: props.number ? props.number : null, 
      modalOpen : true,
      submitStatus : props.number && props.strict 
        ? { status: "warning", message: "Requesting OTP on mobile number..." } 
        : null,
      timer : 300,
      statusMessage : {
        status : "info",
        message : "Please note OTP is sent to both mobile and registered e-mail. If SMS is not received on mobile, " + 
                  "please check your e-mail before retrying OTP"
      }
    };
    
    this.timerHandle = null;
  }
  
  async componentDidMount() {
    if (this.props.number && this.props.strict) {
      let statusMessage = await this.handleOTPSend({
        number : this.props.number
      });
      this.setState({statusMessage});
      
      if (this.timerHandle) {
        clearInterval(this.timerHandle);
      }
      
      this.timerHandle = setInterval(this.handleTimerInterval, 1000);
    }
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleOTPSend = async (params) => {
    let statusMessage = { status: "success", message: "Registration OTP sent to mobile number successfully !" };
    try {
      // todo
      params.email = this.props.email;
      let response = await createNewOTPForUser(params);
      
      this.setState({view : OTPValidation.VALIDATE_OTP_VIEW, number:params.number});
      
      if (this.timerHandle) {
        clearInterval(this.timerHandle);
      }
      this.timerHandle = setInterval(this.handleTimerInterval, 1000);
      
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
      
    }
    return statusMessage;
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleOTPVerify = async (params) => {
    let statusMessage = { status: "success", message: "OTP Verification successful"};
    try {
      // check if entered OTP is valid
      let response = await verifyOTPCode(params.number, params.OTP);
      if (this.props.handleOTPValidation) {
        await this.props.handleOTPValidation(params.number, params.OTP);
        this.setState({modalOpen : false});
      }
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
      
      // OTP not found case
      if (error.status === 404) {
        statusMessage.message = "Not a valid OTP. Please enter a valid OTP";
      }  
    }
    return statusMessage;
    
  }
      
  handleResendOTP  = async() => {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
    }
      
    this.setState({
      view : this.props.number && this.props.strict 
        ? OTPValidation.VALIDATE_OTP_VIEW 
        : OTPValidation.SEND_OTP_VIEW, 
      submitStatus : this.props.number && this.props.strict 
        ? { status: "warning", message: "Requesting OTP on mobile number..." } 
        : null,
      timer : 300
    });
  }
  
  handleTimerInterval = () => {
    if (this.state.timer > 0) {
      this.setState({ timer : this.state.timer -1 });
    } else {
      this.handleResendOTP();
    }
  }

  componentWillUnmount() {
   if (this.timerHandle) {
      clearInterval(this.timerHandle);
      this.timerHandle = null;
    }
  }
  
  render() {
   
    const minutes = Math.floor(this.state.timer / 60);
    const seconds = this.state.timer - (minutes * 60);
    
    return (
      <Dialog open={this.state.modalOpen} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle sx={{textAlign: "center"}}>OTP Verification</DialogTitle>
        <DialogContent> 
          {
            this.state.statusMessage &&
            <CustomSnackbar variant={this.state.statusMessage.status}
              message={this.state.statusMessage.message}
              open={this.state.statusMessage.status}
              onClose={() => this.setState({ statusMessage: null })}
            /> 
          }     
          <Container maxWidth="sm">  
          {
            this.state.view === OTPValidation.SEND_OTP_VIEW &&
            <DynamicForm
              formFields={this.otpSendForm}
              handleSubmit={this.handleOTPSend}
              buttonText="Click To Receive OTP"
              buttonWidth= "full"
              columns = {1}
              redirect={null}
              validateOnLoad={true}
            />
          }
          {
            this.state.view === OTPValidation.VALIDATE_OTP_VIEW &&
            <>
              <DynamicForm
                formFields={this.otpVerifyForm}
                handleSubmit={this.handleOTPVerify}
                buttonText="Click to Verify OTP"
                buttonWidth= "full"
                currentValues={{number : this.state.number}}
                columns = {1}
                redirect={null}
              />
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography noWrap variant="h6" color="error.light"> 
                  OTP expires in {`0${minutes}:${seconds >= 10 ? seconds : '0' + seconds} minutes`} 
                </Typography>
                 <Button 
                    onClick={this.handleResendOTP} 
                    startIcon={<ChevronLeft />}
                    variant="contained"
                    color="secondary" size="small" sx={{ml : 2}}
                  > Resend OTP ?
                  </Button>
              </Box> 
            </>
          }
          </Container>
        </DialogContent>
      </Dialog>  
    );
  }
}