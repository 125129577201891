import React, { Component } from 'react';

import { 
  Paper, Typography, TextField, Button, Grid, MenuItem,
  Table, TableHead, TableBody, TableRow, TableCell 
} from '@mui/material';
  
import CustomSnackbar from 'components/CustomSnackbar';

import { fetchBookingLimits } from 'services/bookings';
import { HatcheryStore } from 'store';

export default class CheckFYQuota extends React.Component {
  
  state = { fyLimitData : null, fyear : 0, statusMessage : null};
 
  constructor(props) {
    super(props);
    
    let currentYear = (new Date()).getFullYear();
    let range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    this.filterValues = range(currentYear - 5, currentYear + 1, 1).map( year => {return {'id' : year, 'value' : `${year}-${year + 1}`}});
  }
  
  handleFyearChange = async(e) => {
    
    const fyear = e.target.value;
    const currentHatchery = HatcheryStore.state.currentHatchery;
    
    this.setState({ 
      fyear : e.target.value,
      statusMessage : { status : "warning", message : "Fetching hatchery import balance for the selected date..."} 
    });
    
    try {
      const response = await fetchBookingLimits(currentHatchery.id, fyear, null);
      this.setState({
        statusMessage : { status : "success", message : "Successfully fetched hatchery import balance for the selected date" },
        fyLimitData : {
          fyStockLimit : response.data.fyStockLimit,
          fyStockImported : response.data.fyStockImported,
          fyStockBalance : response.data.fyStockBalance,
          fyear : response.data.fyear,
          license : response.data.license
        }
      });
    } catch (error) {
      console.log(error);
      this.setState({
        statusMessage : {status : "error", message : error.message},
        fyLimitData : null
      });
    }
  }
  
  render() {
    return(
      <Paper elevation={3} sx={{ mt:1, p : 2}}>
        <Grid container spacing={3} justifyContent="space-between" justify="center">
          <Grid key="0-firstgrid" item xs={12} sm={3}>
            {
              this.state.statusMessage &&
              <CustomSnackbar variant={this.state.statusMessage.status}
                message={this.state.statusMessage.message}
                open={this.state.statusMessage.status}
                onClose={async () => await this.setState({ statusMessage: null })}
              />
            }
            <Typography variant="h5" p={1}> Check FY Import Limits </Typography>
            <hr style={{borderColor:"#eef1f6"}} /><br/>
            
            <TextField
              id="bookingDate"
              label=""
              name="bookingDate"
              size="small"
              fullWidth={true}
              type="text"
              select
              value={this.state.fyear}
              onChange={this.handleFyearChange}
            >
              <MenuItem key={0} value={0}>{'-- Select FY --'}</MenuItem>
              {
                this.filterValues.map((opt, i) => {
                  return (<MenuItem key={i+1} value={opt.id}>{opt.value}</MenuItem>);
                })
              }
            </TextField>
          </Grid>
          <Grid key="1-firstgrid" item xs={12} sm={9}>
          {
            this.state.fyLimitData &&
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell key="limits-1x"> Financial Year </TableCell>
                  <TableCell key="limits-2x"> CAA Registation No.</TableCell>
                  <TableCell key="limits-3x">Valid From</TableCell>
                  <TableCell key="limits-4x">Valid To</TableCell>
                  <TableCell key="limits-5x"> Import Limit </TableCell>
                  <TableCell key="limits-6x"> Total Imported </TableCell>
                  <TableCell key="limits-7x"> FY Balance </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell key="limits-1y">{this.state.fyLimitData ? this.state.fyLimitData.fyear : '--'}</TableCell>
                  <TableCell key="limits-2y">{this.state.fyLimitData ? this.state.fyLimitData.license.CAARegistrationNumber : '--'}</TableCell>
                  <TableCell key="limits-3y">{this.state.fyLimitData ? this.state.fyLimitData.license.validFrom : '--'}</TableCell>
                  <TableCell key="limits-4y">{this.state.fyLimitData ? this.state.fyLimitData.license.validTo : '--'}</TableCell>
                  <TableCell key="limits-5y">{this.state.fyLimitData ? this.state.fyLimitData.fyStockLimit : '--'}</TableCell>
                  <TableCell key="limits-6y">{this.state.fyLimitData ? this.state.fyLimitData.fyStockImported : '--'}</TableCell>
                  <TableCell key="limits-7y">{this.state.fyLimitData ? this.state.fyLimitData.fyStockBalance : '--'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          }
          </Grid>
        </Grid>
      </Paper>
    );
  }
}