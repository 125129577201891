import React, { Component } from 'react';

import { FormValidator, FieldCreator, generateFieldRules, ImageCrop } from './components/';

import { withNavigator } from 'hoc';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';
import ConfirmationButton from 'components/ConfirmationButton';

// Aws S3 image uploads
import {convertFileToBlob, uploadImgToBackend, removeImgFromBackend} from 'services/upload';

// Material components/
import { Button, Grid, Typography} from '@mui/material';

class DynamicForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isImgUploading:false,
      validation: props.validation ? props.validation : { isValid: false },
      submitStatus: null,
    }

    this.__mounted = false;
    this.__touched = false;
    
    // field meta info
    this.meta = {};
    
    // list of fields in the order to be rendered.
    this.inputFields = [];

    // start with empty validationRules
    this.validator = {};
    this.validationRules = [];

    // various form action states
    this.errorsOnSubmit = props.validation  ? true : false;
  
  }
  
  async initializeStateFromProps(formFields, fieldCallback=false) {
    
    let fieldState = this.state;
    this.inputFields = [];
    this.validationRules = [];

    // add each field into the state and meta
    formFields.forEach((f) => {

      // skip fields not relevant to this action (create/edit)
      if (f.actions) {
        let actions = f.actions;
        if (!Array.isArray(actions)) {
          actions = actions.split(/,| /);
        }
        if (!actions.find(action => action === this.props.action)) {
          return;
        }
      }
      
      this.inputFields.push({
        'name': f.name,
        'type': f.type,
        'label': f.label,
        'callback' : f.callback,
        'selectOptions':
          (f.selectOptions != null) ? f.selectOptions : undefined
      });
      
      // for header fields do not maintain state
      if (f.type === 'header') return;
      
      // date and datetime fields have default values always
      if (this.meta[f.name] === undefined) {
        this.meta[f.name] = { touched : false };
      }

      if (f.properties) {
        generateFieldRules(f, this.validationRules);
      }
      
      // do not use set state until all fields are read
      if (f.properties.find(prop => prop.readOnly === true) || fieldState[f.name] === undefined) {
        
        fieldState[f.name] = f.hasOwnProperty('default') ? f.default : '';
        
        if (this.props.currentValues && 
          this.props.currentValues.hasOwnProperty(f.name) && 
          this.props.currentValues[f.name] != null) {
          
          if (['image', 'file'].includes(f.type) && !Array.isArray(this.props.currentValues[f.name])) {
            // file and images are arrays
            fieldState[f.name] = [];
            fieldState[f.name].push(this.props.currentValues[f.name]);
          } else {
            // update field value from current value props if valid
            fieldState[f.name] = this.props.currentValues[f.name];
          }
        }
      }
        
      // if select options have changed, and current field state does not reflect values in the new list
      // reset the field state
      if (fieldCallback && fieldState[f.name] && f.selectOptions && f.selectOptions[fieldState[f.name]] === undefined) {
        fieldState[f.name] = '';
      }
      
    });
    
    this.validator = new FormValidator(this.validationRules, formFields);
    
    this.errorsOnSubmit = this.props.validation ? true : false;
    
    await this.setState(fieldState);
    
    if (this.props.validateOnLoad || this.props.action === 'update') {
      await this.triggerFormValidation();
    }
  }
  
  async componentDidMount() {
    this.__mounted = true;
    
    // initialize state from props
    if (this.inputFields.length === 0) {
      await this.initializeStateFromProps(this.props.formFields, false);
    }
  }
  
  componentWillUnmount() {
    this.__mounted = false;
  }
  
  async componentDidUpdate( prevProps, prevState ) {
    
    if (!this.__mounted) return;
    
    // initialize state from props if form current values changes.
    if (this.inputFields.length === 0 || 
      (prevProps.currentValues === null && this.props.currentValues !== null)) {
      await this.initializeStateFromProps(this.props.formFields, false);
      return;
    }
  }
  
  fieldCbAsyncUpdate = async (values) => {
    let currentState = this.state;
    
    Object.keys(values).map ( key => {
      currentState[key] = values[key];
    });
    
    this.setState(currentState);
    
    await this.triggerFormValidation();
  }

  
  handleInputChange = async (event, name) => {
    let field = this.inputFields.find(f => f.name === name);
    let value, formData = this.state;
	
    this.meta[name].touched = true;
    this.__touched = true;
     
    // captcha special handling
    if (field.type === 'captcha') {
      value = event;
    } else if (field.type === 'datetime') {
      // convert time UTC timezone always
      event.preventDefault();
      value = new Date(event.target.value).toISOString();
    } else {
      event.preventDefault();
      value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    }
    
    formData[name] = value;
   
    if (field && field.callback) {
      let {fields, values} = await field.callback(name, value, formData, this.fieldCbAsyncUpdate);
      if (fields) {
        await this.initializeStateFromProps(fields, true);
      }
      
      if (values) {
        this.setState(values);
      } else {
        this.setState({[name]: value});
      }
    } else {
      this.setState({[name]: value});
    }

    await this.triggerFormValidation();
  }
  
  // to validate file size and extensions allowed
  validateFileTypeRules (e) {
    let validation = {isValid: true};
    let formField = this.props.formFields.find( f => f.name === e.target.name );
    
    if (!formField) return validation;
    
    for (let i = 0; i < e.target.files.length; ++i) {
      if (formField.properties.find(p => p.filesize !== undefined)) {
        validation = this.validator.validateSingleRule(
                      formField.name,
                      'isFileSizeMb',
                      e.target.files[i].size);
      }
      
      if (validation.isValid &&
        formField.properties.find(p => p.filetype !== undefined)) {
        validation = this.validator.validateSingleRule(
                    formField.name,
                    'isFileType',
                    e.target.files[i].name);
      }
      
      if (!validation.isValid) return validation;
    }
    
    return validation;
  }
  
  handleFileInputChange = async (event, name) => {
    
    let fieldname = event.target.name;
    let validation = this.state.validation;
    let fieldValidation = this.validateFileTypeRules(event);
    
    this.meta[fieldname].touched = true;
    this.__touched = true;
    
    validation[event.target.name] = fieldValidation[event.target.name];
    let fileData = event.target.files;
 
    if (fieldValidation.isValid) {
      
      for (let i = 0; i < fileData.length; ++i) {
        fileData[i].blob = await convertFileToBlob(event.target.files[i]);
        
        if (this.props.currentValues && this.props.currentValues[fieldname]) {
          if (Array.isArray(this.props.currentValues[fieldname])) {
            fileData[i].oldUrl = this.props.currentValues[fieldname][i];
          } else {
            fileData[0].oldUrl = this.props.currentValues[fieldname];
          }
        }
      }
      this.state[fieldname] = fileData && fileData.length ? fileData : '';
      this.state.validation = validation;
      
      await this.triggerFormValidation();
      
    } else {
      // update form validation status
      validation.isValid = false;
      await this.setState({validation:validation});
    }
  }

  handleImageCropChange = async (fieldname, newImage) => {
    this.meta[fieldname].touched = true;
    let fileData = [];
    
    // keep track of old image for deletion
    if (this.props.currentValues && this.props.currentValues[fieldname]) {
      fileData.push({blob : newImage, oldUrl : this.props.currentValues[fieldname]});
    } else {
      fileData.push({blob : newImage});
    }
    
    await this.setState({[fieldname]: fileData});
    
    await this.triggerFormValidation();
  };

  handleEditorChange = async (event, name) => {
    await this.setState({
      [name]: event.editor.getData(),
    });

    await this.triggerFormValidation();
  }

  triggerFormValidation = async () => {
    // pass meta to validate only touched input fields.
    const validation = this.validator.validate(this.state);
    await this.setState({ validation });
  };
  
  handleFormSubmit = async (event) => {

    event.preventDefault();
    
    let formData = {};
    
    this.errorsOnSubmit = false;

     // handle actual form submission here
    if (this.state.validation.isValid) {

      if (this.props.preFormSubmit) {
        let result = await this.props.preFormSubmit(this.state);
        if (result.status !== "success") {
          this.errorsOnSubmit = true;
          return this.setState({
            isLoading: false, 
            submitStatus: result, 
            validation : result.data && result.data.isValid === false ? result.data : this.state.validation
          });
        }
      }
      
      // compose form data and submit
      let fileUploadErrors = null;
      try {
        
        await this.asyncForEach(this.inputFields, async (f) => {
          let fieldname = f.name;
          
          if (f.type === 'header') return;

          // if field is a image, and needs to be uploaded
          if (this.props.uploadFiles && (f.type === "image" || f.type === "file")) {
            
            if (this.state[f.name] && this.meta[f.name].touched) {
              await this.setState({isImgUploading:true}); 
              
              // upload new image only when there is change
              let files = this.state[fieldname];
              let fileUploaded = false;
              
              for (let i = 0; i < files.length; ++i) {
              
                if (files[i].blob) {
                  let filename = this.props.imageDir ? `${this.props.imageDir}${files[i].name}` : files[i].name;
                
                  // src should be a blob.
                  let response = await uploadImgToBackend(files[i].blob, filename, files.length > 1);
                  if (response.status === 'success') {
                    files[i].fileUrl = response.url;
                    fileUploaded = true;
                    
                    // delete old file if exists
                    if (files[i].oldUrl) {
                      await removeImgFromBackend(files[i].oldUrl);
                    }
                    
                  } else {
                    fileUploadErrors = response.message ? response.message : 'Failed to upload files to server...';
                  }
                }
              }
              
              // set form data as array for multiple file urls or string url for single file
              if (fileUploaded) {
                 if(files.length === 1) {
                  this.state[fieldname] = files[0].fileUrl;
                } else {
                  this.state[fieldname] = [];
                  for (let i = 0; i < files.length; ++i) {
                    this.state[fieldname][i] = files[i].fileUrl;
                  }
                }
              }
            }
            
            formData[fieldname] = this.state[fieldname].length === 1 ? this.state[fieldname][0] : this.state[fieldname];
          } else {
            formData[fieldname] = this.state[f.name];
          }
        });
      } catch(error) {
        console.log(error);
        fileUploadErrors = 'Failed to upload files to server...';
      }

      if (fileUploadErrors) {
        return await this.setState({isLoading: false, 
          isImgUploading : false,
          submitStatus: {status: "error", message: fileUploadErrors}
        });
      }
      
      await this.setState({submitStatus : {status: "warning", message: "Please wait while the request is being processed..."},
                isLoading: true, isImgUploading:false});

      // call parent submit action. if parent submit action returns 
      // validation errors in the data object capture them in validation
      //
      let response = await this.props.handleSubmit(formData);
      
      if (response.data && response.data.isValid === false) {
        this.errorsOnSubmit = true;
        await this.setState({ 
          isLoading: false, 
          submitStatus:  { status : response.status, message : response.message },
          validation : response.data
        });
      } else {
        // form submission successful
        if (this.__mounted) {
          await this.setState({ 
            isLoading: false, 
            submitStatus: { status : response.status, message : response.message }
          });
        }
      }
      
      if (response.status === "success" && this.props.redirect) {
        this.props.navigate(this.props.redirect, {replace:true});
      }
    }
  }

  isFieldError(field, validation) {
    
    const initValue = (this.props.action === 'update' || this.props.validateOnLoad)
      && this.state[field]
      && this.state[field].toString().length;
    
    let fieldChanged = initValue || this.errorsOnSubmit || this.meta[field].touched;
      
    return (fieldChanged && validation[field] && validation[field].message !== '');
  }

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  render() {

    const classes = this.props.classes;
    const validation = this.state.validation;
    
    // set default 2 column layout
    let columns = this.props.columns ? this.props.columns : 2;
    return (
      <React.Fragment>
        <form style={{padding : '16px'}}>
          <div>
            {
              (this.state.submitStatus && this.state.submitStatus.message) &&
              <CustomSnackbar variant={this.state.submitStatus.status}
                message={this.state.submitStatus.message}
                open={this.state.submitStatus.status}
                onClose={() => this.setState({ submitStatus: null })}
              />
            }
            
            <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
            { /* iterate and render all fields. Use meta data */
              this.inputFields.map((f, i) => {
                let formfield = this.props.formFields.find(field => field.name === f.name);

                if (formfield === undefined) return null;

                // check if a field is readonly from form field properties or 
                // readonly field data passed
                //
                let readonly =  Boolean(formfield.properties.find(p => p.readOnly)) ||
                        (this.props.readOnlyFields ? 
                         this.props.readOnlyFields.indexOf(f.name) >= 0 :
                         false);
                         
                let isRequired = formfield.properties && formfield.properties.length > 0 &&
                  formfield.properties.findIndex(p => p.required !== undefined && (p.required === true || p.required === 'true')) >= 0;
                         
                if (formfield.type === 'header') {
                  return (
                    <Grid key={i} item xs={12} sm={12} md={12}>
                      <Typography variant="h4" align="left">{formfield.label}</Typography><hr style={{borderColor:"#eef1f6"}} />
                    </Grid>);
                }
                
                return (
                  <Grid key={i} item xs={12} sm={formfield.type === 'text' ? 12 : 12/columns} 
                    md={formfield.type === 'text' ? 12 : 12/columns}>
                  {   
                    formfield.type === 'image' && formfield.properties &&
                    formfield.properties.find(p => p.crop !== undefined) ?
                    <ImageCrop
                      classes={classes}
                      formField={formfield}
                      action={this.props.action}
                      isHtmlInput={true}
                      onSave={this.handleImageCropChange}
                      value={this.state[f.name]}
                      readOnly = {readonly}
                    /> :
                    
                    <FieldCreator
                      key={'key_' + i}
                      name={f.name}
                      type={f.type}
                      label={f.label}
                      selectOptions={f.selectOptions}
                      selectMultiple={formfield.properties.find(p => p.multiple !== undefined)}
                      value={this.state[f.name]}
                      onChange={this.handleInputChange}
                      onEditorChange={this.handleEditorChange}
                      onFileInputChange={this.handleFileInputChange}
                      error={this.isFieldError(f.name, validation)}
                      helperText={this.isFieldError(f.name, validation) ? validation[f.name].message : ''}
                      readOnly = {readonly}
                      required = {isRequired}
                      valueStrings={formfield.valueStrings}
                      numberProps={(formfield.properties.find(p => p.number !== undefined) || {}).number}
                      floatProps={(formfield.properties.find(p => p.float !== undefined) || {}).float}
                    />
                  }
                  </Grid>              
                )
              })
            }
            </Grid>
          </div><br/>
          {
            this.props.confirmation ? 
            (<ConfirmationButton
              fullWidth = {this.props.buttonWidth === undefined ||  this.props.buttonWidth === 'full' ? true: false}
              color = {this.props.buttonColor === undefined ? "primary" : this.props.buttonColor }
              disabled={!this.__touched || !validation.isValid || this.state.isLoading || this.state.isImgUploading}
              onClick={this.handleFormSubmit}
              confirmation={this.props.confirmation}
              buttonText = {this.state.isImgUploading 
                ? "Uploading File(s)..." 
                : this.state.isLoading ? "Please Wait..." : this.props.buttonText
              }
            />) : 
            (<Button
              fullWidth = {this.props.buttonWidth === undefined ||  this.props.buttonWidth === 'full' ? true: false}
              type = 'submit'
              color = {this.props.buttonColor === undefined ? "primary" : this.props.buttonColor }
              disabled={!this.__touched || !validation.isValid || this.state.isLoading || this.state.isImgUploading}
              onClick={this.handleFormSubmit}
              onSubmit={this.handleFormSubmit}
              size="large"
              variant="contained"
            >
              {this.state.isImgUploading ? "Uploading File(s)..." : 
                this.state.isLoading ? "Please Wait..." : this.props.buttonText}
            </Button>)
          }
        </form>
      </React.Fragment>
    );
  }
}

DynamicForm.defaultProps = {
  data: [],
  onSelect: () => { },
  onShowDetails: () => { },
  formFields: []
};

export default withNavigator(DynamicForm);
