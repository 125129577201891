import React, { Component } from 'react';

// Material components/
import { Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { sampleCollectionFields } from './sample-collection.fields';

import { updateSampleCollection } from "services/sample-collection";

export default class EditSampleCollection extends Component {
  constructor(props) {
    super(props);
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated sample collection details..." };
    
    // todo API calls
    try {
      let response = await updateSampleCollection(this.props.currentValues.id, params);
    } catch(error) {
      console.log(error)
      statusMessage = { status: "error", message : error.message };
    }
    
    // refresh the current page
    window.location.reload();
    
    return statusMessage;
  }

  render() {
    return (
      <>
        <Typography variant="h4"> Update Sample Collection Details </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
        <DynamicForm
          formFields={sampleCollectionFields}
          action={this.props.currentValues ? "update" : "create"}
          handleSubmit={this.handleFormSubmit}
          buttonText="Save Sample Collection Details"
          buttonWidth= "full"
          columns = {1}
          currentValues={this.props.currentValues}
          redirect={this.props.redirectUrl}
        />
      </>
    );
  }
}