import React, { Component } from 'react';

import { FormValidator, FieldCreator, generateFieldRules, ImageCrop, TableFormHelper } from './components/';

import { withNavigator } from 'hoc';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

// Aws S3 image uploads
import {convertFileToBlob, uploadImgToBackend, removeImgFromBackend} from 'services/upload';

// Material components/
import { Button, Grid, Typography} from '@mui/material';

class DynamicTableForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isImgUploading:false,
      validation: props.validation ? props.validation : { isValid: false },
      submitStatus: null,
      tableData : [],
      tableValidation : props.tableFormProps.validation ? props.tableFormProps.validation : null,
      tableSubmit : false,
      showTableValidations : props.tableFormProps.validation ? true : false
    }
    
    this.__mounted = false;
    this.__touched = false;

    // field meta info
    this.meta = {};
    
    // list of fields in the order to be rendered.
    this.inputFields = [];

    // start with empty validationRules
    this.validator = {};
    this.validationRules = [];
    
    // various form action states
    this.errorsOnSubmit = this.props.validation ? true : false;
  }

  async initializeStateFromProps(formFields, fieldCallback=false) {
    let fieldState = this.state;
    this.inputFields = [];
    this.validationRules = [];

    // add each field into the state and meta
    formFields && formFields.forEach((f) => {

      // skip fields not relevant to this action (create/edit)
      if (f.actions) {
        let actions = f.actions;
        if (!Array.isArray(actions)) {
          actions = actions.split(/,| /);
        }

        if (!actions.find(action => action === this.props.action)) {
          return;
        }
      }
      
      this.inputFields.push({
        'name': f.name,
        'type': f.type,
        'label': f.label,
        'callback' : f.callback,
        'selectOptions':
          (f.selectOptions != null) ? f.selectOptions : undefined
      });
      
      // for header fields do not maintain state
      if (f.type === 'header') return;
      
      // date and datetime fields have default values always
      if (this.meta[f.name] === undefined) {
        this.meta[f.name] = { touched : false };
      }
      
      // populate required validation rules for the field
      if (f.properties) {
        generateFieldRules(f, this.validationRules);
      }
      
      // do not use set state until all fields are read
      if (f.properties.find(prop => prop.readOnly === true) || fieldState[f.name] === undefined) {
        
        fieldState[f.name] = f.hasOwnProperty('default') ? f.default : '';
        
        if (this.props.currentValues && 
          this.props.currentValues.hasOwnProperty(f.name) && 
          this.props.currentValues[f.name] != null) {
          
          if (['image', 'file'].includes(f.type) && !Array.isArray(this.props.currentValues[f.name])) {
            // file and images are arrays
            fieldState[f.name] = [];
            fieldState[f.name].push(this.props.currentValues[f.name]);
          } else {
            // update field value from current value props if valid
            fieldState[f.name] = this.props.currentValues[f.name];
          }
        }
      }
        
      // if select options have changed, and current field state does not reflect values in the new list
      // reset the field state
      if (fieldCallback && fieldState[f.name] && f.selectOptions && f.selectOptions[fieldState[f.name]] === undefined) {
        fieldState[f.name] = '';
      }
    });

    this.validator = new FormValidator(this.validationRules, formFields);
    
    // various form action states
    this.errorsOnSubmit = this.props.validation ? true : false;
    
    await this.setState(fieldState);
    
    if (this.props.validateOnLoad || this.props.action === 'update') {
      await this.triggerFormValidation();
    }
  }
  
  async componentDidMount() {
    
    this.__mounted = true;
    
    // initialize state from props
    if (this.inputFields.length === 0) {
      await this.initializeStateFromProps(this.props.formFields, false);
    }
  }
  
  async componentDidUpdate( prevProps, prevState ) {
    
    if (!this.__mounted) return;
    
    // initialize state from props if form current values changes.
    if ((this.inputFields.length === 0 && !this.props.tableFormProps) || 
      (prevProps.currentValues === null && this.props.currentValues !== null)) {
      await this.initializeStateFromProps(this.props.formFields, false);
      return;
    }
  }

  componentWillUnmount() {
    this.__mounted = false;
  }
  
  fieldCbAsyncUpdate = async (formData) => {
    let currentState = this.state;
    
    Object.keys(formData).map ( key => {
      currentState[key] = formData[key];
    });
    
    this.setState(currentState);
    
    await this.triggerFormValidation();
  }
  
  handleInputChange = async (event, name) => {
    let field = this.inputFields.find(f => f.name === name);
    let value, formData = this.state;
   
    this.meta[name].touched = true;
    this.__touched = true;
    
    // captcha special handling
    if (field.type === 'captcha') {
      value = event;
    } else if (field.type === 'datetime') {
      // convert time UTC timezone always
      event.preventDefault();
      value = new Date(event.target.value).toISOString();
    } else {
      event.preventDefault();
      value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    }
    
    formData[name] = value;
    
    
    if (field && field.callback) {
      let {fields, values} = await field.callback(name, value, formData, this.fieldCbAsyncUpdate);
      if (fields) {
        await this.initializeStateFromProps(fields, true);
      }
      if (values) {
        this.setState(values);
      } else {
        this.setState({[name]: value});
      }
    } else {
      this.setState({[name]: value});
    }
    
    await this.setState({[name]: value});
    await this.triggerFormValidation();
  }
  
  // to validate file size and extensions allowed
  validateFileTypeRules (e) {
    let validation = {isValid: true};
    let formField = this.props.formFields.find( f => f.name === e.target.name );
    
    if (!formField) return validation;
    
    for (let i = 0; i < e.target.files.length; ++i) {
      if (formField.properties.find(p => p.filesize !== undefined)) {
        validation = this.validator.validateSingleRule(
                      formField.name,
                      'isFileSizeMb',
                      e.target.files[i].size);
      }
      
      if (validation.isValid &&
        formField.properties.find(p => p.filetype !== undefined)) {
        validation = this.validator.validateSingleRule(
                    formField.name,
                    'isFileType',
                    e.target.files[i].name);
      }

      if (!validation.isValid) return validation;
    }
    
    return validation;
  }

  handleFileInputChange = async (event, name) => {
    
    let fieldname = event.target.name;
    let validation = this.state.validation;
    let fieldValidation = this.validateFileTypeRules(event);
    
    this.meta[fieldname].touched = true;
    this.__touched = true;
    
    validation[event.target.name] = fieldValidation[event.target.name];
    let fileData = event.target.files;
    
    if (fieldValidation.isValid) {
      
      for (let i = 0; i < fileData.length; ++i) {
        fileData[i].blob = await convertFileToBlob(event.target.files[i]);
        
        if (this.props.currentValues && this.props.currentValues[fieldname]) {
          if (Array.isArray(this.props.currentValues[fieldname])) {
            fileData[i].oldUrl = this.props.currentValues[fieldname][i];
          } else {
            fileData[0].oldUrl = this.props.currentValues[fieldname];
          }
        }
      }
     
      this.state[fieldname] = fileData && fileData.length ? fileData : '';
      this.state.validation = validation;
      
      await this.triggerFormValidation();
    } else {
      // update form validation status
      validation.isValid = false;
      await this.setState({validation:validation});
    }
  }

  handleImageCropChange = async (fieldname, newImage) => {
    this.meta[fieldname].touched = true;
    this.__touched = true;
    let fileData = [];
    
    // keep track of old image for deletion
    if (this.props.currentValues && this.props.currentValues[fieldname]) {
      fileData.push({blob : newImage, oldUrl : this.props.currentValues[fieldname]});
    } else {
      fileData.push({blob : newImage});
    }
    
    await this.setState({[fieldname]: {blob : newImage, oldUrl : this.state[fieldname]}});
    
    await this.triggerFormValidation();
  };

  handleEditorChange = async (event, name) => {
    await this.setState({
      [name]: event.editor.getData(),
    });

    await this.triggerFormValidation();
  }

  triggerFormValidation = async () => {
    // pass meta to validate only touched input fields.
    const validation = this.validator.validate(this.state);
    await this.setState({ validation });
  };
  
  handleTableChange = async (tableData, validation) => {
    if (this.props.tableFormProps && 
      this.props.tableFormProps.handleTableDataChange) {
      this.props.tableFormProps.handleTableDataChange(tableData, validation);
    }
    this.__touched = true;
    await this.setState({tableData : tableData, tableValidation : validation});
  }
  
  handleTableFormFileUploads = async () => {
    
    // take care of all file uploads from table form here !!!
    let tableData = this.state.tableData;
    let fileUploadErrors = null;
    
    try {
      await this.asyncForEach(this.props.tableFormProps.fields, async (f) => {
        let fieldname = f.name;
        
        if (f.type === 'header') return;
     
        // if field is a image, and needs to be uploaded
        if (f.type === "image" || f.type === "file") {
     
          await this.setState({isImgUploading:true});
          
          for (let i = 0; i < tableData.length ; ++i) {
            if (tableData[i][f.name]) {
              // upload new image only when there is change
              let files = tableData[i][f.name];
              let fileUploaded = false;
              
              for (let j = 0; j < files.length; ++j) {
                
                if (files[j].blob) {
                  let filename = this.props.imageDir ? `${this.props.imageDir}${files[j].name}` : files[j].name;
                  
                  // src should be a blob.
                  let response = await uploadImgToBackend(files[j].blob, filename, files.length > 1);
                  if (response.status === 'success') {
                    files[j].fileUrl = response.url;
                    fileUploaded = true;
                    
                    // delete old file if exists
                    if (files[j].oldUrl) {
                      await removeImgFromBackend(files[j].oldUrl);
                    }
                    
                  } else {
                    fileUploadErrors = response.message ? response.message : 'Failed to upload files to server...';
                  }
                } else {
                  // no change in table data field
                }
              }
              
              // set form data as array for multiple file urls or string url for single file
              if (fileUploaded) {
                if(files.length === 1) {
                  tableData[i][f.name] = files[0].fileUrl;
                } else {
                  this.state[fieldname] = [];
                  for (let k = 0; k < files.length; ++k) {
                    tableData[i][f.name][k] = files[k].fileUrl;
                  }
                }
              }
            }
          }
        }
      });
    } catch(error) {
      console.log(error);
      fileUploadErrors = 'Failed to upload files to server...';
    }
    
    if (fileUploadErrors) {
      this.setState({
        isLoading: false, 
        isImgUploading : false,
        tableData : tableData,
        submitStatus: {status: "error", message: fileUploadErrors}
      });
    } else {
      this.setState({tableData : tableData, isImgUploading:false });
    }
    
    return fileUploadErrors;
  }
  
  triggerFormSubmit = async () => {
    
    console.log("started actual form submission");
    
    // compose form data and submit
    let fileUploadErrors = false;
    let formData = {};
    
    try {
      await this.asyncForEach(this.inputFields, async (f) => {
        let fieldname = f.name;
        // if field is a image, and needs to be uploaded
        if (this.props.uploadFiles && (f.type === "image" || f.type === "file")) {
          if (this.state[f.name] && this.meta[f.name].touched) {
            await this.setState({isImgUploading:true});  
            
            // upload new image only when there is change
            let files = this.state[fieldname];
            let fileUploaded = false;
            
            for (let i = 0; i < files.length; ++i) {
              if (files[i].blob) {
                let filename = this.props.imageDir ? `${this.props.imageDir}${files[i].name}` : files[i].name;
              
                // src should be a blob.
                let response = await uploadImgToBackend(files[i].blob, filename, files.length > 1);
                if (response.status === 'success') {
                  files[i].fileUrl = response.url;
                  fileUploaded = true;
                  
                  // delete old file if exists
                  if (files[i].oldUrl) {
                    await removeImgFromBackend(files[i].oldUrl);
                  }                                
                } else {
                  fileUploadErrors = true;
                }
              }
            
              // set form data as array for multiple file urls or string url for single file
              if (fileUploaded) {
                 if(files.length === 1) {
                  this.state[fieldname] = files[0].fileUrl;
                } else {
                  this.state[fieldname] = [];
                  for (let i = 0; i < files.length; ++i) {
                    this.state[fieldname][i] = files[i].fileUrl;
                  }
                }
              } 
            }
          }
          formData[fieldname] = this.state[fieldname].length === 1 ? this.state[fieldname][0] : this.state[fieldname];
        } else {
          formData[fieldname] = this.state[f.name];
          await this.setState({isImgUploading:false});  
        }
      });
    } catch(error) {
      console.log(error);
      fileUploadErrors = true;
    }

    if (fileUploadErrors) {
      return await this.setState({isLoading: false, 
        isImgUploading : false,
        submitStatus: {status: "error", message:"Failed to upload files to server..."}
      });
    }
    
    await this.setState({
      submitStatus : {status: "warning", message: "Please wait while the request is being processed..."},
      isLoading: true, 
      isImgUploading:false
    });

    // call parent submit action. if parent submit action returns 
    // validation errors in the data object capture them in validation
    //
    let response = await this.props.handleSubmit(formData, this.state.tableData);
    let invalid = (response.data && response.data.isValid === false) || 
                   (response.tableData && response.tableData.isValid === false);
   
    if (invalid) {
      this.errorsOnSubmit = true;
      await this.setState({ 
        isLoading: false, 
        submitStatus:  { status : response.status, message : response.message },
        validation : response.data,
        tableValidation : response.tableData,
        showTableValidations : true
      });
    } else {
      if (this.__mounted) {
        await this.setState({ isLoading: false, submitStatus: response});
      }
    }
    
    if (response.status === "success" && this.props.redirect) {
      this.props.navigate(this.props.redirect, {replace:true});
    }
  }
  
  handleFormSubmit = async (event) => {

    let tableFormFileUploadError = false;
    event.preventDefault();
    
    // check if table rows submitted is meeting min and max row criteria
    let countRows = this.state.tableData.filter(x => x.hasOwnProperty('_isDeleted') === false).length;
    if (this.props.tableFormProps.minRows && countRows < this.props.tableFormProps.minRows) {
      return this.setState({
        isLoading: false, 
        submitStatus: { 
          status : "error", 
          message : `Total table rows should be minimum ${this.props.tableFormProps.minRows}`
        }
      });
    }
    if(this.props.tableFormProps.maxRows && countRows > this.props.tableFormProps.maxRows) {
      return this.setState({
        isLoading: false, 
        submitStatus: { 
          status : "error", 
          message : `Total table rows should not exceed maximum of ${this.props.tableFormProps.minRows}`
        }
      });
    }
    
    let isFormValid = (this.inputFields.length === 0 || this.state.validation.isValid ) && 
      !Boolean(this.state.tableValidation.find(v => v.isValid === false));

    // handle actual form submission here
    if (isFormValid) {
      if (this.props.preFormSubmit) {
        let result = await this.props.preFormSubmit(this.state);
        if (result.status !== "success") {
          this.errorsOnSubmit = true;
          return this.setState({
            isLoading: false, 
            submitStatus: result, 
            validation : result.data && result.data.isValid === false ? result.data : this.state.validation,
            tableValidation : result.tableData ? result.tableData : this.state.tableValidation,
            showTableValidations : result.tableData ? true : false
          });
        }
      }
      
      console.log("All validations successful, starting form submit");
      
      // submit table data if exits, setting tableSubmit = true triggers
      // table data submit in the child component
      //
      if (this.props.uploadFiles && 
        this.props.tableFormProps && this.props.tableFormProps.fields) {
        // handle table form file uploads first
        tableFormFileUploadError = await this.handleTableFormFileUploads();
      }
      
      // submit non table form data
      if (!tableFormFileUploadError) {
        await this.triggerFormSubmit();
      }
    }
  }

  isFieldError(field, validation) {
    const initValue = (this.props.action === 'update' || this.props.validateOnLoad)
      && this.state[field]
      && this.state[field].toString().length;
    
    let fieldChanged = initValue || this.errorsOnSubmit || this.meta[field].touched;
      
    return (fieldChanged && validation[field] && validation[field].message !== '');
  }
  
  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  render() {

    const classes = this.props.classes;
    const tableFormProps = this.props.tableFormProps;
    let validation = this.state.validation;
    
    let isFormValid = (this.inputFields.length === 0 || this.state.validation.isValid ) && 
      (this.state.tableValidation && this.state.tableValidation.length && 
       !Boolean(this.state.tableValidation.find(v => v.isValid === false
      )));
   
    // set default 2 column layout
    let columns = this.props.columns ? this.props.columns : 2;
    
    return (
      <React.Fragment>
        <form style={{padding : '16px'}}>
          <div>
            {
              (this.state.submitStatus && this.state.submitStatus.message) &&
              <CustomSnackbar variant={this.state.submitStatus.status}
                message={this.state.submitStatus.message}
                open={this.state.submitStatus.status}
                onClose={() => this.setState({ submitStatus: null })}
              />
            }
            
            <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
            { /* iterate and render all fields. Use meta data */
              this.inputFields.map((f, i) => {
                let formfield = this.props.formFields.find(field => field.name === f.name);
                
                if (formfield === undefined) return null;
                // check if a field is readonly from form field properties or 
                // readonly field data passed
                //
                let readonly =  Boolean(formfield.properties.find(p => p.readOnly)) ||
                        (this.props.readOnlyFields ? 
                         this.props.readOnlyFields.indexOf(f.name) >= 0 :
                         false);
                
                let isRequired = formfield.properties && formfield.properties.length > 0 &&
                  formfield.properties.findIndex(p => p.required !== undefined && (p.required === true || p.required === 'true')) >= 0;
                         
                if (formfield.type === 'header') {
                  return (
                    <Grid key={i} item xs={12} sm={12} md={12}>
                      <Typography variant="h6">{formfield.label}</Typography><hr style={{borderColor:"#eef1f6"}} />
                    </Grid>);
                }
                                     
                return (
                  <Grid key={i} item xs={12} sm={formfield.type === 'text' ? 12 : 12/columns} 
                    md={formfield.type === 'text' ? 12 : 12/columns}>
                  {   
                    formfield.type === 'image' && formfield.properties &&
                    formfield.properties.find(p => p.crop !== undefined) ?
                    <ImageCrop
                      classes={classes}
                      formField={formfield}
                      action={this.props.action}
                      isHtmlInput={true}
                      onSave={this.handleImageCropChange}
                      value={this.state[f.name]}
                      readOnly = {readonly}
                    /> :
                    
                    <FieldCreator
                      key={'key_' + i}
                      name={f.name}
                      type={f.type}
                      label={f.label}
                      selectOptions={f.selectOptions}
                      selectMultiple={formfield.properties.find(p => p.multiple !== undefined)}
                      value={this.state[f.name]}
                      onChange={this.handleInputChange}
                      onEditorChange={this.handleEditorChange}
                      onFileInputChange={this.handleFileInputChange}
                      error={this.isFieldError(f.name, validation)}
                      helperText={this.isFieldError(f.name, validation) ? validation[f.name].message : ''}
                      readOnly = {readonly}
                      required={isRequired}
                      valueStrings={formfield.valueStrings}
                      numberProps={(formfield.properties.find(p => p.number !== undefined) || {}).number}
                      floatProps={(formfield.properties.find(p => p.float !== undefined) || {}).float} 
                    />
                  }
                  </Grid>              
                )
              })
            }
            </Grid>
          </div><br/>
          <div>
          {
            tableFormProps && tableFormProps.fields && tableFormProps.fields.length > 0 &&
            <TableFormHelper 
              inputFields = {tableFormProps.fields} 
              handleTableChange = {this.handleTableChange}
              currentValues = {tableFormProps.currentValues}
              serialNoHeader = {tableFormProps.serialNoHeader ? tableFormProps.serialNoHeader : 'S.No'}
              deleteRows = { tableFormProps.deleteRows ? tableFormProps.deleteRows : "all" }
              maxRows={tableFormProps.maxRows}
              minRows={tableFormProps.minRows}
              action={this.props.action}
              validation={this.state.tableValidation}
              showValidations={this.state.showTableValidations}
            />
          }
          </div><br/>
          {
            this.props.confirmation ? 
            (<ConfirmationButton
              fullWidth = {this.props.buttonWidth === undefined ||  this.props.buttonWidth === 'full' ? true: false}
              color = {this.props.buttonColor === undefined ? "primary" : this.props.buttonColor }
              disabled={!this.__touched || !validation.isValid || this.state.isLoading || this.state.isImgUploading}
              onClick={this.handleFormSubmit}
              confirmation={this.props.confirmation}
              buttonText = {this.state.isImgUploading 
                ? "Uploading File(s)..." 
                : this.state.isLoading ? "Please Wait..." : this.props.buttonText
              }
            />) : 
            (<Button
              fullWidth = {this.props.buttonWidth === undefined ||  this.props.buttonWidth === 'full' ? true: false}
              type = 'submit'
              color = {this.props.buttonColor === undefined ? "primary" : this.props.buttonColor }
              disabled={!this.__touched || !isFormValid || this.state.isLoading || this.state.isImgUploading}
              onClick={this.handleFormSubmit}
              onSubmit={this.handleFormSubmit}
              size="large"
              variant="contained">
              {this.state.isImgUploading ? "Uploading File(s)..." : 
                this.state.isLoading ? "Please Wait..." : this.props.buttonText}
            </Button>)
          }
        </form>
      </React.Fragment>
    );
  }
}

export default withNavigator(DynamicTableForm);
