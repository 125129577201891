import React, { Component } from 'react';

import FlightLandOutlined from '@mui/icons-material/FlightLandOutlined';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

// services
import {fetchTotalBsImportedCount} from 'services/booking-reports';

export default class TotalBSImportedCard extends Component {

  state = { count : '...' }
  
  async componentDidMount () {
    try {
      let response = await fetchTotalBsImportedCount({hatcheryId : 'ALL'});
      
      let amount = parseFloat(response.data);
      if (amount > 1000 & amount < 100000) {
        amount = (amount / 1000).toFixed(2) + 'K';
      }else if (amount > 100000 & amount < 10000000) {
        amount = (amount / 100000).toFixed(2) + 'L';
      } else if (amount > 10000000 & amount < 1000000000) {
        amount = (amount / 10000000).toFixed(2) + 'Cr';
      }
      
      this.setState({count : amount})
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {
    return (
      <SummaryWidget 
        label = {'Total BS Imported (No.)'} 
        count = {this.state.count} 
        icon = {<FlightLandOutlined />}
        url = { '/aqf/bookings/test-reports/received-sample-report' }
      />
    );
  }
}