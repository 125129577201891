import { fetchWithTimeout, addPagerParams } from './utils';

import { permissionCheck } from 'permissions';

const SAMPLE_COLLECTION_EP_URL = '/sample-collection-storages';

export const fetchSampleCollection = async(params) => {
  return await fetchWithTimeout({
    path: SAMPLE_COLLECTION_EP_URL + addPagerParams(params, params.sort ? params.sort : 'bookingId:DESC')
      + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : '')
      + (params.hatcheryId !== 'ALL' ? '&bookingId.hatcheryId=' + params.hatcheryId : '')
      + (params.hatcheryName && params.hatcheryName.length ? '&bookingId.hatcheryId.name_contains=' + params.hatcheryName : '')
      + (params.speciesType !== 'ALL' ? '&bookingId.speciesType=' + params.speciesType : ''),
    method: 'GET',
    operation : 'booking:view-sample-collection-storage'
  });
}

export const fetchSampleCollectionTotalCount = async(params) => {
  return await fetchWithTimeout({
    path: SAMPLE_COLLECTION_EP_URL + '/count?_start=0'
        + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : '')
        + (params.hatcheryId !== 'ALL' ? '&bookingId.hatcheryId=' + params.hatcheryId : '')
        + (params.hatcheryName && params.hatcheryName.length ? '&bookingId.hatcheryId.name_contains=' + params.hatcheryName : '')
        + (params.speciesType !== 'ALL' ? '&bookingId.speciesType=' + params.speciesType : ''),
    method: 'GET',
    operation : 'booking:view-sample-collection-storage'
  });
}

export const addSampleCollection = async(params) => {
  return await fetchWithTimeout({
    path: SAMPLE_COLLECTION_EP_URL + '/bulk-upload',
    method: 'POST',
    body : params,
    operation : 'booking:create-sample-collection-storage'
  });
}

export const updateSampleCollection = async(collectionId, params) => {
  return await fetchWithTimeout({
    path: SAMPLE_COLLECTION_EP_URL + '/' + collectionId,
    method: 'PUT',
    body : params,
    operation : 'booking:update-sample-collection-storage'
  });
}