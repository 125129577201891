import { Paper, Typography } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@mui/lab';

import {
  ListAltOutlined,
  LocalShippingOutlined,
  FlightLandOutlined,
  BiotechOutlined,
  StickyNote2Outlined,
} from '@mui/icons-material';

const dateFormatter = (str) => {
  if (str !== null && str !== true && str !== false) {
    return new Date(str).toLocaleString('en-GB', { 
      timezone : 'Asia/Kolkota', 
      year : 'numeric', month : 'numeric', day : 'numeric', 
      hour: '2-digit', minute:'2-digit'
    });
  } else {
    return '--';
  }
}

const BookingStockStatus = (props) => {
  return (
    <Paper elevation={4} sx={{ p :1,m : 1, maxHeight : '600px', overflow: 'auto'}}>
    {
      props.bookingData && props.bookingData.stockStatus &&
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color={props.bookingData.stockStatus.arrivalReport ? "success" : "disabled"} >
              <FlightLandOutlined />
            </TimelineDot>
            <TimelineConnector sx={props.bookingData.stockStatus.arrivalReport ? { bgcolor: 'success' } : {} } />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="overline">Stock Arrival</Typography><br/>
            <Typography variant="caption">Stock arrived at AQF and arrival report ready</Typography><br/>
            <Typography variant="caption" color="textSecondary">
              Date : {dateFormatter(props.bookingData.stockStatus.arrivalReport)} 
            </Typography>
          </TimelineContent>
        </TimelineItem>
        
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector sx={props.bookingData.stockStatus.stockingDetails ? { bgcolor: 'success' } : {} } />
            <TimelineDot color={props.bookingData.stockStatus.stockingDetails ? "success" : "disabled"} >
              <ListAltOutlined />
            </TimelineDot>
            <TimelineConnector sx={props.bookingData.stockStatus.stockingDetails ? { bgcolor: 'success' } : {} } />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="overline" component="span">Cubicle Stocking</Typography><br/>
            <Typography variant="caption">
            { 
              props.bookingData.stockStatus.stockingDetails 
              ? 'Stock moved into cubicles for monitoring ' 
              : 'Stock waiting to be moved into cubicles for monitoring'
            }
            </Typography><br/>
            <Typography variant="caption" color="textSecondary">
              Date : {dateFormatter(props.bookingData.stockStatus.stockingDetails)} 
            </Typography>
          </TimelineContent>
        </TimelineItem>
        
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector sx={props.bookingData.stockStatus.labAnalysisReport ? { bgcolor: 'success' } : {} } />
            <TimelineDot color={props.bookingData.stockStatus.labAnalysisReport ? "success" : "disabled"} >
              <BiotechOutlined />
            </TimelineDot>
            <TimelineConnector sx={props.bookingData.stockStatus.labAnalysisReport ? { bgcolor: 'success' } : {} } />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="overline" component="span"> Lab Analysis Report </Typography><br/>
            <Typography variant="caption">
            { 
              props.bookingData.stockStatus.labAnalysisReport 
              ? 'Lab analysis report generated and approved' 
              : 'Lab analysis report is pending'
            }
            </Typography><br/>
            <Typography variant="caption" color="textSecondary">
             Date : {dateFormatter(props.bookingData.stockStatus.labAnalysisReport)} </Typography>
          </TimelineContent>
        </TimelineItem>
        
        <TimelineItem>
          <TimelineSeparator>
           <TimelineConnector sx={props.bookingData.stockStatus.clearanceCertificate ? { bgcolor: 'success' } : {} } />
            <TimelineDot color={props.bookingData.stockStatus.clearanceCertificate ? "success" : "disabled"} >
              <StickyNote2Outlined />
            </TimelineDot>
            <TimelineConnector sx={props.bookingData.stockStatus.clearanceCertificate ? { bgcolor: 'success' } : {} } />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="overline" component="span"> Clearance Report </Typography><br/>
            <Typography variant="caption">
            { 
              props.bookingData.stockStatus.clearanceCertificate 
              ? 'Clearance certificate is generated' 
              : 'Clearance certificate is pending'
            }
            </Typography><br/>
            <Typography variant="caption" color="textSecondary">
              Date : {dateFormatter(props.bookingData.stockStatus.clearanceCertificate)}
            </Typography>
          </TimelineContent>
        </TimelineItem>
        
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector sx={props.bookingData.stockStatus.shipmentPacking ? { bgcolor: 'success' } : {} } />
            <TimelineDot color={props.bookingData.stockStatus.shipmentPacking ? "success" : "disabled"} >
              <LocalShippingOutlined />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="overline" component="span"> Shipment Ready </Typography><br/>
            <Typography variant="caption">
            { 
              props.bookingData.stockStatus.shipmentPacking 
              ? 'Shipment packing report is generated' 
              : 'Shipment packing report is pending'
            }
            </Typography><br/>
            <Typography variant="caption" color="textSecondary">
              Date : {dateFormatter(props.bookingData.stockStatus.shipmentPacking)} 
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    }
    </Paper>
  );
}

export default BookingStockStatus;