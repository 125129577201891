import { fetchWithTimeout, addPagerParams } from './utils';
import { SpeciesDataStore } from 'store';

const SPECIES_EP_URL = "/species-details";
const SPECIES_STORE_DATA_TIMEOUT = 12000; // seconds
  
export const fetchSpeciesTotalCount = async (params) => {
  return await fetchWithTimeout({
    path : SPECIES_EP_URL + '/count?_start=0'
        + (params.name && params.name.length ? '&type_contains=' + params.name : ''),
    method : 'GET',
    operation : 'common:view-species'
  });
};

export const fetchAllSpecies = async (params) => {
  let storeTimeDiff = SPECIES_STORE_DATA_TIMEOUT;
  
  if (SpeciesDataStore.state.timestamp) {
    storeTimeDiff = Math.ceil((new Date().getTime() - SpeciesDataStore.state.timestamp) / 1000);
  }
  
  // if store data is invalid, fetch it from remote
  if (storeTimeDiff >= SPECIES_STORE_DATA_TIMEOUT || params.per_page !== -1) {
    let response = await fetchWithTimeout({
      path : SPECIES_EP_URL + addPagerParams(params, 'id:ASC') + '&isDelete=false'
        + (params.name && params.name.length ? '&type_contains=' + params.name : ''),
      method : 'GET',
      operation : 'common:view-species'
    });
    
    if (response.status == 200 && params.per_page === -1) {
      SpeciesDataStore.setState({species : response.data, timestamp : new Date().getTime()});
    }
    return response;
  } else {
    return  { status : 200, data : SpeciesDataStore.state.species };
  }
};

export const fetchSpeciesById = async (bookingId) => {
  return await fetchWithTimeout({
    path : SPECIES_EP_URL + '/' + bookingId,
    method : 'GET',
    operation : 'common:view-species'
  });
};

export const createNewSpecies = async (params) => {
  return await fetchWithTimeout({
    path: SPECIES_EP_URL,
    method: 'POST',
    body : params,
    operation : 'common:species-create'
  });
};

export const updateSpecies = async (supplierId, params) => {
  return await fetchWithTimeout({
    path: SPECIES_EP_URL + '/' + supplierId,
    method: 'PUT',
    body : params,
    operation : 'common:species-update'
  });
};