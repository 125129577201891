import React, { Component } from 'react';
import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { shipmentFields } from '../bookings.fields';

// import { mergePdfDocuments } from 'services/common';
import { addShipmentDetails, filterBookingFormFieldsBySpeciesType } from 'services/bookings';
import { fetchAllSuppliers } from 'services/suppliers';

export default class ShipmentForm extends React.Component {
 
  state = { suppliers : null };
  
  constructor(props) {
    super(props);
    
    this.currentValues = {...props.bookingData.shipmentDetail};
    
    // always use country name from the supplier info
    delete this.currentValues.countryName;
    
     // deep copy form fields    
    this.formFields = [];
    shipmentFields.map (f => {
      let field = { ...f};
      
      field.properties = [];
      
       // deep copy properties
      f.properties.map ( p => field.properties.push({...p}));
      
      // deep copy select options
      if (f.selectOptions) {
        field.selectOptions = {...f.selectOptions};
      }
      
      if (f.name === 'totalBs') {
        if (props.bookingData.speciesType.type === 'PPL') {
          field.default = this.currentValues ? this.currentValues.totalBs : props.bookingData.totalNumberOfStock;
        } else {
          if (f.name === 'totalBs' && this.currentValues) {
            field.default = 
              (this.currentValues.maleBs ? parseInt(this.currentValues.maleBs) : 0 ) + 
              (this.currentValues.femaleBs ? parseInt(this.currentValues.femaleBs) : 0)
          }
        }
      }
      
      if (f.name === 'arrivalDate') {
        field.default = props.bookingData.fromDate;
      } else if (f.name === 'supplierName') {
        field.default = this.currentValues.supplierName 
          ? this.currentValues.supplierName 
          : props.bookingData.supplierId;
        field.callback = this.handleSupplierSelection;
      } else if (f.name === 'countryName') {
        field.default = props.bookingData.supplierDetails ? props.bookingData.supplierDetails.country : null;
      } else if (f.name === 'maleBs' || f.name === 'femaleBs') {
        field.callback = this.calculateTotalBs;
      }
      
      this.formFields.push(field);
      
    });
    
    this.formFields = filterBookingFormFieldsBySpeciesType('shipment', 
      props.bookingData.speciesType.type, this.formFields);
    
  }
  
  calculateTotalBs = async (name, value, formData, asyncFn) => {
    let maleBs = formData.maleBs ? parseInt(formData.maleBs) : 0;
    let femaleBs = formData.femaleBs ? parseInt(formData.femaleBs) : 0;
   
    if (maleBs > 0 || femaleBs > 0) {
      formData.totalBs = maleBs + femaleBs;
    }
    
    return { fields : null, values : formData};
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully saved shipment details...." };
    let formData = {};
    
    // console.log(params);
    try {
      /*
      let documents = Array.isArray(params.document) ? params.document : [params.document];
    
      // merge all shipment documents before submitting data
      let mergeFilename = `shipment_docs_${this.props.bookingData.id}`; // no extension
      let response = await mergePdfDocuments(documents, mergeFilename);
      */
      
      formData.documents = { "shipmentDocument" : Array.isArray(params.document) ? params.document : [params.document] };
      delete params.document;
      
      if (params.nocCertificate) {
        formData.documents.nocCertificate = params.nocCertificate;
        delete params.nocCertificate;
      }
      
      // add timestamp to shipment data
      params.updated_at = new Date();
      
      formData.shipmentDetail = params;
      
      let response = await addShipmentDetails(this.props.bookingData.id, formData);
      
      if (this.props.refresh) this.props.refresh(response.data);
    
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message };
    }
    return statusMessage;
  }
  
  handleSupplierSelection = async (name, value, formData, asyncFn) => {
    
    // update country name automatically,
    let supplier = this.state.suppliers.find( x => x.name === value);
    if (supplier) {
      formData.countryName = supplier.country;
    }
    return {fields : null, values : formData };
  }
  
  async componentDidMount() {
    try {
      // fetch and update list of suppliers for the species
      let response = await fetchAllSuppliers({
        speciesType : 
          (this.props.bookingData.hatcheryId && this.props.bookingData.hatcheryId.permittedSpeciesId)
          ? this.props.bookingData.hatcheryId.permittedSpeciesId.id 
          : 'ALL', 
        status : true,
        per_page : -1,
        page : 1
      });
      
      response.data.map( supplier => {
        this.formFields[4].selectOptions[supplier.name] = supplier.name;
      });

      this.setState({suppliers : response.data });
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {

    return (<>
      <Typography variant="h5" align="center" p={1} > 
      { 
        (this.props.bookingData.shipmentDetail ? 'Update Shipment Details ' : 'Add Shipment Details') + 
        `Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
      }
      </Typography><hr style={{borderColor:"#eef1f6"}} />
      <DynamicForm
        formFields={this.formFields}
        action={this.props.bookingData.shipmentDetail ? "update" : "create"}
        handleSubmit={this.handleFormSubmit}
        buttonText="Save Shipment Details"
        buttonWidth= "full"
        columns = {3}
        currentValues={this.currentValues}
        uploadFiles = {true}
        redirect={null}
      />
    </>);
  }
}