import { fetchWithTimeout, addPagerParams } from './utils';

import { permissionCheck } from 'permissions';

const BOOKINGS_EP_URL = "/bookings";
const CUBICLE_STOCKINGS_EP_URL = "/cubicle-stockings";
  
export const fetchBookingsTotalCount = async (params) => {
  let today = new Date().toISOString();
  let typeParams = '';
  
  switch (params.type) {
    case 'active':
      typeParams = '&toDate_gte=' + today; 
      break;
    case 'completed':
      typeParams = '&toDate_lte=' + today; 
      break;
    case 'shipment-approval':
      typeParams = '&shipmentApproval=ApprovalPending'; //&toDate_gte=' + today; 
      break;
    case 'date-range':
      typeParams = '&fromDate_gte=' + params.startDate + '&fromDate_lte=' + params.endDate;
      break;
    case 'supplier-wise-report':
      typeParams = '&fromDate_gte=' + params.startDate + '&fromDate_lte=' + params.endDate 
        + '&status=Confirmed&status=Completed';
      break;
    case 'arrival-pending':
      typeParams = '&status=Confirmed&status=Completed&shipmentApproval=Approved'; //&toDate_gte=' + today; 
      break;
    default: break;
  };
  
  let shipmentFilter = ['arrival-pending', 'shipment-approval'].includes(params.type);
  
  return await fetchWithTimeout({
    path : BOOKINGS_EP_URL + '/count?_start=0'
        + (params.hatcheryId !== 'ALL' ? '&hatcheryId=' + params.hatcheryId : '')
        + (params.supplierId !== 'ALL' ? '&supplierId=' + params.supplierId : '')
        + (params.status !== 'ALL' && params.type !== 'arrival-pending' ? '&status=' + params.status : '')
        + (params.bookingId && params.bookingId.length ? '&id=' + params.bookingId : '')
        + (params.hatcheryName && params.hatcheryName.length ? '&hatcheryId.name_contains=' + params.hatcheryName  : '')
        + (params.speciesType !== 'ALL' ? '&speciesType=' + params.speciesType : '')
        + (shipmentFilter === false && params.shipmentStatus !== 'ALL' ? '&shipmentApproval=' + params.shipmentStatus : '')
        + typeParams,
    method : 'GET',
    operation : permissionCheck('booking', 'view-self') ? 'booking:view-self' : 'booking:view-any'
  });
};

export const fetchBookingsData = async (params) => {
  let today = new Date().toISOString();
  let typeParams = '';
  
  switch (params.type) {
    case 'active':
      typeParams = '&toDate_gte=' + today; 
      break;
    case 'completed':
      typeParams = '&toDate_lte=' + today; 
      break;
    case 'shipment-approval':
      typeParams = '&shipmentApproval=ApprovalPending'; //&toDate_gte=' + today; 
      break;
    case 'date-range':
      typeParams = '&fromDate_gte=' + params.startDate + '&fromDate_lte=' + params.endDate;
      break;
    case 'supplier-wise-report':
      typeParams = '&fromDate_gte=' + params.startDate + '&fromDate_lte=' + params.endDate 
        + '&status=Confirmed&status=Completed';
      break;
    case 'arrival-pending':
      typeParams = '&status=Confirmed&status=Completed&shipmentApproval=Approved'; //&toDate_gte=' + today; 
      break;
    default: break;
  };
  
  let shipmentFilter = ['arrival-pending', 'shipment-approval'].includes(params.type);
  
  return await fetchWithTimeout({
    path : BOOKINGS_EP_URL + addPagerParams(params) 
        + (params.hatcheryId !== 'ALL' ? '&hatcheryId=' + params.hatcheryId : '')
        + (params.supplierId !== 'ALL' ? '&supplierId=' + params.supplierId : '')
        + (params.status !== 'ALL' && params.type !== 'arrival-pending' ? '&status=' + params.status : '')
        + (params.bookingId && params.bookingId.length ? '&id=' + params.bookingId : '')
        + (params.hatcheryName && params.hatcheryName.length ? '&hatcheryId.name_contains=' + params.hatcheryName  : '')
        + (params.speciesType !== 'ALL' ? '&speciesType=' + params.speciesType : '')
        + (shipmentFilter === false && params.shipmentStatus !== 'ALL' ? '&shipmentApproval=' + params.shipmentStatus : '')
        + typeParams,
    method : 'GET',
    operation : permissionCheck('booking', 'view-self') ? 'booking:view-self' : 'booking:view-any'
  });  
};

export const fetchBookingsTotalCountByStatus = async (params) => {
  
  let typeParams = '&startDate=' + params.startDate + '&endDate=' + params.endDate;
  
  return await fetchWithTimeout({
    path : BOOKINGS_EP_URL + '/count-by-status?_start=0' + typeParams
        + (params.hatcheryId !== 'ALL' ? '&hatcheryId=' + params.hatcheryId : ''),
    method : 'GET',
    operation : 'app-reports:bookings-status-count'
  });
};


export const fetchBookingById = async (bookingId) => {
  return await fetchWithTimeout({
    path : BOOKINGS_EP_URL + '/' + bookingId,
    method : 'GET',
    operation : permissionCheck('booking', 'view-self') ? 'booking:view-self' : 'booking:view-any'
  });
};

export const fetchBookingsBySpecies = async (params) => {
  return await fetchWithTimeout({
    path : BOOKINGS_EP_URL + '/species-wise-imported?fromDate=' + params.fromDate + '&toDate=' + params.toDate,
    method : 'GET',
    operation : 'app-reports:species-wise-imported'
  });
}

export const fetchBookingsByHatcheries = async (params) => {
  return await fetchWithTimeout({
    path : BOOKINGS_EP_URL + '/totalByHatchery?fromDate=' + params.fromDate + '&toDate=' + params.toDate
      + (params.state !== 'ALL' ? '&state=' + params.state : '')
      + (params.speciesType !== 'ALL' ? '&speciesType=' + params.speciesType : '')
      + (params.hatcheryName && params.hatcheryName.length ? '&hatcheryName=' + params.hatcheryName : '')
      + (params.district !== 'ALL' ? '&district=' + params.district : '')
      + (params.zeroBookings ? '&zeroBookings=true' : '')
      + (params.download ? '&download=true' : ''),
    method : 'GET',
    operation : 'app-reports:top-hatchery-bookings'
  });
}

export const fetchBookingsByHatcheriesCount = async (params) => {
  return await fetchWithTimeout({
    path : BOOKINGS_EP_URL + '/totalByHatchery/count?fromDate=' + params.fromDate + '&toDate=' + params.toDate
      + (params.state !== 'ALL' ? '&state=' + params.state : '')
      + (params.speciesType !== 'ALL' ? '&speciesType=' + params.speciesType : '')
      + (params.hatcheryName && params.hatcheryName.length ? '&hatcheryName=' + params.hatcheryName : '')
      + (params.district !== 'ALL' ? '&district=' + params.district : '')
      + (params.zeroBookings ? '&zeroBookings=true' : ''),
    method : 'GET',
    operation : 'app-reports:top-hatchery-bookings'
  });
}

export const fetchRequiredCubicles = async (bioMassPerStock, totalNumberOfStock) => {
  return await fetchWithTimeout({
    path: BOOKINGS_EP_URL + '/get-required-cubicles?bioMassPerStock=' 
      + bioMassPerStock + '&totalNumberOfStock=' + totalNumberOfStock,
    method: 'GET',
    operation : 'booking:create'
  });
}

export const createNewBooking = async (params) => {
  return await fetchWithTimeout({
    path: BOOKINGS_EP_URL,
    method: 'POST',
    body : params,
    operation : 'booking:create'
  });
};

export const updateBooking = async (id, params) => {
  return await fetchWithTimeout({
    path: BOOKINGS_EP_URL + '/' + id,
    method: 'PUT',
    body : params,
    operation : permissionCheck('booking', 'update-self') ? 'booking:update-self' : 'booking:update'
  });
};

export const addShipmentDetails = async (bookingId, params) => {
   return await fetchWithTimeout({
    path: BOOKINGS_EP_URL + '/add-shipment-details/' + bookingId,
    method: 'PUT',
    body : params,
    operation : permissionCheck('shipment', 'add-shipment-self') ? 'booking:add-shipment-self' : 'booking:add-shipment-any'
  });
};

export const addAuthPersonDetails = async (bookingId, params) => {
  return await fetchWithTimeout({
    path: BOOKINGS_EP_URL + '/add-authorized-person/' + bookingId,
    method: 'PUT',
    body : params,
    operation : 'booking:add-authperson-self'
  });
};

export const addNoCDocument = async (bookingId, params) => {
  return await fetchWithTimeout({
    path: BOOKINGS_EP_URL + '/upload-noc/' + bookingId,
    method: 'PUT',
    body : params,
    operation : permissionCheck('shipment', 'add-noc-self') ? 'booking:add-noc-self' : 'booking:add-noc-any'
  });
};

export const approveShipmentDocuments = async (bookingId, params) => {
  return await fetchWithTimeout({
    path: BOOKINGS_EP_URL + '/approve-shipment/' + bookingId,
    method: 'PUT',
    body : params,
    operation : 'booking:approve-shipment'
  });
};

export const cancelBooking = async (bookingId, params) => {
  return await fetchWithTimeout({
    path: BOOKINGS_EP_URL + '/cancel/' + bookingId,
    method: 'PUT',
    body : params,
    operation : 'booking:cancel'
  });
};


export const addCubicleStocking = async(params) => {
  return await fetchWithTimeout({
    path: CUBICLE_STOCKINGS_EP_URL,
    method: 'POST',
    body : params,
    operation : 'booking:create-cubicle-stocking'
  });
}

export const fetchCubicleStocking = async(params) => {
  return await fetchWithTimeout({
    path: CUBICLE_STOCKINGS_EP_URL + addPagerParams(params, 'animalStockingDate:ASC')
      + (params.bookingId ? '&bookingId=' + params.bookingId : ''),
    method: 'GET',
    operation : 'booking:view-cubicle-stocking'
  });
}

export const fetchCubicleStatusByDate = async (params) => {
  return await fetchWithTimeout({
    path : CUBICLE_STOCKINGS_EP_URL + '/everydaystock?date=' + params.date,
    method : 'GET',
    operation : 'app-reports:daily-cubicle-stock-status'
  });
}

export const fetchBookingsSupplierWise = async (params) => {
  console.log(params);
  return await fetchWithTimeout({
    path : BOOKINGS_EP_URL + '/supplier-wise-report' 
        + (params.download ? '?_start=0' : addPagerParams(params))
        + (params.bookingId && params.bookingId.length ? '&id=' + params.bookingId : '')
        + (params.hatcheryName && params.hatcheryName ? '&hatcheryId.name_contains=' + params.hatcheryName : '')
        + (params.status !== 'ALL' ? '&status=' + params.status : '&status=Confirmed&status=Completed')
        + (params.supplierId !== 'ALL' ? '&supplierId=' + params.supplierId : '')
        + (params.speciesType !== 'ALL' ? '&speciesType=' + params.speciesType : '')
        + (params.startDate ? '&fromDate_gte=' + params.startDate : '')
        + (params.endDate ? '&fromDate_lte=' + params.endDate : '')
        + (params.download ? '&download=true' : ''),
    method : 'GET',
    operation : 'app-reports:supplier-wise-booking'
  });  
};

export const fetchBookingLimits = async (hatcheryId, fyear=null, bookingDate=null) => {
  return await fetchWithTimeout({
    path : BOOKINGS_EP_URL + '/hatchery-limits?hatcheryId=' + hatcheryId
      + (fyear ? '&fyear=' + fyear : '')
      + (bookingDate ? '&bookingDate=' + bookingDate : ''),
    method : 'GET',
    operation : 'booking:create'
  });  
};

export const filterBookingFormFieldsBySpeciesType = (formType, speciesType, formFields) => {
  let newFields = [];
  
  switch(formType) {
    case 'booking':
      formFields.map ( field => {
        if (speciesType === 'PPL') {
          if (['bioMassPerStock', 'totalBioMass'].includes(field.name)) {
            return; // skip fields for PPL
          }
        }
        newFields.push(field);
      });
      break;
    case 'shipment':
      formFields.map ( field => {
        if (speciesType === 'PPL') {
          if (['maleBs', 'femaleBs', 'avgMaleWt', 'avgFemaleWt'].includes(field.name)) {
            return; // skip fields for PPL
          } else if (field.name === 'totalBs') {
            field.label = 'Total PPL';
            field.properties =  [{required : true}, {number : true}];
          }
        }
        newFields.push(field);
      });
      break;
    default:
      break;
  }
  
  return newFields;
}
      
export const filterBookingDataBySpeciesType = (speciesType, data) => {
  if (speciesType === 'PPL') {
    data['bioMassPerStock'] = data['totalBioMass'] = null;
  }
  
  return data;
};