import React, { Component } from 'react';

// Material helpers
import { Typography, Box, Paper, Button } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

// Shared services
import {resetPasswordOTP, processLoginResp } from 'services/user';

import { fetchRolePermissions } from 'services/roles-permissions';
import { fetchHatcheryById } from 'services/hatcheries';

export default class OtpValidateForm extends Component {
  
  state = { 
    timer : 300,
    statusMessage : {
      status : "info",
      message : "Please note OTP is sent to both mobile and registered e-mail. If SMS is not received on mobile, " + 
                "please check your e-mail before retrying OTP"
    }
  };

  constructor(props) {
    super(props);

    this.otpValidateForm = [
      {
        name: 'mobileNumber',
        label: 'Mobile Number',
        type: 'string',
        default : props.mobileNumber,
        properties: [{readOnly:true}]
      },
      {
        name: 'code',
        label: 'Enter OTP Code',
        type: 'string',
        properties: [{required:true}]
      },
      {
        name: 'password',
        label: 'Enter New Password',
        type: 'password',
        properties: [{required:true}, {strongPassword : true}]
      },
      {
        name: 'passwordConfirmation',
        label: 'Confirm Password',
        type: 'password',
        properties: [{required:true}, {strongPassword : true}]
      }
    ];
    
    this.dashboardPath = '/aqf/dashboard';
    
    this.timerHandle = null;
  }
  
  async componentDidMount()
  {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
    }
    
    this.timerHandle = setInterval(() => this.setState({ timer : this.state.timer > 0 ? this.state.timer -1 : 0 }), 1000);
  }
  
  componentWillUnmount() {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
      this.timerHandle = null;
    }
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  
  handleFormSubmit = async (params) => {
    
    let statusMessage = { status: "success", message: "Password reset successfull. Redirecting..." };
    if (params.password !== params.passwordConfirmation) {
      return statusMessage = { status: "error", message: "Password does not match with Confirm Password..." };
    } 
    try {
      const response = await resetPasswordOTP(params);
      processLoginResp(response.data);
      
      // fetch new app level permissions
      const appPermissions = await fetchRolePermissions();
      
      // if user is a hatchery owner, fetch hatchery details
      if (response.data.user.role.name === 'Hatchery Owner') {
        const hatchery = await fetchHatcheryById(response.data.user.userProfile.hatcheryId);
      }
    
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message };
    }
    
    return statusMessage;
  }


  render() {
    const classes = this.props.classes;
    
    const minutes = Math.floor(this.state.timer / 60);
    const seconds = this.state.timer - (minutes * 60);
    
    return (
      <>
        {
          this.state.statusMessage &&
          <CustomSnackbar variant={this.state.statusMessage.status}
            message={this.state.statusMessage.message}
            open={this.state.statusMessage.status}
            onClose={() => this.setState({ statusMessage: null })}
          /> 
        }
        <Paper sx={{ p : 4}}>
          <Typography align="center">Reset Password </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
          <DynamicForm
            classes={classes}
            formFields={this.otpValidateForm}
            handleSubmit={this.handleFormSubmit}
            buttonText="Reset Password"
            buttonWidth= "full"
            columns = {1}
            redirect={this.dashboardPath}
          />
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography noWrap variant="h6" color="error.light"> 
              OTP expires in {`0${minutes}:${seconds >= 10 ? seconds : '0' + seconds} minutes`} 
            </Typography>
             <Button 
                onClick={this.props.handleResendOTP} 
                startIcon={<ChevronLeft />}
                variant="contained"
                color="secondary" size="small" sx={{ml : 2}}
              > Resend OTP ?
              </Button>
          </Box> 
        </Paper>
      </>
    );
  }
}
