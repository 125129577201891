import { fetchWithTimeout, addPagerParams } from './utils';
import { CubicleDataStore } from 'store';

const CUBICLES_EP_URL = "/cubicles";
const CUBICLE_STORE_DATA_TIMEOUT = 12000; // seconds
  
export const fetchCubiclesTotalCount = async (params) => {
  return await fetchWithTimeout({
    path : CUBICLES_EP_URL + '/count?_start=0'
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'common:view-cubicles'
  });
};

export const fetchAllCubicles = async (params) => {
  let storeTimeDiff = CUBICLE_STORE_DATA_TIMEOUT;
  
  if (CubicleDataStore.state.timestamp) {
    storeTimeDiff = Math.ceil((new Date().getTime() - CubicleDataStore.state.timestamp) / 1000);
  }
  
  // if store data is invalid, fetch it from remote
  if (storeTimeDiff >= CUBICLE_STORE_DATA_TIMEOUT || params.per_page !== -1) {
    let response = await fetchWithTimeout({
      path : CUBICLES_EP_URL + addPagerParams(params, 'number:ASC') + '&isDelete=false'
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
      method : 'GET',
      operation : 'common:view-cubicles'
    });
    
    if (response.status == 200 && params.per_page === -1) {
      CubicleDataStore.setState({cubicles : response.data, timestamp : new Date().getTime()});
    }
    return response;
  } else {
    return  { status : 200, data : CubicleDataStore.state.cubicles };
  }
};

export const fetchCubicleById = async (bookingId) => {
  return await fetchWithTimeout({
    path : CUBICLES_EP_URL + '/' + bookingId,
    method : 'GET',
    operation : 'common:view-cubicles'
  });
};

export const createNewCubicle = async (params) => {
  return await fetchWithTimeout({
    path: CUBICLES_EP_URL,
    method: 'POST',
    body : params,
    operation : 'common:cubicle-create'
  });
};

export const updateCubicle = async (supplierId, params) => {
  return await fetchWithTimeout({
    path: CUBICLES_EP_URL + '/' + supplierId,
    method: 'PUT',
    body : params,
    operation : 'common:cubicle-update'
  });
};