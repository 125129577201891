export const bookingFields = [
  {  
    name: 'id',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'hatcheryName',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{readOnly:true}],
    default : '',
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'hatcheryAddress',
    label: 'Hatchery Address',
    type: 'string',
    properties: [{readOnly:true}],
    default : '',
    actions: ['view']
  },
  {  
    name: 'selectFyear',
    label: 'Select Booking FY',
    type: 'select',
    selectOptions : {
      0 : '-- Select FY --'
    },
    properties:[{required : true}],
    actions: ['create']
  },
  {  
    name: 'CAARegistrationNumber',
    label: 'CAA Registration Number',
    type: 'string',
    properties: [{readOnly:true}, {required : true}],
    default : '',
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'fyBalance',
    label: 'Available Balance (Fy)',
    type: 'string',
    properties: [{readOnly:true},{required : true}],
    actions: ['create','update']
  },
  {  
    name: 'supplierId',
    label: 'Supplier',
    type: 'select',
    selectOptions : {},
    properties: [{required:true}],
    actions: ['create','update', 'view']
  },
  {  
    name: 'ownerOrAuthPerson',
    label: 'Select Face Profile',
    type: 'select',
    selectOptions : {},
    properties : [{required : true}],
    actions: ['create', 'update']
  },
  {  
    name: 'fromDate',
    label: 'Reserved Date',
    type: 'date',
    properties: [{required:true}],
    actions: ['index', 'view']
  },
  {  
    name: 'toDate',
    label: 'Reserved Till',
    type: 'string',
    properties: [{required:true}],
    actions: []
  },
  {  
    name: 'speciesType',
    label: 'Species Type',
    type: 'string',
    properties: [{required:true, readOnly:true}],
    actions: ['index', 'view']
  },
  {  
    name: 'bioMassPerStock',
    label: 'Bio Mass Per Stock (gms)',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'update', 'view']
  },
  {  
    name: 'totalNumberOfStock',
    label: 'Total Stock No.(Qty)',
    type: 'number',
    properties: [{required:true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'totalBioMass',
    label: 'Total Bio Mass (Kgs)',
    type: 'number',
    properties: [{required : true }, {readOnly:true}, { float: true}],
    actions: ['create', 'update', 'view']
  },
  {  
    name: 'numCubicles',
    label: 'No. of Cubicles',
    type: 'number',
    properties: [{required : true }, {readOnly:true}],
    actions: ['create','update', 'view']
  },
  {  
    name: 'cubicleFee',
    label: 'Cubicle Fee',
    type: 'currency',
    properties: [{required : true } , {readOnly:true}],
    actions: ['create','update']
  },
  {  
    name: 'incenerationFee',
    label: 'Fumigation Fee',
    type: 'currency',
    properties: [{required : true } , {readOnly:true}],
    actions: ['create','update']
  },
  {  
    name: 'gst',
    label: 'GST Amount',
    type: 'currency',
    properties: [{required : true } ,{readOnly:true}],
    actions: ['create','update']
  },
  {  
    name: 'pgCharges',
    label: 'Transaction Charges',
    type: 'currency',
    properties: [{required : true } ,{readOnly:true}],
    actions: ['create','update']
  },
  {  
    name: 'totalPrice',
    label: 'Total Inc. GST',
    type: 'currency',
    properties: [{required : true } , {readOnly:true}],
    actions: ['create','update', 'index']
  },
  {  
    name: 'validFrom',
    label: 'License Valid From',
    type: 'date',
    properties: [{readOnly:true}],
    default : '',
    actions: ['view']
  },
  {  
    name: 'validTo',
    label: 'License Valid Till',
    type: 'date',
    properties: [{readOnly:true}],
    default : '',
    actions: ['view']
  },
  {  
    name: 'cubicleNumber',
    label: 'Cubicle No.(s)',
    type: 'string',
    properties: [{required : true } , {readOnly:true}],
    actions: ['index', 'view']
  },
  {  
    name: 'created_at',
    label: 'Booking Date & Time',
    type: 'datetime',
    properties: [{required : true } , {readOnly:true}],
    actions: ['index', 'view']
  },
  {  
    name: 'paymentId',
    label: 'Order Id',
    type: 'string',
    properties: [{required : true } , {readOnly:true}],
    actions: ['index']
  },
  {  
    name: 'transactionDate',
    label: 'Payment Date',
    type: 'datetime',
    properties: [{required : true } , {readOnly:true}],
    actions: ['index']
  },
  {  
    name: 'status',
    label: 'Booking Status',
    type: 'select',
    selectOptions : {
      "Blocked" : "Blocked",
      "Cancelled" : "Cancelled",
      "Confirmed" : "Confirmed",
      "Rejected" : "Rejected",
      "Completed" : "Completed",
      "RetryPayment" : "Retry Payment"
    },
    properties: [{required:true}],
    actions: ['index', 'view']
  },
  {
    name : 'shipmentApproval',
    label : 'Shipment Status',
    type : 'select',
    selectOptions : {
      'ApprovalPending' : 'Approval Pending',
      'Approved' : 'Approved',
      'Rejected' : 'Rejected'
    },
    properties : [{required : false}],
    actions : ['view', 'index'],
  },
  {
    name : 'cancelRemark',
    label : 'Cancel Remarks',
    type : 'string',
    properties : [{required : false}],
    actions : ['view'],
  },
  {
    name : 'updated_at',
    label : 'Updated At',
    type : 'datetime',
    properties : [{required : false}, {readOnly : true}],
    actions : ['view'],
  }
];

export const bookingInvoiceFields = [
  {  
    name: 'cubicleFee',
    label: 'Cubicle Fee',
    type: 'currency',
    properties: [{required : true } , {readOnly:true}],
    actions: ['create','update', 'view']
  },
  {  
    name: 'incenerationFee',
    label: 'Fumigation Fee',
    type: 'currency',
    properties: [{required : true } , {readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'gst',
    label: 'GST Amount',
    type: 'currency',
    properties: [{required : true } ,{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'pgCharges',
    label: 'Transaction Charges',
    type: 'currency',
    properties: [{required : true } ,{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'totalPrice',
    label: 'Total Inc. GST',
    type: 'currency',
    properties: [{required : true } , {readOnly:true}],
    actions: ['view']
  },
  {
    name: 'paymentId',
    label: 'Order Id',
    type: 'string',
    properties: [{required : true } , {readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'transactionDate',
    label: 'Payment Date',
    type: 'datetime',
    properties: [{required : true } , {readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'transactionId',
    label: 'Transaction Ref No.',
    type: 'string',
    properties: [{required : true } , {readOnly:true}],
    actions: ['view']
  },
];

export const shipmentFields = [
  {
    name : 'airlineName',
    label : 'Airline Name',
    type : 'string',
    properties : [{required : true }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'flightNumber',
    label : 'Flight No.',
    type : 'string',
    properties : [{required : true }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'airwayBillNumber',
    label : 'Airway Bill No.',
    type : 'string',
    properties : [{required : true }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'arrivalDate',
    label : 'Arrival Date',
    type : 'datetime',
    properties : [{required : true }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {  
    name: 'supplierName',
    label: 'Supplier',
    type: 'select',
    selectOptions : {},
    properties: [{required:true}],
    actions: ['create','update', 'view']
  },
  {
    name : 'countryName',
    label : 'Country Name',
    type : 'string',
    properties : [{required : true }, {readOnly : true}],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'destination',
    label : 'Destination',
    type : 'string',
    properties : [{required : true }, {readOnly : true}],
    default : 'CHENNAI',
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'maleBs',
    label : 'Total Male Broodstock',
    type : 'number',
    default : 0,
    properties : [{required : true }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'femaleBs',
    label : 'Total Female Broodstock',
    type : 'number',
    default : 0,
    properties : [{required : true }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'totalBs',
    label : 'Total Broodstock',
    type : 'number',
    default : 0,
    properties : [{required : true}, {readOnly : true}],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'avgMaleWt',
    label : 'Average Male Broodstock Weight(gms)',
    type : 'float',
    default : 0,
    properties : [{required : true }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'avgFemaleWt',
    label : 'Average Female Broodstock Weight(gms)',
    type : 'float',
    default : 0,
    properties : [{required : true }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'box',
    label : 'Total Boxes',
    type : 'number',
    properties : [{required : false }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'document',
    label : 'Shipping Documents',
    type : 'file',
    properties : [
      {required : true}, 
      {multiple : true},
      {filesize: { min: 0, max: 10 } },
      {filetype: ["pdf", "docx", "doc", "png", "jpeg", "jpg"]},
    ],
    actions : ['create', 'update'],
  },
  {
    name : 'nocCertificate',
    label : 'NoC Document',
    type : 'file',
    properties : [
      {required : false},
      {filesize: { min: 0, max: 10 } },
      {filetype: ["pdf", "docx", "doc", "png", "jpeg", "jpg"]},
    ],
    actions : ['create', 'update'],
  },
  {
    name : 'shipmentApproval',
    label : 'Shipment Approval Status',
    type : 'select',
    selectOptions : {
      'ApprovalPending' : 'Approval Pending',
      'Approved' : 'Approved',
      'Rejected' : 'Rejected'
    },
    properties : [{required : false}],
    actions : ['view', 'index'],
  },
  {
    name : 'shipmentRejectReason',
    label : 'Shipment Reject Reason',
    type : 'string',
    properties : [{required : false}],
    actions : ['view'],
  },
  {
    name : 'updated_at',
    label : 'Updated At',
    type : 'datetime',
    properties : [{required : false}, {readOnly : true}],
    actions : ['view'],
  },
  {
    name : 'updateUser',
    label : 'Updated By',
    type : 'string',
    properties : [{required : false}, {readOnly : true}],
    actions : ['view']
  }
];

export const authPersonFields = [
  {
    name : 'name',
    label : 'Person Name',
    type : 'string',
    properties : [{required : true }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'mobileNumber',
    label : 'Mobile Number',
    type : 'string',
    properties : [
      {required : true},
      {mobile : true},
      {string : { min : 10, max : 10}}
    ],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'aadhaarNumber',
    label : 'Aadhaar Number',
    type : 'string',
    properties : [{required : true}],
    actions : ['view', 'create', 'update', 'index'],
  },
];

export const cubicleStockingFields = [
  {
    name : 'id',
    label : 'Cubicle Stocking Id',
    type : 'string',
    properties : [{required : true }, {readOnly : true}],
    actions : ['view', 'index'],
  },
  {
    name : 'bookingId',
    label : 'Booking Id',
    type : 'string',
    properties : [{required : true }, {readOnly : true}],
    actions : ['view', 'index'],
  },
  {
    name : 'cubicleId',
    label : 'Cubicle',
    type : 'select',
    selectOptions : {},
    properties : [{required : true }],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'animalStockingDate',
    label : 'Stocking Date',
    type : 'date',
    properties : [{required : true}],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'totalAnimalStocked',
    label : 'Total Animals Stocked',
    type : 'number',
    properties : [{required : true}, { number : { step : 1}}],
    actions : ['view', 'create', 'update', 'index'],
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view', 'index']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view', 'index']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];
