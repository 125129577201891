import { Box, Container, Grid } from '@mui/material';

import TotalBookings from 'pages/bookings/widget-components/TotalBookings';
import TotalHatcheries from 'pages/hatcheries/widget-components/TotalHatcheries';
import TotalEmployees from 'pages/user-accounts/widget-components/TotalEmployees';
import TotalChangeRequestHatcheries from 'pages/hatcheries/widget-components/TotalChangeRequestHatcheries';
import ApprovalPendingHatcheries from 'pages/hatcheries/widget-components/ApprovalPendingHatcheries';
import DailyStockStatus from 'pages/bookings/widget-components/DailyStockStatus';
import SpeciesBookingsGraph from 'pages/bookings/widget-components/SpeciesBookingsGraph';
import BookingStatusGraph from 'pages/bookings/widget-components/BookingStatusGraph';
import ReportVerificationGraph from 'pages/booking-reports/widget-components/ReportVerificationGraph';
import TopHatcheriesCard from 'pages/hatcheries/widget-components/TopHatcheriesCard';
import TopRevenuesCard from 'pages/transactions/widget-components/TopRevenuesCard';

// booking report cards
import TotalBSImportedCard from 'pages/booking-reports/widget-components/TotalBSImportedCard';
import TotalLabAnalysisReportsCard from 'pages/booking-reports/widget-components/TotalLabAnalysisReportsCard';
import TotalClearanceCertificates from 'pages/booking-reports/widget-components/TotalClearanceCertificates';
import TotalShipmentPackingsCard from 'pages/booking-reports/widget-components/TotalShipmentPackingsCard';
import CubicleStatusCard from 'pages/booking-schedules/widget-components/CubicleStatusCard';

import { permissionCheck } from 'permissions';

const AQFEmployee = () => (
  <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 1 }}>
    <Container maxWidth={false}>
      <Grid container spacing={2}>
      {
        permissionCheck('booking', 'view-any') &&
        <Grid item xs={12} sm={4}> <TotalBookings /> </Grid>
      }
      {
        permissionCheck('hatchery', 'view-any') &&
        <Grid item xs={12} sm={4}> <TotalHatcheries /> </Grid>
      }
      {
        permissionCheck('hatchery', 'approve') &&
        <Grid item xs={12} sm={4}> <ApprovalPendingHatcheries /> </Grid>
      }
      {
        permissionCheck('hatchery', 'approve-changes') &&
        <Grid item xs={12} sm={4}> <TotalChangeRequestHatcheries /> </Grid>
      }
      {
        permissionCheck('user-accounts', 'view-any') &&
        <Grid item xs={12} sm={4}> <TotalEmployees /> </Grid>
      }
      {
        permissionCheck('booking-reports', 'view-received-sample-report') &&
        <Grid item xs={12} sm={4}> <TotalBSImportedCard /> </Grid>
      }
      {
        permissionCheck('booking-reports', 'view-lab-analysis-report') &&
        <Grid item xs={12} sm={4}> <TotalLabAnalysisReportsCard /> </Grid>
      } 
      {
        permissionCheck('booking-reports', 'view-clearance-certificate') &&
        <Grid item xs={12} sm={4}> <TotalClearanceCertificates /> </Grid>
      } 
      {
        permissionCheck('booking-reports', 'view-shipment-packing') &&
        <Grid item xs={12} sm={4}> <TotalShipmentPackingsCard /> </Grid>
      }       
      </Grid>
      <Grid container spacing={2} sx={{mt:1}}>
      {
        permissionCheck('app-reports', 'species-wise-imported') &&
        <Grid item xs={12} sm={4}>
          <SpeciesBookingsGraph />
        </Grid>
      }
      {
        permissionCheck('app-reports', 'bookings-status-count') &&
        <Grid item xs={12} sm={4}>
          <BookingStatusGraph />
        </Grid>
      }
      {
        permissionCheck('app-reports', 'lab-report-verification') &&
        <Grid item xs={12} sm={4}>
          <ReportVerificationGraph />
        </Grid>
      }
      </Grid>
          
      <Box sx={{mt:1}}>
      {
        permissionCheck('app-reports', 'daily-cubicle-stock-status') &&
        <DailyStockStatus />
      }
      </Box>
      
      <Box sx={{mt : 1}}>
      {
        permissionCheck('app-reports', 'top-hatchery-bookings') &&
        <TopHatcheriesCard />
      }
      </Box>
      <Box sx={{mt : 1}}>
      {
        permissionCheck('app-reports', 'top-hatchery-revenues') &&
        <TopRevenuesCard />
      }
      </Box>
      <Box sx={{mt : 1}}>
      {
        permissionCheck('booking-schedules', 'create') &&
        <CubicleStatusCard />
      }
      </Box>
    </Container>
  </Box>
);

export default AQFEmployee;