import React, { Component } from 'react';
import { Grid, Card, CardContent, CardHeader, Avatar, Typography, Divider} from '@mui/material';

import Carousel from 'react-material-ui-carousel';

import { PersonAddAltOutlined } from '@mui/icons-material';

// services
import { HatcheryStore } from 'store';
import { calculateAPISignature, obfuscateData } from 'services/utils';

export default class AccountInfoCard extends Component {
  render() {
    const hatcheryData = HatcheryStore.state.currentHatchery;
    const ownerProfile = hatcheryData ? hatcheryData.userProfile : null;
    const authPerson1 = hatcheryData ? hatcheryData.userProfile.authPerson1 : null;
    const authPerson2 = hatcheryData ? hatcheryData.userProfile.authPerson2 : null;
    
    const ownerSignature = ownerProfile && ownerProfile.picture
      ? calculateAPISignature(encodeURIComponent(ownerProfile.picture.replace((/ /g, '%20'))))
      : null;
    const auth1Signature = authPerson1 && authPerson1.picture
      ? calculateAPISignature(encodeURIComponent(authPerson1.picture.replace((/ /g, '%20'))))
      : null;
    const auth2Signature = authPerson2 && authPerson2.picture
      ? calculateAPISignature(encodeURIComponent(authPerson2.picture.replace((/ /g, '%20'))))
      : null;
    
    return (
      <Card>
        <CardHeader title= "Hatchery Account Information"/>
        <Divider />
        <CardContent>
          <Carousel indicators={true} animation="fade" interval={4000} autoPlay={false}>
            <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item key="acc-0" sm={3}>
                <Avatar src={process.env.PUBLIC_URL + '/static/images/misc/info.png'}
                  sx={{ height: 120, width: 120 }}
                  variant="square"
                />
              </Grid>
              <Grid item key="acc-1" sm={9}>
                <Typography color="textPrimary" gutterBottom variant="h3"> Hatchery Name : { hatcheryData.name } </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2"> Species Name : { hatcheryData.permittedSpeciesId.name}</Typography>
                <Typography color="textSecondary" gutterBottom variant="body2"> Species Type : { hatcheryData.permittedSpeciesId.type}</Typography>
                <Typography color="textSecondary" gutterBottom variant="body2"> Address : {hatcheryData.address}</Typography>
                <Typography color="textSecondary" gutterBottom variant="body2"> Email : {hatcheryData.email}</Typography>
                <Typography color="textSecondary" gutterBottom variant="body2"> Contact Number : {hatcheryData.contactNumber}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item key="acc-0" sm={3}>
              {
                ownerProfile &&
                <Avatar src={`${process.env.REACT_APP_STRAPI_FILE_URL}${ownerProfile.picture}?s=${ownerSignature}`}
                  sx={{ height: 120, width: 120 }}
                  variant="square"
                />
              }
              </Grid>
              <Grid item key="acc-1" sm={9}>
                <Typography color="textPrimary" gutterBottom variant="h3"> {ownerProfile ? ownerProfile.name : '-------'} </Typography>
                <Typography color="textSecondary" variant="body1">Profile Type :
                  {ownerProfile && ownerProfile.isHatcheryIncharge ? 'Hatchery Incharge' : 'Hatchery Owner'}
                </Typography>
                <Typography color="textSecondary" variant="body1">E-mail : {hatcheryData ? hatcheryData.email : '----'}</Typography>
                <Typography color="textSecondary" variant="body1">Mobile : {ownerProfile ? ownerProfile.phoneNumber : '----'}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item key="acc-0" sm={3}>
              {
                authPerson1 ?
                <Avatar src={`${process.env.REACT_APP_STRAPI_FILE_URL}${authPerson1.picture}?s=${auth1Signature}`}
                  sx={{ height: 120, width: 120 }}
                  variant="square"
                /> :
                <Avatar sx={{ height: 120, width: 120 }} variant="square"> <PersonAddAltOutlined /> </Avatar>
              }
              </Grid>
              <Grid item key="acc-1" sm={9}>
                <Typography color="textPrimary" gutterBottom variant="h3"> {authPerson1 ? authPerson1.name : '-------'} </Typography>
                <Typography color="textSecondary" variant="body1"> {'Profile Type : Authorized Person 1'}</Typography>
                <Typography color="textSecondary" variant="body1">E-mail : {authPerson1 ? authPerson1.email : '----'}</Typography>
                <Typography color="textSecondary" variant="body1">Mobile : {authPerson1 ? authPerson1.phoneNumber : '----'}</Typography>
                <Typography color="textSecondary" variant="body1">
                  Aadhaar No : {authPerson1 ? obfuscateData('aadhaar', authPerson1.aadhaarNumber) : '----'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item key="acc-0" sm={3}>
              {
                authPerson2 ?
                <Avatar src={`${process.env.REACT_APP_STRAPI_FILE_URL}${authPerson2.picture}?s=${auth2Signature}`}
                  sx={{ height: 120, width: 120 }}
                  variant="square"
                /> :
                <Avatar sx={{ height: 120, width: 120 }} variant="square"> <PersonAddAltOutlined /> </Avatar>
              }
              </Grid>
              <Grid item key="acc-1" sm={9}>
                <Typography color="textPrimary" gutterBottom variant="h3"> {authPerson2 ? authPerson2.name : '-------'} </Typography>
                <Typography color="textSecondary" variant="body1"> {'Profile Type : Authorized Person 2'}</Typography>
                <Typography color="textSecondary" variant="body1">E-mail : {authPerson2 ? authPerson2.email : '----'}</Typography>
                <Typography color="textSecondary" variant="body1">Mobile : {authPerson2 ? authPerson2.phoneNumber : '----'}</Typography>
                <Typography color="textSecondary" variant="body1">
                  Aadhaar No : {authPerson2 ? obfuscateData('aadhaar', authPerson2.aadhaarNumber) : '----'}
                </Typography>
              </Grid>
            </Grid>
          </Carousel>
        </CardContent>
      </Card>
    );
  }
}
